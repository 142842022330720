import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import storage from 'utils/storage';
import createComponent from 'styles/fela/createComponent';
import { BREAKPOINTS } from 'utils/constants';
import {
  Link,
  Notification,
  Text,
} from 'components';

const Container = createComponent(() => ({
  maxWidth: `${BREAKPOINTS.md}px`,
  textAlign: 'center',
  fontWeight: '600',
}));

const CookiePolicyNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    cookiePolicyUrl,
  } = useSelector(state => ({
    cookiePolicyUrl: state.settings.features?.cookiePolicy?.url,
  }));

  useEffect(() => {
    if (cookiePolicyUrl && !storage.getItem('cookiesNotificationShown')) {
      setIsVisible(true);
    }
  }, []);

  const close = () => {
    storage.setItem('cookiesNotificationShown', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Notification onClose={close} bottom fullWidth>
      <Container data-nosnippet>
        <Text
          id="cookiePolicy.message"
          values={{
            link: (
              <Link
                href={cookiePolicyUrl}
                target="_blank"
                id="cookiePolicy.linkMessage"
                key="cookiePolicy"
              />
            ),
          }}
        />
      </Container>
    </Notification>
  );
};

export default CookiePolicyNotification;
