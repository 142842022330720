import React from 'react';
import { RouterLocation } from 'router';
import {
  Container,
  IconContainer,
} from './Styles';

type InfoButtonProps = {
  to: RouterLocation,
};

function InfoButton(props: InfoButtonProps) {
  const { to, ...restProps } = props;

  return (
    <Container
      to={to}
      data-tooltip-id="main"
      data-tooltip-i18n="tooltip.moreInfo"
      {...restProps}
    >
      <IconContainer>
        <svg width="100%" height="100%" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="23.0052" cy="23.0052" r="22.0052" strokeWidth="2" />
          <path d="M22.9588 33C23.4381 33 23.846 32.8301 24.1826 32.4902C24.5192 32.1503 24.6875 31.7338 24.6875 31.2408V22.3192C24.6875 21.8486 24.5169 21.4434 24.1756 21.1038C23.8344 20.7642 23.4242 20.5944 22.9449 20.5944C22.4656 20.5944 22.0556 20.7642 21.7147 21.1038C21.374 21.4434 21.2036 21.8486 21.2036 22.3192V31.2408C21.2036 31.7338 21.3763 32.1503 21.7217 32.4902C22.0672 32.8301 22.4795 33 22.9588 33ZM22.8267 16.669C23.3443 16.669 23.7807 16.4966 24.1361 16.1517C24.4913 15.8069 24.669 15.3796 24.669 14.8698C24.669 14.3148 24.4941 13.8644 24.1444 13.5186C23.7946 13.1729 23.3612 13 22.8441 13C22.2936 13 21.8486 13.1705 21.5092 13.5116C21.1697 13.8526 21 14.2919 21 14.8294C21 15.355 21.1751 15.793 21.5252 16.1434C21.8753 16.4938 22.3092 16.669 22.8267 16.669Z" />
        </svg>
      </IconContainer>
    </Container>
  );
}

export default React.memo(InfoButton);
