import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { LINK_INTERFACE_TYPES } from 'utils/constants';
import {
  Image,
  imageTypes,
} from 'components/Image/Image';
import CleanLink from 'components/StyledSystem/CleanLink';

const Link = createComponent(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  display: 'block',
  '::before': {
    position: 'absolute',
    content: '""',
    display: 'block',
    height: '100%',
    width: '100%',
    borderRadius: theme.radius.poster,
  },
}), CleanLink);

const GenreTitle = createComponent(({ theme, isTextSmall }) => ({
  color: theme.color.genreTitle,
  cursor: 'pointer',
  fontSize: (isTextSmall ? theme.fontSize.medium : theme.fontSize.pageHeading),
  fontWeight: 'bold',
  letterSpacing: '2.7px',
  textAlign: 'center',
  filter: `drop-shadow(${theme.textShadow.genreCard})`,
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
}), 'div', ['isTextSmall']);

export const genreShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  __typename: PropTypes.oneOf(Object.values(LINK_INTERFACE_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
});

const GenreCard = ({ className, genre }) => {
  // number of chars in title should not be larger than number of rems
  const maxTitleLength = imageTypes.genreCard.width;
  const isTextSmall = genre.title?.length > maxTitleLength;

  let routeName = 'collection';
  if (genre.__typename === LINK_INTERFACE_TYPES.ViewLink) {
    routeName = 'collections';
  }

  return (
    <Link
      className={className}
      to={{ name: routeName, params: { id: genre.id } }}
    >
      <Image
        alt={genre.title}
        hasShadow
        withMarginBottom
        src={genre.image}
        type="genreCard"
        rounded
        data-cy="genre-card"
      />
      <GenreTitle isTextSmall={isTextSmall}>{genre.title}</GenreTitle>
    </Link>
  );
};

GenreCard.propTypes = {
  genre: genreShape.isRequired,
  className: PropTypes.string,
};

export default GenreCard;
