import React from 'react';
import PropTypes from 'prop-types';
import { useFela } from 'react-fela';
import { Icon } from 'components';

export default function GridIcon({ selected, ...props }) {
  const { theme } = useFela();
  const [hover, setHover] = React.useState(false);

  const active = selected || hover;

  return (
    <Icon
      {...props}
      viewBox="0 0 39 27"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-cy="grid-icon"
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect width="38" height="26" x="-.5" y="-.5" stroke={theme.color.brand} rx="3" fill={active ? theme.color.brand : 'none'} />
        <g fill={active ? theme.color.typeSwitchColor : theme.color.brand} transform="translate(3 3)">
          <rect width="8" height="8.444" rx="1" />
          <rect width="8" height="8.444" x="11" rx="1" />
          <rect width="8" height="8.444" y="10.556" rx="1" />
          <rect width="8" height="8.444" x="11" y="10.556" rx="1" />
          <rect width="8" height="8.444" x="22" rx="1" />
          <rect width="8" height="8.444" x="22" y="10.556" rx="1" />
        </g>
      </g>
    </Icon>
  );
}
GridIcon.propTypes = {
  selected: PropTypes.bool.isRequired,
};
