import createComponent from 'styles/fela/createComponent';

const Spacer = createComponent(({ theme, height, width, minWidth, flexGrow } = {}) => ({
  width: '1px',
  height: '1px',

  extend: [
    {
      condition: height,
      style: {
        height: theme.margin[height] || height,
      },
    },
    {
      condition: width,
      style: {
        width: theme.margin[width] || width,
      },
    },
    {
      condition: minWidth,
      style: {
        minWidth: theme.margin[minWidth] || minWidth,
      },
    },
    {
      condition: flexGrow,
      style: {
        flexGrow: 1,
      },
    },
  ],
}), 'div', ['height', 'width', 'minWidth', 'flexGrow'], true);

Spacer.displayName = 'Spacer';

export default Spacer;
