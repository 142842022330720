import React from 'react';
import Icon from './Icon';

export default function HideEyeIcon(props) {
  return (
    <Icon width="32" height="32" {...props} viewBox="0 0 32 32" fill="currentColor" data-cy="hide-eye-icon">
      <path d="M16.0001 10.9583C18.5301 10.9583 20.5834 13.0117 20.5834 15.5417C20.5834 16.0092 20.4917 16.4583 20.3634 16.88L23.1684 19.685C24.4426 18.5575 25.4509 17.1458 26.0834 15.5325C24.4976 11.5175 20.5834 8.66667 16.0001 8.66667C14.8359 8.66667 13.7176 8.85 12.6634 9.18917L14.6526 11.1783C15.0834 11.05 15.5326 10.9583 16.0001 10.9583ZM7.48425 7.89667C7.12675 8.25417 7.12675 8.83167 7.48425 9.18917L9.29008 10.995C7.80508 12.1775 6.62258 13.7358 5.91675 15.5417C7.50258 19.5658 11.4167 22.4167 16.0001 22.4167C17.3934 22.4167 18.7226 22.1417 19.9509 21.665L22.4443 24.1583C22.8018 24.5158 23.3793 24.5158 23.7368 24.1583C24.0943 23.8008 24.0943 23.2233 23.7368 22.8658L8.78592 7.89667C8.42842 7.53917 7.84175 7.53917 7.48425 7.89667ZM16.0001 20.125C13.4701 20.125 11.4167 18.0717 11.4167 15.5417C11.4167 14.8358 11.5817 14.1667 11.8659 13.58L13.3051 15.0192C13.2776 15.1842 13.2501 15.3583 13.2501 15.5417C13.2501 17.0633 14.4784 18.2917 16.0001 18.2917C16.1834 18.2917 16.3484 18.2642 16.5226 18.2275L17.9617 19.6667C17.3659 19.96 16.7059 20.125 16.0001 20.125ZM18.7226 15.2392C18.5851 13.9558 17.5767 12.9567 16.3026 12.8192L18.7226 15.2392Z" />
    </Icon>
  );
}
