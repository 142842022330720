import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TRemoveFromWatchlistMutationVariables = Types.Exact<{
  viewableId: Types.Scalars['String'];
}>;


export type TRemoveFromWatchlistMutation = { __typename?: 'Mutation', removeFromMyList?: { __typename?: 'RemoveFromMyListPayload', viewableId?: string | null } | null };


export const RemoveFromWatchlistDocument = gql`
    mutation removeFromWatchlist($viewableId: String!) {
  removeFromMyList(input: {viewableId: $viewableId, clientMutationId: ""}) {
    viewableId
  }
}
    `;
export type TRemoveFromWatchlistMutationFn = Apollo.MutationFunction<TRemoveFromWatchlistMutation, TRemoveFromWatchlistMutationVariables>;

/**
 * __useRemoveFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWatchlistMutation, { data, loading, error }] = useRemoveFromWatchlistMutation({
 *   variables: {
 *      viewableId: // value for 'viewableId'
 *   },
 * });
 */
export function useRemoveFromWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<TRemoveFromWatchlistMutation, TRemoveFromWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TRemoveFromWatchlistMutation, TRemoveFromWatchlistMutationVariables>(RemoveFromWatchlistDocument, options);
      }
export type RemoveFromWatchlistMutationHookResult = ReturnType<typeof useRemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationResult = Apollo.MutationResult<TRemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationOptions = Apollo.BaseMutationOptions<TRemoveFromWatchlistMutation, TRemoveFromWatchlistMutationVariables>;