import React from 'react';
import { promoShape } from 'components/CollectionUI/prop-types';
import { colorWithOpacity } from 'utils/helpers';
import { Hero } from 'components';
import { useCollectionUIStyles } from 'utils/hooks';
import { Content } from 'views/ContentListView/Featured/Styles';
import MetadataContainer from './MetadataContainer';
import MetaDataPromo from './MetaDataPromo';

function FeaturedPromo({ collection }) {
  const { blockUI, title, description, ctaTarget, ctaLabel, ctaValue, ctaValueType } = collection;
  const BlockUIStyles = useCollectionUIStyles(blockUI);

  const {
    containerAlignment,
    metadataContainerColor,
    metadataContainerOpacity,
    blockLayoverColor,
    blockLayoverOpacity,
  } = blockUI || {};

  if (!collection.image) {
    return null;
  }

  const blockOverLay = blockUI ? colorWithOpacity(
    blockLayoverColor, blockLayoverOpacity,
  ) : undefined;
  const metadataOverlay = blockUI?.metadataContainerOpacity ? colorWithOpacity(
    metadataContainerColor, metadataContainerOpacity,
  ) : undefined;

  const showBlur = blockUI?.metadataContainerOpacity > 0;

  return (
    <Hero
      src={collection.image}
      imageSize={blockUI?.blockSize}
      imagePosition={blockUI?.heroImageVerticalAlignment}
      overlay={blockOverLay}
      className="e2e-featured-promo"
    >

      {(BlockUIStyles?.layoutObjects.length) ? (
        <Content
          column
          alignItems="flex-start"
          ml={containerAlignment === 'right' ? 'auto' : undefined}
          mr={containerAlignment === 'left' ? 'auto' : undefined}
          mx={containerAlignment === 'center' ? 'auto' : undefined}
        >

          <MetadataContainer
            showBlur={showBlur}
            overlay={metadataOverlay}
            layoutObjects={BlockUIStyles.layoutObjects}
            viewableId={collection.id}
          >
            {/* Create a Component to render the metaData */}
            {BlockUIStyles.layoutObjects.map((blockItem, index) => (
              <MetaDataPromo
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                blockItem={blockItem}
                title={title}
                description={description}
                ctaTarget={ctaTarget}
                ctaLabel={ctaLabel}
                ctaValue={ctaValue}
                ctaValueType={ctaValueType}
              />
            ))}
          </MetadataContainer>
        </Content>
      ) : null}
    </Hero>
  );
}

FeaturedPromo.propTypes = {
  collection: promoShape.isRequired,
};

export default React.memo(FeaturedPromo);
