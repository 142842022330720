import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  ResetPinLink,
  ResetPinModal,
  ErrorMessage,
  Box,
  Text,
  Heading,
} from 'components';
import { ToggleSwitch } from 'components/Forms';
import GET_PARENTAL_CONTROL_DETAILS from './queries/getParentalControlDetails.gql';
import ENABLE_PARENTAL_CONTROL from './queries/enableParentalControl.gql';
import DisablePINModal from './DisablePINModal';

function ParentalControlSection() {
  const [showDisablePINModal, setShowDisablePINModal] = React.useState(false);
  const [showResetPINModal, setShowResetPINModal] = React.useState(false);

  const {
    data: parentalControlData,
    loading,
  } = useQuery(GET_PARENTAL_CONTROL_DETAILS);

  const [
    enableParentalControlMutation,
    {
      error: enableParentalControlError,
    },
  ] = useMutation(ENABLE_PARENTAL_CONTROL, {
    refetchQueries: [{ query: GET_PARENTAL_CONTROL_DETAILS }],

    onError() {}, // suppress promise rejection in mutation
  });

  if (loading && !parentalControlData) {
    return null;
  }

  const {
    user: {
      canEditParentalControl,
      hasActiveParentalControl,
      hasParentalControlPinCode,
    },
  } = parentalControlData;

  const onSwitchHandler = () => {
    if (hasActiveParentalControl) {
      setShowDisablePINModal(true);
      return;
    }

    if (hasParentalControlPinCode) {
      enableParentalControlMutation();
      return;
    }

    setShowResetPINModal(true);
  };

  const onPINReset = () => {
    setShowResetPINModal(false);
    enableParentalControlMutation();
  };

  return (
    <>
      <Heading
        id="appSettings.parentalControlHeading"
        fontSize="sectionHeading"
        mb="medium"
      />

      <Box mb="medium">
        <Text
          id="appSettings.parentalControlMessage"
          whiteSpace="pre-wrap"
          color="secondary"
          fontSize="small"
        />
      </Box>

      {(hasActiveParentalControl || !canEditParentalControl) && (
        <Box mb="medium">
          <ResetPinLink />
        </Box>
      )}

      {canEditParentalControl && (
        <Box mb="medium">
          {showDisablePINModal && (
            <DisablePINModal
              onClose={() => setShowDisablePINModal(false)}
            />
          )}

          {showResetPINModal && (
            <ResetPinModal
              preventPinReset
              onSuccess={onPINReset}
              onClose={() => setShowResetPINModal(false)}
            />
          )}

          <Box
            flexBox
            wrap
            alignItems="center"
            mb="small"
          >
            <Text
              id="appSettings.activationParentalControl"
              bold
              color="secondary"
            />

            <ToggleSwitch
              checked={hasActiveParentalControl}
              onSwitchHandler={onSwitchHandler}
            />
          </Box>

          {enableParentalControlError && (
            <ErrorMessage id="appSettings.enableParentalControlError" />
          )}
        </Box>
      )}
    </>
  );
}

export default React.memo(ParentalControlSection);
