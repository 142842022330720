import React from 'react';
import { useSelector } from 'reducers';
import logger from 'utils/logger';
import { WATCHLIST_TYPES } from 'utils/constants';
import Text from 'components/StyledSystem/Text';
import Spacer from 'components/StyledSystem/Spacer';
import { ViewableType } from 'types';
import {
  Container,
  IconContainer,
  StyledCircleIcon,
  StyledPlusIcon,
  StyledCheckIcon,
  StyledCheckIconContainer,
} from './Styles';
import { setInMyList, useAddToWatchlistMutation, useRemoveFromWatchlistMutation } from './actions';

type WatchlistButtonProps = {
  viewable: ViewableType,
  refreshData: () => Promise<void>,
  onClick: () => void,
  short?: boolean,
  big?: boolean,
};

function WatchlistButton({
  viewable,
  refreshData,
  short,
  big,
  onClick,
  ...restProps
}: WatchlistButtonProps) {
  const watchlistEnabled = useSelector(({ settings }) => !!settings.features.watchList);
  const [addToWatchlist, addToWatchListResult] = useAddToWatchlistMutation({
    update(cache) {
      setInMyList(cache, viewable, true);
    },
  });
  const [removeFromWatchlist, removeFromWatchListResult] = useRemoveFromWatchlistMutation({
    update(cache) {
      setInMyList(cache, viewable, false);
    },
  });
  const inProgress = addToWatchListResult?.loading || removeFromWatchListResult?.loading;

  const toggleWatchlist = async () => {
    if (inProgress) {
      return;
    }

    onClick?.();

    try {
      if (viewable.inMyList) {
        await removeFromWatchlist({ variables: { viewableId: viewable.id } });
      } else {
        await addToWatchlist({ variables: { viewableId: viewable.id } });
      }

      await refreshData();
    } catch (e) {
      logger.error('WATCHLIST FAILED:', e);
    }
  };

  const onClickHandler = (e: MouseEvent) => {
    void toggleWatchlist();
    e.stopPropagation();
    e.preventDefault();
  };

  const isWatchlistSupported = WATCHLIST_TYPES.includes(viewable.__typename);

  // server returns null for inMyList property for non-authorized users
  if (!watchlistEnabled || typeof viewable.inMyList !== 'boolean' || !isWatchlistSupported) {
    return null;
  }

  return (
    <Container
      onClick={onClickHandler}
      inProgress={inProgress}
      short={short}
      {...(short ? {
        'data-tooltip-id': 'main',
        'data-tooltip-content': viewable.inMyList ? 'watchlist.remove' : 'watchlist.add',
        'data-tooltip-i18n': viewable.inMyList ? 'watchlist.remove' : 'watchlist.add',
      } : null)}
      {...restProps}
    >
      <IconContainer big={big}>
        <StyledCircleIcon />
        {viewable.inMyList
          ? (
            <StyledCheckIconContainer>
              <StyledCheckIcon
                inMyList={viewable.inMyList}
                inProgress={inProgress}
              />
            </StyledCheckIconContainer>
          ) : (
            <StyledPlusIcon
              inMyList={viewable.inMyList}
              inProgress={inProgress}
            />
          )}
      </IconContainer>

      {!short && (
        <>
          <Spacer width="medium" />
          <Text
            fontSize="small"
            id={viewable.inMyList ? 'watchlist.remove' : 'watchlist.add'}
          />
        </>
      )}
    </Container>
  );
}

export default React.memo(WatchlistButton);
