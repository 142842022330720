import React from 'react';
import { OFFER_TYPES, DATE_FORMAT } from 'utils/constants';
import { formatEntitlementDuration } from 'utils/time';
import { Text } from 'components';
import { useI18n } from 'components/I18n';
import { TTransaction } from './types';

interface ITransactionPeriodProps {
  transaction: TTransaction;
}

export default function TransactionPeriod({ transaction }: ITransactionPeriodProps): JSX.Element {
  const i18n = useI18n();

  switch (transaction.offerV3?.__typename) {
    case OFFER_TYPES.RentType: {
      return (
        <Text
          id="entitlement.duration"
          values={formatEntitlementDuration(transaction.offerV3.entitlementDurationSec)}
        />
      );
    }

    case OFFER_TYPES.BuyType: {
      return <Text id="entitlement.bought" />;
    }

    default: {
      return (
        <Text
          id="transaction.datePeriod"
          values={{
            start: i18n.formatDate(transaction.fromDate, DATE_FORMAT),
            end: i18n.formatDate(transaction.toDate, DATE_FORMAT),
          }}
        />
      );
    }
  }
}
