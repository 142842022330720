import React from 'react';
import Icon from './Icon';

/**
 * https://material.io/icons/#ic_check
 */
export default function CheckIcon(props) {
  return (
    <Icon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </Icon>
  );
}
