import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ieInputHideClearIcon } from 'styles/fela/mixins';
import createComponent from 'styles/fela/createComponent';
import { toMessage } from 'components/Forms/utils';
import {
  InputErrorMessage,
} from 'components/Forms/Styles';
import { useShareForwardedRef } from 'utils/hooks';

const StyledInput = createComponent(({ theme, fixedWidth }) => ({
  display: 'block',
  width: '100%', // if flex child
  height: '3.5rem',

  fontSize: theme.fontSize.smedium,
  color: theme.color.inputColor,
  border: 'none',
  borderRadius: theme.radius.default,
  padding: `${theme.margin.small} ${theme.margin.medium}`,

  ':focus': {
    outline: 'none',
  },
  ':-webkit-autofill': {
    '-webkit-box-shadow': `0 0 0 34px ${theme.color.inputBackground} inset !important`,
  },

  extend: [
    ieInputHideClearIcon(),
    {
      condition: fixedWidth,
      style: {
        width: theme.size.fixedWidthButton,
      },
    },
  ],
}), 'input', ['fixedWidth']);

const intlMsgShape = PropTypes.shape({
  id: PropTypes.string,
  values: PropTypes.object,
});

const Input = React.forwardRef((props, ref) => {
  const {
    placeholder,
    type,
    fixedWidth,
    error,
    dirty,
    touched,
    isSubmitted,
    value,
    name,
  } = props;
  const inputRef = useShareForwardedRef(ref);

  useEffect(() => {
    const input = inputRef.current;
    const { autoFocus } = props;
    if (autoFocus) {
      input.focus();

      // put cursor at the end of the input
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }, []);

  const showErrorMessage = error && ((dirty && touched) || isSubmitted);

  const onChange = (e) => {
    if (typeof props.normalize === 'function') {
      e.target.value = props.normalize(e.target.value);
    }

    if (typeof props.onChange === 'function') {
      return props.onChange(e);
    }

    return true;
  };

  return (
    <>
      <StyledInput
        innerRef={inputRef}
        type={type}
        placeholder={placeholder}
        fixedWidth={fixedWidth}
        defaultValue={value}
        onChange={onChange}
        name={name}
      />
      <InputErrorMessage
        isVisible={showErrorMessage}
        fixedWidth={fixedWidth}
      >
        {toMessage(error) || 'empty' /* fallback to preserve space */}
      </InputErrorMessage>
    </>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, intlMsgShape]),
  autoFocus: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  error: PropTypes.object,
  dirty: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
};

export default Input;
