import React from 'react';
import Text from 'components/StyledSystem/Text';
import Link from 'components/StyledSystem/Link';

type Props = {
  consentText: string,
  url: string,
  urlLabel: string,
};

const PrivacyPolicyLink = ({ consentText, url, urlLabel }: Props): React.ReactElement => (
  <Text fontSize="fine">
    {consentText}{' '}<Link href={url} target="_blank">{urlLabel}</Link>.
  </Text>
);

export default React.memo(PrivacyPolicyLink);
