import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { Box, Tabs } from 'components';
import { I18nContext } from 'components/I18n';
import { flattenEdges } from 'utils/helpers';
import WebviewTab from './WebviewTab';
import {
  defaultOrder,
  getAboutTab,
  getLinkedCollectionsTabs,
  getRelatedCollectionTabs,
  getSeasonsTabs,
  TAB_NAMES,
} from './tools';

function ContentTabs({ viewable, order }) {
  const i18n = useContext(I18nContext);

  let defaultWebviewTab = null;
  const webviews = (viewable.webviews || [])
    .map((webview) => {
      const webviewTab = (
        <Box
          key={webview.webviewTitle}
          label={webview.webviewTitle}
        >
          <WebviewTab
            viewableId={viewable.id}
            webview={webview}
          />
        </Box>
      );
      if (webview.default && !defaultWebviewTab) {
        defaultWebviewTab = webviewTab;
      }

      return webviewTab;
    });

  const collections = viewable.collections?.map(collection => (
    { ...collection, viewables: flattenEdges(collection.viewables) }
  ));

  // Add and sort tabs. Depends on settings if provided
  const tabs = (order || defaultOrder).map((tab) => {
    switch (tab) {
      case TAB_NAMES.seasons: return getSeasonsTabs(viewable, i18n);
      case TAB_NAMES.linked: return getLinkedCollectionsTabs(collections, i18n);
      case TAB_NAMES.related: return getRelatedCollectionTabs(viewable.related, i18n);
      case TAB_NAMES.widgets: return webviews;
      case TAB_NAMES.about: return getAboutTab(viewable, i18n);
      default: return null;
    }
  }).flat().filter(t => t);

  const initialSelectedIndex = defaultWebviewTab ? tabs.indexOf(defaultWebviewTab) : 0;

  return (
    <Tabs
      type="material2"
      verticalShift
      pageId={`watch-${viewable.id}`}
      id="tab"
      initialSelectedIndex={initialSelectedIndex}
    >
      {tabs}
    </Tabs>
  );
}

ContentTabs.propTypes = {
  viewable: viewableShape.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(ContentTabs);
