import createComponent from 'styles/fela/createComponent';

export const StyledCheckbox = createComponent(({ theme, checked }) => ({
  border: `2px solid ${theme.color.primary}`,
  borderRadius: '2px',
  cursor: 'pointer',
  height: '1.5rem',
  width: '1.5rem',
  transition: 'background-color 100ms linear, border-color 100ms linear',
  flexShrink: '0',
  extend: [
    {
      condition: checked,
      style: {
        backgroundColor: theme.color.brand,
        borderColor: theme.color.brand,
        color: theme.color.light, // CheckmarkIcon color
      },
    },
  ],
}), 'div', ['checked']);

export const Wrapper = createComponent(() => ({
  textAlign: 'left',
  width: '100%',
}));
