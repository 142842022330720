/* eslint-disable max-depth */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import Cookies from 'js-cookie';
import * as authActions from 'actions/auth';
import * as userActions from 'actions/user';
import { addMessage } from 'actions/messages';
import * as ApiErrors from '@tvoli/js-api-client/src/errors';
import { useI18n } from 'components/I18n';
import logger from 'utils/logger';
import {
  PrimaryButton,
  FormattedError,
  Box,
  Text,
  Heading,
  TermsAndConditionsLink,
  PrivacyPolicyLink,
  PanelContent,
  PanelWrapper,
  Panel,
} from 'components';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import {
  InputFields,
  TextField,
} from 'components/Forms';
import { COOKIES } from 'utils/constants';

function CreatePasswordView() {
  const i18n = useI18n();
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(false);
  const [generalError, setGeneralError] = useState(null);

  const [mounted, setMounted] = useState(false);
  const [identity, setIdentity] = useState('');

  useEffect(() => {
    const emailFromCookies = Cookies.get(COOKIES.CREATE_PASS_EMAIL);
    Cookies.remove(COOKIES.CREATE_PASS_EMAIL);

    setIdentity(emailFromCookies);
    setMounted(true);
  }, []);

  const {
    token,
    initialValues,
    createPasswordMethod,
  } = useSelector(state => ({
    token: state.router.location.params.token,
    initialValues: {
      emailOptIn: state.settings.features?.newsletter?.isDefaultOptIn || false,
    },
    createPasswordMethod: state.settings.features.createPasswordMethod,
  }), shallowEqual);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { handleSubmit, setError } = methods;

  if (!mounted) {
    return null;
  }

  if (!identity) {
    return (
      <ErrorPage buttonType="none">
        <Text id="createPassword.errLinkExpired" />
      </ErrorPage>
    );
  }

  const submit = async (fields) => {
    setInProgress(true);

    try {
      await dispatch(authActions.resetPassword(token, fields.accessKey));
    } catch (err) {
      // stop spinner only if password reset failed
      setInProgress(false);

      if (err instanceof ApiErrors.InvalidInputError) {
        return setGeneralError('createPassword.errLinkExpired');
      }

      logger.error('Failed to reset the password', err);

      return setGeneralError('couldntSave');
    }

    try {
      await dispatch(authActions.login({
        identity,
        accessKey: fields.accessKey,
      }));
    } catch (e) {
      // stop spinner only if login failed
      setInProgress(false);

      if (e.response?.status === 401 || e.message?.includes('401')) {
        return setGeneralError(i18n.formatText('login.errBadCredentials'));
      }

      if (e.response?.status === 400) {
        for (const validationError of (await e.response.json()).errors) {
          setError(validationError.field, {
            type: 'server',
            message: validationError.reason,
          });
        }
      }
      logger.error('Failed to login using password', e);

      return setGeneralError(i18n.formatText('login.errLoginFailed'));
    }

    const userSettings = {};

    const userTagFields = [];
    for (const { inputFields } of createPasswordMethod.inputGroups) {
      for (const { isTag, key } of inputFields) {
        // save input with prop `isTag` value as user tag, usually for `picker` type
        // NB: make sure that all possible values are whitelisted
        if (isTag) {
          userTagFields.push(fields[key]);
          continue;
        }

        if (key === 'accessKey') {
          continue;
        }

        userSettings[key] = fields[key];
      }
    }

    if (Object.keys(userSettings).length) {
      try {
        await dispatch(userActions.updateSettings(userSettings));
      } catch (err) {
        logger.error('Failed to update user settings', err);
      }
    }

    if (userTagFields.length) {
      try {
        await dispatch(userActions.addUserTags(userTagFields));
      } catch (e) {
        logger.error(`Unable to save tags "${userTagFields}"`, e);
      }
    }

    try {
      await dispatch(userActions.getUser());
      await dispatch(authActions.openInitialView());
    } catch (e) {
      dispatch(addMessage({ contentId: 'failedMessage' }));
      logger.error('Failed to get user', e);
    }

    return null;
  };

  return (
    <PanelWrapper>
      <Panel textAlign="center">
        <Heading mb="small">
          {createPasswordMethod.inputGroups[0].title}
        </Heading>

        <Heading fontSize="medium" mb="medium" as="h2">
          {createPasswordMethod.inputGroups[0].subtitle}
        </Heading>

        <FormProvider {...methods}>
          <PanelContent
            as="form"
            onSubmit={handleSubmit(submit)}
            method="POST"
            noValidate
          >
            <Box
              column
              alignItems="flex-start"
            >
              <TextField
                type="text"
                disabled
                name="identity"
                placeholder={i18n.formatText('email')}
                defaultValue={identity}
              />

              <InputFields
                inputGroups={createPasswordMethod.inputGroups}
              />

              {generalError && (
                <FormattedError error={generalError} />
              )}
            </Box>

            <Box row my="medium" justifyContent="center">
              <PrimaryButton variant="brand" type="submit" showSpinner={inProgress} disabled={inProgress}>
                {createPasswordMethod.doneLabel}
              </PrimaryButton>
            </Box>

            <Box mb="fine">
              <TermsAndConditionsLink />

              <PrivacyPolicyLink />
            </Box>
          </PanelContent>
        </FormProvider>
      </Panel>
    </PanelWrapper>
  );
}

export default React.memo(CreatePasswordView);
