import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { PlayerContext } from 'components/Player';

export const PreviewPlayerContext = React.createContext({
  muted: true,
  toggleMute: () => {},
  pauseMiniPlayer: () => {},
});

// pause mini-player only if PreviewPlayer is muted
const pauseMiniPlayer = (muted, playerContext) => {
  if (!muted && playerContext.viewableId) {
    playerContext.pause();
  }
};

export default function PreviewPlayerProvider({ children }) {
  const playerContext = useContext(PlayerContext);
  // Muted by default. Cannot be in storage see https://developer.chrome.com/blog/autoplay/
  const [muted, setMuted] = useState(true);
  const pauseMiniPlayerRef = useRef();
  const toggleMute = useRef();

  pauseMiniPlayerRef.current = () => {
    pauseMiniPlayer(muted, playerContext);
  };

  toggleMute.current = () => {
    setMuted(!muted);
    pauseMiniPlayer(!muted, playerContext);
  };

  const state = {
    muted,
    toggleMute: () => toggleMute.current(),
    pauseMiniPlayer: () => pauseMiniPlayerRef.current(),
  };

  return (
    <PreviewPlayerContext.Provider value={state}>
      {children}
    </PreviewPlayerContext.Provider>
  );
}

PreviewPlayerProvider.propTypes = {
  children: PropTypes.node,
};
