import PropTypes from 'prop-types';

export const routerShape = PropTypes.shape({
  setRoutes: PropTypes.func.isRequired,
  getUrl: PropTypes.func.isRequired,
  getActiveRoute: PropTypes.func.isRequired,
  resolve: PropTypes.func.isRequired,
});

export const locationShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  params: PropTypes.object,
  query: PropTypes.object,
});
