import createComponent from 'styles/fela/createComponent';
import {
  Box,
  Link,
} from 'components';
import { withBasePadding } from 'styles/fela/mixins';

export const FooterContainer = createComponent(({ theme }) => ({
  background: theme.color.page,
  borderTop: `1px solid ${theme.color.divider}`,
  paddingBottom: theme.margin.medium,
  paddingTop: theme.margin.large,
  textAlign: 'center',
  width: '100%',
  extend: [
    withBasePadding(),
  ],
}), 'footer');

export const FooterLink = createComponent(({ theme }) => ({
  ':not(:first-of-type)': {
    borderLeft: `1px solid ${theme.color.secondary}`,
  },
  color: theme.color.link,
  fontSize: theme.fontSize.small,
  padding: `0 ${theme.margin.small}`,
}), Link);

export const FooterImageWrapper = createComponent(() => ({
  '& div + div': {
    margin: '0 5px',
  },
  '& div:first-of-type': {
    margin: '0 5px 0 0',
  },
  '& div:last-of-type': {
    margin: '0 0 0 5px',
  },
  '& div:only-of-type': {
    margin: 0,
  },
}), Box, []);
export const FooterImageCont = createComponent(({ width, height }) => ({
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'inline-block',
  width: width || 'auto',
  height: height || 'auto',
}), 'div', [
  'width',
  'height',
]);

export const FooterImage = createComponent(({ width, height }) => ({
  maxHeight: '100%',
  maxWidth: '100%',
  width: width || 'auto',
  height: height || 'auto',
}), 'img', [
  'width',
  'height',
]);
