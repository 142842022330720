import createComponent from 'styles/fela/createComponent';
import { Text } from 'components';

export const CollectionText = createComponent(({ theme }) => ({
  fontWeight: '600',
  fontSize: theme.fontSize.sectionHeading,
  color: theme.color.primary,
  marginBottom: theme.margin.medium,

  extend: [
    {
      condition: theme.collectionTitle?.style,
      style: {
        fontStyle: theme.collectionTitle?.style,
      },
    },
    {
      condition: theme.collectionTitle?.weight,
      style: {
        fontWeight: theme.collectionTitle?.weight,
      },
    },
  ],
}), Text);
