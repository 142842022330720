import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, PrimaryButton, Text, Spacer } from 'components';
import createComponent from 'styles/fela/createComponent';
import { colorWithOpacity } from 'utils/helpers';
import Cookies from 'js-cookie';
import { API_URL, DEVEL_HOST, LOCAL_HOST } from 'server/middlewares/constants';
import { useRouter } from 'router';
import { sessionStorage } from 'utils/storage';
import { useApolloClient } from '@apollo/client';

const AppInfoTriangle = createComponent(({ theme, show }) => ({

  color: theme.color.light,
  backgroundColor: colorWithOpacity(theme.header.backgroundColor, 50),

  display: 'flex',
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: 9000,

  cursor: 'pointer',

  padding: '0.5rem',

  extend: [
    {
      condition: show === 'true',
      style: {
        color: theme.header.textColor,
        backgroundColor: colorWithOpacity(theme.header.backgroundColor, 90),
      },
    },
  ],

  '& hr': {
    marginBlockStart: '0.2em',
    marginBlockEnd: '0.5em',
  },
}), 'div');

const StyledSelect = createComponent(() => ({
  flexGrow: 1,
}), 'select');

const AppInfo = () => {
  const {
    apiBaseUri,
    app,
  } = useSelector(({ settings }) => ({
    apiBaseUri: settings.apiBaseUri,
    app: settings.app,
  }));

  const router = useRouter();
  const location = (new URL(router.getBaseUrl()));

  const apolloClient = useApolloClient();

  const [show, setShow] = useState(false);
  const [magineApiCookie, setMagineApiCookie] = useState(() => {
    const cookie = Cookies.get('-magine-api');
    let value = '';
    if (cookie) {
      value = cookie;
    } else if (apiBaseUri.startsWith(API_URL.develop)) {
      value = '';
    } else if (apiBaseUri.startsWith(API_URL.integration)) {
      value = 'test';
    }
    return value;
  });

  const toggleShow = () => {
    setShow(!show);
  };

  const handleMagineApiCookieClick = (e) => {
    e.stopPropagation();
  };

  const handleMagineApiCookieChange = (e) => {
    e.stopPropagation();
    setMagineApiCookie(e.target.value);
  };

  const reloadPage = () => {
    apolloClient.clearStore().then(() => {
      window.location.reload();
    });
  };

  const handleMagineApiCookieSetClick = (e) => {
    e.stopPropagation();
    const origCookie = Cookies.get('-magine-api');
    if (magineApiCookie) {
      Cookies.set('-magine-api', magineApiCookie);
    } else {
      Cookies.remove('-magine-api');
    }
    if (origCookie !== magineApiCookie) {
      sessionStorage.setItem('api-change', true);
      reloadPage();
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem('api-change') && Cookies.get('-magine-api')) {
      Cookies.remove('-magine-api');
      reloadPage();
    } else if (sessionStorage.getItem('api-change') && !Cookies.get('-magine-api')) {
      sessionStorage.removeItem('api-change');
    }
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = () => {
      if (!sessionStorage.getItem('api-change')) {
        Cookies.remove('-magine-api');
      }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  });

  if (!show) {
    return (
      <AppInfoTriangle show={`${show}`} onClick={toggleShow}>
        <Text bold fontSize="medium">
          &#x24D8;
        </Text>
      </AppInfoTriangle>

    );
  }
  return (
    <AppInfoTriangle show={`${show}`} onClick={toggleShow}>
      <Box mr="fine">
        <Text bold fontSize="medium">
          &#x24D8;
        </Text>
      </Box>
      <Box>
        <Box mb="small">
          <Text bold fontSize="medium">
            App Info
          </Text>
          <hr />
          <div>
            <Text bold fontSize="smedium">Version: </Text>
            <Text fontSize="smedium">{app.version}</Text>
          </div>
        </Box>
        <Box mb="small">
          <Text bold fontSize="medium">
            Git Info
          </Text>
          <hr />
          <div>
            <Text bold fontSize="smedium">Commit Hash: </Text>
            <Text fontSize="smedium">{process.env.COMMIT_HASH}</Text>
          </div>
          <div>
            <Text bold fontSize="smedium">Git Branch: </Text>
            <Text fontSize="smedium">{process.env.GIT_BRANCH}</Text>
          </div>
          <div>
            <Text bold fontSize="smedium">Git Tag: </Text>
            <Text fontSize="smedium">{process.env.GIT_TAG}</Text>
          </div>
        </Box>
        <Box mb="small">
          <Text bold fontSize="medium">
            API Info
          </Text>
          <hr />
          <Box flexBox alignItems="center" mt="fine">
            <Text bold fontSize="smedium">Url:&nbsp;</Text>
            <Text fontSize="smedium">{apiBaseUri}</Text>
          </Box>
          <Box flexBox alignItems="center" mt="fine">
            <Text bold fontSize="smedium">Env:&nbsp;</Text>
            {((location.host === DEVEL_HOST || location.host === LOCAL_HOST) && app.magineEnvironment !== 'production') ? (
              <>
                <StyledSelect
                  id="select-magine-api"
                  onClick={handleMagineApiCookieClick}
                  onChange={handleMagineApiCookieChange}
                  value={magineApiCookie}
                >
                  <option value="dev">Develop</option>
                  <option value="test">Integration</option>
                </StyledSelect>
                <Spacer width="fine" />
                <PrimaryButton
                  variant="brandSecondary"
                  size="medium"
                  minWidth="auto"
                  onClick={handleMagineApiCookieSetClick}
                >
                  Set
                </PrimaryButton>
              </>
            ) : (
              <Text fontSize="smedium">{app.magineEnvironment}</Text>
            )}
          </Box>
        </Box>
      </Box>
    </AppInfoTriangle>
  );
};

export default AppInfo;
