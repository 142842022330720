import React from 'react';
import { ChannelType, ViewableType } from 'types';
import { VIEWABLE_TYPES } from 'utils/constants';
import ChannelTooltip from './ChannelTooltip';
import VideoTooltip, { VideoTooltipProps } from './VideoTooltip';

type ViewableTooltipProps = VideoTooltipProps & {
  viewable: ChannelType | ViewableType,
};

function ViewableTooltip({ viewable, ...restProps }: ViewableTooltipProps): JSX.Element {
  if (viewable.__typename === VIEWABLE_TYPES.Channel) {
    return (
      <ChannelTooltip {...restProps} viewable={viewable as ChannelType} />
    );
  }

  return (
    <VideoTooltip {...restProps} viewable={viewable} />
  );
}

export default React.memo(ViewableTooltip);
