import createComponent from 'styles/fela/createComponent';
import { imageTypes } from 'components';

export const PlayerArea = createComponent(({ theme, mini, hidden, fullPageMode }) => ({
  background: theme.color.playerBackground,
  flexShrink: 0,
  height: '56vw',
  maxHeight: `calc(100vh - ${theme.header.height} - 2rem - ${Math.floor(imageTypes.poster.height * (3 / 4))}rem)`,
  minHeight: '13rem',
  overflow: 'hidden',
  position: 'relative',
  zIndex: (mini || fullPageMode) ? theme.zIndex.miniPlayer : theme.zIndex.player,
  width: '100%',
  extend: [
    {
      condition: mini,
      style: {
        height: 0,
        minHeight: 0,
        overflow: 'visible',
      },
    },
    {
      condition: hidden,
      style: {
        display: 'none',
      },
    },
    {
      condition: fullPageMode && !mini,
      style: {
        position: 'fixed',
        minHeight: '100vh',
        top: 0,
      },
    },
  ],
}), 'div', ['mini', 'hidden', 'fullPageMode']);

export const Container = createComponent(() => ({
  width: '100%',
  height: '100%',
}));
