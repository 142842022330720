import React from 'react';
import { useFela } from 'react-fela';
import { Tooltip } from 'react-tooltip';
import { autoPlacement, offset } from '@floating-ui/dom';
import { useI18n } from 'components/I18n';

const OFFSET = 10;
const ALLOWED_PLACEMENTS = ['top', 'top-start', 'top-end'];

const styles = ({ theme }) => ({
  background: theme.color.tooltipBackground,
  color: theme.color.tooltipColor,
  fontSize: theme.fontSize.small,
  fontWeight: 'bold',
  padding: `${theme.margin.fine} ${theme.margin.small}`,
  marginLeft: '-0.35rem', // theme.margin.small / 2
  boxShadow: '0px 2px 8px 2px #00000040',
  zIndex: theme.zIndex.tooltip,
  borderRadius: theme.radius.paper,
  opacity: 0,
});

/**
 * @example
 * <InfoButton data-tooltip-id="main" data-tooltip-i18n="tooltip.moreInfo" />
 * // with i18n-values
 * <InfoButton
 *    data-tooltip-id="main"
 *    data-tooltip-i18n="subscription.price"
 *    data-tooltip-i18n-values={{price: 100}}
 * />
 * // change [data-tooltip-content] if you need hot reload
 * <WatchlistButton
 *    data-tooltip-id="main"
 *    data-tooltip-content={viewable.inMyList ? 'watchlist.remove' : 'watchlist.add'}
 *    data-tooltip-i18n={viewable.inMyList ? 'watchlist.remove' : 'watchlist.add'}
 * />
 */

function LiteTooltip(props) {
  const i18n = useI18n();
  const { css } = useFela();

  return (
    <Tooltip
      noArrow
      place="top"
      opacity="1"
      className={css(styles)}
      positionStrategy="fixed"
      disableStyleInjection
      middlewares={[
        offset(OFFSET),
        autoPlacement({ allowedPlacements: ALLOWED_PLACEMENTS }),
      ]}
      {...props}
      render={({ activeAnchor }) => {
        const i18nId = activeAnchor?.getAttribute('data-tooltip-i18n');

        return i18nId
          ? i18n.formatText(
            i18nId,
            activeAnchor?.getAttribute('data-tooltip-i18n-values'),
          ) : '';
      }}
    />
  );
}

export default LiteTooltip;
