import createComponent from 'styles/fela/createComponent';
import { ItemWrapper } from 'components/HorizontalScroll/HorizontalScroll';
import {
  maxWidth, minWidth,
  reserveBoldTextWidth,
  widthRange,
} from 'styles/fela/mixins';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

export const TabTypes = {
  material1: 'material1',
  material2: 'material2',
};
const defaultTabType = TabTypes.material1;
const UNDERLINE_HEIGHT = '3px';
const ARROW_WIDTH = '1.5rem';
const ARROW_WIDTH_NEGATIVE = `-${ARROW_WIDTH}`;

export const TabBar = createComponent(({
  theme,
  type = defaultTabType,
}) => ({
  color: theme.color.primary,
  position: 'relative',
  display: 'flex',
  scrollMargin: `calc(${theme.header.height} + ${theme.margin.large})`,

  '> div:nth-child(2)': { width: '100%' },

  extend: [
    {
      condition: type === TabTypes.material1,
      style: {
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          content: '""',
          height: '100%',
          backgroundColor: theme.color.highlight,
          boxShadow: `0 3px 4px 0 ${theme.color.overlayDarkFaint}`,
          extend: [
            maxWidth(BREAKPOINTS.sm, {
              marginLeft: `-${BASE_MARGIN.small}`,
              width: `calc(100% + (2 * ${BASE_MARGIN.small}))`,
            }),
            widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
              marginLeft: `-${BASE_MARGIN.medium}`,
              width: `calc(100% + (2 * ${BASE_MARGIN.medium}))`,
            }),
            minWidth(BREAKPOINTS.md, {
              marginLeft: `-${BASE_MARGIN.large}`,
              width: `calc(100% + (2 * ${BASE_MARGIN.large}))`,
            }),
          ],
        },
      },
    },
  ],
}), 'div', ['type']);

export const TabLabel = createComponent(({ theme, selected, type = defaultTabType }) => ({
  textAlign: 'center',
  borderBottom: `${UNDERLINE_HEIGHT} solid transparent`,
  cursor: 'pointer',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  marginRight: theme.margin.large,
  ':last-child': { marginRight: 0 },
  ...reserveBoldTextWidth(),

  extend: [
    {
      condition: type === TabTypes.material1,
      style: {
        padding: `${theme.margin.medium} 0 ${theme.margin.small}`,
      },
    },
    {
      condition: type === TabTypes.material2,
      style: {
        fontSize: theme.fontSize.medium,
        lineHeight: 1.6,
      },
    },
    {
      condition: selected,
      style: {
        borderBottomColor: theme.color.primary,
        fontWeight: 'bold',
      },
    },
  ],
}), ItemWrapper);

export const ArrowContainer = createComponent(({ position, isVisible, verticalShift }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
  top: 0,
  bottom: 0,
  userSelect: 'none',
  width: ARROW_WIDTH,
  zIndex: '10',
  extend: [
    {
      condition: !isVisible,
      style: {
        visibility: 'hidden',
      },
    },
    {
      condition: position === 'left',
      style: {
        marginLeft: ARROW_WIDTH_NEGATIVE,
      },
    },
    {
      condition: position === 'right',
      style: {
        marginRight: ARROW_WIDTH_NEGATIVE,
      },
    },
    {
      condition: !!verticalShift,
      style: {
        padding: `0 0 ${typeof verticalShift === 'string' ? verticalShift : UNDERLINE_HEIGHT}`,
      },
    },
  ],
}), 'div', ['position', 'isVisible', 'verticalShift']);
