import createComponent from 'styles/fela/createComponent';
import CleanLink from 'components/StyledSystem/CleanLink';
import { BUTTON_HEIGHT } from 'utils/constants';

export const Container = createComponent(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  paddingRight: theme.margin.small,

  '& svg path': {
    fill: theme.color.light,
  },
  '& svg circle': {
    fill: theme.color.brand,
    stroke: theme.color.brand,
    transition: '0.2s ease-in-out',
  },
  ':hover svg circle': {
    fill: theme.color.primaryButtonHover,
    stroke: theme.color.primaryButtonHover,
  },
}), CleanLink);

export const IconContainer = createComponent(() => ({
  position: 'relative',
  width: BUTTON_HEIGHT,
  height: BUTTON_HEIGHT,
}));
