import {
  HIDE_HORIZONTAL_MENU,
  SET_COLLECTION_DISPLAY_STYLE,
  SET_HEADER_TRANSPARENCY,
  UIActions,
} from 'actions/ui';
import { CollectionDisplayType } from 'utils/constants';

export type UIReducerState = {
  horizontalMenuHidden: boolean,
  collectionDisplayStyle?: CollectionDisplayType,
  headerTransparency: Record<string, boolean>,
};

const defaultState: UIReducerState = {
  horizontalMenuHidden: false,
  collectionDisplayStyle: undefined,
  headerTransparency: {},
};

export function uiReducer(state = defaultState, action: UIActions): UIReducerState {
  switch (action.type) {
    case HIDE_HORIZONTAL_MENU: {
      return {
        ...state,
        horizontalMenuHidden: action.hide,
      };
    }

    case SET_COLLECTION_DISPLAY_STYLE: {
      return {
        ...state,
        collectionDisplayStyle: action.collectionDisplayStyle,
      };
    }

    case SET_HEADER_TRANSPARENCY: {
      return {
        ...state,
        headerTransparency: {
          ...state.headerTransparency,
          [`${action.locationName}:${action.collectionId}`]: action.supportsTransparency,
        },
      };
    }

    default: {
      return state;
    }
  }
}
