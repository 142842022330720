import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Spacer,
} from 'components';
import { useTrailer } from './TrailerContext';
import {
  IconContainer,
  StyledCircleIcon,
  StyledTrailerIcon,
  Container,
} from './Styles';

function TrailerButton({ short }) {
  const [trailerIsPlaying, playTrailer, viewable] = useTrailer();

  if (!viewable.trailer && !viewable.trailers?.length) {
    return null;
  }

  return (
    <Container
      onClick={playTrailer}
      {...(short ? {
        'data-tooltip-id': 'main',
        'data-tooltip-i18n': 'trailer.watch',
        'data-tooltip-content': 'trailer.watch',
      } : null)}
    >
      <IconContainer>
        <StyledCircleIcon />
        <StyledTrailerIcon inProgress={trailerIsPlaying} />
      </IconContainer>
      {!short && (
        <>
          <Spacer minWidth="medium" />
          <Text fontSize="small" id="trailer.watch" />
        </>
      )}
    </Container>
  );
}

TrailerButton.propTypes = {
  short: PropTypes.bool,
};

export default TrailerButton;
