import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authActions from 'actions/auth';
import {
  Text,
  LinkButton,
} from 'components';
import ArrowForward from 'components/Icons/ArrowForward';

function SkipOnboardingButton() {
  const dispatch = useDispatch();

  const {
    onboardingMandatory,
  } = useSelector(state => ({
    onboardingMandatory: state.settings.features.payment?.onboarding?.mandatory || false,
  }), shallowEqual);

  if (onboardingMandatory) {
    return null;
  }

  return (
    <LinkButton onClick={() => dispatch(authActions.openInitialView())}>
      <Text id="payment.skipForNow" />
      <ArrowForward style={{ margin: '-0.2rem 0.5rem' }} />
    </LinkButton>
  );
}

export default React.memo(SkipOnboardingButton);
