import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';

const Container = createComponent(({ theme, withBackground, size }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',

  textAlign: 'center',
  color: theme.color.channelLogoAltText,

  extend: [
    {
      condition: withBackground,
      style: {
        backgroundColor: theme.color.channelBackground,
        borderRadius: '50%',
        padding: theme.margin.fine,
      },
    },
    {
      condition: size,
      style: {
        height: size,
        width: size,
      },
    },
  ],
}), 'div', ['withBackground', 'size'], true);

const Image = createComponent(() => ({
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
}), 'img');

export default function ChannelLogo({ alt, src, withBackground, size = '5.625rem', ...restProps }) {
  if (!src && !alt) {
    return null;
  }
  return (
    <Container withBackground={withBackground} size={size} {...restProps}>
      {src ? (
        <Image
          alt={alt}
          src={src}
        />
      ) : (
        alt
      )}
    </Container>
  );
}

ChannelLogo.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  withBackground: PropTypes.bool,
  size: PropTypes.string,
};
