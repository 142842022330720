import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function NextIcon({ right }) {
  const isRTL = useSelector(state => state.settings.l10n.direction === 'rtl');

  const isRight = () => (isRTL ? !right : right);

  return (
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" data-cy="next-icon">
      {
        isRight()
          ? <path d="M1.72792 0.999984L14.4558 13.7279L1.72792 26.4558" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          : <path d="M14.7281 0.999984L2.00021 13.7279L14.7281 26.4558" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      }
    </svg>
  );
}
NextIcon.propTypes = {
  right: PropTypes.bool,
};
