import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as routerActions from 'router/actions';
import {
  PaymentProviderFooter,
  AdyenResultHandler,
} from 'components/Payment';
import {
  Box,
  Heading,
  Text,
} from 'components';
import NoOffersErrorPage from 'components/ErrorPage/NoOffersErrorPage';
import { RouterLocation } from 'router';
import { AppDispatch } from 'reducers';
import { Offer } from 'types';
import { flattenEdges } from 'utils/helpers';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';
import { useGetOffersToChangeQuery } from '../getOffersToChange.generated';
import OfferComponent from './Offer';
import ChangePlanModal from '../ChangePlanModal';
import { Container } from './styles';

type Props = {
  location: RouterLocation,
};

// Select offer component to change user's entitlement offer. Can be opened from My Plan page
// Route: '/offers/change/:id' where id is entitlement's offerId
function SelectOffersChange({ location }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = location.params!;

  const [newOffer, setNewOffer] = useState<Offer | null>(null);

  const {
    data,
    error: apolloError,
  } = useGetOffersToChangeQuery({ variables: { offerId: id } });

  if (apolloError) {
    return (
      <ErrorPage button="retry" icon={ERROR_ICONS.NOT_FOUND}>
        <Text id="error" />
      </ErrorPage>
    );
  }

  if (!data) {
    return null;
  }

  const originalOffer = data.viewer.offer as Offer;
  // find availableOffers from subscriptions.replacements API
  // only UPGRADE ones for now
  const availableOffers = flattenEdges(
    data.viewer.subscriptions?.edges?.find(s => s?.node.offerId === id)?.node.replacements || [],
  ).filter((s: Offer) => s?.subscribeKind === 'UPGRADE') as Offer[];

  if (!originalOffer || !availableOffers.length) {
    return (
      <NoOffersErrorPage hasEntitlement />
    );
  }

  const onOfferClick = (offer: Offer) => {
    setNewOffer(offer);
  };

  const onChangeOfferConfirm = (offer: Offer) => {
    dispatch(routerActions.push({
      name: 'checkout-upgrade',
      params: { id: offer.id, originalOfferId: originalOffer.id },
    }));
  };

  return (
    <Container column mx="auto" mt="xxxlarge" maxWidth="100%">

      <Box mb="medium">
        <AdyenResultHandler />
      </Box>

      <Box
        mb="xlarge"
        maxWidth="100%"
      >
        <Heading
          fontSize="sectionHeading"
          align="center"
          id="checkout.changeOffer"
        />

        <Box mt="xlarge" row wrap columnGap="xlarge">
          <OfferComponent
            key={originalOffer.id}
            offer={originalOffer}
            btnText="payment.currentOffer"
            onClick={() => {}}
            disabled
          />
          {availableOffers.map(offer => (
            <OfferComponent
              key={offer.id}
              offer={offer}
              onClick={() => onOfferClick(offer)}
            />
          ))}
        </Box>
      </Box>

      <Box mb="small">
        <PaymentProviderFooter />
      </Box>

      {newOffer && (
        <ChangePlanModal
          currentOffer={originalOffer}
          newOffer={newOffer}
          onDismiss={() => setNewOffer(null)}
          onConfirm={onChangeOfferConfirm}
        />
      )}
    </Container>
  );
}

export default React.memo(SelectOffersChange);
