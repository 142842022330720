import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components';
import {
  RadioButton,
  TextArea,
} from 'components/Form';

export default function Survey({ answers, selectedAnswer, onSelected }) {
  const ref = React.useRef([]);
  const items = answers.map((answer) => {
    const isSelected = selectedAnswer?.key === answer.key;
    const selectAnswer = freeText => onSelected({ key: answer.key, label: answer.label, freeText });

    return (
      <Box key={answer.key} mb="large">
        <RadioButton
          label={answer.label}
          checked={isSelected}
          onChange={isSelected ? null : () => {
            const freeText = ref.current[`${answer.key}-textarea`]?.value;
            selectAnswer(freeText);
          }}
        />

        {answer.textBox && (
          <Box mt="small">
            <TextArea
              innerRef={(el) => { ref.current[`${answer.key}-textarea`] = el; }}
              id={`${answer.key}-textarea`}
              placeholder={answer.textBox.placeholder}
              onChange={e => selectAnswer(e.target.value)}
              onFocus={e => selectAnswer(e.target.value)}
              disabled={!isSelected}
            />
          </Box>
        )}
      </Box>
    );
  });

  return (
    <>
      {items}
    </>
  );
}

Survey.propTypes = {
  selectedAnswer: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    freeText: PropTypes.string,
  }),
  onSelected: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textBox: PropTypes.shape({
      placeholder: PropTypes.string,
    }),
  })).isRequired,
};
