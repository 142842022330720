import React from 'react';
import Icon, { IconType } from '../Icon';

const MuteIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
    <path fill="#fff" d="M64.167 76.667v16.666a4.179 4.179 0 0 0 4.166 4.167h12.5l13.709 13.708c2.625 2.625 7.125.75 7.125-2.958V61.708c0-3.708-4.5-5.583-7.125-2.958L80.833 72.5h-12.5a4.179 4.179 0 0 0-4.166 4.167Z" />
  </Icon>
);

export default MuteIcon;
