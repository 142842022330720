import React from 'react';
import { RouterLocation } from 'router';
import {
  Container,
  IconContainer,
} from './Styles';

type PlayButtonProps = {
  to: RouterLocation,
};

function PlayButton(props: PlayButtonProps) {
  const { to, ...restProps } = props;

  return (
    <Container {...restProps} to={to}>
      <IconContainer>
        <svg width="100%" height="100%" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="23.0052" cy="23.8154" r="22.0052" strokeWidth="2" />
          <path d="M17.1958 15.9553V32.0448C17.1958 33.2717 18.513 34.0172 19.5274 33.3494L31.8518 25.3046C32.7905 24.6989 32.7905 23.3012 31.8518 22.6799L19.5274 14.6507C18.513 13.9829 17.1958 14.7283 17.1958 15.9553Z" />
        </svg>
      </IconContainer>
    </Container>
  );
}

export default React.memo(PlayButton);
