import React from 'react';
import Icon from './Icon';

export default function HamburgerIcon(props) {
  return (
    <Icon {...props} viewBox="0 0 36 23">
      <g
        fillRule="evenodd"
        fill="none"
        strokeLinecap="square"
        strokeWidth="3"
        stroke="currentColor"
      >
        <path d="M2.444 1.5h31.112M2.444 11.5h31.112M2.444 21.5h31.112" />
      </g>
    </Icon>
  );
}
