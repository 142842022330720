import React from 'react';
import PropTypes from 'prop-types';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import {
  imageTypes,
  Scrollable,
  Text,
} from 'components';
import GenreCard, { genreShape } from 'components/GenreCard/GenreCard';
import ComponentWrapper, { ShowAllLink } from 'components/ComponentWrapper/ComponentWrapper';
import { ClickTracker } from 'components/Tracking';

const Genres = ({ title, links, pageId, collectionId, categoryKind, loadMore, hasNextPage }) => {
  const renderItem = viewable => (
    <ClickTracker
      key={viewable.id}
      component="ContentList"
      eventName="click_genre"
      element="genre"
      action="click"
      clickType="navigation"
      collectionId={collectionId}
      categoryKind={categoryKind}
      viewableId={viewable.id}
    >
      <GenreCard genre={viewable} />
    </ClickTracker>
  );

  if (!links?.length) {
    return null;
  }

  const items = links.map(renderItem);

  const showAllLink = (
    <ClickTracker
      component="ContentList"
      action="click"
      clickType="navigation"
      eventName="click_see_all_button"
      section={categoryKind}
      collectionId={collectionId}
    >
      <ShowAllLink to={{ name: 'genres', params: { id: collectionId } }}>
        <Text id="showAll" />
      </ShowAllLink>
    </ClickTracker>
  );

  return (
    <ComponentWrapper
      title={title}
      right={showAllLink}
    >
      <Scrollable
        pageId={pageId}
        id={`content-list-${collectionId}`}
        loadMore={loadMore}
        hasMoreLoad={hasNextPage}
        itemWidthRem={imageTypes.genreCard.width}
      >
        {items}
      </Scrollable>
    </ComponentWrapper>
  );
};

Genres.propTypes = {
  links: PropTypes.arrayOf(genreShape).isRequired,
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  categoryKind: PropTypes.oneOf([
    BLOCK_INTERFACE_TYPES.LinkCollection,
  ]).isRequired,
  loadMore: PropTypes.func,
  hasNextPage: PropTypes.bool,
};

export default Genres;
