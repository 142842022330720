import createComponent from 'styles/fela/createComponent';

export const Container = createComponent(() => ({
  cursor: 'pointer',
  display: 'inline-block',

  '& g': {
    opacity: 0.7,
    transition: '0.2s ease-in-out',
  },
  ':hover g': {
    opacity: 1,
  },
  '& circle': {
    fill: 'rgba(0, 0, 0, 0.3)',
    transition: '0.2s ease-in-out',
  },
  ':hover circle': {
    fill: 'rgba(0, 0, 0, 0.2)',
  },
}));
