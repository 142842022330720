import React from 'react';
import PropTypes from 'prop-types';
import ObservableInterval from 'utils/observable-interval';
import { useFela } from 'react-fela';
import {
  fill,
  progressBarWrapper,
} from './Styles';

const interval = new ObservableInterval(1000);

function LiveProgressBar({ start, stop, extend }) {
  const [fillWidth, setFillWidth] = React.useState(0);
  const { css } = useFela();

  React.useEffect(() => {
    const listener = () => window.requestAnimationFrame(() => {
      const duration = stop - start;
      const currentTime = Date.now() / 1000;
      const livePoint = currentTime - start;

      setFillWidth(Math.min((livePoint / duration) * 100, 100).toFixed(2));
    });

    interval.subscribe(listener);

    return () => interval.unsubscribe(listener);
  }, [start, stop]);

  return (
    <div className={css([progressBarWrapper, extend])}>
      {fillWidth > 0 && (
        <div
          className={css(fill)}
          style={{
            width: `${fillWidth}%`,
          }}
        />
      )}
    </div>
  );
}

LiveProgressBar.propTypes = {
  start: PropTypes.number.isRequired,
  stop: PropTypes.number.isRequired,
  extend: PropTypes.object,
};

export default React.memo(LiveProgressBar);
