import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import TextField from './TextField/TextField';
import { useValidations } from './utils';

function FormField(props) {
  const {
    validations,
    name,
    ...otherProps
  } = props;

  const {
    getValues,
    register,
    errors,
    formState: {
      dirtyFields,
      touched,
      isSubmitted,
    },
    control,
  } = useFormContext();

  const [defaultValue] = useState(() => getValues(name));

  const value = useWatch({
    control,
    name,
  });

  const registerOptions = {
    validate: useValidations(validations),
  };

  const dirty = !!dirtyFields[name];
  const error = errors[name];

  return React.createElement(props.as, {
    ref: register(registerOptions),
    value: value ?? defaultValue,
    dirty,
    error,
    name,
    isSubmitted,
    touched: touched[name],
    ...otherProps,
  });
}

FormField.propTypes = {
  validations: PropTypes.object,
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.elementType,
  ]),
};

FormField.defaultProps = {
  as: TextField,
  validations: null,
};

export default memo(FormField);
