import { IBillingPayment, BillingPaymentMethod } from 'types';
import {
  GET_PAYMENT_METHOD,
  GET_PAYMENTS,
  BillingActionTypes,
} from 'actions/billing';

export type BillingReducerState = {
  paymentMethod: BillingPaymentMethod | null,
  transactions: IBillingPayment[],
};

const defaultState: BillingReducerState = {
  paymentMethod: null,
  transactions: [],
};

export function billingReducer(
  state = defaultState,
  action: BillingActionTypes,
): BillingReducerState {
  switch (action.type) {
    case GET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.data,
      };

    case GET_PAYMENTS:
      return {
        ...state,
        transactions: action.data,
      };

    default:
      return state;
  }
}
