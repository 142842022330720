import { AnyAction, Dispatch } from 'redux';
import getResellerBrandingQuery from 'queries/getResellerBrandingQuery.gql';
import { ApolloClient } from '@apollo/client';

export const SET_BRAND_LOGO = 'settings/SET_BRAND_LOGO';

interface SetBrandLogoAction {
  type: typeof SET_BRAND_LOGO
  logo: string
}

export type SettingActions = SetBrandLogoAction;

export const setBrandLogo = (logo: string): AnyAction => ({
  type: SET_BRAND_LOGO,
  logo,
});

export const setResellerBrandLogo = async (
  apolloClient: ApolloClient<any>, theme: string, dispatch: Dispatch,
): Promise<void> => {
  const { data: { viewer: { resellerBranding } } } = await apolloClient.query({
    query: getResellerBrandingQuery,
    fetchPolicy: 'network-only',
  });

  if (resellerBranding?.logo[theme]) {
    dispatch(setBrandLogo(resellerBranding.logo[theme]));
  }
};
