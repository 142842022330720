import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
} from 'components';
import ApplePayLogo from './ApplePayDisplayStyles';

export default function ApplePayDisplay({ center }) {
  return (
    <>
      <Box mb="medium">
        <Text id="applePayPayment" />
      </Box>

      <Box my="medium">
        <ApplePayLogo center={center} />
      </Box>
    </>
  );
}

ApplePayDisplay.propTypes = {
  center: PropTypes.bool,
};

ApplePayDisplay.defaultProps = {
  center: false,
};
