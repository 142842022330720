import { useEffect } from 'react';
import PropTypes from 'prop-types';

const ClickOutside = ({ wrapperRef, onClickOutside, children }) => {
  useEffect(() => {
    const onClick = (e) => {
      const isInside = wrapperRef.current === e.target || wrapperRef.current.contains(e.target);

      if (!isInside && onClickOutside) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', onClick);
    document.addEventListener('touchstart', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
      document.removeEventListener('touchstart', onClick);
    };
  }, [onClickOutside]);

  return children;
};

ClickOutside.propTypes = {
  wrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func,
};

export default ClickOutside;
