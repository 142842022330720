import RouterLink from 'router/Link';
import createComponent from 'styles/fela/createComponent';
import { withBasePadding } from 'styles/fela/mixins';

export const Header = createComponent(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  extend: [
    withBasePadding(),
  ],
}));

export const Container = createComponent(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.margin.medium,
  paddingTop: theme.margin.medium,
  width: '100%',
}));

export const ShowAllLink = createComponent(({ theme }) => ({
  color: theme.color.brand,
  cursor: 'pointer',
  textDecoration: 'none',
  fontWeight: 'bold',
  textAlign: 'end',
  ':link': {
    color: theme.color.brand,
  },
  ':visited': {
    color: theme.color.brand,
  },
}), RouterLink);
