import { NetworkAction, NETWORK_REQUEST } from 'actions/helpers';
import { RootState } from 'reducers';
import { Middleware } from 'redux';
import { fetchWithTimeout } from 'utils/fetch';

// Middleware to manage network requests
export function createNetworkRequestMiddleware(): Middleware {
  return store => next => (action: NetworkAction) => {
    if (action.type !== NETWORK_REQUEST) {
      return next(action);
    }

    const state: RootState = store.getState();
    const { clientApiToken } = state.settings;
    const { sessionToken } = state.auth;

    const headers: Record<string, string> = {
      ...action.headers,
      'Magine-AccessToken': clientApiToken,
      Accept: 'application/json',
    };

    if (sessionToken) {
      headers.Authorization = `Bearer ${sessionToken}`;
    }

    if (action.body) {
      headers['Content-Type'] = 'application/json';
    }

    return fetchWithTimeout(action.url, {
      method: action.method,
      mode: 'cors',
      cache: 'no-cache',
      headers,
      body: action.body ? JSON.stringify(action.body) : null,
    });
  };
}
