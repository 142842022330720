import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeMessage } from 'actions/messages';
import { Notification, Text } from 'components';

export default function Messages() {
  const messages = useSelector(state => state.messages);
  const dispatch = useDispatch();

  const handleClose = k => () => {
    dispatch(removeMessage(k));
  };

  useEffect(() => {
    if (Object.keys(messages).length > 0) {
      Object.keys(messages).forEach((k) => {
        setTimeout(() => {
          dispatch(removeMessage(k));
        }, messages[k].duration * 1000);
      });
    }
  }, []);

  if (Object.keys(messages).length === 0) return null;

  return (
    <>
      {
        Object.keys(messages).map(k => (
          <Notification onClose={handleClose(k)} key={k} type={messages[k].type}>
            {
              messages[k].contentId
                ? (<Text id={messages[k].contentId} values={messages[k].contentValue} />)
                : messages[k].content
            }
          </Notification>
        ))
      }
    </>
  );
}
