import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/StyledSystem/Box';

const Tab = ({ isSelected, children }) => {
  const [wasRendered, setWasRendered] = useState(isSelected);

  useEffect(() => {
    const newWasRendered = wasRendered || isSelected;
    if (newWasRendered !== wasRendered) {
      setWasRendered(newWasRendered);
    }
  }, [isSelected]);

  // do not render background tab unless it was already rendered
  if (!wasRendered && !isSelected) {
    return null;
  }

  return (
    <Box display={isSelected ? 'block' : 'none'}>
      {children}
    </Box>
  );
};

Tab.propTypes = {
  isSelected: PropTypes.bool,
  children: PropTypes.node,
};

export default Tab;
