import React from 'react';
import { useI18n } from 'components/I18n';
import { VIEWABLE_TYPES } from 'utils/constants';

export function useTranslatedPlayerMessages(language) {
  const i18n = useI18n();

  return React.useMemo(() => {
    if (__SERVER__) {
      return {};
    }

    const messages = {};

    const HipsterPlayer = require('@tvoli/hipster-player').default; // eslint-disable-line global-require

    for (const msgId of Object.keys(HipsterPlayer.DEFAULT_MESSAGES.en)) {
      if (i18n.hasMessage(msgId)) {
        messages[msgId] = values => i18n.formatText(msgId, values);
      }
    }

    return {
      [language]: messages,
    };
  }, [language]);
}

export function isLiveDisabled(viewable) {
  return viewable
    && viewable.__typename === VIEWABLE_TYPES.Channel
    && !viewable.liveAvailable
    && viewable.schedule?.[0]
    && !viewable.broadcastById;
}
