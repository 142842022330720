import React from 'react';
import PropTypes from 'prop-types';
import {
  InputPlaceholder,
  Input,
  InputWrapper,
} from '../Styles';

const NOT_DIGITS = /[^\d]/g;

function NumberInput(props) {
  const {
    name,
    value,
    placeholder,
    onChange,
    onBlur: parentOnBlur,
    isApproved,
    max,
    min,
    defaultValue,
  } = props;
  const valueRef = React.useRef();

  const [isActive, setIsActive] = React.useState(false);

  const onBlur = () => {
    setIsActive(false);
    parentOnBlur();
  };

  const onInput = (e) => {
    let normalizedValue = e.target.value.replace(NOT_DIGITS, '');

    // limit input maximum value
    if (
      typeof max !== 'undefined'
      && normalizedValue.length >= max.toString().length
      && Number(normalizedValue) > max
    ) {
      normalizedValue = max.toString();
    }

    // limit input minimal value
    if (
      typeof min !== 'undefined'
      && normalizedValue.length >= min.toString().length
      && Number(normalizedValue) < min
    ) {
      normalizedValue = min.toString();
    }

    // set defaultValue when click on spin box
    if (
      typeof defaultValue !== 'undefined'
      && !valueRef.current // only on first change
      && (
        normalizedValue.length === max.toString().length
        || normalizedValue.length === min.toString().length
      )
    ) {
      normalizedValue = defaultValue.toString();
    }

    e.target.value = normalizedValue;
    valueRef.current = normalizedValue;

    onChange(normalizedValue);
  };

  return (
    <InputWrapper>
      <InputPlaceholder
        isFloating={isActive || !!value}
        isFocused={isActive}
      >
        {placeholder}
      </InputPlaceholder>
      <Input
        name={name}
        type="number"
        pattern="/[^0-9]/g"
        max={max}
        min={min}
        isApproved={isApproved}
        onInput={onInput}
        onFocus={() => setIsActive(true)}
        onBlur={onBlur}
      />
    </InputWrapper>
  );
}

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isApproved: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  defaultValue: PropTypes.number,
};

export default React.memo(NumberInput);
