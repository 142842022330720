import createComponent from 'styles/fela/createComponent';
import {
  responsiveLg,
} from 'styles/fela/mixins';

export const InputWrapper = createComponent(({ theme }) => ({
  paddingBottom: theme.margin.fine,
  paddingTop: theme.margin.medium,
  position: 'relative',
  width: '100%',
}));

export const InputErrorMessage = createComponent(({ theme, isVisible, fixedWidth }) => ({
  color: theme.color.danger,
  display: 'block',
  width: '100%', // if flex child
  fontSize: theme.fontSize.fine,
  marginTop: theme.margin.fine,
  minHeight: '1.1em',
  opacity: '0',
  textAlign: 'left',
  transform: 'scale(0, 0) translateY(-100%)',
  transition: '300ms all',
  extend: [
    {
      condition: isVisible,
      style: {
        opacity: '1',
        transform: 'scale(1, 1)  translateY(0%)',
      },
    },
    {
      condition: fixedWidth,
      style: {
        width: theme.size.fixedWidthButton,
      },
    },
  ],
}), 'label', ['isVisible', 'fixedWidth']);

export const InputPlaceholder = createComponent(({ theme, isFocused, isFloating }) => ({
  color: theme.color.inputColor,
  display: 'block',
  fontSize: theme.fontSize.normal,
  fontWeight: 'bold',
  left: '0',
  paddingTop: theme.margin.large,
  paddingLeft: theme.margin.medium,
  pointerEvents: 'none',
  position: 'absolute',
  textAlign: 'left',
  height: 63,
  transition: 'all .2s cubic-bezier(.4, 0, .2, 1)',
  width: '100%',
  extend: [
    {
      condition: isFocused,
      style: {
        ':after': {
        },
      },
    },
    {
      condition: isFloating,
      style: {
        fontSize: theme.fontSize.fine,
        paddingTop: theme.margin.small,
      },
    },
    responsiveLg({
      height: 67,
    }),
  ],
}), 'label', ['isFloating', 'isFocused']);

export const Input = createComponent(({
  theme,
  isApproved,
  onAutoFillCancel,
  onAutoFillStart,
  rightPadding,
  disabled,
  type,
  clientSide,
}) => ({
  // Safari 'Strong password' btn fix
  '::-webkit-strong-password-auto-fill-button': {
    marginBottom: '10px',
  },
  background: theme.color.inputBackground,
  border: '0',
  borderRadius: theme.radius.adyenDropIn,
  boxShadow: 'none',
  color: theme.color.inputColor,
  display: 'inline-block',
  fontSize: theme.fontSize.normal,
  fontWeight: 'bold',
  margin: '0',
  outline: '0',
  paddingTop: theme.margin.large,
  paddingBottom: theme.margin.fine,
  paddingLeft: theme.margin.medium,
  paddingRight: theme.margin.medium,
  width: '100%',
  height: '4rem',
  lineHeight: '2rem',
  appearance: 'textfield',
  '::-webkit-inner-spin-button': {
    appearance: 'none',
  },

  extend: [
    {
      condition: clientSide,
      style: {
        ':-webkit-autofill': {
          animationName: onAutoFillStart,
          '-webkit-box-shadow': `0 0 0 34px ${theme.color.inputBackground} inset !important`,
        },
        ':not(:-webkit-autofill)': {
          animationName: onAutoFillCancel,
        },
      },
    },
    {
      condition: rightPadding,
      style: {
        paddingRight: theme.margin.xxlarge,
      },
    },
    {
      condition: isApproved,
      style: {
        borderBottomColor: theme.color.secondaryFocus,
      },
    },
    {
      condition: disabled,
      style: {
        color: theme.color.secondary,
      },
    },
    {
      condition: type === 'password',
      style: {
        letterSpacing: '2px',
        fontWeight: 'bold',
      },
    },
  ],
}), 'input', ['rightPadding', 'isApproved', 'render', 'onAutoFillStart', 'onAutoFillCancel', 'clientSide']);
