import React from 'react';
import { useTransformScheduler } from 'utils/hooks';
import { ANIMATION_DURATION_SEC } from './Styles';

const TRANSITION = `transform ${ANIMATION_DURATION_SEC}s ease-out`;
const SWIPE_DELTA_X_PX = 150;

// Detect swipes on container
function useSwipe(elRef, onSwipe, isRTL) {
  const onSwipeRef = React.useRef(onSwipe);
  const touchStartRef = React.useRef(null);
  const scheduleTransform = useTransformScheduler(elRef);

  React.useEffect(() => {
    onSwipeRef.current = onSwipe;
  }, [onSwipe]);

  // TOUCH START
  React.useEffect(() => {
    const el = elRef.current;

    if (!el) {
      return () => {};
    }

    const onTouchStart = (e) => {
      touchStartRef.current = e.touches[0];
    };

    el.addEventListener('touchstart', onTouchStart, { passive: true });

    return () => {
      el.removeEventListener('touchstart', onTouchStart);
    };
  }, [elRef.current]);

  // TOUCH MOVE
  React.useEffect(() => {
    const el = elRef.current;

    if (!el) {
      return () => {};
    }

    const onTouchMove = (e) => {
      const newPos = e.touches[0];
      const touchStart = touchStartRef.current;

      if (!touchStart) {
        return;
      }

      const deltaX = touchStart.clientX - newPos.clientX;
      const deltaY = touchStart.clientY - newPos.clientY;

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        // Scrolled more vertically than horizontally therefore we
        // assume the user didn't mean to trigger a horizontal scroll.
        return;
      }

      const multiplier = isRTL ? -1 : 1;

      if (deltaX > SWIPE_DELTA_X_PX) {
        touchStartRef.current = null;
        scheduleTransform(null);
        onSwipeRef.current(1 * multiplier);
        return;
      }

      if (deltaX < -SWIPE_DELTA_X_PX) {
        touchStartRef.current = null;
        scheduleTransform(null);
        onSwipeRef.current(-1 * multiplier);
        return;
      }

      scheduleTransform(`translate3d(calc(${multiplier} * -100% - ${deltaX}px), 0, 0)`);
    };

    el.addEventListener('touchmove', onTouchMove, { passive: true });

    return () => {
      el.removeEventListener('touchmove', onTouchMove);
    };
  }, [elRef.current, isRTL]);

  // TOUCH END
  React.useEffect(() => {
    const el = elRef.current;

    if (!el) {
      return () => {};
    }

    const onTouchEnd = () => {
      if (!touchStartRef.current) {
        return;
      }

      touchStartRef.current = null;
      scheduleTransform('');
    };

    el.addEventListener('touchend', onTouchEnd, { passive: true });

    return () => {
      el.removeEventListener('touchend', onTouchEnd);
    };
  }, [elRef.current]);
}

// Scroll to next/prev item with animation
function useContainerAnimator(elRef, pos, isRTL) {
  const [animatePos, setAnimatePos] = React.useState(0);

  if (__SERVER__) { // fix React warning about usage of useLayoutEffect during SSR
    return [animatePos, setAnimatePos];
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useLayoutEffect(() => {
    setAnimatePos(0);
  }, [pos]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useLayoutEffect(() => {
    const el = elRef.current;

    if (!el) {
      return;
    }

    if (animatePos === 1) {
      el.style.transition = TRANSITION;
      el.style.transform = `translate3d(${isRTL ? '+' : '-'}200%, 0, 0)`;
    }

    if (animatePos === 0) {
      el.style.transition = '';
      el.style.transform = '';
    }

    if (animatePos === -1) {
      el.style.transition = TRANSITION;
      el.style.transform = 'translate3d(0, 0, 0)';
    }
  }, [elRef.current, animatePos, isRTL]);

  return [animatePos, setAnimatePos];
}

export function useCarouselManager(elRef, size, isRTL) {
  const [pos, setPos] = React.useState(0);

  let nextPos = pos + 1;
  if (nextPos === size) {
    nextPos = 0;
  }
  let prevPos = pos - 1;
  if (prevPos === -1) {
    prevPos = size - 1;
  }

  const [animatePos, setAnimatePos] = useContainerAnimator(elRef, pos, isRTL);
  useSwipe(elRef, setAnimatePos, isRTL);

  // activate next/prev item when animation ends
  React.useEffect(() => {
    const el = elRef.current;

    if (!el || animatePos === 0) {
      return () => {};
    }

    const handler = () => {
      window.requestAnimationFrame(() => {
        if (animatePos === 1) {
          setPos(nextPos);
        }

        if (animatePos === -1) {
          setPos(prevPos);
        }
      });
    };

    el.addEventListener('transitionend', handler, { passive: true, capture: true });

    return () => {
      el.removeEventListener('transitionend', handler, true);
    };
  }, [elRef.current, animatePos, nextPos, prevPos]);

  return {
    pos,
    animatedPos: pos + animatePos,
    prevPos,
    nextPos,
    setPos,
    animateNextPos() {
      setAnimatePos(1);
    },
    animatePrevPos() {
      setAnimatePos(-1);
    },
  };
}
