import React, { useState, useEffect } from 'react';
import {
  addDays,
  subDays,
  getUnixTime,
  startOfDay,
} from 'date-fns';
import { formatYYYYMMDD } from 'utils/human-format';
import { useDispatch, useSelector } from 'reducers';
import { loadNextDay, loadPreviousDay } from 'actions/epg';
import { ArrowIcon } from '../EpgStyles';

interface IDateSwitcherProps {
  currentScreenDate: Date;
  scrollToTime: (timestamp: number) => Promise<void>;
  children: React.ReactChild;
}

const DateSwitcher: React.FC<IDateSwitcherProps> = (props) => {
  const { currentScreenDate, scrollToTime, children } = props;
  const { isMinDateLoaded, isMaxDateLoaded, datesLoaded } = useSelector(state => state.epg);
  const [isPrevActive, setIsPrevActive] = useState(true);
  const [isNextActive, setIsNextActive] = useState(true);
  const dispatch = useDispatch();

  const currentScreenDay = formatYYYYMMDD(startOfDay(currentScreenDate));

  useEffect(() => {
    const firstLoadedDay = formatYYYYMMDD(startOfDay(datesLoaded[0]));
    const lastLoadedDay = formatYYYYMMDD(startOfDay(datesLoaded[datesLoaded.length - 1]));
    setIsPrevActive(!(isMinDateLoaded && currentScreenDay === firstLoadedDay));
    setIsNextActive(!(isMaxDateLoaded && currentScreenDay === lastLoadedDay));
  }, [isMinDateLoaded, isMaxDateLoaded, datesLoaded, currentScreenDay]);

  const onClickPrevDay = async () => {
    const newScreenTime = getUnixTime(subDays(currentScreenDate, 1));
    void scrollToTime(newScreenTime);
    await dispatch(loadPreviousDay());
  };

  const onClickNextDay = async () => {
    const newScreenDate = addDays(currentScreenDate, 1);
    void scrollToTime(getUnixTime(newScreenDate));
    await dispatch(loadNextDay());
  };

  return (
    <div>
      <ArrowIcon direction="left" isVisible={isPrevActive} onClick={onClickPrevDay} />
      {children}
      <ArrowIcon direction="right" isVisible={isNextActive} onClick={onClickNextDay} />
    </div>
  );
};

export default DateSwitcher;
