import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'react-fela';
import { Box, LiveInfo, Spacer, Text } from 'components';
import ItemTitle from 'components/VideoItems/SixteenNineItem/ItemTitle';
import { TITLE_TYPES, UpcomingTitle } from 'components/Upcoming';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { extractLiveEventTime } from 'utils/live-event';
import { layoutObjectShape } from 'components/CollectionUI/prop-types';
import Metadata from 'components/CollectionUI/Metadata';
import { VIEWABLE_TYPES } from 'utils/constants';

const collectionUITheme = { collectionUI: { withTextShadow: false } };

const SliderTitles = ({ viewable, hideKind, layoutObjects }) => {
  const liveEventTime = extractLiveEventTime(viewable);
  const upcomingTimestamp = extractUpcomingStartTime(viewable);

  const seasonEpisodeInfo = useMemo(() => {
    if (viewable.__typename === VIEWABLE_TYPES.Episode) {
      return (
        <Text
          bold
          id="seasonEpisodeShort"
          values={{
            seasonNumber: viewable.seasonNumber,
            episodeNumber: viewable.episodeNumber,
          }}
        />
      );
    }
    return null;
  }, [viewable.__typename]);

  const layoutUpcomingTitle = useMemo(() => {
    if (upcomingTimestamp) {
      return (
        <UpcomingTitle
          type={TITLE_TYPES.POSTER}
          startTime={upcomingTimestamp}
        />
      );
    }
    return null;
  }, [upcomingTimestamp]);

  const upcomingTitleAndSEInfo = useMemo(() => {
    if (layoutUpcomingTitle || seasonEpisodeInfo) {
      return (
        <Box mb="fine">
          {layoutUpcomingTitle}
          {layoutUpcomingTitle && seasonEpisodeInfo ? ' | ' : null}
          {seasonEpisodeInfo}
        </Box>
      );
    }

    return null;
  }, [upcomingTimestamp, seasonEpisodeInfo]);

  return (
    <>
      <Spacer height="small" />
      {
        layoutObjects ? (
          <>
            {upcomingTitleAndSEInfo}
            <ThemeProvider theme={collectionUITheme}>
              <Metadata
                viewable={viewable}
                layoutObjects={layoutObjects || []}
              />
            </ThemeProvider>
          </>
        ) : (
          <>
            {!hideKind && (
              <Box mb="fine">
                <Text
                  color="secondary"
                  fontSize="small"
                  letterSpacing="1.3px"
                  id={`viewableType.${viewable.__typename}`}
                />
              </Box>
            )}
            <ItemTitle viewable={viewable} minHeight />
            {upcomingTimestamp ? (
              <Box mb="fine">
                <UpcomingTitle
                  type={TITLE_TYPES.POSTER}
                  startTime={upcomingTimestamp}
                />
              </Box>
            ) : null}
            {liveEventTime && (
              <Box mb="fine">
                <LiveInfo liveEventTime={liveEventTime} onlyToday />
              </Box>
            )}
          </>
        )
      }
    </>
  );
};

SliderTitles.propTypes = {
  viewable: viewableShape.isRequired,
  hideKind: PropTypes.bool,
  layoutObjects: PropTypes.arrayOf(layoutObjectShape),
};

export default React.memo(SliderTitles);
