import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { TermsAndConditionsLink } from 'components';

const TermsAndConditionsLinkPayment = (): React.ReactElement | null => {
  const termsAndConditions = useSelector((state: RootState) => state.settings.features?.terms);

  if (!termsAndConditions || !termsAndConditions.paymentLabel) {
    return null;
  }

  return (
    <TermsAndConditionsLink
      consentText={termsAndConditions.paymentLabel}
      url={termsAndConditions.url}
      urlLabel={termsAndConditions.urlLabel}
    />
  );
};

export default TermsAndConditionsLinkPayment;
