import React from 'react';
import { viewableShape } from 'types/viewableShape';
import { ChannelLogo, Box } from 'components';

export default function InfoChannelLogo({ viewable }) {
  const channel = viewable.defaultPlayable?.channel || viewable;
  if (channel.logoDark) {
    return (
      <Box mb="large">
        <ChannelLogo
          size="5rem"
          xl-size="7rem"
          alt={channel.title}
          src={channel.logoDark}
          withBackground
        />
      </Box>
    );
  }

  return null;
}

InfoChannelLogo.propTypes = {
  viewable: viewableShape.isRequired,
};
