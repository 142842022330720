export const renderTitle = viewable => (
  viewable.broadcastById?.title
  || viewable.schedule?.['0']?.title
  || viewable.title
);

export function findNextEpisode(viewable) {
  const currentSeason = viewable
    .seasons?.find(season => season.seasonNumber === viewable.seasonNumber);
  if (!currentSeason) return null;

  let foundNextEpisode = (currentSeason.episodes || [])
    .find(episode => episode.episodeNumber === viewable.episodeNumber + 1);

  if (!foundNextEpisode) {
    const currentEpisodeIndex = currentSeason.episodes
      .findIndex(episode => episode.episodeNumber === viewable.episodeNumber);

    foundNextEpisode = currentSeason.episodes[currentEpisodeIndex + 1];

    if (!foundNextEpisode) {
      const nextSeason = viewable.seasons
        .find(season => season.seasonNumber === viewable.seasonNumber + 1);

      if (!nextSeason || nextSeason.episodes?.length === 0) return null;

      return nextSeason.episodes[0];
    }
  }

  return foundNextEpisode;
}

export function getPageTitle(viewable) {
  if (viewable.broadcastById) {
    return viewable.broadcastById.title || viewable.title;
  }

  return viewable.title;
}

export function getPageDescription(viewable) {
  if (viewable.broadcastById) {
    return viewable.broadcastById.description || viewable.channelDescription;
  }
  // channel description as main. Schedule description as fallback
  return viewable.channelDescription || viewable.description;
}

export function getCanonicalData(location, isShow) {
  return {
    name: location.name,
    params: isShow ? { id: location.params.id } : location.params,
    query: null,
  };
}
