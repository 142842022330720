// types should be added here if you see only __typename in the data (spread issue)
// https://github.com/apollographql/apollo-client/issues/7648
// all types is in ./possibleTypes.generated.json

const possibleTypes = {
  ViewableInterface: ['Movie', 'Channel', 'Program', 'Show', 'Episode'],
  Playable: ['VodPlayable', 'BroadcastPlayable', 'ChannelPlayable', 'LiveEventPlayable'],
  OfferInterfaceType: ['ThirdPartyType', 'SubscribeType', 'BuyType', 'DefaultType', 'PassType', 'RentType'],
  EntitlementInterfaceType: ['EntitlementSubscribeType', 'EntitlementBuyType', 'EntitlementDefaultType', 'EntitlementPassType', 'EntitlementPurchaseType', 'EntitlementRentType', 'EntitlementThirdPartyType'],
};

export default possibleTypes;
