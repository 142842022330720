import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import storage from 'utils/storage';
import {
  Notification,
  Text,
} from 'components';

const ServiceNotAvailableNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    isActive,
  } = useSelector(({ country, auth, settings }) => ({
    isActive: !!settings.features.register
      && !country.isCreateAccountEnabled && !auth.isLoggedIn,
  }));

  useEffect(() => {
    if (isActive && !storage.getItem('serviceNotAvailableInRegionNotificationShown')) {
      setIsVisible(true);
    }
  });

  const close = () => {
    storage.setItem('serviceNotAvailableInRegionNotificationShown', 'true');
    setIsVisible(false);
  };

  // when user logged in isActive could be false, but isVisible would be still true
  // so let's use both
  if (!isActive || !isVisible) {
    return null;
  }

  return (
    <>
      <Notification onClose={close}>
        <Text id="serviceNotAvailableInRegion" />
      </Notification>
    </>
  );
};

export default ServiceNotAvailableNotification;
