import React from 'react';
import PropTypes from 'prop-types';
import { COLLECTION_DISPLAY_TYPES } from 'utils/constants';
import {
  Box,
  Text,
  Spacer,
} from 'components';
import GridIcon from './GridIcon';
import ListIcon from './ListIcon';

export default function CollectionDisplayTypeSwitch({ selected, onClick }) {
  return (
    <Box flexBox alignItems="center">
      <Text
        id="search.modesView"
        color="secondary"
        noselect
      />

      <Spacer width="medium" />

      <GridIcon
        size="39px"
        selected={selected === COLLECTION_DISPLAY_TYPES.grid}
        onClick={() => onClick(COLLECTION_DISPLAY_TYPES.grid)}
      />

      <Spacer width="small" />

      <ListIcon
        size="39px"
        selected={selected === COLLECTION_DISPLAY_TYPES.list}
        onClick={() => onClick(COLLECTION_DISPLAY_TYPES.list)}
      />
    </Box>
  );
}

CollectionDisplayTypeSwitch.propTypes = {
  selected: PropTypes.oneOf(Object.values(COLLECTION_DISPLAY_TYPES)).isRequired,
  onClick: PropTypes.func.isRequired,
};
