import createComponent from 'styles/fela/createComponent';
import ButtonWithSpinner from './ButtonWithSpinner';

const LinkButton = createComponent(({ theme, variant = 'link', noPadding, inline }) => ({
  border: '0 none',
  background: 'transparent',
  textAlign: 'center',
  outline: 'none',
  userSelect: 'none',

  cursor: 'pointer',
  display: 'block',
  width: 'auto',
  paddingLeft: theme.margin.medium,
  paddingRight: theme.margin.medium,

  '&[disabled]': {
    cursor: 'auto',
    color: theme.color.disabled,
  },

  '&:hover': {
    textDecoration: 'underline',
  },

  extend: [
    {
      condition: variant === 'link',
      style: {
        color: theme.color.link,
      },
    },
    {
      condition: variant === 'brand',
      style: {
        color: theme.color.brand,
      },
    },
    {
      condition: noPadding || inline,
      style: {
        padding: 0,
      },
    },
    {
      condition: inline,
      style: {
        display: 'inline',
      },
    },
  ],
}), ButtonWithSpinner, ['variant', 'noPadding', 'inline']);

export default LinkButton;
