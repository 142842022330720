import React, { useEffect, useState, useMemo } from 'react';
import Link from 'router/Link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { useIsMountedRef, useOfferButtonClickHandler } from 'utils/hooks';
import ObservableInterval from 'utils/observable-interval';
import {
  getLiveEventState,
  LIVE_EVENT_STATES,
  extractLiveEventTime,
} from 'utils/live-event';
import {
  PrimaryButton,
  AsyncButton,
  Box,
  Text,
} from 'components';
import { ClickTracker } from 'components/Tracking';
import { viewableShape } from 'views/ContentListView/prop-types';
import { getButtonProps } from './utils';

const interval = new ObservableInterval(1000);

const getExtra = (viewable, videoPreview, isLoggedIn) => ({
  area: 'button',
  isPlayAvailable: !!viewable?.entitlement,
  hasTrailer: !!videoPreview
    && (!!viewable.trailer || (isLoggedIn && !!viewable.trailers?.length)),
});

export default function ActionButtons({
  viewable, // Show's S1:E1 or Viewable
  collectionId,
  viewableId, // Real viewableId
}) {
  const {
    isLoggedIn,
    isSignUpViaMovistar,
    videoPreview,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    isSignUpViaMovistar: state.user.isSignUpViaMovistar,
    videoPreview: state.settings.features.videoPreview,
  }));

  const liveEventTime = extractLiveEventTime(viewable);
  const isMounted = useIsMountedRef();
  const [liveEventState, setLiveEventState] = useState(null);

  const updateLiveEventState = () => {
    if (!isMounted.current) return;
    setLiveEventState(getLiveEventState(liveEventTime));
  };

  useEffect(() => {
    if (!liveEventTime) return;
    updateLiveEventState();

    interval.subscribe(updateLiveEventState);

    // eslint-disable-next-line consistent-return
    return () => {
      interval.unsubscribe(updateLiveEventState);
    };
  }, [liveEventTime]);

  const buttons = useMemo(
    () => !viewable?.entitlement && getButtonProps(viewable?.offers),
    [viewable?.offers],
  );

  const renderButtonToWatchView = labelId => (
    <ClickTracker
      component="ContentList"
      eventName="click_asset"
      action="click"
      clickType="asset"
      collectionId={collectionId}
      categoryKind={BLOCK_INTERFACE_TYPES.FeaturedCollection}
      viewableId={viewableId}
      extra={getExtra(viewable, videoPreview, isLoggedIn)}
    >
      <PrimaryButton
        fixedWidth
        variant="brand"
        to={{
          name: 'watch',
          params: { id: viewableId },
        }}
        as={Link}
      >
        <Text
          id={labelId}
          fontWeight="600"
        />
      </PrimaryButton>
    </ClickTracker>
  );

  const offerButtonClickHandler = useOfferButtonClickHandler(viewable);

  const getPaymentButtons = () => buttons.map(button => (
    <Box key={button.types}>
      <AsyncButton
        fixedWidth
        alignCenter={false}
        onClick={() => offerButtonClickHandler(button.types)}
      >
        <Text id={button.text} />
      </AsyncButton>
    </Box>
  ));

  if (!isLoggedIn) {
    return (
      <Box flexBox wrap>
        <Box fullWidth mb="medium">
          <Text
            as={Link}
            id="featured.readMore"
            bold
            fontSize="smedium"
            dropShadow="featured"
            hover={null}
            color="light"
            textDecoration="none"
            to={{
              name: 'watch',
              params: { id: viewableId },
            }}
          />
        </Box>

        {!viewable.entitlement && (
          <Box fullWidth flexBox wrap gap="0.5rem">
            {getPaymentButtons()}
          </Box>
        )}
      </Box>
    );
  }

  if (!viewable.entitlement && !isSignUpViaMovistar) {
    return (buttons?.length) ? (
      <>
        <Box fullWidth mb="medium">
          <Text
            as={Link}
            id="featured.readMore"
            bold
            fontSize="smedium"
            dropShadow="featured"
            hover={null}
            color="light"
            textDecoration="none"
            to={{
              name: 'watch',
              params: { id: viewableId },
            }}
          />
        </Box>
        <Box fullWidth flexBox wrap gap="0.5rem">
          {getPaymentButtons()}
        </Box>
      </>
    ) : renderButtonToWatchView('featured.learnMore');
  }

  let labelId = 'featured.watch';
  if (liveEventState === LIVE_EVENT_STATES.live) {
    labelId = 'featured.watchLive';
  } else if (liveEventState === LIVE_EVENT_STATES.upcomingToday
    || liveEventState === LIVE_EVENT_STATES.upcoming) {
    labelId = 'featured.learnMore';
  }

  return renderButtonToWatchView(labelId);
}

ActionButtons.propTypes = {
  viewableId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  viewable: viewableShape.isRequired,
};
