/* eslint-disable no-console */
import { format } from 'date-fns';

const isNode = process?.title !== 'browser';

let bunyanLogger;
if (isNode) {
  // eslint-disable-next-line global-require
  const bunyan = require('bunyan');
  bunyanLogger = bunyan.createLogger({
    name: 'web-mamo',
    level: process.env.LOG_LEVEL || 'warn',
  });
}

const createLogger = level => (...msg) => {
  if (isNode) {
    bunyanLogger[level](...msg);
  } else if (!isNode && console[level] && process.env.NODE_ENV !== 'production') {
    console[level](`${format(new Date(), 'yyyy-MM-dd HH:mm:ss,SSS')} ${level.toUpperCase()} web-mamo`, ...msg);
  }
};

export default {
  error: createLogger('error'),
  warn: createLogger('warn'),
  info: createLogger('info'),
  debug: createLogger('debug'),
  trace: createLogger('trace'),
};
