import React from 'react';
import { viewableShape } from 'types/viewableShape';
import { Box, Text } from 'components';
import Tag from './Tag';
import ViewableImage from './ViewableImage';

const AboutTab = ({ viewable }) => {
  const {
    description,
    providedBy,
    tagsToRender,
  } = viewable;

  const tags = tagsToRender?.map(tag => (<Tag data={tag} key={tag.translationKey} />));

  return (
    <Box
      wrap
      row
      lineHeight="1.5em"
    >
      <Box
        width="20%"
        maxWidth="17.3rem" // see detailsPoster img size
        xs-display="none"
        mr="xlarge"
        flex="0 0 auto"
        mb="medium"
      >
        <ViewableImage viewable={viewable} />
      </Box>

      <Box
        flex="1 1 0"
        row
        wrap
      >
        <Box
          flex="1 1 0"
          mb="medium"
        >
          <Text whiteSpace="pre-wrap">
            {description}
          </Text>
        </Box>

        <Box
          hideEmpty={false}
          md-hideEmpty={false}
          md-width="30vw"
          md-ml="5vw"
          mb="medium"
          flex="0 0 100%"
          md-flex="0 0 auto"
        >
          {providedBy && (
            <Tag data={{
              translationKey: 'productionMetadata.label.providedBy',
              values: [providedBy.brand],
              searchable: false,
            }}
            />
          )}

          {tags}
        </Box>
      </Box>
    </Box>
  );
};

AboutTab.propTypes = {
  viewable: viewableShape.isRequired,
};

export default AboutTab;
