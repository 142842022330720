export default class ObservableInterval {
  intervalMs = 1000;

  _interval = null;
  _listeners = new Set();

  constructor(intervalMs) {
    this.intervalMs = intervalMs;
  }

  subscribe(cb) {
    this._listeners.add(cb);
    if (this._listeners.size === 1) {
      this._start();
    }
  }

  unsubscribe(cb) {
    this._listeners.delete(cb);
    if (this._listeners.size === 0) {
      this._stop();
    }
  }

  _start() {
    this._interval = setInterval(this._notifyListeners, this.intervalMs);
  }

  _stop() {
    clearInterval(this._interval);
    this._interval = null;
  }

  _notifyListeners = () => {
    for (const listener of this._listeners) {
      listener();
    }
  };
}
