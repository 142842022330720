import * as React from 'react';
import { Box } from 'components/StyledSystem/Box';
import { ITEM_MODE } from 'components/VideoItems/constants';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { ValueOf, ViewableType } from 'types';

const defaultProps = {
  refreshData: () => null,
};

type PropsType = {
  items: ViewableType[],
  refreshData?: (viewable: ViewableType) => Promise<void>,
  onClick: () => void,
  ItemComponent: React.ElementType,
  loadMore: React.ReactNode,
  hideChannelLogo: boolean,
  categoryKind?: ValueOf<typeof BLOCK_INTERFACE_TYPES>,
  collectionId?: string,
} & typeof defaultProps;

function MetadataListLayout(props: PropsType) {
  const {
    items,
    refreshData,
    onClick,
    ItemComponent,
    loadMore,
    hideChannelLogo,
    collectionId,
    categoryKind,
  } = props;

  return (
    <Box
      column
      alignItems="flex-start"
      lg-maxWidth="50%"
      md-maxWidth="75%"
      maxWidth="100%"
    >
      {items.map(viewable => (
        <ItemComponent
          key={viewable.id}
          viewable={viewable}
          onClick={onClick}
          mode={ITEM_MODE.LIST}
          hideChannelLogo={hideChannelLogo}
          refreshData={() => refreshData(viewable)}
          collectionId={collectionId}
          categoryKind={categoryKind}
        />
      ))}
      {loadMore}
    </Box>
  );
}

MetadataListLayout.defaultProps = defaultProps;

export default React.memo(MetadataListLayout);
