import React, { useContext, useEffect, useState } from 'react';
import {
  ParentalControlModal,
} from 'components';
import { PINCODE_ERRORS } from 'utils/constants';
import { PlayerContext } from './PlayerContext';

const PlayerParentalControlModal = () => {
  const {
    player,
    retryWithPincode: retryWithPinCode,
    stop: stopPlayer,
    clearPinCodeRequirement,
  } = useContext(PlayerContext);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const onIncorrectPinCode = () => {
      setError(PINCODE_ERRORS.WRONG_PIN_CODE);
      setPending(false);
    };

    if (player) {
      player.addEventListener('entitlement:incorrect-pincode', onIncorrectPinCode);
    }

    return () => {
      if (player) {
        player.removeEventListener('entitlement:incorrect-pincode', onIncorrectPinCode);
      }
    };
  }, []);

  const submitPinCode = (pinCode) => {
    setPending(true);
    setError('');
    retryWithPinCode(pinCode);
  };

  const handleClose = () => {
    stopPlayer();
    clearPinCodeRequirement();
  };

  return (
    <ParentalControlModal
      onClose={handleClose}
      submitPinCode={submitPinCode}
      error={error}
      pending={pending}
    />
  );
};

export default PlayerParentalControlModal;
