import React from 'react';
import {
  Text,
} from 'components';
import ErrorPage from './ErrorPage';

export default function GeneralErrorPage() {
  return (
    <ErrorPage button="retry">
      <Text id="error" />
    </ErrorPage>
  );
}
