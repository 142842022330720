import * as React from 'react';
import { Router } from './router';

export const RouterContext = React.createContext<Router | undefined>(undefined);

export function useRouter(): Router {
  const router = React.useContext(RouterContext);

  if (!router) {
    throw new Error('Router context is unavailable');
  }

  return router;
}
