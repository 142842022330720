import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'react-fela';
import { logoDarkOrLightUrl } from 'utils/helpers';
import { Wrapper, ChannelButton } from './ChannelPickerStyles';

export const myListChannelShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoLight: PropTypes.string,
  logoDark: PropTypes.string,
});

const onClick = (channelId, favoriteChannelIds, addFavorite, removeFavorite) => {
  if (favoriteChannelIds.includes(channelId)) {
    removeFavorite(channelId);
  } else {
    addFavorite(channelId);
  }
};

const ChannelPicker = (props) => {
  const {
    channels,
    favoriteChannelIds,
    addFavorite,
    removeFavorite,
    theme,
  } = props;
  const items = channels.map((channel) => {
    const isFavorite = favoriteChannelIds.includes(channel.id);

    return (
      <ChannelButton
        key={channel.id}
        disabled={!isFavorite}
        isFavorite={isFavorite}
        onClick={() => onClick(channel.id, favoriteChannelIds, addFavorite, removeFavorite)}
        style={{ backgroundImage: logoDarkOrLightUrl(channel, theme) }}
      />
    );
  });

  return (
    <Wrapper>
      {items}
    </Wrapper>
  );
};

ChannelPicker.propTypes = {
  favoriteChannelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  channels: PropTypes.arrayOf(myListChannelShape).isRequired,
  removeFavorite: PropTypes.func.isRequired,
  addFavorite: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ChannelPicker);
