import React from 'react';
import { Grid, imageTypes } from 'components';
import SixteenNineItem from 'components/VideoItems/SixteenNineItem';
import RegularItem from 'components/VideoItems/RegularItem';
import { ITEM_MODE } from 'components/VideoItems/constants';
import { ViewableType } from 'types';

interface ILinkedCollectionViewablesTabProps {
  viewables: ViewableType[],
  is16x9?: boolean,
}

const LinkedCollectionViewablesTab: React.FC<ILinkedCollectionViewablesTabProps> = (props) => {
  const { viewables, is16x9 } = props;
  const gridItemWidth = is16x9 ? imageTypes.sixteenNineBanner.width : imageTypes.poster.width;

  const itemMapper = is16x9
    ? (viewable: ViewableType) => (
      <SixteenNineItem
        hideKind
        hideChannelLogo
        key={viewable.id}
        viewable={viewable}
        mode={ITEM_MODE.RELATED}
      />
    )
    : (viewable: ViewableType) => (
      <RegularItem
        disableLiveInfo
        hideChannelLogo
        key={viewable.id}
        viewable={viewable}
        mode={ITEM_MODE.GRID}
      />
    );

  return (
    <Grid itemWidth={gridItemWidth} is16x9={is16x9}>
      {viewables.map(itemMapper)}
    </Grid>
  );
};

export default React.memo(LinkedCollectionViewablesTab);
