import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { replaceWebviewParam } from 'utils/helpers';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import { IFrame } from 'components';

const WebView = ({ location }) => {
  const menuItem = useSelector(
    state => state.settings.navigation.menu.find(item => item._id === location.params.id),
  );
  const userId = useSelector(state => state.auth.userId);

  if (!menuItem) {
    return <NotFoundView />;
  }

  // replace [userId] in the url with current user id
  const url = replaceWebviewParam(menuItem.url, 'userId', userId);

  return (
    <IFrame
      title={menuItem.title}
      url={url}
    />
  );
};

WebView.propTypes = {
  location: PropTypes.object.isRequired,
};

export default WebView;
