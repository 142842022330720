import React from 'react';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { Box } from 'components/index';
import { TITLE_TYPES, UpcomingTitle } from 'components/Upcoming';
import { viewableShape } from 'components/VideoItems/commonPropTypes';

const GridTitles = ({ viewable }) => {
  const upcomingTimestamp = extractUpcomingStartTime(viewable);

  return upcomingTimestamp ? (
    <Box mb="fine">
      <UpcomingTitle
        type={TITLE_TYPES.POSTER}
        startTime={upcomingTimestamp}
        opacity={0.7}
      />
    </Box>
  ) : null;
};

GridTitles.propTypes = {
  viewable: viewableShape,
};

export default React.memo(GridTitles);
