import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  I18nContext,
  I18n,
} from './I18nContext';
import { DateFnsLocaleContext } from './DateFnsLocaleContext';

function I18nProvider({ language, children }) {
  const copy = useSelector(state => state.copy[language]);
  const dateFnsLocale = React.useContext(DateFnsLocaleContext);

  const i18n = React.useMemo(
    () => new I18n(language, copy, dateFnsLocale),
    [language, copy, dateFnsLocale],
  );

  return (
    <I18nContext.Provider value={i18n}>
      {children}
    </I18nContext.Provider>
  );
}
I18nProvider.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default React.memo(I18nProvider);
