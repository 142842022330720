const VISIBILITY_THRESHOLD = [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
const MIN_VISIBILITY = 0.2;

/*
 * Play/pause video depends on visibility or priority
 * Pause if:
 * 1. Visible part < 20%
 * 2. Another browser tab is active
 * 3. Tooltip preview has bigger priority
 * 4. Smaller carousel/featured if 2+ are present on screen
 */

// [id => { play, pause, ratio, important}]
const componentsMap = new Map();

// Play most visible/important video. Pause all others
const playPauseComponents = () => {
  let componentToPlay;

  // find component with the biggest ratio. Pause others
  componentsMap.forEach((c) => {
    if (
      !componentToPlay
      || c.important // tooltip preview has bigger priority
      || (c.ratio > componentToPlay.ratio && !componentToPlay.important)
    ) {
      if (componentToPlay) componentToPlay.pause();
      componentToPlay = c;
    } else {
      c.pause();
    }
  });

  // Play if possible
  if (
    componentToPlay
    && (componentToPlay.ratio >= MIN_VISIBILITY || componentToPlay.important)
    && !document.hidden // play video only if browser tab is active
  ) {
    componentToPlay.play();
  } else {
    componentToPlay?.pause();
  }
};

// update component's visibility ratio
const updateRatio = (entries) => {
  entries.forEach((e) => {
    const component = componentsMap.get(e.target.id);

    if (component) {
      component.ratio = e.intersectionRatio;
    }
  });

  playPauseComponents();
};

let visibilityObserver;
// trigger callback if some component change visibility
const initVisibilityObserver = () => {
  visibilityObserver = new IntersectionObserver(
    updateRatio,
    { root: null, threshold: VISIBILITY_THRESHOLD },
  );
};

const initTabVisibility = () => {
  document.addEventListener('visibilitychange', playPauseComponents);
};

const init = () => {
  initVisibilityObserver();
  initTabVisibility();
};

export default {
  add: (el, controls) => {
    if (!visibilityObserver) init();

    if (componentsMap.get(el.id)) return;

    componentsMap.set(el.id, controls);
    visibilityObserver.observe(el);
  },
  remove: (el) => {
    if (!visibilityObserver || !el) return;

    componentsMap.delete(el.id);
    visibilityObserver.unobserve(el);

    playPauseComponents();
  },
};
