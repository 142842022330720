import React from 'react';
import PropTypes from 'prop-types';
import { useFela } from 'react-fela';
import { Icon } from 'components';

export default function ListIcon({ selected, ...props }) {
  const { theme } = useFela();
  const [hover, setHover] = React.useState(false);

  const active = selected || hover;

  return (
    <Icon
      {...props}
      viewBox="0 0 39 27"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-cy="list-icon"
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect width="38" height="26" x="-.5" y="-.5" stroke={theme.color.brand} rx="3" fill={active ? theme.color.brand : 'none'} />
        <g fill={active ? theme.color.typeSwitchColor : theme.color.brand} transform="translate(2 3)">
          <rect width="8" height="8" rx="2" />
          <rect width="8" height="8" y="10" rx="2" />
          <rect width="20" height="4" x="11" y="2" rx="2" />
          <rect width="20" height="4" x="11" y="12" rx="2" />
        </g>
      </g>
    </Icon>
  );
}
ListIcon.propTypes = {
  selected: PropTypes.bool.isRequired,
};
