import { useEffect } from 'react';
import PropTypes from 'prop-types';

const RefreshOnExpire = ({ expiresAt, refreshAction, frequencyInMs = 1000, children }) => {
  useEffect(() => {
    const checkExpirationInterval = setInterval(() => {
      if (new Date().getTime() / 1000 > expiresAt) {
        clearInterval(checkExpirationInterval);
        refreshAction();
      }
    }, frequencyInMs);

    return () => {
      clearInterval(checkExpirationInterval);
    };
  }, [expiresAt, refreshAction, frequencyInMs]);

  return children;
};

RefreshOnExpire.propTypes = {
  expiresAt: PropTypes.number.isRequired,
  refreshAction: PropTypes.func.isRequired,
  frequencyInMs: PropTypes.number,
  children: PropTypes.node,
};

export default RefreshOnExpire;
