import createComponent from 'styles/fela/createComponent';
import { PulsingButton } from 'components';

export const ChangePasswordLinkButton = createComponent(({ theme }) => ({
  fontSize: theme.fontSize.small,
}), PulsingButton);

export const AccountSettingsForm = createComponent(() => ({
  maxWidth: '20rem',
}), 'form');
