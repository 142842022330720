import {
  SET_SCROLLABLE_OFFSET,
  SET_SCROLLABLE_SIZE,
  SCROLLABLE_SCROLL_LEFT,
  SCROLLABLE_SCROLL_RIGHT,
  SET_CLEAR_SCROLLABLE_OFFSET,
  RESET_SCROLLABLE_OFFSET,
  ScrollableActions,
} from 'actions/scrollable';
import { RootState } from 'reducers';

export type ScrollableState = {
  width: number,
  fullWidth: number,
  offset: number,
  itemWidth: number,
  spacerWidth: number,
  marginLeftPx: number,
  length: number,
  hasPrev: boolean,
  hasNext: boolean,
};
export type ScrollableReducerState = Record<string, Record<string, ScrollableState>>;
const defaultState: ScrollableReducerState = {};

const defaultScrollableState = {
  width: 0,
  fullWidth: 0,
  offset: 0,
  itemWidth: 0,
  spacerWidth: 0,
  marginLeftPx: 0,
  length: 0,
  hasPrev: false,
  hasNext: false,
};

export const getScrollableState = (
  state: RootState,
  pageId: string,
  id: string,
): ScrollableState => state.scrollable[pageId]?.[id] || defaultScrollableState;

const normalizeOffset = (offset: number, width: number, fullWidth: number): number => {
  if (width >= fullWidth) {
    return 0;
  }

  return Math.min(Math.max(offset, 0), fullWidth - width);
};

export function scrollableReducer(
  state = defaultState,
  action: ScrollableActions,
): ScrollableReducerState {
  switch (action.type) {
    case SET_SCROLLABLE_OFFSET: {
      const scrollableState = state[action.pageId]?.[action.id] || defaultScrollableState;
      let minOffset = 0;
      let newOffset = normalizeOffset(
        action.offset,
        scrollableState.width,
        scrollableState.fullWidth,
      );

      const cycleWidth = scrollableState.length * scrollableState.itemWidth;

      if (cycleWidth) {
        minOffset = Math.floor(scrollableState.offset / cycleWidth) * cycleWidth;
      }

      newOffset = Math.max(newOffset, minOffset);

      return {
        ...state,

        [action.pageId]: {
          ...state[action.pageId],

          [action.id]: {
            ...scrollableState,
            offset: newOffset,
            hasPrev: newOffset !== minOffset,
            hasNext: newOffset + scrollableState.width < scrollableState.fullWidth,
          },
        },
      };
    }
    case SET_CLEAR_SCROLLABLE_OFFSET: {
      const scrollableState = state[action.pageId]?.[action.id] || defaultScrollableState;
      const newOffset = normalizeOffset(
        action.offset,
        scrollableState.width,
        scrollableState.fullWidth,
      );

      return {
        ...state,

        [action.pageId]: {
          ...state[action.pageId],

          [action.id]: {
            ...scrollableState,
            offset: newOffset,
          },
        },
      };
    }

    case SET_SCROLLABLE_SIZE: {
      const scrollableState = state[action.pageId]?.[action.id] || defaultScrollableState;
      const newOffset = normalizeOffset(scrollableState.offset, action.width, action.fullWidth);

      return {
        ...state,

        [action.pageId]: {
          ...state[action.pageId],

          [action.id]: {
            width: action.width,
            fullWidth: action.fullWidth,
            offset: newOffset,
            itemWidth: action.itemWidth || 0,
            spacerWidth: action.spacerWidth || 0,
            marginLeftPx: action.marginLeftPx || 0,
            length: action.length,
            hasPrev: scrollableState.hasPrev,
            hasNext: newOffset + scrollableState.width < scrollableState.fullWidth,
          },
        },
      };
    }

    case SCROLLABLE_SCROLL_LEFT: {
      const scrollableState = state[action.pageId]?.[action.id] || defaultScrollableState;
      const minOffset = 0;
      let newOffset = normalizeOffset(
        scrollableState.offset - scrollableState.width,
        scrollableState.width,
        scrollableState.fullWidth,
      );

      if (newOffset !== 0
          && scrollableState.itemWidth) {
        newOffset = Math.ceil((newOffset + scrollableState.spacerWidth) / scrollableState.itemWidth)
            * scrollableState.itemWidth;
      }

      newOffset = Math.max(newOffset, minOffset);

      return {
        ...state,

        [action.pageId]: {
          ...state[action.pageId],

          [action.id]: {
            ...scrollableState,
            offset: newOffset,
            hasPrev: newOffset !== minOffset,
            hasNext: newOffset + scrollableState.width < scrollableState.fullWidth,
          },
        },
      };
    }

    case SCROLLABLE_SCROLL_RIGHT: {
      const scrollableState = state[action.pageId]?.[action.id] || defaultScrollableState;
      const minOffset = 0;
      let newOffset = normalizeOffset(
        scrollableState.offset + scrollableState.width,
        scrollableState.width,
        scrollableState.fullWidth,
      );

      // scroll by portion of full items
      if (
        newOffset + scrollableState.width !== scrollableState.fullWidth
        && scrollableState.itemWidth
      ) {
        const spaceWithoutSpacer = newOffset - scrollableState.spacerWidth;
        // remove first item as it doesn't have margin left, so it is not standard item width
        const spaceWithoutFirstItem = spaceWithoutSpacer - scrollableState.itemWidth
            + scrollableState.marginLeftPx;
        // add back first item to number of item count
        const numberOfItems = Math.floor(spaceWithoutFirstItem / scrollableState.itemWidth) + 1;
        newOffset = numberOfItems * scrollableState.itemWidth;
      }

      return {
        ...state,

        [action.pageId]: {
          ...state[action.pageId],

          [action.id]: {
            ...scrollableState,
            offset: newOffset,
            hasPrev: newOffset !== minOffset,
            hasNext: newOffset + scrollableState.width < scrollableState.fullWidth,
          },
        },
      };
    }

    case RESET_SCROLLABLE_OFFSET: {
      if (!state[action.pageId] || Object.keys(state[action.pageId]).length === 0) return state;

      return {
        ...state,

        [action.pageId]: {},
      };
    }

    default: {
      return state;
    }
  }
}
