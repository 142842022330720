export enum OfferTypes {
  DefaultType = 'DefaultType',
  PassType = 'PassType',
  ThirdPartyType = 'ThirdPartyType',
  BuyType = 'BuyType',
  RentType = 'RentType',
  SubscribeType = 'SubscribeType',
}

export enum TrialPeriod {
  DAYS = 'DAYS',
  SECONDS = 'SECONDS',
}

export enum EntitlementStatus {
  active = 'active',
  cancelled = 'cancelled',
  expired = 'expired',
}

export enum RecurringPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum PaymentMethods {
  PAYPAL = 'paypal',
  GOOGLEPAY = 'googlepay',
  APPLEPAY = 'applepay',
  CREDITCARD = 'credit_card',
}

export enum LayoutObjectTypes {
  channelLogo = 'channelLogo',
  textString = 'textString',
  textMultiFields = 'textMultiFields',
  textKeyAndList = 'textKeyAndList',
  buttonCta = 'buttonCta',
}

export enum FieldToRenderTypes {
  title = 'title',
  synopsis = 'synopsis',
  kind = 'kind',
  genres = 'genres',
  productionYear = 'productionYear',
  rating = 'rating',
  duration = 'duration',
  directors = 'directors',
  cast = 'cast',
  description = 'description', // to go with Promo Shape.
  customTags1 = 'customTags1',
  customTags2 = 'customTags2',
  customTags3 = 'customTags3',
  customTags4 = 'customTags4',
  customTags5 = 'customTags5',
  customTags6 = 'customTags6',
  customTags7 = 'customTags7',
  customTags8 = 'customTags8',
  customTags9 = 'customTags9',
  customTags10 = 'customTags10',
  collectionTitle = 'collectionTitle',
  collectionDescription = 'collectionDescription',
  collectionCtaLabel = 'collectionCtaLabel',
}

export enum ScreenSizeStyles {
  bigScreenStyle = 'bigScreenStyle',
  mediumScreenStyle = 'mediumScreenStyle',
  smallScreenStyle = 'smallScreenStyle',
}
