import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
} from 'components';
import GooglePayLogo from './GooglePayDisplayStyles';

export default function GooglePayDisplay({ center }) {
  return (
    <>
      <Box mb="medium">
        <Text id="googlePayPayment" />
      </Box>

      <Box my="medium">
        <GooglePayLogo center={center} />
      </Box>
    </>
  );
}

GooglePayDisplay.propTypes = {
  center: PropTypes.bool,
};

GooglePayDisplay.defaultProps = {
  center: false,
};
