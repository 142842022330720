import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'react-fela';
import { rem2px } from 'utils/helpers';

const Anchor = ({ as: Element = 'div', theme, id, ...otherProps }) => {
  const scrollIntoView = () => {
    const el = document.getElementById(id);
    if (!el) {
      throw new Error(`Anchor failed: can't find element with id ${id}`);
    }

    el.scrollIntoView();

    // take header height into account
    const headerHeight = rem2px(parseFloat(theme.header.height, 10));
    document.documentElement.scrollTop -= headerHeight;
  };

  useEffect(() => {
    if (window.location.hash === `#${id}`) {
      if (document.readyState === 'complete') {
        scrollIntoView();
      } else {
        document.addEventListener('readystatechange', scrollIntoView);
      }
    }

    return () => {
      document.removeEventListener('readystatechange', scrollIntoView);
    };
  }, []);

  return (
    <Element id={id} {...otherProps} />
  );
};

Anchor.propTypes = {
  id: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  theme: PropTypes.object.isRequired,
};

export default withTheme(Anchor);
