export const progressBarWrapper = ({ theme }) => ({
  backgroundColor: theme.color.progressTrack,
  borderBottomLeftRadius: theme.radius.poster,
  borderBottomRightRadius: theme.radius.poster,
  height: '0.5rem',
  overflow: 'hidden',
  position: 'absolute',
  bottom: '0',
  width: '100%',
});

export const fill = ({ theme }) => ({
  background: theme.color.brand,
  height: '0.5rem',
});
