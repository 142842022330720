import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  useValidations,
} from '../utils';
import DatePicker from './DatePicker';

function DatePickerField(props) {
  const {
    name,
    placeholder,
    validations,
  } = props;

  const {
    errors,
    formState: {
      dirtyFields,
      touched,
      isSubmitted,
    },
    control,
  } = useFormContext();

  const allValidations = useValidations(validations);

  const dirty = !!dirtyFields[name];
  const error = errors[name];

  return (
    <Controller
      control={control}
      name={name}
      rules={{ validate: allValidations }}
      defaultValue=""
      render={({ onChange, onBlur }) => (
        <DatePicker
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          dirty={dirty}
          error={error}
          touched={touched[name]}
          isSubmitted={isSubmitted}
        />
      )}
    />
  );
}
DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  validations: PropTypes.object,
};

export default React.memo(DatePickerField);
