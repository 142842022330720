import { IUser } from 'types/api.user';
import { GET_USER, GET_USER_TAGS, UserActions } from 'actions/user';
import { PLATFORMS } from 'utils/constants';

export interface IUserReducerState
  extends Pick<IUser, 'locale' | 'openIds' | 'name' | 'tags' | 'emailOptIn' | 'signupInfo'> {
  contactEmail: string;
  market: string;
  isSignUpViaMovistar: boolean;
}

const defaultState: IUserReducerState = {
  contactEmail: '',
  emailOptIn: false,
  locale: '',
  market: '',
  openIds: {},
  name: '',
  tags: [],
  signupInfo: {},
  isSignUpViaMovistar: false,
};

export function userReducer(state = defaultState, action: UserActions): IUserReducerState {
  switch (action.type) {
    case GET_USER: {
      return {
        ...state,
        contactEmail: action.data.email,
        emailOptIn: !!action.data.emailOptIn,
        locale: action.data.locale,
        market: action.data.country,
        openIds: action.data.openIds,
        name: action.data.name,
        signupInfo: action.data.signupInfo,
        isSignUpViaMovistar: Boolean(action.data.signupInfo?.devicePlatform === PLATFORMS.MOVISTAR),
      };
    }

    case GET_USER_TAGS: {
      return {
        ...state,
        tags: action.data,
      };
    }

    default: {
      return state;
    }
  }
}
