import React from 'react';
import { Text } from 'components';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';

const StepDenied = () => (
  <ErrorPage icon={ERROR_ICONS.NOT_FOUND}>
    <Text id="deviceAuth.requestCanceled" />
  </ErrorPage>
);

export default StepDenied;
