import createComponent from 'styles/fela/createComponent';
import { maxWidth, minWidth, widthRange } from 'styles/fela/mixins';
import { BREAKPOINTS } from 'utils/constants';

const Hidden = createComponent(({ when, preserveSpace, from, to }) => {
  const style = preserveSpace ? { visibility: 'hidden' } : { display: 'none' };

  return {
    extend: [
      {
        condition: when,
        style,
      },
      {
        condition: from && to,
        style: widthRange(from === 'xs' ? 0 : BREAKPOINTS[from], BREAKPOINTS[to], style),
      },
      {
        condition: from && !to,
        style: minWidth(from === 'xs' ? 0 : BREAKPOINTS[from], style),
      },
      {
        condition: !from && to,
        style: maxWidth(BREAKPOINTS[to] - 1, style),
      },
    ],
  };
}, 'div', ['when', 'preserveSpace', 'from', 'to']);

Hidden.displayName = 'Hidden';

export default Hidden;
