import createComponent from 'styles/fela/createComponent';
import { withBasePadding } from 'styles/fela/mixins';

const Content = createComponent(({ theme, limitWidth }) => ({
  color: theme.color.primary,
  fontSize: theme.fontSize.normal,
  paddingTop: theme.margin.large,
  paddingBottom: theme.margin.large,
  width: '100%',
  extend: [
    withBasePadding(),
    {
      condition: limitWidth,
      style: {
        maxWidth: '1280px',
      },
    },
  ],
}), 'div', ['limitWidth', 'basePadding']);

Content.displayName = 'Hidden';

export default Content;
