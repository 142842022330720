import { Middleware } from 'redux';
import thunk from 'redux-thunk';
import { Router } from 'router';
import { createRouterMiddleware } from 'router/routerMiddleware';
import { createNetworkRequestMiddleware } from './network-request-middleware';
import { createApiMiddleware } from './api';
import { createGraphqlMiddleware } from './graphql';

export function createReduxMiddlewares(requestHeaders = {}, router: Router): Middleware[] {
  return [
    thunk,
    router && createRouterMiddleware(router),
    createNetworkRequestMiddleware(),
    createApiMiddleware(requestHeaders),
    createGraphqlMiddleware(requestHeaders),
  ].filter(middleware => !!middleware);
}
