import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
} from 'components';
import ErrorPage, { ERROR_ICONS } from './ErrorPage';

export default function NoOffersErrorPage({ hasEntitlement }) {
  return (
    <ErrorPage buttonType="none" icon={ERROR_ICONS.NOT_FOUND}>
      <Text id={hasEntitlement ? 'error.noAvailableOffersLeft' : 'error.noOffersAvailable'} />
    </ErrorPage>
  );
}

NoOffersErrorPage.propTypes = {
  hasEntitlement: PropTypes.bool,
};
