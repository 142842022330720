import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { ChevronArrowIcon } from 'components/Icons';
import { TIMEBAR_HEIGHT } from './Timebar/Timebar';

export const Epg = createComponent(({ theme }: ComponentRuleProps): RuleStyles => ({
  height: `calc(100vh - ${theme.header.height})`, // mandatory for IE
  backgroundColor: theme.color.epgBackground,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
}));

export const EPG_HEADER_HEIGHT = 45;

export const TimelineContainer = createComponent(
  ({ scrollBarWidth }: ComponentRuleProps): RuleStyles => ({
    position: 'absolute',
    top: EPG_HEADER_HEIGHT + TIMEBAR_HEIGHT,
    bottom: TIMEBAR_HEIGHT - scrollBarWidth,
    left: 0,
    right: -scrollBarWidth,
    overflow: 'hidden',
  }), 'div', ['scrollBarWidth'],
);

export const ArrowIcon = createComponent(
  ({ isVisible }: ComponentRuleProps): RuleStyles => ({
    margin: '0 0.7rem',
    height: '0.85rem',
    extend: [
      {
        condition: !isVisible,
        style: {
          opacity: 0,
          visibility: 'hidden',
        },
      },
    ],
  }), ChevronArrowIcon, ['isVisible'],
);
