import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import TrailerPlayer from 'views/WatchView/ViewableInfo/Trailer/TrailerPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'router/actions';
import { PlayerContext } from 'components/Player';
import { createRedirectLink } from 'utils/routing';
import { viewableShape } from 'types/viewableShape';
import { TrailerContext } from './TrailerContext';

export function TrailerProvider({ children, viewable }) {
  const [showTrailerModal, setShowTrailerModal] = useState(false);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const location = useSelector(state => state.router.location);
  const dispatch = useDispatch();
  const playerContext = useContext(PlayerContext);

  useEffect(() => {
    if (isLoggedIn && 'playTrailers' in location.query) {
      setShowTrailerModal(true);
    }
  }, [isLoggedIn, location.query]);

  const handleTrailerModalClose = () => {
    dispatch(push({
      ...location,
      query: {
        ...location.query,
        playTrailers: undefined,
      },
      suppressViewChange: true,
    }));

    setShowTrailerModal(false);
  };

  const playTrailer = useCallback(() => {
    if (viewable.trailers?.length && !isLoggedIn) {
      dispatch(push({
        name: 'create-account',
        query: {
          redirectTo: createRedirectLink({
            ...location,
            query: {
              ...location.query,
              playTrailers: true,
            },
          }),
        },
      }));
      return;
    }

    setShowTrailerModal(true);

    if (playerContext.viewableId) {
      if (playerContext.mini) {
        playerContext.stop();
      } else {
        playerContext.pause();
      }
    }
  }, [playerContext.viewableId]);

  const contextValue = useMemo(() => ({
    showTrailerModal,
    playTrailer,
    viewable,
  }), [viewable, playTrailer]);

  return (
    <TrailerContext.Provider value={contextValue}>
      {children}
      {
        showTrailerModal && (
          <TrailerPlayer
            title={viewable.title}
            trailerUrl={viewable.trailer}
            trailers={viewable.trailers}
            onClose={handleTrailerModalClose}
          />
        )
      }
    </TrailerContext.Provider>
  );
}

TrailerProvider.propTypes = {
  children: PropTypes.node,
  viewable: viewableShape,
};
