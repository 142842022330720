import React, { PureComponent } from 'react';

export const PlayerContext = React.createContext({});

export const withPlayerContext = mapContextToProps => Component => (
  class extends PureComponent {
    static contextType = PlayerContext;

    render() {
      return (
        <Component {...this.props} {...mapContextToProps(this.context, this.props)} />
      );
    }
  }
);
