import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const getTime = (currentTime) => {
  const date = new Date(currentTime * 1000);

  const hours = date.getHours();

  return [
    hours > 12 ? hours - 12 : hours,
    date.getMinutes(),
  ];
};

const getAngles = (currentTime) => {
  const [hours, minutes] = getTime(currentTime);

  return [
    (hours / 12) * 360,
    (minutes / 60) * 360,
  ];
};

const DynamicClockIcon = ({ currentTime, ...otherProps }) => {
  const [
    hoursAngle,
    minutesAngle,
  ] = getAngles(currentTime);

  return (
    <Icon
      {...otherProps}
      viewBox="0 0 40 40"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    >
      <line
        x1="0"
        y1="0"
        x2="0"
        y2="7"
        transform={`translate(20 20) rotate(${-180 + hoursAngle})`}
      />

      <line
        x1="0"
        y1="0"
        x2="0"
        y2="10"
        transform={`translate(20 20) rotate(${-180 + minutesAngle})`}
      />

      <circle
        cx="20"
        cy="20"
        r="16"
      />
    </Icon>
  );
};

DynamicClockIcon.propTypes = {
  currentTime: PropTypes.number.isRequired,
};

export default DynamicClockIcon;
