import createComponent from 'styles/fela/createComponent';
import {
  responsiveMd,
  responsiveXl,
  withBasePadding,
} from 'styles/fela/mixins';

const PanelWrapper = createComponent(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  extend: [
    withBasePadding(),
    responsiveMd({
      paddingTop: theme.margin.large,
    }),
    responsiveXl({
      paddingTop: theme.margin.xxlarge,
    }),
  ],
}));

PanelWrapper.displayName = 'PanelWrapper';

export default PanelWrapper;
