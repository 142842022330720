import React from 'react';
import Text from 'components/StyledSystem/Text';
import Link from 'components/StyledSystem/Link';

type Props = {
  consentText: string,
  url: string,
  urlLabel: string,
};

const TermsAndConditionsLink = ({ consentText, url, urlLabel }: Props): React.ReactElement => (
  <Text fontSize="fine">
    {consentText}
    {' '}
    <Link
      key="termsAndConditions"
      href={url}
      target="_blank"
    >
      {urlLabel}
    </Link>
    {' '}
  </Text>
);

export default TermsAndConditionsLink;
