import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as searchActions from 'actions/search';
import * as routerActions from 'router/actions';
import SearchInput from 'components/SearchInput/SearchInput';
import SearchLink from './SearchLink';

function Search(props) {
  const {
    asLink,
  } = props;

  const [isInputVisible, setIsInputVisible] = useState(false);
  const [keepFocus, setKeepFocus] = useState(false);

  const location = useSelector(state => state.router.location);

  const dispatch = useDispatch();

  useEffect(() => {
    if (asLink) {
      setIsInputVisible(false);
    } else {
      setIsInputVisible(isInputVisible || location.name === 'search');
    }
  }, [asLink, location]);

  const locationBeforeSearch = useRef({ name: 'home' });
  const prevLocation = useRef({ name: 'home' });

  useEffect(() => {
    // save location before search
    if (prevLocation.current.name !== 'search' && location.name === 'search') {
      locationBeforeSearch.current = prevLocation.current;
    }

    // hide input if not on search page
    if (prevLocation.current.name === 'search' && location.name !== 'search' && !keepFocus) {
      setIsInputVisible(false);
    }

    // save previous location
    prevLocation.current = location;
  }, [location, keepFocus]);

  const handleCloseSearch = (params) => {
    setIsInputVisible(params.forceHide ? false : isInputVisible);
    setKeepFocus(params.keepFocus || false);

    // redirect to original location when closing search
    if (location.name === 'search') {
      const pageBeforeSearch = params.keepFocus
        ? { ...locationBeforeSearch.current, query: { focus: 'search' } }
        : locationBeforeSearch.current;
      dispatch(routerActions.push(pageBeforeSearch));
    }
    if (location.name === 'watch' && location.query?.focus === 'search') {
      dispatch(routerActions.replacePassive({ ...location, query: { focus: undefined } }));
    }
  };

  const onClick = (e) => {
    if (!asLink) {
      setIsInputVisible(true);
      e.preventDefault();
    }
  };

  if (!isInputVisible) {
    return (
      <SearchLink
        onClick={onClick}
        iconSize={28}
      />
    );
  }

  return (
    <SearchInput
      autoFocus
      onSearch={(query, kinds) => dispatch(searchActions.search(query, kinds))}
      onClose={handleCloseSearch}
      key="SearchInput"
      iconSize={28}
    />
  );
}

Search.propTypes = {
  asLink: PropTypes.bool.isRequired,
};

export default React.memo(Search);
