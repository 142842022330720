import { onlyDigits } from 'utils/helpers';

export const CardType = {
  JCB: 'JCB',
  AMEX: 'AMEX',
  DINERS_CLUB: 'DINERS_CLUB',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  DISCOVER: 'DISCOVER',
  MAESTRO: 'MAESTRO',
};

const re = {
  jcb: /^(?:2131|1800|35)[0-9]{0,}$/,
  amex: /^3[47][0-9]{0,}$/,
  diners: /^3(?:0[0-59]{1}|[689])[0-9]{0,}$/,
  visa: /^4[0-9]{0,}$/,
  mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
  maestro: /^(5[06789]|6)[0-9]{0,}$/,
  discover: /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/,
};

export function detectCardType(creditCardNumber) {
  const number = onlyDigits(creditCardNumber || '');

  if (!number) {
    return null;
  }

  if (number.match(re.jcb)) {
    return CardType.JCB;
  }

  if (number.match(re.amex)) {
    return CardType.AMEX;
  }

  if (number.match(re.diners)) {
    return CardType.DINERS_CLUB;
  }

  if (number.match(re.visa)) {
    return CardType.VISA;
  }

  if (number.match(re.mastercard)) {
    return CardType.MASTERCARD;
  }

  if (number.match(re.discover)) {
    return CardType.DISCOVER;
  }

  if (number.match(re.maestro)) {
    if (number[0] === '5') {
      return CardType.MASTERCARD;
    }
    return CardType.MAESTRO;
  }
  return null;
}

export function cardTypeFromShort(shortCardType) {
  const cardType = shortCardType.toUpperCase();

  const shortToLong = {
    MC: 'MASTERCARD',
  };

  if (shortToLong[cardType]) {
    return shortToLong[cardType];
  }
  return cardType;
}

export function extractLastGroup(paymentMethod) {
  if (!paymentMethod?.data?.ccMaskedNumber) {
    return '';
  }
  // for Stripe, we get only last 4 numbers here
  if (paymentMethod.data.ccMaskedNumber.length <= 4) {
    return paymentMethod.data.ccMaskedNumber;
  }

  const cardType = cardTypeFromShort(paymentMethod.data.ccFinancialInstitution);
  // we receive 16 chars for card number even for American Express cards
  // but we should use only last 3 numbers
  return paymentMethod.data.ccMaskedNumber.substring(
    cardType === CardType.AMEX ? 13 : 12,
  );
}
