import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, LiveInfo, Text, CatchupCountdown } from 'components';
import { TITLE_TYPES, UpcomingTitle } from 'components/Upcoming';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import { extractLiveEventTime } from 'utils/live-event';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { VIEWABLE_TYPES } from 'utils/constants';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import { useIsSmallScreen, useAdditionalInfo } from 'utils/hooks';
import { useSelector } from 'reducers';

const ListTitles = ({ viewable, itemHeight, refreshData }) => {
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);
  const [maxLine, setMaxLine] = useState(11);
  const watchlistEnabled = useSelector(({ settings }) => !!settings.features.watchList);
  const liveEventTime = extractLiveEventTime(viewable);
  const upcomingTimestamp = extractUpcomingStartTime(viewable);
  const isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;
  const metadata = useAdditionalInfo(viewable);

  const correctViewable = useMemo(() => {
    const isEpisode = viewable.__typename === VIEWABLE_TYPES.Episode;
    return isEpisode && viewable.show ? viewable.show : viewable;
  }, [viewable]);

  const isAnyContent = Boolean(
    metadata
    || liveEventTime?.startTime
    || viewable.description
    || upcomingTimestamp
    || !isChannel,
  );

  const height = `${itemHeight + 0.25}rem`;
  const xsHeight = `${itemHeight - 6.25}rem`;

  const isMobile = useIsSmallScreen();

  useEffect(() => {
    if (descriptionRef.current) {
      const divHeight = parseFloat(
        window.getComputedStyle(containerRef.current, null)
          .getPropertyValue('height'),
      );
      const lineHeight = parseFloat(
        window.getComputedStyle(descriptionRef.current, null)
          .getPropertyValue('line-height'),
      );

      setMaxLine(Math.floor(divHeight / lineHeight));
    }
  }, []);

  return (
    <Box
      flexBox
      flexDirection="column"
      sm-ml="large"
      pt="0.25rem"
      flex="1 0"
      maxWidth="100%"
      xs-width="auto"
      xs-height="auto"
      minWidth="0px"
      {...(isChannel ? { maxHeight: height, mt: 'auto', mb: 'auto' } : { height })}
    >
      <Box mb={isAnyContent ? 3 : 0}>
        <Text bold>
          {viewable.title}
        </Text>
      </Box>

      {!!metadata && !isChannel && (
        <Box mb={3}>
          <Text color="secondary" fontSize="small" bold>
            {metadata}
            <CatchupCountdown viewable={viewable} />
          </Text>
        </Box>
      )}

      {liveEventTime && (
        <Box mb="fine">
          <LiveInfo liveEventTime={liveEventTime} onlyToday />
        </Box>
      )}

      <Box
        innerRef={containerRef}
        position="relative"
        overflow="hidden"
        flex="1 0"
        xs-maxHeight={xsHeight}
      >
        <Text innerRef={descriptionRef} fontSize="small" textNumberLines={maxLine}>
          {viewable.description}
        </Text>

        {upcomingTimestamp ? (
          <Box mt="fine">
            <UpcomingTitle
              type={TITLE_TYPES.POSTER}
              startTime={upcomingTimestamp}
              opacity={0.7}
            />
          </Box>
        ) : null}

      </Box>

      {!isChannel && watchlistEnabled && (
        <>
          <Box flexBox mt="fine">
            <WatchlistButton
              viewable={correctViewable}
              refreshData={refreshData}
              short={!isMobile}
            />
          </Box>
        </>
      )}

    </Box>
  );
};

ListTitles.propTypes = {
  viewable: viewableShape,
  itemHeight: PropTypes.number,
  refreshData: PropTypes.func,
};

export default React.memo(ListTitles);
