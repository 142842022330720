import React from 'react';
import { useSelector } from 'reducers';
import {
  PAYMENT_PROVIDERS,
} from 'utils/constants';
import {
  PaymentProviderFooter,
  AdyenResultHandler,
  SkipOnboardingButton,
  usePromoCode,
} from 'components/Payment';
import {
  Box,
  ErrorMessage,
  Heading,
  Text,
  AsyncButton,
  PanelWrapper,
  PanelContent,
} from 'components';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import PaymentOptionSelection, { PaymentOptions } from 'components/Payment/PaymentOptionSelection';
import { TOfferPurchasable } from 'views/Checkout/types';
import PrivacyPolicyLink from 'views/WatchView/PurchaseModal/PrivacyPolicyLink';
import TermsAndConditionsLink from 'views/WatchView/PurchaseModal/TermsAndConditions';
import { getTrialPeriodInDays } from './utils';
import PurchaseButton from './PurchaseButton';
import OfferInfo from './OfferInfo';
import AdyenPayment from './AdyenPayment';

interface ICheckoutViewProps {
  createAccount?: boolean;
  packageId?: string;
  promoCode?: string;
  offer?: TOfferPurchasable;
  redirectTo?: string;
  originalOfferId?: string;
}

function CheckoutView(props: ICheckoutViewProps) {
  const {
    createAccount,
    packageId = '',
    promoCode,
    offer,
    redirectTo, // user will be redirected here when press on Start watching btn on Receipt page
    originalOfferId, // an offer id to upgrade
  } = props;

  const {
    providerType,
  } = useSelector(state => ({
    providerType: state.settings.features.payment?.provider?.type,
  }));

  // Payment option. New or prev credit card. Only Stripe
  const [
    selectedPaymentOption,
    setSelectedPaymentOption,
  ] = React.useState<PaymentOptions>(PaymentOptions.EXISTING);

  // Do a payment by pass. Special promo code. No credit card required. Only Adyen
  const [purchaseBypass, setPurchaseBypass] = React.useState(false);

  const {
    isPromoCodeAllowed,
    error,
    data: {
      campaignName,
      bypassPaymentMethod,
      discount,
      usps,
    },
    loading,
  } = usePromoCode(promoCode, packageId);

  if (!offer) {
    return (
      <NotFoundView />
    );
  }

  const price = discount ? discount.price?.amount : offer.priceInCents;
  const reducedPrice = discount ? discount.reducedPrice?.amount : offer.priceInCents;

  const trialDays = 'trialPeriod' in offer && offer.trialPeriod && !bypassPaymentMethod
    ? getTrialPeriodInDays(offer.trialPeriod)
    : 0;

  return (
    <PanelWrapper className="e2e-checkout">
      <PanelContent centered width="100%">
        <Box mb="medium">
          <AdyenResultHandler />
        </Box>

        <Box mb="medium">
          {promoCode && error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </Box>

        <Box
          width="100%"
          maxWidth="45rem"
          textAlign="left"
        >
          <Heading
            id="payment.checkoutConfirmation"
            mb="medium"
            align="center"
          />

          <OfferInfo
            date={new Date()}
            usps={usps}
            offer={offer}
            price={price}
            packageId={packageId}
            promoCode={promoCode}
            hasDiscount={!!discount}
            reducedPrice={reducedPrice}
            campaignName={campaignName}
            promoCodeExpiry={discount?.reducedPrice?.expiry}
            // No promoCode and trialPeriod for offer upgrade
            trialDays={originalOfferId ? 0 : trialDays}
            isPromoCodeAllowed={isPromoCodeAllowed && !originalOfferId}
          />
        </Box>
      </PanelContent>

      {/* Adyen */}
      {/* Show adyen component */}
      { providerType === PAYMENT_PROVIDERS.ADYEN_DROPIN
        && (!bypassPaymentMethod || purchaseBypass)
        && !loading && (
          <AdyenPayment
            createAccount={createAccount}
            packageId={packageId}
            promoCode={discount ? promoCode : undefined}
            offer={offer}
            redirectTo={redirectTo}
            bypassPaymentMethod={bypassPaymentMethod}
            originalOfferId={originalOfferId}
          />
      )}

      {/* Confirm button for purchase by pass */}
      { providerType === PAYMENT_PROVIDERS.ADYEN_DROPIN
        && bypassPaymentMethod && !purchaseBypass && (
        <Box
          mt="xlarge"
          mb="medium"
        >
          <AsyncButton type="button" variant="brand" fixedWidth onClick={() => setPurchaseBypass(true)} infinite>
            <Text id="payment.bypassCheckoutBtn" />
          </AsyncButton>
        </Box>
      )}

      {/* Stripe */}
      { // Old or new credit card option
        reducedPrice > 0 && (
          <PaymentOptionSelection
            selected={selectedPaymentOption}
            onChange={setSelectedPaymentOption}
          />
        )
      }

      {/* Button that redirects user to Stripe side */}
      { providerType === PAYMENT_PROVIDERS.STRIPE && (
        <Box
          mt="xlarge"
          mb="medium"
        >
          <PurchaseButton
            id={offer.id}
            promoCode={error ? undefined : promoCode}
            bypassCheckout={bypassPaymentMethod}
            createAccount={createAccount}
            useExistingCard={selectedPaymentOption === PaymentOptions.EXISTING}
            redirectTo={redirectTo}
            originalOfferId={originalOfferId}
          />
        </Box>
      )}

      <Box
        maxWidth="45rem"
        mt="large"
        mb="small"
        textAlign="center"
      >
        <TermsAndConditionsLink />
        <PrivacyPolicyLink />
      </Box>

      <Box my="medium" row>
        <SkipOnboardingButton />
      </Box>

      <Box my="medium">
        <PaymentProviderFooter centered />
      </Box>
    </PanelWrapper>
  );
}

export default React.memo(CheckoutView);
