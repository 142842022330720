export default class Interval {
  listeners = new Set();
  intervalId = null;
  startTimeMs = null;
  intervalMs = null;

  constructor(intervalMs) {
    this.intervalMs = intervalMs;
    // rely on performance.now() to resist local time changes;
    this.startTimeMs = Date.now() - performance.now();
  }

  _getCurrentTime() {
    return this.startTimeMs + performance.now();
  }

  addListener(listener) {
    this.listeners.add(listener);

    // call it instantly to provide current time
    listener(this._getCurrentTime());

    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        const currentTimeMs = this._getCurrentTime();
        this.listeners.forEach(cb => cb(currentTimeMs));
      }, this.intervalMs);
    }
  }

  removeListener(listener) {
    this.listeners.delete(listener);

    if (!this.listeners.size) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
