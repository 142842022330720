import React from 'react';
import { useFela } from 'react-fela';

const IconButtonStyle = () => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'inherit',
  display: 'flex',
  height: '3rem',
  justifyContent: 'center',
  width: '3rem',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  padding: '0 1rem',
});

type IconButtonProps = React.HTMLAttributes<HTMLElement> & {
  children: React.ReactNode;
  onClick: () => void;
};

const IconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {
  const { css } = useFela();

  return (
    <button type="button" {...props} className={css(IconButtonStyle)}>
      {children}
    </button>
  );
};

export default IconButton;
