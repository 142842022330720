import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
} from 'components';
import PaypalLogo from './PaypalDisplayStyles';

export default function PaypalDisplay({ center }) {
  return (
    <>
      <Box mb="medium">
        <Text id="paypalPayment" />
      </Box>

      <Box my="medium">
        <PaypalLogo center={center} />
      </Box>
    </>
  );
}

PaypalDisplay.propTypes = {
  center: PropTypes.bool,
};

PaypalDisplay.defaultProps = {
  center: false,
};
