import React from 'react';
import Icon, { IconType } from './Icon';

export default function CalendarIcon(props: IconType): JSX.Element {
  return (
    <Icon {...props} width="32" height="32" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="15" fill="currentColor" stroke="#fff" strokeWidth="2" />
      <path fill="#fff" fillRule="evenodd" d="M12.354 9.5a.575.575 0 0 1 1.148 0v1.006h5.74V9.5a.575.575 0 0 1 1.148 0v1.007h.768c1.056 0 1.912.879 1.912 1.972v7.876c0 1.089-.859 1.972-1.912 1.972h-9.572c-1.056 0-1.912-.88-1.912-1.972v-7.876c0-1.09.859-1.972 1.912-1.972h.768V9.5ZM10.44 20.353V13.66h11.864v6.694c0 .653-.515 1.184-1.146 1.184h-9.571c-.635 0-1.147-.528-1.147-1.184Z" clipRule="evenodd" />
      <rect width="3.062" height="2.679" x="10.823" y="14.48" fill="#fff" rx="1.12" />
      <path fill="#fff" d="M10.823 19.044c0-.618.501-1.12 1.12-1.12h.821c.619 0 1.12.502 1.12 1.12v.44a1.12 1.12 0 0 1-1.12 1.12h-.821a1.12 1.12 0 0 1-1.12-1.12v-.44ZM18.669 15.6c0-.619.5-1.12 1.12-1.12h.821c.619 0 1.12.501 1.12 1.12v.439a1.12 1.12 0 0 1-1.12 1.12h-.822a1.12 1.12 0 0 1-1.12-1.12v-.44ZM18.669 19.044c0-.618.5-1.12 1.12-1.12h.821c.619 0 1.12.502 1.12 1.12v.44a1.12 1.12 0 0 1-1.12 1.12h-.822a1.12 1.12 0 0 1-1.12-1.12v-.44Z" />
      <rect width="3.636" height="2.679" x="14.459" y="14.48" fill="#fff" rx="1.12" />
      <path fill="#fff" d="M14.459 19.044c0-.618.5-1.12 1.12-1.12h1.395c.619 0 1.12.502 1.12 1.12v.44a1.12 1.12 0 0 1-1.12 1.12h-1.396a1.12 1.12 0 0 1-1.12-1.12v-.44Z" />
    </Icon>
  );
}
