import availableOffersQuery from 'queries/availableOffers.graphql';
import {
  OFFER_TYPE_TO_QUERY_TYPE,
  PURCHASE_FLOW_OFFER_TYPES,
  OFFER_TYPES,
  OfferType,
} from 'utils/constants';
import { graphqlRequest, AppAsyncAction } from './helpers';

export const FETCH_OFFERS = 'offers/FETCH_OFFERS';
interface FetchOffersAction {
  type: typeof FETCH_OFFERS
  data: any
  errors: Error[]
}
export const fetchOffers = (types: OfferType[]): AppAsyncAction => (
  async (dispatch) => {
    const variables = {
      type: types.map(offerType => OFFER_TYPE_TO_QUERY_TYPE[offerType]),
    };

    const {
      data,
      errors,
    } = await dispatch(graphqlRequest(availableOffersQuery, variables));

    dispatch({
      type: FETCH_OFFERS,
      data,
      errors,
    });
  });

export const fetchPurchaseFlowOffers = (): AppAsyncAction => fetchOffers(PURCHASE_FLOW_OFFER_TYPES);

export const fetchAllOffers = (): AppAsyncAction => fetchOffers(Object.values(OFFER_TYPES));

export type OffersActions = FetchOffersAction;
