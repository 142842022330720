import React from 'react';
import Icon, { IconType } from '../Icon';

const FullscreenOutIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
    <path fill="#fff" d="M60 101.667h8.333V110a4.179 4.179 0 0 0 4.167 4.167A4.179 4.179 0 0 0 76.667 110V97.5a4.179 4.179 0 0 0-4.167-4.167H60a4.179 4.179 0 0 0-4.167 4.167A4.18 4.18 0 0 0 60 101.667Zm8.333-33.334H60a4.179 4.179 0 0 0-4.167 4.167A4.179 4.179 0 0 0 60 76.667h12.5a4.179 4.179 0 0 0 4.167-4.167V60a4.179 4.179 0 0 0-4.167-4.167A4.179 4.179 0 0 0 68.333 60v8.333ZM97.5 114.167a4.18 4.18 0 0 0 4.167-4.167v-8.333H110a4.18 4.18 0 0 0 4.167-4.167A4.179 4.179 0 0 0 110 93.333H97.5a4.179 4.179 0 0 0-4.167 4.167V110a4.179 4.179 0 0 0 4.167 4.167Zm4.167-45.834V60a4.18 4.18 0 0 0-4.167-4.167A4.179 4.179 0 0 0 93.333 60v12.5a4.179 4.179 0 0 0 4.167 4.167H110a4.179 4.179 0 0 0 4.167-4.167A4.179 4.179 0 0 0 110 68.333h-8.333Z" />
  </Icon>
);

export default FullscreenOutIcon;
