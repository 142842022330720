import React, { useRef, useEffect, useState } from 'react';
import { imageWithSize, rem2px, hashCode } from 'utils/helpers';
import { useFela } from 'react-fela';
import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { GlobalTheme } from 'types';
import { extractThemeProp } from 'components/StyledSystem/Box';

export enum ImageTypes {
  default = 'default',
  poster = 'poster',
  bigPoster = 'bigPoster',
  smallPoster = 'smallPoster',
  detailsPoster = 'detailsPoster',
  featuredBanner = 'featuredBanner',
  smallBanner = 'smallBanner',
  episodeBanner = 'episodeBanner',
  sixteenNineBanner = 'sixteenNineBanner',
  gridSixteenNineBanner = 'gridSixteenNineBanner',
  listSixteenNineBanner = 'listSixteenNineBanner',
  genreCard = 'genreCard',
  offerBanner = 'offerBanner',
}

type ImageDimensions = {
  height: number,
  width: number,
};

const imageSizes = {
  [ImageTypes.default]: {
    height: 0,
    width: 0,
  },

  [ImageTypes.poster]: {
    height: 14,
    width: 9.5,
  },
  [ImageTypes.bigPoster]: {
    height: 16,
    width: 10.867,
  },
  [ImageTypes.smallPoster]: {
    height: 9.8,
    width: 6.65,
  },
  [ImageTypes.detailsPoster]: {
    height: 26,
    width: 17.3,
  },
  [ImageTypes.featuredBanner]: {
    height: 0,
    width: 0,
  },
  [ImageTypes.smallBanner]: {
    height: 8.4375,
    width: 15,
  },
  [ImageTypes.episodeBanner]: {
    height: 13.375,
    width: 24,
  },
  [ImageTypes.sixteenNineBanner]: {
    height: 10.125, // 162px
    width: 18, // 288px
  },
  [ImageTypes.gridSixteenNineBanner]: {
    height: 8.0625, // 129px
    width: 13.5625, // 217px
  },
  [ImageTypes.listSixteenNineBanner]: {
    height: 9.9, // 158px
    width: 17.6, // 282px
  },
  [ImageTypes.genreCard]: {
    height: 12.9375,
    width: 23,
  },
  [ImageTypes.offerBanner]: {
    height: 12.375,
    width: 22,
  },
};

const getImage = (image: ImageDimensions, src: string) => {
  if (!src || !src.includes('/')) {
    return '';
  }

  if (image.width && image.height) {
    return imageWithSize(src, rem2px(image.width), rem2px(image.height));
  }

  return src;
};

const getImageTypeWithSizeFactor = (type: ImageTypes, sizeFactor = 1): ImageDimensions => {
  const imageSize = imageSizes[type];
  if (!imageSize) {
    return ({
      width: 0,
      height: 0,
    });
  }

  return {
    ...imageSize,
    ...(imageSize.width ? { width: (imageSize.width * sizeFactor) } : {}),
    ...(imageSize.height ? { height: (imageSize.height * sizeFactor) } : {}),
  };
};

const imgStyles = (
  {
    theme,
    hasShadow,
    rounded,
    imageType,
    withMarginBottom = true,
    withMaxWidth = false,
    height,
    width,
    fullWidth,
    loading,
    noPlaceholder,
    keepAspectRatio,
    imageError,
  }: ComponentRuleProps,
): RuleStyles => ({
  display: 'block', // img won't add extra spacing below

  extend: [
    {
      condition: imageType.height && imageType.width,
      style: {
        height: `${imageType.height}rem`,
        width: `${imageType.width}rem`,
      },
    },
    {
      condition: !theme.hideThumbnailShadow && hasShadow,
      style: {
        boxShadow: `0 0.125rem 0.25rem 0 ${theme.color.overlayDarkMedium}`,
        marginTop: !fullWidth ? '0.25rem' : undefined,
        marginBottom: !fullWidth && withMarginBottom ? '0.35rem' : '0',
      },
    },
    {
      condition: rounded,
      style: {
        borderRadius: typeof rounded === 'string' ? extractThemeProp(theme.radius, rounded) : theme.radius.poster,
      },
    },
    {
      condition: withMaxWidth,
      style: {
        maxWidth: '100%',
      },
    },
    {
      condition: height,
      style: {
        height,
      },
    },
    {
      condition: width,
      style: {
        width,
      },
    },
    {
      condition: fullWidth,
      style: {
        height: 'auto',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
    {
      condition: !noPlaceholder,
      style: {
        transition: '0.2s ease-in-out',
      },
    },
    {
      condition: (loading && !noPlaceholder) || imageError,
      style: {
        opacity: 0,
      },
    },
    {
      condition: keepAspectRatio && imageType.height && imageType.width,
      style: {
        maxHeight: `${imageType.height}rem`,
        maxWidth: `${imageType.width}rem`,
        height: '100%',
        width: '100%',
        objectFit: 'contain',
      },
    },
  ],
});

const placeholderFullWidthStyles = (
  { theme, hasShadow, imageType, withMarginBottom, width, height }: ComponentRuleProps,
): RuleStyles => ({
  position: 'relative',
  display: 'block',
  width: '100%',
  '::before': {
    content: '""',
    display: 'block',
    width: '100%',
    paddingTop: `${((imageType?.height ?? height) / (imageType?.width ?? width)) * 100}%`,
  },
  extend: [
    {
      condition: !theme.hideThumbnailShadow && hasShadow,
      style: {
        marginTop: '0.25rem',
        marginBottom: withMarginBottom ? '0.35rem' : '0',
      },
    },
  ],
});

const placeholderStyles = (
  { theme, rounded, bgColor, loading, imageError }: ComponentRuleProps,
): RuleStyles => ({
  transition: '0.2s ease-in-out',
  backgroundColor: theme.color.imageBackground,
  extend: [
    {
      condition: rounded,
      style: {
        borderRadius: typeof rounded === 'string' ? extractThemeProp(theme.radius, rounded) : theme.radius.poster,
      },
    },
    {
      condition: bgColor,
      style: {
        backgroundColor: extractThemeProp(theme.color, bgColor),
      },
    },
    {
      condition: !loading && !imageError,
      style: {
        backgroundColor: 'transparent',
      },
    },
  ],
});

type ImageComponentProps = {
  src: string,
  type: ImageTypes,
  sizeFactor?: number,
  hasShadow?: boolean,
  rounded: boolean | string,
  bgColor?: string,
  withMaxWidth?: boolean,
  withMarginBottom?: boolean,
  height?: number | string,
  width?: number | string,
  fullWidth?: boolean,
  alt?: string,
  'data-cy'?: string,
  noPlaceholder?: boolean,
  keepAspectRatio?: boolean,
};

function ImageComponent(
  {
    src,
    type = ImageTypes.default,
    sizeFactor = 1,
    hasShadow,
    rounded,
    bgColor,
    withMaxWidth,
    height,
    width,
    fullWidth,
    alt,
    withMarginBottom,
    'data-cy': dataCy,
    noPlaceholder = false,
    keepAspectRatio = false,
  }: ImageComponentProps,
): JSX.Element {
  const imageType = getImageTypeWithSizeFactor(type, sizeFactor);
  const imageSrc = getImage(imageType, src);
  const [imgKey, setImgKey] = useState(hashCode(src ?? ''));
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const ref = useRef<HTMLImageElement>(null);
  const { css } = useFela<GlobalTheme>({
    imageType,
    hasShadow,
    rounded,
    bgColor,
    withMaxWidth,
    height,
    width,
    fullWidth,
    withMarginBottom,
    loading,
    noPlaceholder,
    keepAspectRatio,
    imageError,
  });

  useEffect(() => {
    // re-render StyledImage if server-rendered src doesn't match client's
    if (
      ref.current?.src
      && ref.current?.src !== imageSrc
      && ref.current?.src !== `${window.location.protocol}${imageSrc}`
    ) {
      setImgKey(hashCode(imageSrc));
    }
  }, [ref.current?.src]);

  useEffect(() => {
    // handle initial loading of image do not call onLoad
    if (ref.current?.complete) {
      setLoading(false);
      if (!src) {
        setImageError(true);
      }
    }
  }, []);

  const imgComponent = (
    <img
      suppressHydrationWarning
      alt={alt}
      key={imgKey}
      ref={ref}
      loading="lazy"
      src={imageSrc}
      className={css(imgStyles)}
      data-cy={dataCy}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        setImageError(true);
      }}
      onLoad={() => {
        setLoading(false);
      }}
    />
  );

  if (noPlaceholder) {
    return imgComponent;
  }

  if (fullWidth) {
    return (
      <div className={css(placeholderStyles, placeholderFullWidthStyles)}>
        {imgComponent}
      </div>
    );
  }

  return (
    <div className={css(placeholderStyles)}>
      {imgComponent}
    </div>
  );
}

ImageComponent.displayName = 'Image';

export const Image = React.memo(ImageComponent);
export const imageTypes = imageSizes;
