import React from 'react';
import { Offer } from 'types';
import {
  Text,
  Box,
  Heading,
  Price,
  AsyncButton,
  PrimaryButton,
  Image,
} from 'components';
import { ImageTypes } from 'components/Image/Image';
import { Usps } from 'components/Payment';
import { getImageUrl } from './utils';

type Props = {
  offer: Offer,
  onClick?: () => void,
  disabled?: boolean,
  btnText?: string,
};

export default function OfferComponent(
  { offer, onClick, disabled, btnText, ...otherProps }: Props,
) {
  const price = offer.currency ? (
    <Price key="price" price={offer.priceInCents} currency={offer.currency} />
  ) : '';

  const imgUrl = getImageUrl(offer.images, false) || getImageUrl(offer.images, true);

  const getBtnText = () => {
    if (btnText) {
      return <Text id={btnText} />;
    }
    if (offer.trialPeriod) {
      return <Text id="payment.startFreeTrial" />;
    }

    return (
      <Text
        id="payment.payAction"
        values={{
          period: offer.recurringPeriod?.unit,
          price,
        }}
      />
    );
  };

  return (
    <Box
      column
      width="27rem"
      bg="paperBackground"
      borderRadius="paper"
      border={disabled ? 'checkoutOffer' : 'none'}
      mb="xlarge"
      xs-mt="0"
      xs-mb="medium"
      p="xlarge"
      {...otherProps}
      className="e2e-offer"
      alignItems="stretch"
      maxWidth="100%"
    >
      {imgUrl && (
        <Box mb="large">
          <Image
            alt={offer.title}
            bgColor="paperBackground"
            rounded="6px"
            src={imgUrl}
            type={ImageTypes.offerBanner}
            keepAspectRatio
          />
        </Box>
      )}
      <Heading
        fontSize="sectionHeading"
        align="center"
        fontWeight="bold"
        wordWrap="break-word"
      >
        {offer.title}
      </Heading>

      <Usps
        type="bullet"
        size="small"
        mt="large"
        mx="small"
        usps={offer.usps}
        alignItems="start"
      />

      <Box flex="1" hideEmpty={false} />

      <Box my="xlarge">
        <Heading
          id="payment.price"
          values={{
            period: offer.recurringPeriod?.unit,
            price,
          }}
          fontSize="sectionHeading"
          align="center"
        />
      </Box>

      <AsyncButton
        as={PrimaryButton}
        variant="brand"
        infinite={!offer.subscribeKind}
        onClick={onClick}
        className="e2e-offer-cta"
        alignCenter={false}
        disabled={disabled}
        minWidth="unset"
        lineHeight="unset"
      >
        {getBtnText()}
      </AsyncButton>
    </Box>
  );
}
