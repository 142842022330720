import createComponent from 'styles/fela/createComponent';

export const App = createComponent(({ fixedHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  extend: [
    {
      condition: fixedHeight,
      style: {
        height: '100vh',
      },
    },
  ],
}), 'div', ['fixedHeight']);

App.displayName = 'App';

export const Main = createComponent(({ theme, fixedHeight }) => ({
  backgroundColor: theme.color.page,
  color: theme.color.primary,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: `calc(100vh - ${theme.header.height})`,
  width: '100%',
  extend: [
    {
      condition: fixedHeight,
      style: {
        minHeight: 'auto',
      },
    },
  ],
}), 'div', ['fixedHeight']);

export const Content = createComponent(() => ({
  flexGrow: 1,

  display: 'flex',
  alignItems: 'stretch',
}));
