import React from 'react';
import Icon from './Icon';

export default function PlayIcon(props) {
  return (
    <Icon {...props} height="75" width="75" viewBox="0 0 75 75" data-cy="play-icon">
      <defs>
        <path id="mqbnjcgx6a" d="M0 0L97 0 97 97 0 97z" />
        <path id="d9cun4dcic" d="M37.5 0C58.21 0 75 16.79 75 37.5S58.21 75 37.5 75 0 58.21 0 37.5 16.79 0 37.5 0z" />
        <path id="7fysopb71e" d="M0 0L33 18 0 36z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-215 -110) translate(207 100)">
              <mask id="mb2vjl7isb" fill="#fff">
                <use xlinkHref="#mqbnjcgx6a" />
              </mask>
              <path fill="#D8D8D8" fillOpacity="0" d="M-10.778 107.778L107.778 107.778 107.778 -10.778 -10.778 -10.778z" mask="url(#mb2vjl7isb)" />
            </g>
            <g transform="translate(-215 -110) translate(207 100) translate(8 10)">
              <mask id="mdrl33rw5d" fill="#fff">
                <use xlinkHref="#d9cun4dcic" />
              </mask>
              <path fill="#000" fillOpacity=".3" d="M-10.714 85.714L85.714 85.714 85.714 -10.714 -10.714 -10.714z" mask="url(#mdrl33rw5d)" />
            </g>
            <g transform="translate(-215 -110) translate(207 100) translate(34 30)">
              <mask id="facmf2z01f" fill="#fff">
                <use xlinkHref="#7fysopb71e" />
              </mask>
              <path fill="#FFF" d="M-11 46.588L44 46.588 44 -10.588 -11 -10.588z" mask="url(#facmf2z01f)" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}
