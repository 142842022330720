import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { relatedViewableShape } from 'types/viewableShape';
import { Grid, imageTypes } from 'components';
import SixteenNineItem from 'components/VideoItems/SixteenNineItem';
import { ITEM_MODE } from 'components/VideoItems/constants';
import RegularItem from 'components/VideoItems/RegularItem';

function RelatedViewablesTab({ viewables }) {
  const relatedThumbnails = useSelector(({ settings }) => settings.features.relatedThumbnails);
  const hideChannelLogo = useSelector(
    ({ settings }) => !!settings.features.hideThumbnailChannelLogo,
  );
  const is16x9 = relatedThumbnails?.thumbnailType === 'sixteen-nine';
  const gridItemWidth = is16x9 ? imageTypes.sixteenNineBanner.width : imageTypes.poster.width;

  const itemMapper = is16x9
    ? viewable => (
      <SixteenNineItem
        key={viewable.id}
        disableLiveInfo
        viewable={viewable}
        hideKind
        mode={ITEM_MODE.RELATED}
        hideChannelLogo={hideChannelLogo}
      />
    )
    : viewable => (
      <RegularItem
        key={viewable.id}
        disableLiveInfo
        viewable={viewable}
        mode={ITEM_MODE.GRID}
        hideChannelLogo={hideChannelLogo}
      />
    );

  return (
    <Grid
      itemWidth={gridItemWidth}
      is16x9={is16x9}
    >
      {viewables.map(itemMapper)}
    </Grid>
  );
}

RelatedViewablesTab.propTypes = {
  viewables: PropTypes.arrayOf(relatedViewableShape).isRequired,
};

export default React.memo(RelatedViewablesTab);
