import { Action } from 'redux';
import { push } from 'router/actions';
import { SearchFilterKind } from 'utils/constants';

export function search(query: string, kinds?: SearchFilterKind[]): Action {
  return push({
    name: 'search',
    query: {
      q: query,
      kinds,
    },
  });
}
