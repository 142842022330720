import React from 'react';
import { IFrame } from 'components';
import { useQuery } from '@apollo/client';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import { hashCode } from 'utils/helpers';
import { locationShape } from 'router/prop-types';
import COLLECTION_QUERY from './collection.gql';

const getUrlFromCollectionUI = (collectionUI, encodedUrl) => {
  let layoutObjectWithUrl = null;

  ['smallScreenStyle', 'mediumScreenStyle', 'bigScreenStyle'].forEach((screen) => {
    layoutObjectWithUrl = (collectionUI?.[screen]?.layoutObjects || [])
      .find(
        layoutObject => layoutObject.type === 'buttonCta'
          && hashCode(layoutObject.ctaTargetValue) === encodedUrl,
      ) ?? layoutObjectWithUrl;
  });

  if (!layoutObjectWithUrl) {
    return false;
  }

  return layoutObjectWithUrl.ctaTargetValue;
};

function CollectionWebView({ location }) {
  const {
    loading,
    error,
    data,
  } = useQuery(
    COLLECTION_QUERY,
    {
      variables: {
        collectionId: location.params.collectionId,
      },
      skip: !location.params.collectionId,
    },
  );

  if ((loading && !data) || error) {
    return null;
  }

  if (!data.viewer.block) {
    return (
      <NotFoundView />
    );
  }

  const collectionUI = JSON.parse(data.viewer.block.collectionUI || null);
  const url = getUrlFromCollectionUI(collectionUI, location.params.encodedUrl);

  if (!url) {
    return (
      <NotFoundView />
    );
  }

  return (
    <IFrame
      title={data.viewer.block.title}
      url={url}
    />
  );
}

CollectionWebView.propTypes = {
  location: locationShape.isRequired,
};

export default React.memo(CollectionWebView);
