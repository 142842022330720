import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsContext } from './AnalyticsProvider';
import PropTypes from 'prop-types';

const PageViewTracker = ({ pageAnalytics }) => {
  const analytics = useContext(AnalyticsContext);
  const { name, params } = useSelector(state => state.router.location);

  useEffect(() => {
    if (pageAnalytics?.component) {
      analytics.onPageView(pageAnalytics.component, pageAnalytics.context);
    }
  }, [name, params]);

  return null;
};

PageViewTracker.propTypes = {
  pageAnalytics: PropTypes.shape({
    component: PropTypes.string.isRequired,
    context: PropTypes.shape({
      view: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default React.memo(PageViewTracker);
