/* eslint-disable no-underscore-dangle */
import {
  compose,
  applyMiddleware,
  createStore as reduxCreateStore,
  Middleware,
} from 'redux';

function composeMiddlewares(reduxMiddlewares: Middleware[]) {
  let composeEnhancers = compose;

  if (__DEVELOPMENT__ && __CLIENT__) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  return composeEnhancers(applyMiddleware(...reduxMiddlewares))(reduxCreateStore);
}

type Params = {
  reduxMiddlewares: Middleware[],
  rootReducer: any,
  initialState: any,
};
export function createStore({ reduxMiddlewares, rootReducer, initialState }: Params) {
  return composeMiddlewares(reduxMiddlewares)(rootReducer, initialState);
}
