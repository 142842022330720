import * as Types from '../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TGetOffersToChangeQueryVariables = Types.Exact<{
  offerId: Types.Scalars['String'];
}>;


export type TGetOffersToChangeQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, offer?: { __typename: 'SubscribeType', id: string, title?: string | null, usps?: Array<string> | null, priceInCents: any, currency: string, images?: Array<{ __typename?: 'KindImageType', url: string, isDefault: boolean, kind?: string | null }> | null, recurringPeriod?: { __typename?: 'RecurringPeriodType', length: number, unit: Types.TRecurringPeriodUnitType } | null } | { __typename: 'BuyType', id: string } | { __typename: 'DefaultType', id: string } | { __typename: 'PassType', id: string } | { __typename: 'RentType', id: string } | { __typename: 'ThirdPartyType', id: string } | null, subscriptions: { __typename?: 'UserSubscriptionsConnection', edges?: Array<{ __typename?: 'UserSubscriptionsEdge', node: { __typename?: 'SubscriptionType', offerId: string, replacements: { __typename?: 'UserReplacementSubscriptionsConnection', edges?: Array<{ __typename?: 'UserReplacementSubscriptionsEdge', node: { __typename: 'SubscribeType', id: string, subscribeKind?: Types.TSubscribeKindType | null, priceInCents: any, currency: string, refundPriceInCents?: any | null, title?: string | null, usps?: Array<string> | null, images?: Array<{ __typename?: 'KindImageType', url: string, isDefault: boolean, kind?: string | null }> | null, recurringPeriod?: { __typename?: 'RecurringPeriodType', length: number, unit: Types.TRecurringPeriodUnitType } | null } } | null> | null } } } | null> | null } } };

export type TSubscribeOfferFieldsNoTrialFragment = { __typename?: 'SubscribeType', title?: string | null, usps?: Array<string> | null, priceInCents: any, currency: string, images?: Array<{ __typename?: 'KindImageType', url: string, isDefault: boolean, kind?: string | null }> | null, recurringPeriod?: { __typename?: 'RecurringPeriodType', length: number, unit: Types.TRecurringPeriodUnitType } | null };

export const SubscribeOfferFieldsNoTrialFragmentDoc = gql`
    fragment subscribeOfferFieldsNoTrial on SubscribeType {
  title
  usps
  images {
    url
    isDefault
    kind
  }
  priceInCents
  currency
  recurringPeriod {
    length
    unit
  }
}
    `;
export const GetOffersToChangeDocument = gql`
    query getOffersToChange($offerId: String!) {
  viewer {
    id: magineId
    offer(offerId: $offerId) {
      __typename
      id
      ...subscribeOfferFieldsNoTrial
    }
    subscriptions {
      edges {
        node {
          offerId
          replacements {
            edges {
              node {
                __typename
                id
                subscribeKind
                priceInCents
                currency
                refundPriceInCents
                ...subscribeOfferFieldsNoTrial
              }
            }
          }
        }
      }
    }
  }
}
    ${SubscribeOfferFieldsNoTrialFragmentDoc}`;

/**
 * __useGetOffersToChangeQuery__
 *
 * To run a query within a React component, call `useGetOffersToChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersToChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersToChangeQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useGetOffersToChangeQuery(baseOptions: Apollo.QueryHookOptions<TGetOffersToChangeQuery, TGetOffersToChangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TGetOffersToChangeQuery, TGetOffersToChangeQueryVariables>(GetOffersToChangeDocument, options);
      }
export function useGetOffersToChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TGetOffersToChangeQuery, TGetOffersToChangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TGetOffersToChangeQuery, TGetOffersToChangeQueryVariables>(GetOffersToChangeDocument, options);
        }
export type GetOffersToChangeQueryHookResult = ReturnType<typeof useGetOffersToChangeQuery>;
export type GetOffersToChangeLazyQueryHookResult = ReturnType<typeof useGetOffersToChangeLazyQuery>;
export type GetOffersToChangeQueryResult = Apollo.QueryResult<TGetOffersToChangeQuery, TGetOffersToChangeQueryVariables>;