import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'router/actions';
import { I18nContext } from 'components/I18n';
import { useAnalytics } from 'components/Tracking';
import Tabs from 'components/Tabs/Tabs';
import {
  Box,
  PageWrapper,
} from 'components';
import {
  Menu,
  MenuItem,
  TabsWrapper,
  StyledContent,
  ContentWrapper,
} from './Styles';

const SettingsContainer = ({ children, wide = false, maxWidth }) => {
  const context = useContext(I18nContext);
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const location = useSelector(state => state.router.location);
  const isSubscriptionEnabled = useSelector(state => !!state.settings.features.subscription);
  const isPaymentInformationEnabled = useSelector(
    state => !!state.settings.features.settings.payment,
  );
  const isTransactionsEnabled = useSelector(
    state => !!state.settings.features.settings.transactions,
  );

  const trackLogoutClick = useCallback(() => {
    analytics.onClick({
      component: 'Settings',
      action: 'click',
      clickType: 'navigation',
      eventName: 'click_sign_out_button',
      element: 'sign_out_button',
    });
  }, [analytics]);

  const getMenuItems = useCallback(() => {
    const menuItems = [];

    menuItems.push({
      id: 'account',
      text: context.formatText('account'),
      to: { name: 'settings-account' },
    });

    if (isSubscriptionEnabled) {
      menuItems.push({
        id: 'subscription',
        text: context.formatText('subscription'),
        to: { name: 'settings-subscription' },
      });
    }

    if (isPaymentInformationEnabled) {
      menuItems.push({
        id: 'payment',
        text: context.formatText('payment'),
        to: { name: 'settings-payment' },
        aliases: [
          'settings-payment-setup',
        ],
      });
    }

    if (isTransactionsEnabled) {
      menuItems.push({
        id: 'transactions',
        text: context.formatText('transactions'),
        to: { name: 'settings-transactions' },
      });
    }

    menuItems.push({
      id: 'settings',
      text: context.formatText('settings'),
      to: { name: 'settings' },
    });

    menuItems.push({
      id: 'logOut',
      onlyTabs: true,
      className: 'e2e-log-out',
      text: context.formatText('logOut'),
      to: { name: 'log-out' },
      onClick: trackLogoutClick,
    });

    return menuItems;
  }, [context, isSubscriptionEnabled, isPaymentInformationEnabled,
    isTransactionsEnabled, trackLogoutClick]);

  const menuItems = useMemo(() => getMenuItems(), [getMenuItems]);

  const activeIndex = menuItems.findIndex(
    ({ to, aliases = [] }) => to.name === location.name || aliases.includes(location.name),
  );

  // when user goes away from inside settings activeIndex is -1
  const activeId = activeIndex === -1 ? null : menuItems[activeIndex].id;

  return (
    <PageWrapper background row>
      <Menu>
        <div>
          {
            menuItems
              .filter(menuItem => !menuItem.onlyTabs)
              .map(({ className, content, text, to, id }) => (
                <MenuItem
                  active={id === activeId}
                  className={className}
                  key={to.name}
                  to={to}
                >
                  {content || text}
                </MenuItem>
              ))
          }
        </div>
        <MenuItem
          className="e2e-log-out"
          to={{ name: 'log-out' }}
          onClick={trackLogoutClick}
        >
          <Box flexBox wrap alignItems="center">
            {context.formatText('logOut')}
          </Box>
        </MenuItem>
      </Menu>

      <StyledContent limitWidth>
        <TabsWrapper>
          <Tabs
            pageId="settings-menu"
            id="tab"
            initialSelectedIndex={activeIndex}
            onClick={(index) => {
              if (menuItems[index].onClick) {
                menuItems[index].onClick();
              }
              dispatch(routerActions.push(menuItems[index].to));
            }}
          >
            {menuItems.map(({ className, text, to }) => (
              <div
                className={className}
                key={to.name}
                label={text}
              />
            ))}
          </Tabs>
        </TabsWrapper>

        <ContentWrapper wide={wide} maxwidth={maxWidth}>
          {children}
        </ContentWrapper>
      </StyledContent>
    </PageWrapper>
  );
};

SettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default SettingsContainer;
