import PropTypes from 'prop-types';
import {
  LAYOUT_OBJECT_TYPES,
  FIELD_TO_RENDER_TYPES,
  SCREEN_SIZE_STYLES,
  BLOCK_INTERFACE_TYPES,
  COLLECTION_SIZE,
} from 'utils/constants';

export const layoutObjectShape = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(LAYOUT_OBJECT_TYPES)).isRequired,
  fieldToRender: PropTypes.oneOf(Object.values(FIELD_TO_RENDER_TYPES)),
  fieldsToRender: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELD_TO_RENDER_TYPES))),
  fieldsToRenderSeparator: PropTypes.string,

  textFontName: PropTypes.string,
  textFontSize: PropTypes.number,
  textFontColor: PropTypes.string,
  textNumberLines: PropTypes.number,
  textCaps: PropTypes.bool,
  objectVerticalSpacing: PropTypes.number,
  textBold: PropTypes.bool,
});

const screenStyleShape = PropTypes.shape({
  layoutObjects: PropTypes.arrayOf(layoutObjectShape),
});

export const collectionUIShape = PropTypes.shape({
  collectionSize: PropTypes.oneOf(Object.values(COLLECTION_SIZE)),
  collectionLayoverOpacity: PropTypes.number,
  collectionLayoverColor: PropTypes.string,
  metadataContainerOpacity: PropTypes.number,
  metadataContainerColor: PropTypes.string,
  collectionThumbnailsSize: PropTypes.number,
  heroImageVerticalAlignment: PropTypes.oneOf(['top', 'center']),

  [SCREEN_SIZE_STYLES.mediumScreenStyle]: screenStyleShape,
  [SCREEN_SIZE_STYLES.smallScreenStyle]: screenStyleShape,
});

export const collectionShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  magineId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  collectionUI: collectionUIShape,
});

export const promoShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  magineId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  blockUI: collectionUIShape,
});
