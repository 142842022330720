import React from 'react';
import Icon, { IconType } from '../Icon';

const PauseIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path opacity="0.4" d="M170 85C170 131.944 131.944 170 85 170C38.0558 170 0 131.944 0 85C0 38.0558 38.0558 0 85 0C131.944 0 170 38.0558 170 85Z" fill="black" />
    <path d="M68.3333 114.167C72.9167 114.167 76.6667 110.417 76.6667 105.833V64.1667C76.6667 59.5833 72.9167 55.8333 68.3333 55.8333C63.75 55.8333 60 59.5833 60 64.1667V105.833C60 110.417 63.75 114.167 68.3333 114.167ZM93.3333 64.1667V105.833C93.3333 110.417 97.0833 114.167 101.667 114.167C106.25 114.167 110 110.417 110 105.833V64.1667C110 59.5833 106.25 55.8333 101.667 55.8333C97.0833 55.8333 93.3333 59.5833 93.3333 64.1667Z" fill="white" />
  </Icon>
);

export default PauseIcon;
