import React, { useCallback, useEffect, useRef } from 'react';
import { useFela } from 'react-fela';
import { Text, Box } from 'components';
import { responsiveLg, responsiveMd } from 'styles/fela/mixins';
import { GlobalTheme } from 'types';
import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';

const ReactPinField = React.lazy(() => import('react-pin-field'));

const inputRules = ({ theme }: ComponentRuleProps): RuleStyles => ({
  border: 'none',
  borderBottom: `4px solid ${theme.color.primary}`,
  borderRadius: 0,
  backgroundColor: 'transparent',
  width: 32,
  outline: 'none',
  margin: '0 5px',
  padding: 0,
  fontSize: 30,
  color: theme.color.primary,
  textAlign: 'center',
  fontWeight: 'bold',
  ':placeholder-shown': {
    opacity: 0.3,
  },
  ':focus': {
    opacity: 1,
  },
  ':invalid': {
    borderBottom: `4px solid ${theme.color.danger}`,
  },
  extend: [
    {
      condition: true,
      style: responsiveMd({
        width: 60,
        fontSize: 50,
        margin: '0 10px',
      }),
    },
    {
      condition: true,
      style: responsiveLg({
        width: 80,
        fontSize: 80,
        margin: '0 14px',
      }),
    },
  ],
});

type UserCodeInputProps = {
  length?: number,
  onComplete?: (code: string) => void,
  onChange?: (code: string) => void,
  initValue?: string,
  invalid?: boolean,
};

const UserCodeInput = (
  { length = 8, onComplete, onChange, initValue, invalid }: UserCodeInputProps,
): JSX.Element => {
  const { css } = useFela<GlobalTheme>();
  const ref = useRef<HTMLInputElement[] | null>(null);

  useEffect(() => {
    if (!invalid) return;

    ref.current?.forEach((input) => {
      input.value = '';
      input.setCustomValidity('Invalid');
    });
    ref.current?.[0].focus();
  }, [invalid]);

  const handleChange = (code: string) => {
    ref.current?.forEach((input) => {
      input.setCustomValidity('');
    });
    onChange?.(code);
  };

  const handleRect = useCallback((node: HTMLInputElement[]) => {
    ref.current = node;

    if (!initValue) return;

    ref.current?.forEach((input, idx) => {
      input.value = initValue[idx];
    });
  }, []);

  useEffect(() => {
    if (initValue && initValue.length === length) {
      onComplete?.(initValue);
    }
  }, []);

  return (
    <React.Suspense fallback={<Text id="loading" />}>
      <Box flexBox flexDirection="column" alignItems="center">
        <div id="code-inputs">
          <ReactPinField
            ref={handleRect}
            length={length}
            onComplete={onComplete}
            onChange={handleChange}
            className={css(inputRules)}
            placeholder=" "
            format={k => k.toUpperCase()}
          />
        </div>
        <Box mt="medium">
          {
            invalid
              ? <Text color="danger" fontSize="smedium" id="deviceAuth.invalidCode" />
              : <Text>&nbsp;</Text>
          }
        </Box>
      </Box>
    </React.Suspense>
  );
};

export default UserCodeInput;
