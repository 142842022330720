import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsContext } from './AnalyticsProvider';

const ClickTracker = ({ children, component, action, ...rest }) => {
  const analytics = useContext(AnalyticsContext);

  const onClick = (e) => {
    analytics.onClick({
      component,
      action,
      ...rest,
    });

    if (children.props.onClick) {
      return children.props.onClick(e);
    }

    return undefined;
  };

  return React.cloneElement(children, {
    onClick,
  });
};

ClickTracker.propTypes = {
  children: PropTypes.element.isRequired,
  component: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default ClickTracker;
