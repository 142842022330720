
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentListNodeFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ViewableInterface"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewableFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChannelFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"EpisodeFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShowFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MovieFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProgramFragment"},"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PageInfoFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PageInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startCursor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endCursor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":445}};
    doc.loc.source = {"body":"#import \"./viewable.gql\"\n#import \"./channel.gql\"\n#import \"./episode.gql\"\n#import \"./show.gql\"\n#import \"./movie.gql\"\n#import \"./program.gql\"\n\n# ContentList node fragment\nfragment ContentListNodeFragment on ViewableInterface {\n  __typename\n  ...ViewableFragment\n  ...ChannelFragment\n  ...EpisodeFragment\n  ...ShowFragment\n  ...MovieFragment\n  ...ProgramFragment\n}\n\nfragment PageInfoFragment on PageInfo {\n  startCursor\n  endCursor\n  hasNextPage\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./viewable.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./channel.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./episode.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./show.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./movie.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./program.gql").definitions));


      module.exports = doc;
    
