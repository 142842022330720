import { isSameDay } from 'date-fns';
import { PLAYABLE_KINDS } from './constants';
import { nowS } from './time';

export const LIVE_EVENT_STATES = {
  upcoming: 'upcoming',
  upcomingToday: 'upcomingToday',
  live: 'live',
  ended: 'ended',
};

export function getLiveEventState(liveEvent) {
  const currentTimeS = nowS();

  if (liveEvent.catchupStop && liveEvent.catchupStop < currentTimeS) {
    return LIVE_EVENT_STATES.ended;
  }

  if (currentTimeS >= liveEvent.startTime) {
    return LIVE_EVENT_STATES.live;
  }

  if (isSameDay(currentTimeS * 1000, liveEvent.startTime * 1000)) {
    return LIVE_EVENT_STATES.upcomingToday;
  }

  return LIVE_EVENT_STATES.upcoming;
}

export function extractLiveEventTime(viewable) {
  if (!viewable) {
    return null;
  }

  const { defaultPlayable, playable } = viewable;

  const isLiveEvent = defaultPlayable?.kind === PLAYABLE_KINDS.LIVE_EVENT
    || playable?.kind === PLAYABLE_KINDS.LIVE_EVENT;

  if (!isLiveEvent) {
    return null;
  }

  return {
    startTime: defaultPlayable?.startTimeUtc || playable?.startTimeUtc || null,
    catchupStop: defaultPlayable?.catchupStop || playable?.catchupStop || null,
  };
}

export function ifLiveEventIsItAvailable(viewable) {
  const liveEventTime = extractLiveEventTime(viewable);
  if (!liveEventTime?.startTime) {
    return true;
  }

  const liveEventState = getLiveEventState(liveEventTime);

  return liveEventState === LIVE_EVENT_STATES.live || liveEventState === LIVE_EVENT_STATES.ended;
}
