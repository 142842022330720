import React, { useContext } from 'react';
import { AnalyticsContext } from './AnalyticsProvider';

export default function withAnalytics(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const NewComponent = (props) => {
    const analytics = useContext(AnalyticsContext);

    return (
      <WrappedComponent analytics={analytics} {...props} />
    );
  };
  NewComponent.displayName = `withAnalytics(${displayName})`;

  return NewComponent;
}
