import React, { useState, useEffect, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { PlayerContext } from 'components/Player';
import NielsenService from './NielsenService';

function Nielsen() {
  const {
    player,
    viewable,
    isPlayedToEnd,
  } = useContext(PlayerContext);

  const trackMMS = viewable?.playable?.mms || false;
  const mmsOrigCode = viewable?.playable?.mmsOrigCode || '';
  const broadcastId = viewable?.broadcastById?.broadcastId;

  const [service, setService] = useState(null);
  const [isCasting, setCasting] = useState(false);

  const {
    nielsenAppId,
    userEmail,
  } = useSelector(state => ({
    nielsenAppId: state.settings.features.mms?.nielsenAppId || false,
    userEmail: state.user.contactEmail,
  }), shallowEqual);

  // initialize NielsenService if needed
  useEffect(() => {
    if (!player || !nielsenAppId || !trackMMS || service) {
      return () => {};
    }

    const nService = new NielsenService({
      appId: nielsenAppId,
      instanceName: 'Browser',
      userEmail,
      player,
    });

    setService(nService);

    const chromecastOn = () => setCasting(true);
    const chromecastOff = () => setCasting(false);

    player.addEventListener('chromecastActivated', chromecastOn);
    player.addEventListener('chromecastDeactivated', chromecastOff);

    return () => {
      nService.stop();
      player.removeEventListener('chromecastActivated', chromecastOn);
      player.removeEventListener('chromecastDeactivated', chromecastOff);
    };
  }, [player, nielsenAppId, trackMMS]);

  // start NielsenService if channel should be tracked
  useEffect(() => {
    if (service && nielsenAppId && viewable && trackMMS && !isPlayedToEnd && !isCasting) {
      service.start({
        mmsOrigCode,
        viewable,
        broadcastId,
      });
    }

    return () => {
      service?.stop();
    };
  }, [service, nielsenAppId, mmsOrigCode, broadcastId, isPlayedToEnd, trackMMS, isCasting]);

  return null;
}

export default React.memo(Nielsen);
