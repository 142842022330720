import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ModalHeading } from 'components/Modal/ModalStyles';
import Modal from 'components/Modal/Modal';
import ModalContainer from 'components/Modal/ModalContainer';
import { Box } from 'components/StyledSystem/Box';
import PrimaryButton from 'components/Button/PrimaryButton';
import Text from 'components/StyledSystem/Text';

const ResetPinConfirmationModal = (props) => {
  const {
    onClose,
  } = props;

  const {
    email,
  } = useSelector(({ user }) => ({
    email: user.contactEmail,
  }));

  return (
    <Modal onDismiss={onClose}>
      <ModalContainer
        onClickClose={onClose}
        withWidth="35rem"
        withShadow
        dark
      >
        <Box column>
          <Box mt="medium" mb="large">
            <ModalHeading id="appSettings.sendNewPinCodeModalHeader" />
          </Box>

          <Box>
            <Text
              bold
              align="center"
              id="appSettings.sendNewPinCodeModalSuccessModalInfo"
              values={{ email }}
            />
          </Box>

          <Box
            mt="xxxlarge"
            mb="xlarge"
          >
            <PrimaryButton
              variant="brand"
              onClick={onClose}
            >
              <Text id="appSettings.sendNewPinCodeModalBtnText" />
            </PrimaryButton>
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

ResetPinConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ResetPinConfirmationModal;
