import React, { SVGProps } from 'react';
import Icon from './Icon';

const DeleteIcon = (props: SVGProps<SVGElement>): JSX.Element => (
  <Icon {...props} width="16" viewBox="0 0 16 24">
    <g fillRule="evenodd">
      <path
        d="M15 6v14a3 3 0 01-3 3H4a3 3 0 01-3-3V6h14zM5.25 7.79h-.625a1 1 0 00-1 1v9.631a1 1 0 001 1h.625a1 1 0 001-1V8.79a1 1 0 00-1-1zm6.125 0h-.625a1 1 0 00-1 1v9.631a1 1 0 001 1h.625a1 1 0 001-1V8.79a1 1 0 00-1-1zM0 3H16V5H0z"
      />
      <path d="M6 0H11V2H6z" />
    </g>
  </Icon>
);

export default React.memo(DeleteIcon);
