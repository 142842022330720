import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { Box } from 'components';

export const BoxWithoutOuterMargin = createComponent(
  ({ theme }: ComponentRuleProps): RuleStyles => ({
    marginLeft: `-${theme.margin.large}`,
    marginRight: `-${theme.margin.large}`,
  }),
  Box,
);

BoxWithoutOuterMargin.displayName = 'BoxWithoutOuterMargin';
