import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { replaceWebviewParam } from 'utils/helpers';
import { webviewShape } from 'types/viewableShape';
import { IFrame } from 'components';

const WebviewTab = (props) => {
  const {
    viewableId,
    webview,
  } = props;

  const {
    userId,
  } = useSelector(({ auth }) => ({
    userId: auth.userId,
  }));

  // replace [userId] in the url with current user id
  const urlWithUserId = replaceWebviewParam(webview.webviewUrl, 'userId', userId);

  // replace [contentId] in the url with the viewable id
  const url = replaceWebviewParam(urlWithUserId, 'contentId', viewableId);

  return (
    <IFrame
      title={webview.webviewTitle}
      url={url}
      height={webview.webviewHeight || '38rem'}
      hideScroll={!!webview.webviewHeight}
    />
  );
};

WebviewTab.propTypes = {
  viewableId: PropTypes.string.isRequired,
  webview: webviewShape.isRequired,
};

export default WebviewTab;
