import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isSameDay } from 'date-fns';
import { msToTimeUnits } from 'utils/time';
import Interval from './Interval';

let interval;
if (__CLIENT__) {
  interval = new Interval(1000);
}

const MetaCountdown = ({ eventTimeMs, onEvent, children, disabled }) => {
  const [timeLeftMs, setTimeLeftMs] = useState(0);

  const listener = useCallback((currentTimeMs) => {
    const currentTimeLeftMs = eventTimeMs - currentTimeMs;
    if (currentTimeLeftMs <= 0 && !!onEvent && timeLeftMs) {
      // if !timeLeftMs - event was completed before initialization
      // there is no need to call onEvent
      onEvent();
    }

    const value = currentTimeLeftMs > 0 ? currentTimeLeftMs : null;
    setTimeLeftMs(value);

    if (value === null || disabled) {
      interval.removeListener(listener);
    }
  }, [eventTimeMs, onEvent, !!timeLeftMs]);

  useEffect(() => {
    if (timeLeftMs !== null) {
      interval.addListener(listener);
    }

    return () => {
      interval.removeListener(listener);
    };
  }, [eventTimeMs, onEvent, !!timeLeftMs]);

  if (!children) {
    return null;
  }

  const time = msToTimeUnits((timeLeftMs || 0));
  if (time) time.today = isSameDay(eventTimeMs, Date.now());

  return children(time, eventTimeMs, timeLeftMs);
};

MetaCountdown.propTypes = {
  eventTimeMs: PropTypes.number.isRequired,
  onEvent: PropTypes.func,
  children: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MetaCountdown;
