import React from 'react';
import PropTypes from 'prop-types';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { Text, imageTypes } from 'components';
import Scrollable from 'components/Scrollable/Scrollable';
import { TooltipTrigger } from 'components/HoverState';
import PosterItem from 'components/VideoItems/PosterItem';
import ComponentWrapper, { ShowAllLink } from 'components/ComponentWrapper/ComponentWrapper';
import { ClickTracker } from 'components/Tracking';
import { viewableShape } from '../prop-types';
import { isShowAllBtn } from '../helper';

function PosterRow(props) {
  const {
    viewables,
    title,
    refreshData,
    pageId,
    collectionId,
    categoryKind,
    hasNextPage,
    thumbnailSizeFactor,
    hideChannelLogo,
    loadMore,
  } = props;

  if (!viewables.length) {
    return null;
  }

  const items = viewables.map(viewable => (
    <TooltipTrigger
      viewable={viewable}
      refreshData={refreshData}
      key={viewable.id}
      collectionId={collectionId}
      categoryKind={categoryKind}
    >
      <ClickTracker
        component="ContentList"
        eventName="click_asset"
        action="click"
        clickType="asset"
        collectionId={collectionId}
        categoryKind={categoryKind}
        viewableId={viewable.id}
      >
        <PosterItem
          viewable={viewable}
          hideChannelLogo={hideChannelLogo}
          thumbnailSizeFactor={thumbnailSizeFactor}
        />
      </ClickTracker>
    </TooltipTrigger>
  ));

  const showAllLink = isShowAllBtn(hasNextPage, items) ? (
    <ClickTracker
      component="ContentList"
      action="click"
      clickType="navigation"
      eventName="click_see_all_button"
      section={categoryKind}
      collectionId={collectionId}
    >
      <ShowAllLink to={{ name: 'collection', params: { id: collectionId } }}>
        <Text id="showAll" />
      </ShowAllLink>
    </ClickTracker>
  ) : null;

  return (
    <ComponentWrapper
      title={title}
      right={showAllLink}
    >
      <Scrollable
        pageId={pageId}
        id={`content-list-${collectionId}`}
        loadMore={loadMore}
        hasMoreLoad={hasNextPage}
        itemWidthRem={imageTypes.poster.width * (thumbnailSizeFactor || 1)}
      >
        {items}
      </Scrollable>
    </ComponentWrapper>
  );
}

PosterRow.propTypes = {
  refreshData: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  viewables: PropTypes.arrayOf(viewableShape).isRequired,
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  categoryKind: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  thumbnailSizeFactor: PropTypes.number,
  hideChannelLogo: PropTypes.bool,
};

PosterRow.defaultProps = {
  thumbnailSizeFactor: 1,
  hideChannelLogo: false,
};

export default React.memo(PosterRow);
