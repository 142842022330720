import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import ObservableInterval from 'utils/observable-interval';
import {
  getLiveEventState,
  LIVE_EVENT_STATES,
} from 'utils/live-event';
import ClientSideComponent from 'components/ClientSideComponent/ClientSideComponent';
import Text from 'components/StyledSystem/Text';
import { useIsMountedRef } from 'utils/hooks';
import { LiveEventTime } from 'types';

const Container = createComponent((
  {
    theme,
    liveEventState,
    big,
  }: ComponentRuleProps,
): RuleStyles => ({
  borderRadius: '4px 4px 0 0',
  transform: 'skewX(-17deg)',
  fontSize: theme.fontSize.fine,
  fontWeight: 'bolder',
  lineHeight: '1',
  userSelect: 'none',
  extend: [
    {
      condition: liveEventState === LIVE_EVENT_STATES.live,
      style: {
        background: theme.color.liveLabelRunning,
        color: 'white',
        padding: '4px 6px 2px',
        letterSpacing: '0.7px',
      },
    },
    {
      condition: liveEventState === LIVE_EVENT_STATES.upcomingToday
        || liveEventState === LIVE_EVENT_STATES.upcoming
        || liveEventState === LIVE_EVENT_STATES.ended,
      style: {
        background: theme.color.liveLabelUpcoming,
        color: 'white',
        border: '1px solid white',
        padding: '5px 10px 3px',
      },
    },
    {
      condition: big,
      style: {
        fontSize: theme.fontSize.medium,
      },
    },
  ],
}), 'div', ['liveEventState', 'big']);

const interval = new ObservableInterval(1000);

type LiveLabelProps = {
  big?: boolean,
  liveEventTime?: LiveEventTime;
};

const LiveLabel = ({ big, liveEventTime }: LiveLabelProps): JSX.Element | null => {
  const isMounted = useIsMountedRef();
  const [liveEventState, setLiveEventState] = useState(getLiveEventState(liveEventTime));

  const updateLiveEventState = () => {
    if (!isMounted.current) return;
    setLiveEventState(getLiveEventState(liveEventTime));
  };

  useEffect(() => {
    interval.subscribe(updateLiveEventState);

    return () => {
      interval.unsubscribe(updateLiveEventState);
    };
  }, []);

  if (!liveEventTime) return null;

  return (
    <ClientSideComponent>
      <Container
        big={big}
        liveEventState={liveEventState}
        data-cy="live-label"
      >
        {liveEventState === LIVE_EVENT_STATES.live && (
          <Text id="liveLabel.live" />
        )}

        {liveEventState === LIVE_EVENT_STATES.upcomingToday && (
          <Text id="liveLabel.today" />
        )}

        {liveEventState === LIVE_EVENT_STATES.upcoming && (
          format((liveEventTime.startTime || 0) * 1000, 'd MMM')
        )}

        {liveEventState === LIVE_EVENT_STATES.ended && (
          <Text id="liveLabel.ended" />
        )}
      </Container>
    </ClientSideComponent>
  );
};

export default LiveLabel;
