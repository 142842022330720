import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { smallScreen, withBasePadding } from 'styles/fela/mixins';
import { IconButton, CloseIcon, CircleCheckIcon } from 'components/Icons';

const Container = createComponent(({ theme, bottom, fullWidth, type }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.modal,
  top: '2px',
  left: '50%',
  transform: 'translateX(-50%)',

  color: theme.color.snackbarColor,
  backgroundColor: theme.color.snackbarBackground,
  paddingTop: theme.margin.large,
  paddingBottom: theme.margin.large,
  borderRadius: theme.radius.default,
  boxShadow: `0 1px 3px 0 ${theme.color.overlayDarkFaint}, 0 1px 1px 0 ${theme.color.overlayDarkTranslucent}, 0 2px 1px -1px ${theme.color.overlayDarkTranslucent}`,

  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',

  extend: [
    withBasePadding(),
    smallScreen({
      width: '100%',
      borderRadius: 0,
    }),
    {
      condition: bottom,
      style: {
        top: 'auto',
        bottom: '0',
      },
    },
    {
      condition: fullWidth,
      style: {
        lef: '0',
        width: '100%',
        borderRadius: '0',
      },
    },
    {
      condition: type === 'success',
      style: {
        backgroundColor: theme.color.successNotification,
        fontWeight: 'bold',
      },
    },
  ],
}), 'div', ['bottom', 'fullWidth', 'type']);

const CloseButtonContainer = createComponent(({ center }) => ({
  position: 'absolute',
  right: '9px',
  top: '0',

  extend: [
    {
      condition: center,
      style: {
        bottom: '0',
        right: '0',
        display: 'flex',
        alignItems: 'center',
      },
    },
  ],
}), 'div', ['center']);

const Notification = (props) => {
  const {
    bottom,
    fullWidth,
    children,
    onClose,
    type,
  } = props;

  return (
    <Container bottom={bottom} fullWidth={fullWidth} type={type}>
      {type === 'success' && (<CircleCheckIcon size="1.3rem" style={{ marginRight: '0.5rem' }} />)}

      {children}

      <CloseButtonContainer center={!bottom}>
        <IconButton style={{ width: 'auto' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
    </Container>
  );
};

Notification.propTypes = {
  onClose: PropTypes.func.isRequired,
  bottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

export default Notification;
