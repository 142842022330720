import React from 'react';
import { AppStore } from 'reducers';
import { RouterLocation } from 'router';
import NoOffersErrorPage from 'components/ErrorPage/NoOffersErrorPage';
import { TOfferPurchasable } from 'views/Checkout/types';
import { useGetAvailableOfferQuery } from 'views/Checkout/offer.generated';
import { getPaymentMethod } from 'actions/billing';
import Checkout from './Checkout';

interface ICheckoutViewProps {
  location: RouterLocation;
}

type TCheckoutViewLocationQuery = {
  createAccount?: boolean;
  packageId?: string;
  redirectTo?: string;
};

function Upgrade({ location }: ICheckoutViewProps) {
  const {
    createAccount,
    redirectTo,
  }: TCheckoutViewLocationQuery = location.query || {};

  const packageId = location?.params?.id as string;
  const originalOfferId = location?.params?.originalOfferId as string;

  const {
    data,
    loading,
    error: apolloError,
  } = useGetAvailableOfferQuery({
    variables: {
      offerId: packageId,
    },
  });

  if (loading) return null;

  const { offer, entitlements } = data?.viewer || {};
  const isOfferBought = entitlements?.edges?.some(e => e?.node.offerId === packageId);

  if (!offer || isOfferBought || apolloError) {
    return <NoOffersErrorPage hasEntitlement={!!entitlements?.edges?.length} />;
  }

  return (
    <Checkout
      createAccount={createAccount}
      packageId={packageId}
      offer={offer as TOfferPurchasable}
      redirectTo={redirectTo}
      originalOfferId={originalOfferId}
    />
  );
}

export const initUpgrade = (store: AppStore): Promise<void> => (
  store.dispatch(getPaymentMethod())
);

export default React.memo(Upgrade);
