import createComponent from 'styles/fela/createComponent';
import { responsiveLg } from 'styles/fela/mixins';

export const Wrapper = createComponent(({ theme }) => ({
  margin: `${theme.margin.fine} auto`,
  paddingBotttom: theme.margin.large,
  textAlign: 'center',
}));

export const ChannelButton = createComponent(({ theme, isFavorite, disabled }) => ({
  backgroundColor: theme.color.overlayDarkFaint,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  border: '2px solid lightgray',
  borderRadius: '50%',
  cursor: 'pointer',
  height: '6vw',
  margin: '1.375vw 1.5625vw',
  maxHeight: '72px',
  maxWidth: '72px',
  minHeight: '6px',
  minWidth: '36px',
  outline: 'none',
  padding: 0,
  width: '6vw',
  extend: [
    responsiveLg({
      margin: '15px 20px',
    }),
    {
      condition: isFavorite,
      style: {
        backgroundColor: theme.color.highlight,
        borderColor: theme.color.brand,
      },
    },
    {
      condition: disabled,
      style: {
        filter: 'grayscale(100%)',
      },
    },
  ],
}), 'button', ['isFavorite', 'disabled']);

ChannelButton.displayName = 'ChannelButton';
