import React, { useEffect, useRef } from 'react';
import { Box, Heading, Spinner } from 'components';
import SettingsContainer from 'views/Settings/SettingsContainer';
import * as billingActions from 'actions/billing';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as routerActions from 'router/actions';
import { locationShape } from 'router/prop-types';
import {
  useSubmitAdyenCheckoutPaymentDetailsMutation,
} from 'components/AdyenDropIn/submitAdyenCheckoutPaymentDetails.generated';
import { ADYEN_RESULT_TYPES, PAYMENT_PROVIDERS } from 'utils/constants';
import logger from 'utils/logger';
import { useI18n } from 'components/I18n';
import { addMessage } from 'actions/messages';

const PaymentMethodSetupConfirmView = ({ location }) => {
  const timerId = useRef();
  const { paymentMethod } = useSelector(({ billing }) => ({
    paymentMethod: billing.paymentMethod,
  }), shallowEqual);

  const i18n = useI18n();

  const dispatch = useDispatch();
  const paymentsPageRoute = {
    name: 'settings-payment',
  };
  const paymentsSetupPageRoute = {
    name: 'settings-payment-setup',
  };
  const retryConfig = {
    interval: 1000,
    timeout: 15000,
  };

  const {
    redirectResult,
    transactionId,
  } = location.query;
  const {
    isAdyenDropin,
  } = useSelector(({ settings }) => ({
    isAdyenDropin: settings.features.payment.provider.type === PAYMENT_PROVIDERS.ADYEN_DROPIN,
  }), shallowEqual);

  const getPaymentMethodWithRetry = async () => {
    // updating of payment method is async,
    // thus we need to call get payment method api periodically to know when
    // payment method has been updated
    await dispatch(billingActions.getPaymentMethod());
    const timer = setTimeout(() => {
      getPaymentMethodWithRetry();
    }, retryConfig.interval);
    timerId.current = timer;
  };

  const [
    getAdyenPaymentDetails,
  ] = useSubmitAdyenCheckoutPaymentDetailsMutation({
    onCompleted: (data) => {
      try {
        const adyenResponse = JSON.parse(data.result.adyenResponse);
        const resultCode = adyenResponse.resultCode.toUpperCase();
        // check authentication status for 3DS
        if (resultCode === ADYEN_RESULT_TYPES.AUTHORISED) {
          getPaymentMethodWithRetry();
        } else {
          logger.error('Payment details refused', adyenResponse.refusalReason);
          let contentId = 'paymentSettings.transactionFailed';
          let contentValue;
          if (adyenResponse.refusalReason) {
            const reasonI18nKey = `payment.refusal_${adyenResponse.refusalReason}`;
            contentId = 'paymentSettings.transactionFailedWithReason';
            contentValue = {
              reason: i18n.hasMessage(reasonI18nKey)
                ? i18n.formatText(reasonI18nKey)
                : adyenResponse.refusalReason,
            };
          }
          dispatch(addMessage({ contentId, contentValue }));
          dispatch(routerActions.push(paymentsSetupPageRoute));
        }
      } catch (e) {
        logger.error('Payment details response with error', e);
        dispatch(routerActions.push(paymentsPageRoute));
      }
    },
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // navigate back to account setting payment page when timeout is reached
      // regardless of whether payment method is available or not
      clearTimeout(timerId.current);
      dispatch(routerActions.push(paymentsPageRoute));
    }, retryConfig.timeout);

    if (redirectResult && isAdyenDropin) {
      // call get payment details for 3DS to check authentication status
      try {
        getAdyenPaymentDetails({
          variables: {
            input: {
              transactionId,
              details: JSON.stringify({
                redirectResult,
              }),
            },
          },
        });
      } catch (e) {
        logger.error('Get payment details failed with error', e);
        dispatch(routerActions.push(paymentsPageRoute));
      }
    } else {
      getPaymentMethodWithRetry();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (paymentMethod?.method) {
      dispatch(routerActions.push(paymentsPageRoute));
    }
  }, [paymentMethod?.method]);

  return (
    <SettingsContainer>
      <Heading
        id="payment"
        letterSpacing="2.3px"
        mb="large"
      />

      <Box my="xxxlarge">
        <Spinner size={7} strokeWidth={2} />
      </Box>

    </SettingsContainer>
  );
};

PaymentMethodSetupConfirmView.propTypes = {
  location: locationShape.isRequired,
};

export default React.memo(PaymentMethodSetupConfirmView);
