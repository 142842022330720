import { ADD_MESSAGE, CLEAR_MESSAGE, MessagesActions } from 'actions/messages';

type Message = {
  key: number,
  contentId: string,
  contentValue?: Record<string, string>,
  duration: number,
};
type MessagesReducerState = Record<string, Message>;
const initialState: MessagesReducerState = {};

export function messagesReducer(
  store = initialState,
  action: MessagesActions,
): MessagesReducerState {
  switch (action.type) {
    case ADD_MESSAGE: {
      return {
        ...store,
        [action.payload.key]: action.payload,
      };
    }

    case CLEAR_MESSAGE: {
      const nextStore = { ...store };
      delete nextStore[action.payload.key];

      return nextStore;
    }

    default: {
      return store;
    }
  }
}
