import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as routerActions from 'router/actions';
import { useQuery } from '@apollo/client';
import {
  SEARCH_FILTER_KINDS, VIEWABLE_TYPES,
} from 'utils/constants';
import * as searchActions from 'actions/search';
import { useI18n } from 'components/I18n';
import {
  Metadata,
  Box,
  Text,
  Spacer,
  Content,
  PageWrapper,
} from 'components';
import { SearchIcon } from 'components/Icons';
import SearchInput from 'components/SearchInput/SearchInput';
import { flattenEdges } from 'utils/helpers';
import { locationShape } from 'router/prop-types';
import CollectionDisplayTypeSwitch from 'components/CollectionDisplayTypeSwitch';
import KindFilter from './KindFilter';
import SearchResultSection from './SearchResultSection';
import SEARCH_QUERY from './queries/search.gql';
import SEARCH_KIND_QUERY from './queries/searchKind.gql';

const SECTIONS = ['shows', 'movies', 'programs'];
const SECTION_TO_KIND = {
  movies: 'movie',
  shows: 'show',
  programs: 'program',
};

const filterViewables = (viewable) => {
  if (!viewable) return false;

  return !(viewable.__typename === VIEWABLE_TYPES.Show
      && (!viewable.seasons || !viewable.seasons.length || !viewable.selectedEpisode));
};

const reduceDoubledItems = (acc, item) => {
  if (acc.some(i => i.id === item.id)) {
    return acc;
  }
  return [...acc, item];
};
const getSearchVariables = (searchTerm, searchFilterKinds) => ({
  query: searchTerm || '',
  kinds: searchFilterKinds.length > 0
    ? searchFilterKinds.sort().map(kind => SECTION_TO_KIND[kind]).toString()
    : Object.values(SECTION_TO_KIND).sort().toString(),
  blocksFirst: 40,
});

const normalizeSearchResult = (searchData) => {
  const results = flattenEdges(searchData?.viewer.results)
    .filter(filterViewables)
    .reduce(reduceDoubledItems, []) || [];
  const pagination = searchData?.viewer.results.pageInfo;
  return { results, pagination };
};

function SearchView({ location }) {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const { query: { q: searchTerm, kinds } } = location;

  const {
    collectionDisplayStyles,
    searchResultFilter,
    isLoggedIn,
    horizontalMenuHidden,
  } = useSelector(({ settings, auth, ui }) => ({
    collectionDisplayStyles: settings.features.searchResultDisplay.listStyle,
    searchResultFilter: !!settings.features.searchResultFilter,
    isLoggedIn: auth.isLoggedIn,
    horizontalMenuHidden: ui.horizontalMenuHidden,
  }), shallowEqual);

  const searchFilterKinds = useMemo(() => {
    if (!kinds) {
      return [];
    }
    return Array.isArray(kinds) ? [...kinds] : [kinds];
  }, [kinds]);

  const [displayType, setDisplayType] = useState(collectionDisplayStyles[0]);

  const searchVariables = getSearchVariables(searchTerm, searchFilterKinds);

  const {
    loading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(SEARCH_QUERY, {
    variables: searchVariables,
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: kindLoading,
    error: kindError,
    data: kindData,
  } = useQuery(SEARCH_KIND_QUERY, {
    variables: {
      query: searchTerm || '',
    },
  });

  const [searchResults, setSearchResults] = useState(normalizeSearchResult(data));

  useEffect(() => {
    if (loading) return;

    setSearchResults(normalizeSearchResult(data));
  }, [data, loading]);

  const {
    results,
    pagination,
    searchResultsCount = results.length,
    hasMore = pagination?.hasNextPage || false,
  } = searchResults;

  const toggleSearchFilterKind = (kind, force = false) => {
    let nextSearchKinds = [...searchFilterKinds];

    if (kind === SEARCH_FILTER_KINDS.all) {
      nextSearchKinds = undefined;
    } else if (nextSearchKinds.includes(kind)) {
      nextSearchKinds = nextSearchKinds.filter(k => k !== kind);
    } else {
      nextSearchKinds.push(kind);
    }

    dispatch((force ? routerActions.replace : routerActions.push)({
      name: 'search',
      query: {
        q: searchTerm,
        kinds: nextSearchKinds,
      },
    }));
  };

  useEffect(() => {
    // refetch data from apiQL on user login/logout
    if (
      data
      && 'isAuthenticated' in data.viewer
      && isLoggedIn !== data.viewer.isAuthenticated
    ) {
      refetch();
    }
  }, [isLoggedIn, data]);

  useEffect(() => {
    const someKindsArePresent = searchResultsCount > 0;
    if (
      searchFilterKinds.length > 0
      && !searchFilterKinds.includes(SEARCH_FILTER_KINDS.all)
      && !someKindsArePresent
      && !loading
    ) {
      // force set filter to show all kinds
      toggleSearchFilterKind(SEARCH_FILTER_KINDS.all, true);
    }
  },
  [searchResultsCount, searchFilterKinds, loading]);

  const searchResultCounts = useMemo(() => (
    SECTIONS.reduce((acc, key) => ({
      ...acc,
      [key]: flattenEdges(kindData?.viewer[key])?.length,
    }), {})
  ), [searchTerm, kindLoading]);

  const createHandlerLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        cursor: pagination.endCursor,
        ...searchVariables,
      },
      notifyOnNetworkStatusChange: true,
    });
  }, [pagination, searchVariables]);

  const handleCloseSearch = ({ forceHide }) => {
    if (forceHide) {
      dispatch(searchActions.search(''));
    }
  };
  const handleSearch = q => dispatch(searchActions.search(q, searchFilterKinds));

  const handleDisplayTypeSwitch = newType => setDisplayType(newType);

  const showDisplayStyleSwitch = collectionDisplayStyles.length === 2 && searchResultsCount > 0;
  const showKindFilter = searchResultFilter && searchResultsCount > 0;

  const content = error || kindError ? null : (
    <>
      <Box
        flexBox
        justifyContent="space-between"
        wrap
        mb="xlarge"
        height="39px"
        hidden={!searchResultFilter && !showDisplayStyleSwitch}
      >
        {showKindFilter ? (
          <KindFilter
            searchFilterKinds={searchFilterKinds}
            searchResultCounts={searchResultCounts}
            onClick={toggleSearchFilterKind}
          />
        ) : (<Spacer />)}

        {showDisplayStyleSwitch && (
          <CollectionDisplayTypeSwitch
            selected={displayType}
            onClick={handleDisplayTypeSwitch}
          />
        )}
      </Box>

      <SearchResultSection
        items={results}
        displayType={displayType}
        query={searchTerm}
        visible
        hasMore={hasMore}
        loadMore={createHandlerLoadMore}
        loading={loading}
      />
      {!searchResultsCount && !loading && (
        <Box
          column
          pt="xxxlarge"
        >
          <SearchIcon
            size="13rem"
          />

          <Box row width="50%">
            <Text
              id={searchTerm ? 'search.noMatches' : 'search.emptyState'}
              values={{ query: searchTerm }}
              fontSize="large"
              align="center"
              lineHeight="1.6"
              whiteSpace="pre-line"
              wordBreak="break-word"
            />
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <PageWrapper background>
      <Metadata title={i18n.formatText('search.pageTitle', { query: searchTerm })} />
      <Content>
        {
          horizontalMenuHidden ? (
            <Box>
              <SearchInput
                onSearch={handleSearch}
                onClose={handleCloseSearch}
                autoFocus
                iconSize={29.54}
              />
            </Box>
          ) : null
        }
        {content}
      </Content>
    </PageWrapper>
  );
}

SearchView.propTypes = {
  location: locationShape,
};

export default React.memo(SearchView);
