import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import nprogress from 'nprogress';
import { connect as connectStyles } from 'react-fela';
import * as styleRules from './LoadingBarStyleRules';

const LoadingBar = (props) => {
  const {
    loading,
    styles: {
      bar,
      peg,
    },
  } = props;

  const timer = useRef();

  useEffect(() => {
    nprogress.configure({
      template: `<div class="${bar}" role="bar">
        <div class="${peg}"></div>
      </div>`,
      minimum: 0.2,
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => nprogress.done(), 100);
    }

    if (loading) {
      nprogress.start();
    }
  }, [loading]);

  return null;
};

const mapStylesToProps = {
  bar: styleRules.bar,
  peg: styleRules.peg,
};

LoadingBar.propTypes = {
  loading: PropTypes.bool,
  styles: PropTypes.shape({
    bar: PropTypes.string.isRequired,
    peg: PropTypes.string.isRequired,
  }),
};

export default connectStyles(mapStylesToProps)(LoadingBar);
