import LinkComponent from 'router/Link';
import createComponent from 'styles/fela/createComponent';

const CleanLink = createComponent(({ theme, block = false, animate = false }) => ({
  color: theme.color.primary,
  textDecoration: 'none',
  extend: [
    {
      condition: block,
      style: {
        display: 'block',
        position: 'relative',
      },
    },
    {
      condition: animate,
      style: {
        '& svg': {
          transition: '0.5s transform',
        },
        ':hover svg': {
          transform: 'scale(1.1)',
        },
      },
    },
  ],
}), LinkComponent, ['active', 'block', 'animate']);

CleanLink.displayName = 'CleanLink';

export default CleanLink;
