import createComponent from 'styles/fela/createComponent';
import {
  withSpinning,
} from 'styles/fela/mixins';
import PlusIcon from 'components/Icons/PlusIcon';
import CircleIcon from 'components/Icons/CircleIcon';
import { BUTTON_HEIGHT } from 'utils/constants';

export const Container = createComponent(({ theme, inProgress, short }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: theme.fontSize.normal,
  letterSpacing: '0.5px',
  userSelect: 'none',
  extend: [
    {
      condition: inProgress,
      style: {
        cursor: 'wait',
      },
    },
    {
      condition: short,
      style: {
        display: 'inline-block',
        paddingRight: theme.margin.small,
      },
    },
  ],
  ':hover:not([disabled]) svg circle': {
    fill: theme.color.secondaryButtonHover,
  },
}), 'div', ['inProgress', 'short']);

export const IconContainer = createComponent(() => ({
  position: 'relative',
  width: BUTTON_HEIGHT,
  height: BUTTON_HEIGHT,
}));

export const StyledCircleIcon = createComponent(({ theme }) => ({
  color: theme.color.brand,
  width: '100%',
  height: '100%',
  overflow: 'visible',
  fill: 'transparent',
  '& circle': {
    transition: 'fill 0.2s ease-in-out',
  },
}), CircleIcon, []);

export const StyledPlusIcon = createComponent(({ theme, inProgress }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  color: theme.color.brand,
  width: '100%',
  height: '100%',
  overflow: 'visible',
  transform: 'rotate(45deg)',
  extend: [
    {
      condition: inProgress,
      style: withSpinning(500),
    },
  ],
}), PlusIcon, ['inProgress']);
