import React from 'react';
import Heading from 'components/StyledSystem/Heading';

export function ModalHeading(props) {
  return (
    <Heading
      as="h2"
      fontSize="sectionHeading"
      align="center"
      wordWrap="break-word"
      {...props}
    />
  );
}

export function ModalSubHeading(props) {
  return (
    <Heading
      as="h4"
      fontSize="medium"
      align="center"
      wordWrap="break-word"
      {...props}
    />
  );
}
