import React from 'react';
import createComponent from 'styles/fela/createComponent';

const Svg = createComponent(() => ({
  position: 'relative',
  float: 'right',
  right: '-10px',
  bottom: '28px',
}), 'svg');

const CatchupIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <path
      d="M11.5 23C5.149 23 0 17.851 0 11.5S5.149 0 11.5 0 23 5.149 23 11.5 17.851 23 11.5 23zM17 12L7 7v10l10-5z"
      fillRule="evenodd"
    />
  </Svg>
);

export default CatchupIcon;
