import { IUser, IUserSettings } from 'types/api.user';
import { apiNetworkRequest, AppAsyncAction } from './helpers';

export const GET_USER = 'user/GET_USER';
interface GetUserAction {
  type: typeof GET_USER
  data: IUser
}
export const getUser = (): AppAsyncAction => async (dispatch) => {
  const result: IUser = await dispatch(apiNetworkRequest('user.get'));

  dispatch<GetUserAction>({
    type: GET_USER,
    data: result,
  });
};

export const GET_USER_TAGS = 'user/GET_USER_TAGS';
interface GetUserTagsAction {
  type: typeof GET_USER_TAGS
  data: IUser['tags']
}
export const getUserTags = (): AppAsyncAction => async (dispatch) => {
  const result: IUser['tags'] = await dispatch(apiNetworkRequest('user.getTags'));

  dispatch<GetUserTagsAction>({
    type: GET_USER_TAGS,
    data: result,
  });
};

export const addUserTags = (tags: IUser['tags']): AppAsyncAction => async (dispatch) => {
  await dispatch(apiNetworkRequest('user.addTags', { tags }));
};

type UserSettings = Partial<Omit<IUserSettings, 'email'> & { contactEmail?: string }>;

export const updateSettings = (settings: UserSettings): AppAsyncAction => async (dispatch) => {
  const { contactEmail, emailOptIn, ...other } = settings;

  await dispatch(apiNetworkRequest('user.update', {
    email: contactEmail ? contactEmail.trim() : undefined,
    emailOptIn,
    ...other,
  }));
};

export function resetDevices(): AppAsyncAction {
  return async (dispatch) => {
    await dispatch(apiNetworkRequest('playback.resetDevices'));
  };
}

export type UserActions = GetUserAction | GetUserTagsAction;
