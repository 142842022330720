import React from 'react';
import PropTypes from 'prop-types';
import { CollectionText } from './CollectionText';
import {
  Header,
  Container,
  ShowAllLink,
} from './ComponentWrapperStyles';

export {
  ShowAllLink,
};

const ComponentWrapper = ({
  className,
  children,
  title,
  right,
}) => (
  <Container className={className}>
    <Header>
      {title && (
        <CollectionText as="h1">
          {title}
        </CollectionText>
      )}
      {right}
    </Header>
    {children}
  </Container>
);

ComponentWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  right: PropTypes.node,
};

export default ComponentWrapper;
