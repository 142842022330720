import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'react-virtualized';
import { broadcastShape, channelShape } from 'reducers/epg';
import { sec2px } from 'utils/broadcast';
import DragScroll from 'components/DragScroll';
import { PlayerContext } from 'components/Player';
import { analyticsShape } from 'components/Tracking';
import Broadcast from './Broadcast';
import { deduceBroadcastState } from './utils';
import {
  CHANNEL_SIZE,
  ROW_HEIGHT,
  OFFSET_TOP,
  OFFSET_BOTTOM,
} from '../ChannelList';

const collectionStyle = { outline: 'none', overscrollBehaviorX: 'none' };

const Broadcasts = (
  {
    broadcasts,
    channels,
    startTime,
    currentTime,
    scrollTop,
    onScroll,
    scrollLeft,
    height,
    width,
    isRTL,
    analytics,
  },
) => {
  const playerContext = useContext(PlayerContext);
  const broadcastStates = useRef({});

  const trackClick = (channelId, playableId, isActive) => {
    if (isActive) {
      analytics.onClick({
        component: 'EPG',
        eventName: 'click_broadcast',
        action: 'click',
        clickType: 'asset',
        channelId,
        element: 'broadcast',
        playableId,
      });
    } else {
      analytics.onClick({
        component: 'EPG',
        eventName: 'click_disabled_broadcast',
        action: 'click',
        clickType: 'click',
        playableId,
      });
    }
  };

  const getPosition = ({ index }) => {
    // make sure there is enough height for all channels
    // even if they doesn't have broadcasts right now
    if (index === broadcasts.length) {
      return {
        width: 1,
        height: OFFSET_TOP + channels.length * ROW_HEIGHT - CHANNEL_SIZE.gap + OFFSET_BOTTOM,
        x: 0,
        y: 0,
      };
    }

    const broadcast = broadcasts[index];
    const duration = broadcast.stop - broadcast.start;
    const channelIndex = channels.findIndex(channel => channel.id === broadcast.channelId);

    return {
      width: sec2px(duration),
      height: CHANNEL_SIZE.height,
      x: sec2px(broadcast.start - startTime),
      y: OFFSET_TOP + channelIndex * ROW_HEIGHT,
    };
  };

  const renderCell = ({ index, key, style }) => {
    if (index === broadcasts.length) {
      return (
        <div key={key} style={style} />
      );
    }

    const broadcast = broadcasts[index];

    // this allows to prevent ChannelList from hiding broadcast labels
    let textOffset = 0;
    if (isRTL) {
      const channelListLeftOffset = scrollLeft + width - CHANNEL_SIZE.width;
      const broadcastRightOffset = style.left + style.width;

      const isCovered = broadcastRightOffset > channelListLeftOffset;

      textOffset = isCovered
        ? broadcastRightOffset - channelListLeftOffset
        : 0;
    } else {
      const channelListRightOffset = scrollLeft + CHANNEL_SIZE.width;
      const broadcastLeftOffset = style.left;

      const isCovered = channelListRightOffset > broadcastLeftOffset;

      textOffset = isCovered
        ? channelListRightOffset - broadcastLeftOffset
        : 0;
    }

    const channel = channels.find(item => item.id === broadcast.channelId);

    return (
      <div key={key} style={style}>
        <Broadcast
          broadcast={broadcast}
          broadcastState={broadcastStates.current[broadcast.id]}
          activePlayableId={playerContext.playableId}
          textOffset={textOffset}
          isEntitled={channel.isEntitled}
          isRTL={isRTL}
          trackClick={trackClick}
        />
      </div>
    );
  };

  const updateBroadcastStates = () => {
    let newStates;

    // for each broadcast deduce current state
    // if at least one broadcast state changed, create new object with states
    // to force Collection re-render
    for (const broadcast of broadcasts) {
      const broadcastState = deduceBroadcastState(broadcast, currentTime);

      if (broadcastState === broadcastStates.current[broadcast.id]) {
        continue; // eslint-disable-line no-continue
      }

      if (!newStates) {
        newStates = { ...broadcastStates.current };
      }

      newStates[broadcast.id] = broadcastState;
    }

    if (newStates) {
      broadcastStates.current = newStates;
    }
  };

  updateBroadcastStates();

  return (
    <DragScroll>
      <Collection
        cellCount={broadcasts.length + 1}
        cellRenderer={renderCell}
        cellSizeAndPositionGetter={getPosition}
        onScroll={onScroll}
        scrollTop={scrollTop}
        scrollLeft={scrollLeft}
        width={width}
        height={height}
        verticalOverscanSize={ROW_HEIGHT * 10}
        style={collectionStyle}
        broadcastStates={broadcastStates.current}
      />
    </DragScroll>
  );
};

Broadcasts.propTypes = {
  broadcasts: PropTypes.arrayOf(broadcastShape).isRequired,
  channels: PropTypes.arrayOf(channelShape).isRequired,
  startTime: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  scrollTop: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
  scrollLeft: PropTypes.number,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  isRTL: PropTypes.bool.isRequired,
  analytics: analyticsShape.isRequired,
};

export default Broadcasts;
