import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'react-fela';
import { logoDarkOrLight } from 'utils/helpers';
import ChannelLogo from './ChannelLogo';

const channelShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  logoDark: PropTypes.string,
  logoLight: PropTypes.string,
});

const AdaptiveChannelLogo = ({ theme, channel, ...otherProps }) => (
  <ChannelLogo
    alt={channel.title}
    src={logoDarkOrLight(channel, theme)}
    {...otherProps}
  />
);

AdaptiveChannelLogo.propTypes = {
  channel: channelShape.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(AdaptiveChannelLogo);
