import React from 'react';
import PropTypes from 'prop-types';
import {
  cardTypeFromShort,
  extractLastGroup,
} from 'utils/credit-card';
import {
  Box,
} from 'components';
import {
  Bullet,
  CardLogo,
  CardWrapper,
} from './CreditCardDisplayStyles';

export default function CreditCardDisplay({ paymentMethod }) {
  const cardType = cardTypeFromShort(paymentMethod.data.ccFinancialInstitution);
  const expiryMonth = paymentMethod.data.ccExpMonth;
  const expiryYear = paymentMethod.data.ccExpYear;

  return (
    <CardWrapper>
      <Box my="medium">
        <CardLogo type={cardType} />
      </Box>

      <Box mb="medium">
        <Bullet /><Bullet /><Bullet /><Bullet /> &nbsp;
        <Bullet /><Bullet /><Bullet /><Bullet /> &nbsp;
        <Bullet /><Bullet /><Bullet /><Bullet /> &nbsp;
        {extractLastGroup(paymentMethod)}
      </Box>

      <Box mb="medium">
        {expiryMonth} / {expiryYear.toString().substring(2)}
      </Box>
    </CardWrapper>
  );
}

CreditCardDisplay.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
};
