import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from 'components/I18n';
import {
  Box,
  Heading,
} from 'components';
import {
  InputErrorMessage,
} from '../Styles';
import {
  toMessage,
} from '../utils';
import NumberInput from './NumberInput';

function DatePicker(props) {
  const {
    name,
    placeholder,
    onChange,
    onBlur,
    dirty,
    error,
    touched,
    isSubmitted,
  } = props;

  const i18n = useI18n();

  const [day, setDay] = React.useState(undefined);
  const [month, setMonth] = React.useState(undefined);
  const [year, setYear] = React.useState(undefined);

  React.useEffect(() => {
    if (day !== undefined || month !== undefined || year !== undefined) {
      const yearStr = `${year}`.padStart(4, '0');
      const monthStr = `${month}`.padStart(2, '0');
      const dayStr = `${day}`.padStart(2, '0');
      onChange(`${yearStr}-${monthStr}-${dayStr}`); // ISO 8601
    }
  }, [onChange, day, month, year]);

  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  const fieldsOrder = React.useMemo(() => (
    new Intl.DateTimeFormat(i18n.language || navigator?.language)
      .formatToParts(new Date())
      .map(i => i.type)
  ), []);

  const isApproved = !error && dirty && touched;
  const showErrorMessage = error && ((dirty && touched) || isSubmitted);

  const inputs = {
    day: (
      <Box key="day" width="3.5rem">
        <NumberInput
          name={`${name}-day`}
          value={day}
          placeholder={i18n.formatText('dd')}
          onChange={setDay}
          onBlur={onBlur}
          isApproved={isApproved}
          max={31}
          min={1}
        />
      </Box>
    ),
    month: (
      <Box key="month" width="3.5rem">
        <NumberInput
          name={`${name}-month`}
          value={month}
          placeholder={i18n.formatText('mm')}
          onChange={setMonth}
          onBlur={onBlur}
          isApproved={isApproved}
          max={12}
          min={1}
        />
      </Box>
    ),
    year: (
      <Box key="year" width="5rem">
        <NumberInput
          name={`${name}-year`}
          value={year}
          placeholder={i18n.formatText('yyyy')}
          onChange={setYear}
          onBlur={onBlur}
          isApproved={isApproved}
          max={currentYear}
          min={1900}
          defaultValue={1940}
        />
      </Box>
    ),
  };

  const orderedInputs = fieldsOrder.map(f => inputs[f]).filter(i => i);

  return (
    <Box
      fullWidth
      pt="large"
      pb="fine"
    >
      <Heading
        fontSize="small"
        mb="fine"
        as="h4"
        align="left"
      >
        {placeholder}
      </Heading>

      <Box
        flexBox
        justifyContent="flex-start"
        gap="medium"
      >
        {orderedInputs}
      </Box>

      <InputErrorMessage isVisible={!!showErrorMessage}>
        {toMessage(error)}
      </InputErrorMessage>
    </Box>
  );
}
DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.object,
  isSubmitted: PropTypes.bool,
};

export default React.memo(DatePicker);
