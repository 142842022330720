import React from 'react';
import {
  Icon,
} from 'components';

export default function TrailerIcon(props) {
  return (
    <Icon width="52" height="52" viewBox="0 0 52 52" fill="none" {...props}>
      <path d="M18.0729 34.8453V35.9775C18.0729 36.2586 17.9695 36.4993 17.7627 36.6996C17.5559 36.8999 17.3091 37 17.0221 37C16.7409 37 16.5002 36.899 16.3001 36.6969C16.1 36.4949 16 36.2538 16 35.9738V16.0466C16 15.7608 16.1014 15.5149 16.3041 15.309C16.5069 15.103 16.7489 15 17.03 15C17.3111 15 17.5552 15.103 17.7623 15.309C17.9693 15.5149 18.0729 15.7608 18.0729 16.0466V17.1547H21.2059V16.2317C21.2059 15.8913 21.325 15.601 21.5632 15.3606C21.8015 15.1202 22.0874 15 22.4209 15H29.1052C29.4445 15 29.7352 15.1211 29.9773 15.3632C30.2195 15.6054 30.3405 15.8961 30.3405 16.2354V17.1547H33.4736V16.0429C33.4736 15.7561 33.575 15.5105 33.7778 15.3063C33.9806 15.1021 34.2225 15 34.5036 15C34.7847 15 35.0288 15.103 35.2358 15.309C35.4429 15.5149 35.5465 15.7608 35.5465 16.0466V35.9738C35.5465 36.2538 35.4431 36.4949 35.2363 36.6969C35.0295 36.899 34.7827 37 34.4957 37C34.2145 37 33.9738 36.899 33.7737 36.6969C33.5736 36.4949 33.4736 36.2538 33.4736 35.9738V34.8453H30.3405V35.7888C30.3405 36.1233 30.2195 36.4088 29.9773 36.6453C29.7352 36.8818 29.4445 37 29.1052 37H22.4209C22.0874 37 21.8015 36.8809 21.5632 36.6427C21.325 36.4044 21.2059 36.1186 21.2059 35.7851V34.8453H18.0729ZM18.0729 32.7724H21.2059V29.6394H18.0729V32.7724ZM18.0729 27.5665H21.2059V24.4335H18.0729V27.5665ZM18.0729 22.3606H21.2059V19.2276H18.0729V22.3606ZM30.3405 32.7724H33.4736V29.6394H30.3405V32.7724ZM30.3405 27.5665H33.4736V24.4335H30.3405V27.5665ZM30.3405 22.3606H33.4736V19.2276H30.3405V22.3606ZM23.2788 34.9271H28.2677V17.0729H23.2788V34.9271Z" fill="currentColor" />
    </Icon>
  );
}
