import React, { FC } from 'react';
import { createComponent, useFela } from 'react-fela';
import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import {
  Modal,
  ModalContainer,
  PrimaryButton,
  Text,
  Heading,
  Box,
  Price,
} from 'components';
import { GlobalTheme, Offer } from 'types';
import { CloseIcon, IconButton } from 'components/Icons';
import { CheckmarkIcon } from './CheckmarkIcon';
import { BoxWithoutOuterMargin } from './BoxWithoutOuterMargin';

const checkedIconStyles = ({ theme }: ComponentRuleProps): RuleStyles => ({
  position: 'absolute',
  top: theme.margin.large,
  right: theme.margin.large,
});

const CloseButtonContainer = createComponent(() => ({
  position: 'absolute',
  right: '-0.6rem',
  top: '-0.8rem',
}), 'div', []);

interface ChangePlanModalProps {
  currentOffer: Offer;
  newOffer: Offer;
  onDismiss: () => void;
  onConfirm: (offer: Offer) => void;
}

const ChangePlanModal: FC<ChangePlanModalProps> = ({
  currentOffer,
  newOffer,
  onDismiss,
  onConfirm,
}) => {
  const { theme, css } = useFela<GlobalTheme>();

  const renderOfferString = (offer: Offer, isDisabled?: boolean) => {
    if (!offer) {
      return '';
    }

    return (
      <Text
        fontSize="medium"
        id="changeOffer.offerString"
        opacity={isDisabled ? '0.5' : '1'}
        values={{
          offerTitle: offer.title,
          price: (
            <Price key="price" price={offer.priceInCents} currency={offer.currency} />
          ),
          period: offer.recurringPeriod.unit.toLowerCase(),
        }}
      />
    );
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalContainer withWidth="40rem">
        <Heading id="changeOffer.confirmDialogTitle" fontSize="sectionHeading" />
        <CloseButtonContainer>
          <IconButton onClick={onDismiss} style={{ padding: 0 }}>
            <CloseIcon size={35} />
          </IconButton>
        </CloseButtonContainer>

        <BoxWithoutOuterMargin
          mb="small"
          mt="large"
          bg="paperBackground"
          p="large"
          data-cy="current-offer"
        >
          <Text
            fontSize="medium"
            fontWeight="bold"
            id="offer.current"
            mb="fine"
            as="p"
            opacity="0.5"
          />
          {renderOfferString(currentOffer, true)}
        </BoxWithoutOuterMargin>

        <BoxWithoutOuterMargin
          mb="large"
          p="large"
          bg="paperBackground"
          position="relative"
          data-cy="new-offer"
        >
          <Text
            fontSize="medium"
            fontWeight="bold"
            id="offer.new"
            mb="fine"
            as="p"
          />
          {renderOfferString(newOffer)}
          <CheckmarkIcon className={css(checkedIconStyles({ theme }))} />
        </BoxWithoutOuterMargin>

        <Box mb="xlarge">
          <Text
            id="changeOffer.deductionString"
            values={{
              offerTitle: newOffer.title,
              price: (
                <Price key="refundPriceInCents" price={newOffer.refundPriceInCents} currency={newOffer.currency} />
              ),
            }}
            fontSize="medium"
          />
        </Box>

        <Box m="large" row flexBox alignItems="center">
          <PrimaryButton variant="brand" onClick={() => onConfirm(newOffer)}>
            <Text id="changeOffer.continue" />
          </PrimaryButton>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default ChangePlanModal;
