import createComponent from 'styles/fela/createComponent';
import { smallScreen } from 'styles/fela/mixins';

const Panel = createComponent(({ theme, textAlign, maxWidth: maxWidthP }) => ({
  maxWidth: maxWidthP || '40rem',
  marginTop: theme.margin.xxxlarge,
  padding: theme.margin.large,
  width: '100%',
  extend: [
    {
      condition: textAlign,
      style: {
        textAlign,
      },
    },
    smallScreen({
      padding: `${theme.margin.large} 0`,
    }),
  ],
}), 'div', ['textAlign', 'maxWidth']);

Panel.displayName = 'Panel';

export default Panel;
