import type { IStyle } from 'fela';

type KeyframeType = {
  [persent: string]: IStyle | undefined;
  from?: IStyle | undefined;
  to?: IStyle | undefined;
};

export const hotkeyIconWrapperStyle = (): IStyle => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  opacity: 1,
  pointerEvents: 'none',
  color: 'white',
});

export const hotkeyIconAnimation = (): KeyframeType => ({
  from: {
    transform: 'scale(0.7)',
    opacity: 1,
  },
  to: {
    transform: 'scale(1)',
    opacity: 0,
  },
});
