import createComponent from 'styles/fela/createComponent';
import { minWidth } from 'styles/fela/mixins';
import { CHANNEL_SIZE } from '../Timeline/ChannelList';

const width = 55;
const height = 110;

// needed to not cut the shadow because of `overflow: hidden`.
const padding = 5;

export const ScrollButtonWrapper = createComponent(({ isLeft }) => ({
  height: `${(height + (padding * 2))}px`,
  width: `${(width + (padding * 2))}px`,
  padding: `${padding}px`,
  overflow: 'hidden',
  pointerEvents: 'none',
  userSelect: 'none',

  position: 'absolute',
  top: '50%',
  zIndex: 4,

  display: 'none',

  extend: [
    minWidth(600, {
      display: 'block',
    }),
    {
      condition: isLeft,
      style: {
        left: CHANNEL_SIZE.width,
      },
    },
    {
      condition: !isLeft,
      style: {
        right: 0,
      },
    },
  ],
}), 'div', ['isLeft']);

export const ArrowButton = createComponent(({ theme, isLeft }) => ({
  backgroundColor: theme.color.zapperBackground,
  color: theme.color.zapper,
  border: 0,
  borderRadius: `${width}px`,
  boxShadow: `0 0.125rem 0.25rem 0 ${theme.color.overlayDarkMedium}`,
  cursor: 'pointer',
  display: 'block',
  height: `${height}px`,
  lineHeight: `${height}px`,
  outline: 0,
  padding: 0,
  pointerEvents: 'auto',
  position: 'absolute',
  width: `${height}px`,
  extend: [
    {
      condition: isLeft,
      style: {
        right: `${padding * 3}px`,
        transition: 'right .2s ease-out',
        '&:hover': {
          right: `${padding * 2}px`,
        },
      },
    },
    {
      condition: !isLeft,
      style: {
        left: `${padding * 3}px`,
        transition: 'left .2s ease-out',
        '&:hover': {
          left: `${padding * 2}px`,
        },
      },
    },
  ],
}), 'button', ['isLeft']);

export const Arrow = createComponent(({ theme, isLeft }) => ({
  color: theme.color.zapper,
  display: 'block',
  fontFamily: 'Icons, sans-serif',
  fontSize: theme.fontSize.sectionHeading,
  position: 'absolute',
  top: 0,
  width: `${width}px`,
  extend: [
    {
      condition: isLeft,
      style: {
        right: 0,
      },
    },
    {
      condition: !isLeft,
      style: {
        left: 0,
      },
    },
  ],
}), 'span', ['isLeft']);
