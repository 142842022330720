import React, { useEffect, useState, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { Box, PrimaryButton, SecondaryButton, Text } from 'components';
import { useDispatch, useSelector } from 'reducers';
import { fetchWithTimeout } from 'utils/fetch';
import { push } from 'router/actions';
import { PLATFORMS, PLATFORMS_NAME } from 'utils/constants';
import { I18nContext } from 'components/I18n';
import StepSuccess from './Steps/Success';
import StepDenied from './Steps/Denied';
import UserCodeInput from './UserCodeInput';

const STEPS = {
  VERIFY: 'verify',
  SUCCESS: 'success',
  DENIED: 'denied',
};

const DeviceAuthView = () => {
  const {
    clientApiToken,
    sessionToken,
    location,
    magineEnvironment,
  } = useSelector(state => ({
    clientApiToken: state.settings.clientApiToken,
    sessionToken: state.auth.sessionToken,
    location: state.router.location,
    magineEnvironment: state.settings.app.magineEnvironment,
  }), shallowEqual);
  const deviceAuthServiceURI = magineEnvironment === 'production'
    ? 'https://device-auth.eu-west-1.prod.magine.com'
    : 'https://device-auth.eu-west-1.test.tvoli.com';
  const dispatch = useDispatch();
  const [clientDevice, setClientDevice] = useState({});
  const [isUserCodeInvalid, setUserCodeInvalid] = useState(false);
  const [isLoadingDeny, setIsLoadingDeny] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const flowStep = location.params.step;

  const i18n = useContext(I18nContext);

  const checkUserCode = (userCode) => {
    const searchParams = new URLSearchParams({ userCode });
    return fetchWithTimeout(`${deviceAuthServiceURI}/code/check?${searchParams}`, {
      method: 'GET',
      headers: {
        'Magine-AccessToken': clientApiToken,
        Authorization: `Bearer ${sessionToken}`,
      },
    });
  };

  const confirmUserCode = async (userCode) => {
    setIsLoadingConfirm(true);
    const response = await fetchWithTimeout(`${deviceAuthServiceURI}/code/confirm`, {
      method: 'POST',
      headers: {
        'Magine-AccessToken': clientApiToken,
        Authorization: `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userCode,
      }),
    });
    setIsLoadingConfirm(false);
    return response;
  };

  const denyRequestUserCode = async (userCode) => {
    setIsLoadingDeny(true);
    const response = await fetchWithTimeout(`${deviceAuthServiceURI}/code/deny`, {
      method: 'POST',
      headers: {
        'Magine-AccessToken': clientApiToken,
        Authorization: `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userCode,
      }),
    });
    setIsLoadingDeny(false);
    return response;
  };

  useEffect(() => {
    (async () => {
      if (flowStep === STEPS.VERIFY) {
        const response = await checkUserCode(location.query?.userCode);
        if (response.ok) {
          const { device } = await response.json();
          setClientDevice(device);
        } else {
          dispatch(push({
            name: 'device-auth',
            params: {
              platform: location.params.platform,
            },
          }));
        }
      }
    })();
  }, []);

  const handleUserCodeComplete = async (userCode) => {
    const response = await checkUserCode(userCode);
    if (response.ok) {
      const { device } = await response.json();
      setClientDevice(device);
      dispatch(
        push({
          name: 'device-auth',
          params: {
            platform: location.params.platform,
            step: STEPS.VERIFY,
          },
          query: {
            userCode,
          },
        }),
      );
    } else {
      dispatch(
        push({
          name: 'device-auth',
          params: {
            platform: location.params.platform,
          },
        }),
      );
      setUserCodeInvalid(true);
    }
  };

  const handleConfirmUserCode = async () => {
    const response = await confirmUserCode(location.query?.userCode);

    if (response.ok) {
      dispatch(push({
        name: 'device-auth',
        params: {
          platform: location.params.platform,
          step: STEPS.SUCCESS,
        },
      }));
    }
  };

  const handleDenyUserCode = async () => {
    const response = await denyRequestUserCode(location.query?.userCode);

    if (response.ok) {
      dispatch(push({
        name: 'device-auth',
        params: {
          platform: location.params.platform,
          step: STEPS.DENIED,
        },
      }));
    }
  };

  const handleInputChange = () => {
    setUserCodeInvalid(false);
  };

  if (flowStep === STEPS.SUCCESS) {
    return <StepSuccess />;
  }

  if (flowStep === STEPS.DENIED) {
    return <StepDenied />;
  }

  const platformDevice = i18n.formatText(location.params.platform === PLATFORMS.MOVISTAR ? 'decoder' : 'device');

  return (
    <Box flex fullWidth mt="xxlarge" px="medium">
      <Text
        bold
        as="h1"
        align="center"
        md-fontSize="giant"
        fontSize="loud"
        mb="xxlarge"
        id="deviceAuth.activateYourTv"
      />

      {
        flowStep === STEPS.VERIFY
          ? (
            <Text
              as="h2"
              align="center"
              md-fontSize="loud"
              fontSize="sectionHeading"
              id="deviceAuth.confirmCodeOnDevice"
              values={{
                vendor: clientDevice.vendor,
                model: clientDevice.model,
              }}
            />
          )
          : (
            <Text
              as="h2"
              align="center"
              md-fontSize="loud"
              fontSize="sectionHeading"
              id="deviceAuth.enterCodeOnDevice"
              values={{
                platform: PLATFORMS_NAME[location.params.platform?.toUpperCase()],
                device: platformDevice,
              }}
            />
          )
      }
      <Box mx="auto" mt="xxlarge" mb="large" flexBox justifyContent="center">
        <UserCodeInput
          invalid={isUserCodeInvalid}
          onChange={handleInputChange}
          initValue={location.query.userCode}
          onComplete={handleUserCodeComplete}
        />
      </Box>
      {
        flowStep === STEPS.VERIFY ? (
          <Box
            mx="auto"
            mt="medium"
            flexBox
            justifyContent="center"
            wrap="wrap"
            flexDirection="column-reverse"
            md-flexDirection="row"
            alignItems="center"
          >
            <Box mx="medium" my="small">
              <SecondaryButton
                showSpinner={isLoadingDeny}
                disabled={isLoadingConfirm}
                onClick={handleDenyUserCode}
              >
                <Text id="cancel" />
              </SecondaryButton>
            </Box>
            <Box mx="medium" my="small">
              <PrimaryButton
                showSpinner={isLoadingConfirm}
                disabled={isLoadingDeny}
                onClick={handleConfirmUserCode}
              >
                <Text id="confirm" />
              </PrimaryButton>
            </Box>
          </Box>
        ) : null
      }
    </Box>
  );
};

export default DeviceAuthView;
