import { CountryActions, SET_COUNTRY_INFO } from 'actions/country';

type CountryReducerState = {
  isCreateAccountEnabled: boolean,
  isPurchaseFlowEnabled: boolean,
  isOpenServiceActive: boolean,
  geoipCountry: string,
};

const defaultState: CountryReducerState = {
  isCreateAccountEnabled: false,
  isPurchaseFlowEnabled: false,
  isOpenServiceActive: false,
  geoipCountry: '',
};

export function countryReducer(state = defaultState, action: CountryActions): CountryReducerState {
  switch (action.type) {
    case SET_COUNTRY_INFO:
      return {
        ...state,
        geoipCountry: action.country,
        isOpenServiceActive: action.isOpenServiceActive,
        isCreateAccountEnabled: action.isCreateAccountEnabled,
        isPurchaseFlowEnabled: action.isPurchaseFlowEnabled,
      };

    default:
      return state;
  }
}
