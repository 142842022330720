import { Discount, EntitledPack } from 'types';
import {
  EntitlementStatus,
  ENTITLEMENT_TYPES,
} from 'utils/constants';

export function isSubscriptionActive(pack?: EntitledPack): boolean {
  return pack?.__typename === ENTITLEMENT_TYPES.SUBSCRIBE
      && pack?.status === EntitlementStatus.active
      && !pack?.isFree;
}

// see https://magine.atlassian.net/browse/MDM-12867
export function getSubscriptionPrice(pack: EntitledPack, discount?: Discount): number {
  if (!discount || discount.endDate === undefined) {
    return pack.netPrice;
  }

  if (discount.endDate <= pack.nextBillingDate.getTime()) {
    return pack.grossPrice;
  }

  return pack.netPrice;
}
