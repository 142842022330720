import createComponent from 'styles/fela/createComponent';
import applePayLogo from './images/applePay.png';

const ApplePayLogo = createComponent(({ theme, center }) => ({
  width: theme.size.applePayLogoSize,
  aspectRatio: theme.size.applePayLogoAspectRatio,
  position: 'relative',
  ':after': {
    content: '" "',
    backgroundImage: `url(${applePayLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    width: theme.size.applePayLogoSize,
    aspectRatio: theme.size.applePayLogoAspectRatio,
    position: 'absolute',
  },

  extend: [
    {
      condition: center,
      style: {
        margin: '0 auto',
      },
    },
  ],
}), 'div', ['center']);

export default ApplePayLogo;
