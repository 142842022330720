import {
  LOCATION_CHANGE,
  SET_ACTIVE_LOCATION,
  SET_ROUTE_ERROR,
  RouterActionTypes,
} from './actions';
import { Query, RouterLocation } from './location';

export type RouterReducerState = {
  pathname: string,
  query: Query,
  isPush: boolean,

  location?: RouterLocation,
  routeLoading: boolean,
  routeError?: Error,
};

const defaultState: RouterReducerState = {
  pathname: '/',
  query: {},
  isPush: false,

  location: undefined,
  routeLoading: true,
  routeError: undefined,
};

export function routerReducer(state = defaultState, action: RouterActionTypes): RouterReducerState {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,

        routeLoading: !action.suppressViewChange,
        routeError: undefined,

        pathname: action.pathname,
        query: action.query,
        isPush: action.isPush,
      };

    case SET_ACTIVE_LOCATION:
      return {
        ...state,

        routeLoading: false,
        location: action.location,
      };

    case SET_ROUTE_ERROR:
      return {
        ...state,

        routeLoading: false,
        routeError: action.error,
      };

    default:
      return state;
  }
}
