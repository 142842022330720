import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { Text, imageTypes } from 'components';
import Scrollable from 'components/Scrollable/Scrollable';
import { ClickTracker, useAnalytics } from 'components/Tracking';
import { TooltipTrigger } from 'components/HoverState';
import ComponentWrapper, { ShowAllLink } from 'components/ComponentWrapper/ComponentWrapper';
import SixteenNineItem from 'components/VideoItems/SixteenNineItem';
import { ITEM_MODE } from 'components/VideoItems/constants';
import { viewableShape } from '../prop-types';
import { isShowAllBtn } from '../helper';

function SixteenNineItems(props) {
  const analytics = useAnalytics();
  const {
    viewables,
    title,
    refreshData,
    pageId,
    collectionId,
    categoryKind,
    hasNextPage,
    collectionUI,
    hideChannelLogo,
    loadMore,
  } = props;
  const hideKind = useSelector(({ settings }) => settings.hideKind
    || categoryKind === BLOCK_INTERFACE_TYPES.ContinueWatchingCollection);

  if (!viewables.length) {
    return null;
  }

  const trackClick = (viewableId, playableId) => {
    analytics.onClick({
      component: 'ContentList',
      action: 'click',
      clickType: 'asset',
      eventName: 'click_asset',
      element: 'asset',
      viewableId,
      playableId,
      collectionId,
      categoryKind,
    });
  };

  const items = viewables.map(viewable => (
    <TooltipTrigger
      viewable={viewable}
      refreshData={refreshData}
      collectionId={collectionId}
      categoryKind={categoryKind}
      key={viewable.id}
    >
      <SixteenNineItem
        viewable={viewable}
        onClick={trackClick}
        hideKind={hideKind}
        thumbnailSizeFactor={collectionUI?.collectionThumbnailsSize}
        mode={ITEM_MODE.SLIDER}
        layoutObjects={collectionUI?.layoutObjects}
        hideChannelLogo={hideChannelLogo}
      />
    </TooltipTrigger>
  ));

  const showAllLink = isShowAllBtn(hasNextPage, items) ? (
    <ClickTracker
      component="ContentList"
      action="click"
      clickType="navigation"
      eventName="click_see_all_button"
      section={categoryKind}
      collectionId={collectionId}
    >
      <ShowAllLink to={{ name: 'collection', params: { id: collectionId } }}>
        <Text id="showAll" />
      </ShowAllLink>
    </ClickTracker>
  ) : null;

  return (
    <ComponentWrapper
      title={title}
      right={showAllLink}
    >
      <Scrollable
        pageId={pageId}
        id={`content-list-${collectionId}`}
        loadMore={loadMore}
        hasMoreLoad={hasNextPage}
        itemWidthRem={
          imageTypes.sixteenNineBanner.width * (collectionUI?.collectionThumbnailsSize || 1)
        }
      >
        {items}
      </Scrollable>
    </ComponentWrapper>
  );
}

SixteenNineItems.propTypes = {
  pageId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  categoryKind: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  viewables: PropTypes.arrayOf(viewableShape).isRequired,
  refreshData: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  collectionUI: PropTypes.object,
  hideChannelLogo: PropTypes.bool,
};

export default React.memo(SixteenNineItems);
