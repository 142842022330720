import { Middleware } from 'redux';
import {
  PUSH,
  PUSH_RELATIVE,
  REPLACE,
  REPLACE_PASSIVE,
  propagateCurrentLocation,
  RouterActionTypes,
} from './actions';
import { RouterLocation } from './location';
import { Router } from './router';

const applyOnNavigation = (router: Router, location: RouterLocation) => {
  const nextRoute = router.getRouteByName(location.name);

  if (!nextRoute?.onNavigation.length) {
    return location;
  }

  const previousLocation = router.getActiveLocation();
  if (!previousLocation) {
    throw new Error('previous location isn\'t available');
  }

  const previousRoute = router.getRouteByName(previousLocation.name);

  return nextRoute.onNavigation
    .reduce((nextLocation, onNavigation) => onNavigation({
      location: nextLocation,
      route: nextRoute,
      previousLocation,
      previousRoute,
    }) ?? nextLocation, location);
};

export function createRouterMiddleware(router: Router): Middleware {
  return (store) => {
    // handle browser back/forward buttons, and history.back()/forward()/go()
    window.addEventListener('popstate', () => store.dispatch(propagateCurrentLocation()));

    return next => (action: RouterActionTypes) => {
      switch (action.type) {
        case PUSH: {
          window.history.pushState(null, '', router.getUrl(applyOnNavigation(router, action.to)));
          store.dispatch(propagateCurrentLocation(true, action.to.suppressViewChange));
          return undefined;
        }

        case PUSH_RELATIVE: {
          window.history.pushState(null, '', action.relativeHref);
          store.dispatch(propagateCurrentLocation(true));
          return undefined;
        }

        case REPLACE: {
          window.history.replaceState(null, '', router.getUrl(applyOnNavigation(router, action.to)));
          store.dispatch(propagateCurrentLocation(true, action.to.suppressViewChange));
          return undefined;
        }

        case REPLACE_PASSIVE: {
          window.history.replaceState(null, '', router.getUrl(action.to));
          return undefined;
        }

        default: {
          return next(action);
        }
      }
    };
  };
}
