import createComponent from 'styles/fela/createComponent';

export const VideoContainer = createComponent(({ theme, bg, zoom }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: bg ? theme.color.playerBackground : 'transparent',
  zIndex: 1,

  '& video': {
    transform: `scale(${zoom / 100})`,
    objectFit: 'cover',
  },
  // for hosted trailers
  '& > video': {
    width: '100%',
    height: '100%',
  },
}), 'div', ['bg', 'zoom']);

VideoContainer.displayName = 'VideoContainer';

export const MuteBtnContainer = createComponent(({ theme }) => ({
  position: 'absolute',
  right: theme.margin.large,
  bottom: theme.margin.large,
  zIndex: 2,
}));
