import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import createComponent, { RuleStyles } from 'styles/fela/createComponent';
import { useFela } from 'react-fela';
import {
  responsiveSm,
  responsiveMd,
  responsiveLg,
} from 'styles/fela/mixins';
import { useAutofocus, useOnlineStatus } from 'utils/hooks';
import {
  Modal,
  ModalContainer,
  Spacer,
  Heading,
  Box,
} from 'components';
import { CloseIcon } from 'components/Icons';
import { useTranslatedPlayerMessages } from 'components/Player/utils';
import { useSelector } from 'reducers';
import storage from 'utils/storage';
import type MagineMediaPlayer from '@tvoli/hipster-player';

const VideoContainer = createComponent((): RuleStyles => ({
  maxWidth: '100%',
  maxHeight: '78vh',
  minWidth: 'calc(100vw - 4rem)',
  height: 'calc(56vw - 4rem)',
  outline: 'none',
  ':fullscreen': {
    height: '100%',
    maxHeight: '100%',
  },
  extend: [
    {
      condition: true,
      style: responsiveSm({
        minWidth: '40rem',
        height: '22.5rem',
      }),
    },
    {
      condition: true,
      style: responsiveMd({
        minWidth: '40rem',
        height: '22.5rem',
      }),
    },
    {
      condition: true,
      style: responsiveLg({
        minWidth: '50rem',
        height: '28.125rem',
      }),
    },
  ],
}), 'div');

VideoContainer.displayName = 'VideoContainer';

interface ITrailerPlayerProps {
  title: string;
  trailerUrl?: string;
  trailers: {
    id: string;
    defaultPlayable: {
      id: string;
    }
  }[];
  onClose: () => void;
}

export default function TrailerPlayer(props: ITrailerPlayerProps): JSX.Element {
  const { title, trailerUrl, trailers, onClose } = props;
  const focusRef = useAutofocus();
  const { theme, renderer: felaRenderer } = useFela();
  const [player, setPlayer] = useState<MagineMediaPlayer | null>(null);
  const {
    apiBaseUri,
    language,
    clientApiToken,
    sessionToken,
    defaultAudioTrack,
    muxKey,
    userId,
    partner,
  } = useSelector(state => ({
    apiBaseUri: state.settings.apiBaseUri,
    language: state.settings.l10n.language,
    clientApiToken: state.settings.clientApiToken,
    sessionToken: state.auth.sessionToken,
    defaultAudioTrack: state.settings.features.player.defaultAudioTrack,
    muxKey: state.settings.features.mux?.id,
    userId: state.auth.userId,
    partner: state.settings.partner,
  }), shallowEqual);

  const messages = useTranslatedPlayerMessages(language);

  const [preOfflinePosition, setPreOfflinePosition] = React.useState<number | undefined>(undefined);
  const [preOfflineIsPlaying, setPreOfflineIsPlaying] = React.useState(false);
  const isOnline = useOnlineStatus();

  const play = (viewableId: string, playableId: string) => {
    player?.renderControls({
      language,
      messages,
      theme,
      felaRenderer,
    });

    return player?.loadWithPreflight({
      accessToken: clientApiToken,
      ads: false,
      assetId: playableId,
      viewableId,
      authorizationKey: sessionToken,
      playbackStartTime: 0,
    });
  };

  const handleClose = () => {
    if (player && !player?.currentPlayer?.videoPlayer) {
      player.addEventListener('playerisinitialized', function handler() {
        void player.unload();
        player.removeEventListener('playerisinitialized', handler);
      });
    } else if (player) {
      void player.unload();
    }
    onClose();
  };

  const playTrailers = useCallback(async () => {
    let trailerIndexPlaying = 0;

    const playNextTrailer = async () => {
      if (trailerIndexPlaying === trailers.length) {
        // last trailer was played, cleanup
        player?.removeEventListener('streamEnded', playNextTrailer);
        handleClose();
      } else {
        const nextTrailer = trailers[trailerIndexPlaying];
        await play(
          nextTrailer.id,
          nextTrailer.defaultPlayable.id,
        );
        trailerIndexPlaying += 1;
      }
    };

    await playNextTrailer();

    player?.addEventListener('streamEnded', playNextTrailer);
  }, [player]);

  const initPlayer = (node: HTMLElement) => {
    if (player) {
      return player;
    }

    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const HipsterPlayer = require('@tvoli/hipster-player').default;

    const hipsterPlayer = new HipsterPlayer({
      baseUri: apiBaseUri,
      logLevel: __PRODUCTION__ ? 1 : 10,
      preferredAudio: storage.getItem('audioTrack') || defaultAudioTrack,
      playerContainer: node,
      muxKey,
      userId,
      partner,
      trailerTitle: title,
    });

    setPlayer(hipsterPlayer);

    return hipsterPlayer;
  };

  useEffect(() => {
    if (player) {
      void playTrailers();
    }
  }, [player]);

  useEffect(() => {
    if (!player) {
      return;
    }
    if (!isOnline && !preOfflinePosition) {
      void player.pause();
      setPreOfflinePosition(player.model?.currentTime);
      setPreOfflineIsPlaying(player.model?.isPlaying);
    }
    if (isOnline && preOfflinePosition) {
      void player.seek(preOfflinePosition).then(() => {
        if (preOfflineIsPlaying) {
          void player.play();
        }
      });
      setPreOfflinePosition(undefined);
    }
  }, [isOnline, preOfflinePosition, preOfflineIsPlaying]);

  const playerContainerRef = useCallback((node: HTMLElement) => {
    if (node) {
      initPlayer(node);
    }
  }, []);

  return (
    <Modal onDismiss={handleClose}>
      <ModalContainer
        withShadow
        rounded={false}
      >
        <Box
          px="medium"
          spaceBetween
        >
          <Heading
            fontSize="medium"
            id="trailer.title"
            values={{ title }}
          />

          <CloseIcon onClick={handleClose} />
        </Box>

        <Spacer height="medium" />
        {
          trailerUrl && !trailers?.length && (
            <VideoContainer
              as="video"
              controls
              autoPlay
              muted
              disablePictureInPicture
              controlsList="nodownload"
              innerRef={focusRef}
            >
              <source src={trailerUrl} />
            </VideoContainer>
          )
        }
        {
          trailers?.length > 0 && (
            <VideoContainer innerRef={playerContainerRef} />
          )
        }
      </ModalContainer>
    </Modal>
  );
}
