import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery } from '@apollo/client';
import { locationShape } from 'router/prop-types';
import * as uiActions from 'actions/ui';
import {
  BLOCK_INTERFACE_TYPES,
  LINK_INTERFACE_SUPPORTED_TYPES,
  CONTENT_LIST_SUPPORTED_BLOCK_TYPES,
  CONTENT_LIST_SUPPORTED_VIEWABLE_TYPES,
} from 'utils/constants';
import { flattenEdges } from 'utils/helpers';
import NotFoundView from 'views/NotFoundView/NotFoundView';
import {
  Metadata,
  Box,
  PageWrapper,
} from 'components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/Spinner/Spinner';
import * as pageActions from 'actions/page';
import Collection from './Collection';
import getContentList from './getContentList.gql';
import { updateCollectionData } from './helper';

const FEATURED_BLOCK = [
  BLOCK_INTERFACE_TYPES.FeaturedCollection,
  BLOCK_INTERFACE_TYPES.FeaturedCarouselCollection,
];

const RELOAD_TYPES = [
  BLOCK_INTERFACE_TYPES.BookmarksCollection,
  BLOCK_INTERFACE_TYPES.FavouriteChannelsCollection,
  BLOCK_INTERFACE_TYPES.ContinueWatchingCollection,
];

const BOOKMARK_TYPES = [
  BLOCK_INTERFACE_TYPES.BookmarksCollection,
  BLOCK_INTERFACE_TYPES.FavouriteChannelsCollection,
];

const CONTENT_LIST_MAX_ITEMS = 22;
const BLOCKS_LIMIT = 10;

const normalizeCollection = collection => ({
  __typename: collection.__typename,
  magineId: collection.magineId,
  title: collection.title || '',
  image: collection.image,
  description: collection.description || '',
  ctaValue: collection.ctaValue,
  ctaLabel: collection.ctaLabel,
  ctaTarget: collection.ctaTarget,
  ctaValueType: collection.ctaValueType,
  blockUI: JSON.parse(collection.blockUI || null),
  collectionUI: JSON.parse(collection.collectionUI || null),
  viewables: flattenEdges(collection.viewables)
    .filter(Boolean)
    .filter(viewable => CONTENT_LIST_SUPPORTED_VIEWABLE_TYPES.includes(viewable.__typename)),
  links: flattenEdges(collection.links)
    .filter(Boolean)
    .filter(link => LINK_INTERFACE_SUPPORTED_TYPES.includes(link.__typename)),
  hasNextPage: (
    collection.viewables?.pageInfo.hasNextPage
    || collection.links?.pageInfo.hasNextPage
    || false
  ),
  endCursor: collection.viewables?.pageInfo.endCursor
    || collection.links?.pageInfo.endCursor,
});

function ContentListView({ rootCategoryId, location }) {
  const id = rootCategoryId || location.params.id;

  const dispatch = useDispatch();

  const { watchlistEnabled, isLoggedIn, isPageExpired } = useSelector((
    { settings, auth, page },
  ) => ({
    watchlistEnabled: !!settings.features.watchList,
    isLoggedIn: auth.isLoggedIn,
    isPageExpired: page[id]?.isExpired || false,
  }), shallowEqual);

  const {
    loading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(
    getContentList,
    {
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore',
      variables: {
        rootCategoryId: id,
        fetchMaxLimit: CONTENT_LIST_MAX_ITEMS,
        blocksFirst: BLOCKS_LIMIT,
      },
      skip: !id,
    },
  );

  const fetchData = () => {
    const cursor = data?.viewer?.view?.blocks?.pageInfo?.endCursor;
    fetchMore({
      variables: {
        cursor,
      },
    });
  };

  // reloads collection if collectionId present
  // or reloads bookmarks
  const reload = (collectionId, reloadTypes = BOOKMARK_TYPES) => {
    const edges = data?.viewer?.view?.blocks?.edges || [];

    return Promise.all(edges.reduce((promises, c, i) => {
      // find out all collections that we need to update
      if (
        (collectionId && c.node.magineId === collectionId)
        || (!collectionId && reloadTypes.includes(c.node.__typename))
      ) {
        // find prev cursor to reload a bookmark collection
        const cursor = edges[i - 1]?.cursor;
        promises.push(fetchMore({
          variables: { cursor, blocksFirst: 1 },
          updateQuery: updateCollectionData,
        }));
      }
      return promises;
    }, []));
  };

  useEffect(() => void reload(null, RELOAD_TYPES), []);

  const view = data?.viewer?.view;

  const blocks = useMemo(
    () => flattenEdges(view?.blocks)
      .filter(block => CONTENT_LIST_SUPPORTED_BLOCK_TYPES.includes(block.__typename))
      .map(normalizeCollection),
    [data],
  );

  const setHeaderTransparency = () => {
    // Force header opaque if first block in content list is not featured
    const firstVisibleBlock = blocks.find(block => block.viewables.length > 0);
    const withTransparentHeader = FEATURED_BLOCK.includes(firstVisibleBlock?.__typename);
    dispatch(uiActions.setHeaderTransparency(location.name, id, withTransparentHeader));
  };

  useEffect(() => {
    // refetch data from apiQL on user login/logout
    if (
      data
      && 'isAuthenticated' in data.viewer
      && isLoggedIn !== data.viewer.isAuthenticated
    ) {
      refetch();
    }
  }, [isLoggedIn, data]);

  useEffect(() => {
    if (isPageExpired) {
      refetch();
      dispatch(pageActions.UpdatePage({
        id,
        isExpired: false,
      }));
    }
  }, [isPageExpired]);

  useEffect(() => {
    setHeaderTransparency();
  }, [blocks, id]);

  if ((loading && !data) || error) {
    return null;
  }

  if (__SERVER__) {
    // trigger dispatch on SSR step
    setHeaderTransparency();
  }

  if (!view) {
    return (
      <NotFoundView />
    );
  }

  const items = blocks
    .map(collection => (
      <Collection
        key={collection.magineId}
        pageId={id}
        collection={collection}
        refreshData={reload}
        watchlistEnabled={watchlistEnabled}
      />
    ));

  return (
    <PageWrapper>
      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={data?.viewer?.view?.blocks?.edges?.length}
        next={fetchData}
        hasMore={data?.viewer?.view?.blocks?.pageInfo?.hasNextPage}
        loader={<Box column justifyContent="center" pt="xxxlarge" pb="xxxlarge"><Spinner size={3} /></Box>}
      >
        <Metadata description={view.description} imageUrl={view.image} />
        {items}
      </InfiniteScroll>
    </PageWrapper>
  );
}

ContentListView.propTypes = {
  rootCategoryId: PropTypes.string,
  location: locationShape.isRequired,
};

export default React.memo(ContentListView);
