import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/StyledSystem/Box';
import ChannelLogo from 'components/ChannelLogo/ChannelLogo';

const FixedChannelLogo = ({ offset, size, ...otherProps }) => (
  <Box
    position="absolute"
    right={offset}
    bottom={offset}
    width={size}
    pt={size}
  >
    <Box
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
    >
      <ChannelLogo
        size="100%"
        {...otherProps}
      />
    </Box>
  </Box>
);

FixedChannelLogo.propTypes = {
  offset: PropTypes.string,
  size: PropTypes.string,
};

FixedChannelLogo.defaultProps = {
  offset: '1em',
  size: '24%',
};

export default FixedChannelLogo;
