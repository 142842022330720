import * as React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { extractLastGroup } from 'utils/credit-card';
import {
  Price,
  Text,
  ClientSideComponent,
} from 'components';
import { Discount, EntitledPack, PaymentMethods } from 'types';
import {
  MessageBox,
} from '../Styles';
import { getSubscriptionPrice } from './utils';

type PropType = {
  pack: EntitledPack,
  discount?: Discount,
  paymentMethod?: {
    method?: PaymentMethods,
    data: {
      ccMaskedNumber: string,
    }
  },
};

function SubscriptionInfo({ pack, paymentMethod, discount }: PropType) {
  if (!pack || !paymentMethod?.method) return null;

  const { method } = paymentMethod;

  const days = differenceInCalendarDays(pack.nextBillingDate, new Date());

  const price = (
    <Price
      key="price"
      price={getSubscriptionPrice(pack, discount)}
      currency={pack.currency}
    />
  );

  return (
    <ClientSideComponent>
      <MessageBox>
        {method === PaymentMethods.CREDITCARD && (
          <Text
            id="paymentSettings.messageCreditCard"
            values={{ days, lastGroup: extractLastGroup(paymentMethod), price }}
          />
        )}

        {method === PaymentMethods.PAYPAL && (
          <Text
            id="paymentSettings.messagePaypal"
            values={{ days, price }}
          />
        )}

        {method === PaymentMethods.GOOGLEPAY && (
          <Text
            id="paymentSettings.messageGooglePay"
            values={{ days, price }}
          />
        )}

        {method === PaymentMethods.APPLEPAY && (
          <Text
            id="paymentSettings.messageApplePay"
            values={{ days, price }}
          />
        )}
      </MessageBox>
    </ClientSideComponent>
  );
}

export default React.memo(SubscriptionInfo);
