import PropTypes from 'prop-types';
import { FETCH_OFFERS, OffersActions } from 'actions/offers';
import {
  TRIAL_PERIOD,
  RECURRING_PERIOD,
  OFFER_TYPES,
  OfferType,
  RecurringPeriod,
  TrialPeriod,
} from 'utils/constants';

export const offerShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(OFFER_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  priceInCents: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  recurringPeriod: PropTypes.shape({
    length: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(Object.values(RECURRING_PERIOD)).isRequired,
  }),
  trialPeriod: PropTypes.shape({
    length: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(Object.values(TRIAL_PERIOD)).isRequired,
  }),
  buttonText: PropTypes.string.isRequired,
  usps: PropTypes.arrayOf(PropTypes.string.isRequired),
  image: PropTypes.string,
});

export type Offer = {
  __typename: OfferType,
  id: string,
  title: string,
  priceInCents: number,
  currency: string,
  recurringPeriod?: {
    length: number,
    unit: RecurringPeriod,
  },
  trialPeriod?: {
    length: number,
    unit: TrialPeriod,
  },
  buttonText: string,
  usps?: string[],
  image?: string,
};

export type OffersReducerState = Offer[];

export function offersReducer(
  state: OffersReducerState = [],
  action: OffersActions,
): OffersReducerState {
  switch (action.type) {
    case FETCH_OFFERS: {
      const entitlements = action.data.viewer.entitlements
        .edges.map((edge: any) => edge.node.offerId);

      return action.data.viewer.offers.edges
        .map((edge: any) => edge.node)
        .filter((node: any) => !entitlements.includes(node.id));
    }

    default: {
      return state;
    }
  }
}
