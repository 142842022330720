import React from 'react';
import { IStyle } from 'fela';
import LinkComponent, { Props as RouterLinkProps } from 'router/Link';
import Text from './Text';

const underline = { textDecoration: 'underline' };

type LinkProps = {
  hover?: IStyle,
} & RouterLinkProps & {
  id?: string,
};

const Link = ({ hover, ...props }: LinkProps): React.ReactElement => (
  <Text
    as={LinkComponent}
    textDecoration="none"
    color="link"
    cursor="pointer"
    hover={hover}
    {...props}
  />
);

Link.defaultProps = {
  hover: underline,
};

export default Link;
