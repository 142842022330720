function extractFreeWheelAdId(content) {
  // content should look like: <Parameter name="_fw_4AID"><![CDATA[S1DOSP2012]]></Parameter>
  if (!content?.includes('_fw_4AID')) {
    return '';
  }

  const customAdIdMatcher = /CDATA\[([A-Za-z0-9]+)\]/g;
  return customAdIdMatcher.exec(content)?.[1] || '';
}

function extractAdServerAdId(content) {
  // content should look like: <AdInfo advertiserId="trailer-admin" customaid="S1EHBI1502"/>
  if (!content?.includes('customaid')) {
    return '';
  }

  const customAdIdMatcher = /customaid="([^"]+)"/g;
  return customAdIdMatcher.exec(content.replace('\\"', '"'))?.[1] || '';
}

const adIdExtractors = {
  FreeWheel: extractFreeWheelAdId,
  AdServer: extractAdServerAdId,
};
const allowedAdContentType = Object.keys(adIdExtractors);

export function extractCustomAdId(ad) {
  const { content, type } = (ad.extensions || [])
    .find(e => allowedAdContentType.includes(e?.type)) || {};

  if (!content || !type || !adIdExtractors[type]) return '';

  return adIdExtractors[type](content);
}
