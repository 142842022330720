import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'views/ContentListView/prop-types';
import Text from 'components/StyledSystem/Text';
import {
  isTimestampInPast,
  isIntervalAvailableNow,
} from 'utils/time';
import { INFO_DELIMITER } from 'utils/constants';
import MetaCountdown from './MetaCountdown';

const catchupTimer = (timer) => {
  if (!timer) return null;

  const { days, hours, minutes } = timer;

  if (days > 0) return <Text id="timeLeftDays" values={{ days }} />;

  if (hours && minutes) {
    return <Text id="timeLeftHoursMinutes" values={{ hours, minutes }} />;
  }

  return <Text id="timeLeftMinutes" values={{ hours, minutes }} />;
};

export const getCatchup = viewable => viewable.broadcastById?.catchup
  || viewable.schedule?.[0]?.catchup
  || viewable.defaultPlayable?.catchup;

const sec2ms = timestampSec => (timestampSec * 1000);

const CatchupCountdown = ({ viewable, refreshData }) => {
  const catchup = getCatchup(viewable);

  if (!catchup) {
    return null;
  }

  const timeMs = sec2ms(catchup.to);

  if (isTimestampInPast(sec2ms(catchup.to))) {
    return (
      <>{INFO_DELIMITER}<Text id="playable.expired" /></>
    );
  }

  if (isIntervalAvailableNow(sec2ms(catchup.from), sec2ms(catchup.to))) {
    return (
      <>
        {INFO_DELIMITER}
        <MetaCountdown
          eventTimeMs={timeMs}
          onEvent={refreshData}
          disabled={!refreshData}
        >
          {catchupTimer}
        </MetaCountdown>
      </>
    );
  }

  return null;
};

CatchupCountdown.propTypes = {
  refreshData: PropTypes.func,
  viewable: viewableShape.isRequired,
};

export default CatchupCountdown;
