import React from 'react';
import Icon, { IconType } from './Icon';

export default function SuccessIcon(props: IconType): JSX.Element {
  return (
    <Icon {...props} width="5rem" height="5rem" viewBox="0 0 80 77">
      <path fill="#008905" d="M80 38.1818L71.2727 28L72.3636 14.5455L59.2727 11.6364L52.3636 0L40 5.45455L27.6364 0L20.7273 11.6364L7.63636 14.5455L8.72727 28L0 38.1818L8.72727 48.3636L7.63636 61.8182L20.7273 64.7273L27.6364 76.3636L40 70.9091L52.3636 76.3636L59.2727 64.7273L72.3636 61.8182L71.2727 48.3636L80 38.1818ZM30.5455 52.7273L21.8182 44C20.3636 42.5455 20.3636 40.3636 21.8182 38.9091L22.1818 38.5455C23.6364 37.0909 25.8182 37.0909 27.2727 38.5455L33.0909 44.3636L52 25.4545C53.4545 24 55.6364 24 57.0909 25.4545L57.4545 25.8182C58.9091 27.2727 58.9091 29.4545 57.4545 30.9091L35.6364 52.7273C34.1818 54.1818 32 54.1818 30.5455 52.7273Z" />
    </Icon>
  );
}
