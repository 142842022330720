import React from 'react';
import PropTypes from 'prop-types';
import { getLinkParams } from 'utils/helpers';
import {
  imageTypes,
  Box,
  CleanLink,
} from 'components';
import { ITEM_MODE, sixteenNineType } from 'components/VideoItems/constants';
import SixteenNineImage from 'components/VideoItems/SixteenNineImage';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import SliderTitles from 'components/VideoItems/SixteenNineItem/SliderTitles';
import ListTitles from 'components/VideoItems/SixteenNineItem/ListTitles';
import RelatedTitles from 'components/VideoItems/SixteenNineItem/RelatedTitles';
import { layoutObjectShape } from 'components/CollectionUI/prop-types';

function SixteenNineItem(props) {
  const {
    viewable,
    mode,
    hideKind,
    thumbnailSizeFactor,
    layoutObjects,
    hideChannelLogo,
    refreshData,
    collectionId,
    categoryKind,
  } = props;

  const handleItemClick = () => {
    const {
      id,
      playableId,
    } = getLinkParams(viewable);

    if (props.onClick) {
      props.onClick(id, playableId);
    }
  };

  return (
    <Box
      as={CleanLink}
      to={{
        name: 'watch',
        params: getLinkParams(viewable),
      }}
      onClick={handleItemClick}
      className="e2e-asset"
      {...(
        mode === ITEM_MODE.SLIDER ? {
          display: 'block',
          position: 'relative',
          width: `${imageTypes.sixteenNineBanner.width * thumbnailSizeFactor}em`,
        } : {
          flexBox: true,
          alignItems: 'stretch',
          width: '100%',
          flexDirection: 'column',
          ...(mode !== ITEM_MODE.RELATED ? {
            'sm-flexDirection': 'row',
          } : {}),
        }
      )}
      mb={mode === ITEM_MODE.LIST ? 'large' : undefined}
    >
      <Box
        width={
          [ITEM_MODE.RELATED, ITEM_MODE.GRID].includes(mode)
            ? '100%'
            : `${imageTypes[sixteenNineType[mode]]?.width * thumbnailSizeFactor}rem`
        }
        position="relative"
      >
        <SixteenNineImage
          viewable={viewable}
          type={sixteenNineType[mode]}
          fullWidth={[ITEM_MODE.RELATED, ITEM_MODE.GRID].includes(mode)}
          sizeFactor={thumbnailSizeFactor}
          hideChannelLogo={hideChannelLogo}
          withProgressBar
        />
      </Box>
      {
        mode === ITEM_MODE.SLIDER
        && <SliderTitles viewable={viewable} hideKind={hideKind} layoutObjects={layoutObjects} />
      }
      {
        mode === ITEM_MODE.LIST
        && (
          <ListTitles
            viewable={viewable}
            itemHeight={imageTypes[sixteenNineType[mode]]?.height}
            refreshData={refreshData}
            collectionId={collectionId}
            categoryKind={categoryKind}
          />
        )
      }
      {mode === ITEM_MODE.RELATED && <RelatedTitles viewable={viewable} />}
    </Box>
  );
}

SixteenNineItem.propTypes = {
  viewable: viewableShape.isRequired,
  onClick: PropTypes.func,
  refreshData: PropTypes.func,
  hideKind: PropTypes.bool,
  mode: PropTypes.string,
  itemHeight: PropTypes.number,
  thumbnailSizeFactor: PropTypes.number,
  layoutObjects: PropTypes.arrayOf(layoutObjectShape),
  hideChannelLogo: PropTypes.bool,
  collectionId: PropTypes.string,
  categoryKind: PropTypes.string,
};

SixteenNineItem.defaultProps = {
  mode: ITEM_MODE.LIST,
  thumbnailSizeFactor: 1,
};

export default React.memo(SixteenNineItem);
