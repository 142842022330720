import createComponent from 'styles/fela/createComponent';
import { ieInputHideClearIcon } from 'styles/fela/mixins';
import { SearchIcon } from 'components/Icons';
import { Hidden } from 'components';

export const SearchWrapper = createComponent(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
}));

export const InputWrapper = createComponent(() => ({
  alignItems: 'center',
  display: 'flex',
}), 'div', ['textColor']);

export const SearchIconStyled = createComponent(() => ({
  flexShrink: 0,
}), SearchIcon, []);

export const Placeholder = createComponent(() => ({
  padding: '0 0 0px 20px',
  visibility: 'hidden',
  height: 0,
}), 'div', []);

export const Input = createComponent(() => ({
  backgroundColor: 'transparent',
  border: '0',
  color: 'inherit',
  margin: '0',
  outline: '0',
  padding: '0 0 0px 20px',
  width: '100%',
  '::placeholder': {
    color: 'inherit',
    opacity: 0.8,
  },
  ...ieInputHideClearIcon(),
}), 'input', ['autoFocus']);

export const StyledHidden = createComponent(() => ({
  '& button': {
    padding: 0,
    width: '14px',
  },
}), Hidden, []);
