export const updateCollectionData = (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev;

  const prevBlocks = prev.viewer?.view?.blocks || { edges: [] };
  const newBlocks = fetchMoreResult.viewer.view.blocks;
  const newCollection = newBlocks.edges[0];

  const index = prevBlocks.edges.findIndex(c => c.node.magineId === newCollection.node.magineId);

  if (index < 0) {
    return prev;
  }

  // replace old collection
  newBlocks.pageInfo = prevBlocks.pageInfo;
  newBlocks.edges = [
    ...prevBlocks.edges,
  ];
  newBlocks.edges[index] = newCollection;

  return fetchMoreResult;
};

export const isShowAllBtn = (hasMore, items) => hasMore || items.length > 16;

export const getListOfTrailers = (viewables = [], isLoggedIn) => viewables.map(
  v => !(v.trailer || (isLoggedIn && v.trailers?.length)),
);
