import PropTypes from 'prop-types';
import {
  VIEWABLE_TYPES,
  PLAYABLE_KINDS,
} from 'utils/constants';

const nodeProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  poster: PropTypes.string,
  banner: PropTypes.string,
};

const playableShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(PLAYABLE_KINDS)).isRequired,
  duration: PropTypes.number,
  watchOffset: PropTypes.number,
  startTimeUtc: PropTypes.number,
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    logoDark: PropTypes.string,
    logoLight: PropTypes.string,
  }),
});

const seasonShape = PropTypes.shape({
  seasonNumber: PropTypes.number,
  episodes: PropTypes.arrayOf(PropTypes.shape({
    episodeNumber: PropTypes.number,
    title: PropTypes.string.isRequired,
    defaultPlayable: playableShape.isRequired,
  })),
});
const showShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Show]).isRequired,
  productionYear: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  inMyList: PropTypes.bool,
  seasons: PropTypes.arrayOf(seasonShape).isRequired,
});
const episodeShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Episode]).isRequired,
  duration: PropTypes.number.isRequired,
  seasonNumber: PropTypes.number.isRequired,
  episodeNumber: PropTypes.number.isRequired,
  defaultPlayable: playableShape.isRequired,
  show: showShape.isRequired,
});
const movieShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Movie]).isRequired,
  productionYear: PropTypes.string,
  duration: PropTypes.number,
  durationHuman: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  inMyList: PropTypes.bool,
  defaultPlayable: playableShape.isRequired,
});
const programShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Program]).isRequired,
  defaultPlayable: playableShape.isRequired,
  genres: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
});
const channelShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Channel]).isRequired,
});

export const viewableShape = PropTypes.oneOfType([
  movieShape,
  episodeShape,
  showShape,
  programShape,
  channelShape,
]);
