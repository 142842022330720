import {
  addDays,
  subDays,
  differenceInDays,
  isPast,
  startOfToday,
} from 'date-fns';
import { formatYYYYMMDD } from 'utils/human-format';
import query from 'queries/epg.graphql';
import { graphqlRequest, AppAsyncAction } from './helpers';

export const GET_EPG = 'epg/GET_EPG';
interface GetEPGAction {
  type: typeof GET_EPG
  data: any
  day: string
}
function getEpg(day: string): AppAsyncAction {
  return async (dispatch) => {
    const {
      data,
      errors,
    } = await dispatch(graphqlRequest(query, { day }));

    dispatch({
      type: GET_EPG,
      data,
      errors,
      day,
    });
  };
}

export const loadToday = (): AppAsyncAction => getEpg(formatYYYYMMDD(new Date()));

export const loadNextDay = (): AppAsyncAction => (
  async (dispatch, getState) => {
    const {
      isMaxDateLoaded,
      datesLoaded,
    } = getState().epg;

    const nextDay = addDays(datesLoaded[datesLoaded.length - 1], 1);

    if (isMaxDateLoaded || datesLoaded.includes(nextDay)) {
      return;
    }

    await dispatch(getEpg(formatYYYYMMDD(nextDay)));
  }
);

export const loadPreviousDay = (): AppAsyncAction => (
  async (dispatch, getState) => {
    const {
      isMinDateLoaded,
      datesLoaded,
    } = getState().epg;

    const previousDay = subDays(datesLoaded[0], 1);

    if (isMinDateLoaded || datesLoaded.includes(previousDay)) {
      return;
    }

    await dispatch(getEpg(formatYYYYMMDD(previousDay)));
  }
);

export const loadThreeDays = (): AppAsyncAction => (
  async (dispatch) => {
    const today = new Date();
    const previousDay = subDays(today, 1);
    const nextDay = addDays(today, 1);

    await Promise.all([
      dispatch(getEpg(formatYYYYMMDD(today))),
      dispatch(getEpg(formatYYYYMMDD(nextDay))),
      dispatch(getEpg(formatYYYYMMDD(previousDay))),
    ]);
  }
);

export const loadDayToToday = (dateToLoad: Date): AppAsyncAction => (
  async (dispatch, getState) => {
    const {
      isMinDateLoaded,
      isMaxDateLoaded,
      datesLoaded,
    } = getState().epg;

    const daysLoaded = datesLoaded.map(date => formatYYYYMMDD(date));

    if (daysLoaded.includes(formatYYYYMMDD(dateToLoad))) {
      return;
    }

    const today = startOfToday();
    const days = [];

    if (isPast(dateToLoad) && !isMinDateLoaded) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < Math.abs(differenceInDays(dateToLoad, today)) + 2; i++) {
        const day = formatYYYYMMDD(subDays(today, i));
        if (!daysLoaded.includes(day)) days.push(dispatch(getEpg(day)));
      }
    }

    if (!isPast(dateToLoad) && !isMaxDateLoaded) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < Math.abs(differenceInDays(dateToLoad, today)) + 1; i++) {
        const day = formatYYYYMMDD(addDays(today, i));
        if (!daysLoaded.includes(day)) days.push(dispatch(getEpg(day)));
      }
    }

    await Promise.all(days);
  }
);

export type EPGActions = GetEPGAction;
