import React from 'react';
import Text from './Text';

export default function ErrorMessage(props) {
  return (
    <Text
      color="danger"
      {...props}
    />
  );
}
