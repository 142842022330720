/* eslint-disable max-len */
import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { EPG_HEADER_HEIGHT } from '../EpgStyles';

export const epgHeaderStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  background: theme.color.page,
  color: theme.color.primary,
  height: `${EPG_HEADER_HEIGHT}px`,
  fontSize: theme.fontSize.medium,
  fontWeight: 'bold',
  padding: `${theme.margin.medium} 0`,
  textAlign: 'center',
});

export const calendarIconStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  color: theme.color.brand,
  position: 'absolute',
  right: '2.25rem',
  top: 'calc(0.5rem + 2px)',
  zIndex: 10,
});

export const dropdownStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  background: theme.color.epgBackground,
  position: 'absolute',
  right: '1.25rem',
  top: '2px',
  minWidth: '15rem',
  zIndex: 9,
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
  fontStyle: 'normal',
  textAlign: 'left',
  textTransform: 'none',
});

export const dropdownTitleStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  fontSize: theme.fontSize.smedium,
  margin: theme.margin.medium,
});

export const dropdownDividerStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  borderBottom: `1px solid ${theme.color.divider}`,
});

export const dropdownListStyle = ({ theme, selectedDate }: ComponentRuleProps): RuleStyles => ({
  fontSize: theme.fontSize.normal,
  padding: `${theme.margin.fine} 0`,
  fontWeight: 'normal',
  overflowY: 'scroll',
  maxHeight: '40vh',
  opacity: selectedDate ? '0.5' : '1',
});

export const dropdownItemStyle = ({ theme, selectedDate }: ComponentRuleProps): RuleStyles => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.margin.fine} ${theme.margin.medium}`,
  ':hover': {
    cursor: 'pointer',
    pointerEvents: selectedDate ? 'none' : 'auto',
    background: selectedDate ? 'none' : theme.color.broadcastCatchupBackground,
  },
});

export const dropdownItemTodayDateStyle = (): RuleStyles => ({
  fontWeight: 'bold',
});

export const dropdownItemCurrentDateStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  color: theme.color.brand,
  fontWeight: 'bold',
});

export const dropdownItemTodayLabelStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  fontSize: theme.fontSize.small,
  fontWeight: 'bold',
});

export const dropdownItemCurrentDateIsTodayLabelStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  fontSize: theme.fontSize.small,
  color: theme.color.brand,
  fontWeight: 'bold',
});

export const dropdownItemSpinnerStyle = ({ theme }: ComponentRuleProps): RuleStyles => ({
  position: 'absolute',
  right: theme.margin.medium,
});
