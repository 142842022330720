import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'reducers';
import { useFela } from 'react-fela';

export const RecaptchaTracker = (): JSX.Element | null => {
  const [loadFallbackScript, setLoadFallbackScript] = React.useState(false);
  const siteKey = useSelector(state => state.settings.features.captcha?.siteKey);

  const { renderer } = useFela();
  React.useEffect(() => {
    renderer.renderStatic(`
    .grecaptcha-badge {
      visibility: hidden;
    }
  `);
  }, []);

  if (!siteKey || __SERVER__) return null;

  // using of global method is intentional
  window.greFallback = () => setLoadFallbackScript(true);

  return (
    <Helmet>
      {
        loadFallbackScript ? (
          <script src={`https://www.google.com/recaptcha/api.js?render=${siteKey}`} />
        ) : (
          <script
            src={`https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`}
            onError={'window.greFallback()' as unknown as React.ReactEventHandler}
          />
        )
      }
    </Helmet>
  );
};

export const useRecaptchaToken = (action: string): [() => Promise<string | undefined>] => {
  const siteKey = useSelector(state => state.settings.features.captcha?.siteKey);

  return [
    async function getToken() {
      if (window?.grecaptcha) {
        const gapi = window.grecaptcha.enterprise ?? window.grecaptcha;
        await new Promise(resolve => gapi.ready(resolve));
        return gapi.execute(siteKey, { action });
      }

      return undefined;
    },
  ];
};

export default RecaptchaTracker;
