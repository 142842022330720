import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'router/Link';
import {
  Box,
} from 'components';
import { sec2px } from 'utils/broadcast';
import { formatHHmm } from 'utils/human-format';
import { broadcastShape } from 'reducers/epg';
import {
  CONTAINER_PADDING,
  BROADCAST_PADDING,
  BroadcastContainer,
  BroadcastDuration,
  BroadcastTitle,
  BroadcastWrapper,
} from './Styles';
import CatchupIcon from './CatchupIcon';
import { BroadcastStates } from './utils';

const MIN_DURATION_WIDTH = 135;
const MIN_CATCHUP_WIDTH = 55;

const getBroadcastWidth = broadcast => sec2px(broadcast.stop - broadcast.start)
  - (2 * CONTAINER_PADDING);

const getBroadcastHoverWidth = (scrollWidth, width) => {
  const textWidth = scrollWidth + (2 * (BROADCAST_PADDING + CONTAINER_PADDING)) - 2;
  return Math.max(width, textWidth, MIN_DURATION_WIDTH);
};

const Broadcast = (
  { broadcast, broadcastState, activePlayableId, textOffset, trackClick, isEntitled, isRTL },
) => {
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(() => getBroadcastWidth(broadcast));
  const [isActive, setIsActive] = useState(false);
  const titleElement = useRef();

  const isLive = broadcastState === BroadcastStates.live;
  const isCatchup = broadcastState === BroadcastStates.catchup;

  const showDuration = width - textOffset >= MIN_DURATION_WIDTH;
  const showCatchupIcon = width >= MIN_CATCHUP_WIDTH;

  const linkTo = ((isLive && broadcast.isLiveAvailable) || isCatchup) ? {
    name: 'watch',
    params: {
      id: broadcast.channelId,
      playableId: isLive ? undefined : broadcast.id,
    },
  } : null;

  useEffect(() => {
    // Active playable id is either channel id for live broadcast or broadcast id for catchups
    const active = (activePlayableId === broadcast.channelId && isLive)
      || activePlayableId === broadcast.id;

    if (isActive !== active) {
      setIsActive(active);
    }
  }, [broadcastState, activePlayableId, broadcast]);

  const onMouseEnter = () => {
    /*
    * We need to set hover and underlying css rules
    * before we calculate actual textWidth
    */
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    if (hover === true) {
      setWidth(getBroadcastHoverWidth(titleElement.current.scrollWidth, width));
    } else {
      setWidth(getBroadcastWidth(broadcast));
    }
  }, [hover]);

  const onClick = () => {
    trackClick(broadcast.channelId, isLive ? undefined : broadcast.id, isActive);
  };

  return (
    <BroadcastContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hover={hover}
    >
      <BroadcastWrapper
        className="e2e-broadcast"
        as={linkTo ? Link : 'div'}
        isActive={isActive}
        state={broadcastState}
        isLiveAvailable={broadcast.isLiveAvailable}
        to={linkTo}
        onClick={onClick}
        width={width}
        style={{ width }}
      >
        <BroadcastTitle
          innerRef={titleElement}
          hover={hover}
          style={{
            transform: `translateX(${isRTL ? '-' : ''}${textOffset}px)`,
            width: `calc(100% - ${textOffset}px)`,
          }}
        >
          {broadcast.title}
        </BroadcastTitle>
        <BroadcastDuration
          state={broadcastState}
          showDuration={showDuration}
          style={{
            transform: `translateX(${isRTL ? '-' : ''}${textOffset}px)`,
            width: `calc(100% - ${textOffset}px)`,
          }}
        >
          {formatHHmm(broadcast.start * 1000)}
          -
          {formatHHmm(broadcast.stop * 1000)}
        </BroadcastDuration>
        {isCatchup && showCatchupIcon && (
          <CatchupIcon />
        )}
        {!isEntitled && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="broadcastNotEntitledOverlay"
            hideEmpty={false}
            zIndex="100"
          />
        )}
      </BroadcastWrapper>
    </BroadcastContainer>
  );
};

Broadcast.propTypes = {
  broadcast: broadcastShape.isRequired,
  broadcastState: PropTypes.oneOf(Object.values(BroadcastStates)).isRequired,
  activePlayableId: PropTypes.string,
  textOffset: PropTypes.number.isRequired,
  trackClick: PropTypes.func.isRequired,
  isEntitled: PropTypes.bool.isRequired,
  isRTL: PropTypes.bool,
};

export default Broadcast;
