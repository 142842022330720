import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PulsingButton from 'components/Button/PulsingButton';
import Text from 'components/StyledSystem/Text';
import ResetPinModal from './ResetPinModal';
import ResetPinConfirmationModal from './ResetPinConfirmationModal';

const ResetPinLink = ({ bold }) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onClick = () => {
    setShowModal(true);
  };

  const onPinResetClose = () => {
    setShowModal(false);
  };

  const onPinConfirmationClose = () => {
    setShowConfirmationModal(false);
  };

  const onPinResetSuccess = () => {
    setShowModal(false);
    setShowConfirmationModal(true);
  };

  return (
    <>
      <PulsingButton
        link
        onClick={onClick}
        bold={bold}
      >
        <Text id="appSettings.resetParentalControl" />
      </PulsingButton>
      { showModal && (
        <ResetPinModal
          onSuccess={onPinResetSuccess}
          onClose={onPinResetClose}
        />
      )}
      { showConfirmationModal && (
        <ResetPinConfirmationModal
          onClose={onPinConfirmationClose}
        />
      )}
    </>
  );
};

ResetPinLink.propTypes = {
  bold: PropTypes.bool,
};

export default ResetPinLink;
