import React, { useState, useEffect, useRef } from 'react';
import { useFela } from 'react-fela';
import { format, differenceInDays, addDays, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'reducers';
import { loadDayToToday } from 'actions/epg';
import { GlobalTheme } from 'types';
import { ClickOutside, Spinner, Text } from 'components';
import { CalendarIcon } from 'components/Icons';
import * as styles from './styles';

interface IDatePickerProps {
  onDateChange: (date: Date) => void;
  currentDateTime: number;
}

const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { onDateChange, currentDateTime } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { datesLoaded } = useSelector(state => state.epg);
  const { css } = useFela<GlobalTheme>({ selectedDate });
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    if (!selectedDate) {
      const dateList = [...datesLoaded];
      while (differenceInDays(Date.now(), dateList[0]) < 7) {
        dateList.unshift(subDays(dateList[0], 1));
      }
      while (differenceInDays(dateList[dateList.length - 1], Date.now()) < 6) {
        dateList.push(addDays(dateList[dateList.length - 1], 1));
      }
      setDates(dateList);
    }
  }, [datesLoaded, selectedDate]);

  const selectDay = async (date: Date) => {
    setSelectedDate(date);
    await dispatch(loadDayToToday(date));
    setIsOpen(false);
    void onDateChange(date);
    setSelectedDate(undefined);
  };

  const getDayStyle = (day: string, today: string, currentDay: string) => {
    let style;
    if (day === currentDay) {
      style = css(styles.dropdownItemCurrentDateStyle);
    } else if (day === today) {
      style = css(styles.dropdownItemTodayDateStyle);
    }

    return style;
  };

  const getDayLabelStyle = (day: string, currentDay: string) => {
    let style = css(styles.dropdownItemTodayLabelStyle);
    if (day === currentDay) {
      style = css(styles.dropdownItemCurrentDateIsTodayLabelStyle);
    }

    return style;
  };

  const formatDate = (date: Date) => {
    const day = format(date, 'EEEE d/M');
    const today = format(new Date(), 'EEEE d/M');
    const currentDay = format(new Date(currentDateTime), 'EEEE d/M');
    return (
      <>
        <span className={getDayStyle(day, today, currentDay)}>
          {day}
        </span>
        {day === today ? (
          <span className={getDayLabelStyle(day, currentDay)}>
            (<Text id="liveLabel.today" />)
          </span>
        ) : (
          selectedDate === date && (
            <div className={css(styles.dropdownItemSpinnerStyle)}>
              <Spinner size={1} strokeWidth={1} />
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <CalendarIcon
        className={css(styles.calendarIconStyle)}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <ClickOutside wrapperRef={ref} onClickOutside={() => setIsOpen(false)}>
          <div ref={ref} className={css(styles.dropdownStyle)}>
            <h2 className={css(styles.dropdownTitleStyle)}>
              <Text id="epg.chooseDay" />
            </h2>
            <div className={css(styles.dropdownDividerStyle)} />
            <ul className={css(styles.dropdownListStyle)}>
              {dates.map(date => (
                <li
                  className={css(styles.dropdownItemStyle)}
                  onClick={() => void selectDay(date)}
                  key={date.toString()}
                >
                  {formatDate(date)}
                </li>
              ))}
            </ul>
          </div>
        </ClickOutside>
      )}
    </>
  );
};

export default DatePicker;
