import React from 'react';
import Logger from 'utils/logger';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components';
import { I18nContext } from 'components/I18n';

export function toMessage(object) {
  if (!object) return null;

  if (object?.id) {
    return (
      <Text id={object.id} values={object.values} />
    );
  }

  if (['validate', 'server'].includes(object?.type) || object?.message) {
    return object.message;
  }

  Logger.warn('Unknown error message', object);

  return null;
}

export function useValidations(validations) {
  const i18n = React.useContext(I18nContext);
  const {
    getValues,
  } = useFormContext();

  return React.useMemo(() => {
    if (!validations) {
      return null;
    }

    const result = {};

    for (const [key, validation] of Object.entries(validations)) {
      const {
        validate,
        message,
      } = validation;

      result[key] = val => validate(val, getValues())
        || (message && i18n.formatText(message.id, message.values));
    }

    return result;
  }, [validations]);
}
