import { isIntervalAvailableNow, isTimestampInPast } from 'utils/time';

export const PIXELS_PER_MINUTE = 7;

export const sec2px = seconds => Math.round(seconds / 60) * PIXELS_PER_MINUTE;

export const px2sec = px => Math.round(px / PIXELS_PER_MINUTE) * 60;

/**
 * Determines whether a catchup is defined, and if yes, whether this catchup
 * is available right now
 *
 * Return false only if we do have a catchup, and the catchup cannot be played.
 *
 * @param {Object} viewable The viewable we're trying to display
 */
export const ifCatchupIsItAvailable = (viewable) => {
  const catchup = viewable.defaultPlayable?.catchup
    || viewable.catchup;

  if (!catchup) {
    return true;
  }

  return isIntervalAvailableNow(catchup.from * 1000, catchup.to * 1000);
};

/**
 * Returns upcoming broadcast time otherwise returns a null
 * @param {Object} playable
 */
export const getUpcomingBroadcastTime = (playable) => {
  if (!playable?.startTimeUtc) {
    return null;
  }

  const adjustedStartTime = playable.startTimeUtc * 1000;

  if (isTimestampInPast(adjustedStartTime)) {
    return null;
  }

  return adjustedStartTime;
};
