import { useSelector, shallowEqual } from 'react-redux';

export function useAppInfo() {
  const {
    magineEnvironment,
  } = useSelector(state => ({
    magineEnvironment: state.settings.app.magineEnvironment,
  }), shallowEqual);

  const isDevToolbarEnabled = () => __DEVELOPMENT__ || magineEnvironment !== 'production';

  return {
    isDevToolbarEnabled,
  };
}
