/*
 * Given a broadcast, determines whether it is a catchup that can be played.
 * The only playable items in the TV Guide are live broadcasts and catchups.
 */
const isAvailable = (broadcast, currentTime) => !!(
  broadcast.availableFrom && broadcast.availableUntil
  && broadcast.availableFrom <= currentTime
  && broadcast.availableUntil >= currentTime
);

export const BroadcastStates = {
  past: 'past',
  catchup: 'catchup',
  live: 'live',
  future: 'future',
};

export function deduceBroadcastState(broadcast, currentTime) {
  if (currentTime >= broadcast.start && currentTime < broadcast.stop) {
    return BroadcastStates.live;
  }

  if (broadcast.start > currentTime) {
    return BroadcastStates.future;
  }

  // catchup in the past
  if (isAvailable(broadcast, currentTime)) {
    return BroadcastStates.catchup;
  }

  return BroadcastStates.past;
}
