import React from 'react';
import { Spacer } from 'components';
import ItemTitle from 'components/VideoItems/SixteenNineItem/ItemTitle';
import { viewableShape } from 'components/VideoItems/commonPropTypes';

function RelatedTitles({ viewable }) {
  return (
    <>
      <Spacer height="small" />
      <ItemTitle viewable={viewable} />
    </>
  );
}

RelatedTitles.propTypes = {
  viewable: viewableShape.isRequired,
};

export default React.memo(RelatedTitles);
