import createComponent from 'styles/fela/createComponent';
import {
  smallScreen,
} from 'styles/fela/mixins';

export const SelectWrapper = createComponent(({ theme, longestItemLength }) => ({
  position: 'relative',
  minWidth: `calc(${longestItemLength}ch * 1.3)`,
  marginBottom: theme.margin.fine,
  marginTop: theme.margin.medium,
  textAlign: 'left',
}), 'div', ['longestItemLength']);

export const Input = createComponent(({ theme, disabled = false }) => ({
  background: theme.color.inputBackground,
  borderRadius: theme.radius.adyenDropIn,
  boxShadow: 'none',
  color: theme.color.inputColor,
  display: 'inline-block',
  fontSize: theme.fontSize.normal,
  fontWeight: 'bold',
  margin: '0',
  outline: '0',
  paddingTop: theme.margin.large,
  paddingBottom: theme.margin.fine,
  paddingLeft: theme.margin.medium,
  paddingRight: theme.margin.xlarge,
  width: '100%',
  height: '4rem',
  lineHeight: '2rem',
  cursor: 'pointer',
  ':focus': {
    outline: 'none',
  },
  ':after': {
    borderColor: `${theme.color.inputColor} transparent transparent`,
    borderStyle: 'solid',
    borderWidth: '8px 8px 4px',
    content: '""',
    display: 'inline-block',
    height: 0,
    position: 'absolute',
    right: theme.margin.medium,
    top: theme.margin.large,
    marginTop: theme.margin.fine,
    width: 0,
  },
  extend: [
    {
      condition: disabled,
      style: {
        opacity: 0.7,
        cursor: 'default',
      },
    },
  ],
}));

export const List = createComponent(({ theme }) => ({
  color: theme.color.inputColor,
  background: theme.color.inputBackground,
  boxShadow: `0 2px 3px 0 ${theme.color.overlayDarkFaint}, 0 2px 1px 0 ${theme.color.overlayDarkTranslucent}, 0 2px 1px -1px ${theme.color.overlayDarkTranslucent}`,
  borderRadius: theme.radius.adyenDropIn,
  maxHeight: '12rem',
  paddingBottom: theme.margin.fine,
  overflowY: 'auto',
  position: 'absolute',
  top: theme.margin.large,
  width: '100%',
  zIndex: theme.zIndex.select,
  extend: [
    smallScreen({
      maxHeight: '8rem',
    }),
  ],
}), 'ul', ['itemsCount']);

export const ListItem = createComponent(({ theme, selected }) => ({
  color: theme.color.inputColor,
  cursor: 'pointer',
  fontSize: theme.fontSize.normal,
  lineHeight: '2rem',
  paddingLeft: theme.margin.medium,
  paddingRight: theme.margin.medium,

  ':hover': {
    fontWeight: 'bold',
  },
  extend: [
    {
      condition: selected,
      style: {
        fontWeight: 'bold',
      },
    },
  ],
}), 'li', ['selected']);
