import React from 'react';
import {
  Text,
  TextDate,
  Price, Spacer,
} from 'components';
import { useI18n } from 'components/I18n';
import ChevronIcon from 'components/Icons/ChevronIcon';
import { DATE_FORMAT } from 'utils/constants';
import {
  TableRow,
  TableCell,
  Separator,
  SubCell,
  CellTitle,
  ToggleButton,
  StyledText,
} from './Styles';
import TransactionPeriod from './TransactionPeriod';
import { TTransaction } from './types';
import { getDiscountTextId } from './utils';

interface ITransactionMobileProps {
  transaction: TTransaction;
}

function TransactionMobile({ transaction }: ITransactionMobileProps) {
  const i18n = useI18n();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpanded = () => setIsExpanded(prevIsExpanded => !prevIsExpanded);

  return (
    <>
      <TableRow expanded={isExpanded}>
        <TableCell expanded={isExpanded}>
          <TextDate mb="4px" value={transaction.timestamp} options={DATE_FORMAT} />
          <StyledText mt="4px" mb="4px" as="div">{transaction.offerV3?.title}</StyledText>
          <Text color="#979797" id={`transactionStatus.${transaction.status.toLowerCase()}`} />
        </TableCell>
        <TableCell expanded={isExpanded}>
          <Text nowrap>
            <Price
              currency={transaction.currency}
              price={transaction.netPrice}
            /> *
          </Text>
        </TableCell>
        <TableCell align="right" expanded={isExpanded}>
          <ToggleButton onClick={toggleExpanded}>
            <Text
              boldWidthForTextInAttr="data-content"
              data-content={i18n.formatText(`transaction.${isExpanded ? 'hide' : 'details'}`)}
              id={`transaction.${isExpanded ? 'hide' : 'details'}`}
            />
            <Spacer width={10} />
            <ChevronIcon direction={isExpanded ? 'down' : 'right'} />
          </ToggleButton>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          <TableRow expanded>
            <td colSpan={5}>
              <Separator />
            </td>
          </TableRow>
          <TableRow expanded>
            <TableCell expanded>
              <SubCell>
                <CellTitle>
                  <Text id="transaction.period" />
                </CellTitle>
                <TransactionPeriod transaction={transaction} />
              </SubCell>
            </TableCell>
            <TableCell colSpan="2" expanded>
              <SubCell>
                <CellTitle>
                  <Text id="transaction.grossPrice" />
                </CellTitle>
                <Price
                  currency={transaction.currency}
                  price={transaction.grossPrice}
                />
              </SubCell>
              <SubCell>
                <CellTitle>
                  <Text id={getDiscountTextId(transaction.transactionType)} />
                </CellTitle>
                <Price
                  currency={transaction.currency}
                  price={-(transaction.grossPrice - transaction.netPrice)}
                />
              </SubCell>
              <SubCell>
                <CellTitle>
                  <Text id="transaction.total" />
                </CellTitle>
                <Price
                  currency={transaction.currency}
                  price={transaction.netPrice}
                  fontSize="1.1em"
                />
              </SubCell>
            </TableCell>
          </TableRow>
          <TableRow expanded>
            <TableCell colSpan="5" paddingTopOff>
              * <Text id="transaction.taxesIncluded" />
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

export default React.memo(TransactionMobile);
