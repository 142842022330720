import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ObservableInterval from 'utils/observable-interval';
import { formatHHmm } from 'utils/human-format';
import {
  getLiveEventState,
  LIVE_EVENT_STATES,
} from 'utils/live-event';
import { DynamicClockIcon } from 'components/Icons';
import { Box } from 'components/StyledSystem/Box';
import Text from 'components/StyledSystem/Text';
import Spacer from 'components/StyledSystem/Spacer';

const interval = new ObservableInterval(1000);

const LiveInfo = (props) => {
  const {
    liveEventTime,
    fromHero,
    onlyToday,
  } = props;

  const [liveEventState, setLiveEventState] = useState();

  const updateUpcomingState = () => {
    setLiveEventState(getLiveEventState(liveEventTime));
  };

  useEffect(() => {
    interval.subscribe(updateUpcomingState);
    updateUpcomingState();
    return () => {
      interval.unsubscribe(updateUpcomingState);
    };
  }, []);

  const shouldRender = liveEventState === LIVE_EVENT_STATES.upcomingToday
   || (!onlyToday && liveEventState === LIVE_EVENT_STATES.upcoming);

  if (!shouldRender) {
    return null;
  }

  return (
    <Box
      flexBox
      alignItems="center"
    >
      <DynamicClockIcon
        currentTime={liveEventTime.startTime}
        size="22px"
        color={fromHero ? 'white' : 'secondary'}
        opacity="0.7"
        data-cy="dynamic-clock-icon"
      />

      <Spacer width="fine" />

      <Text
        id="liveLabel.starting"
        values={{ time: formatHHmm(liveEventTime.startTime * 1000) }}
        color={fromHero ? 'white' : 'secondary'}
        fontSize={fromHero ? 'large' : 'normal'}
      />
    </Box>
  );
};

LiveInfo.propTypes = {
  liveEventTime: PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    catchupStop: PropTypes.number,
  }),
  fromHero: PropTypes.bool,
  onlyToday: PropTypes.bool,
};

export default LiveInfo;
