import {
  format,
} from 'date-fns';
import { sToTimeUnits } from './time';

function leftpad(number) {
  return number > 9 ? number.toString() : `0${number}`;
}

// TODO: Unit tests and possibly break each function out to
// its own file. Saving that for later as other changes such as
// translation might impact the whole structure.
export const formatDuration = (durationInSeconds) => {
  const totalMinutes = Math.floor(durationInSeconds / 60);

  if (totalMinutes === 0) {
    return `${durationInSeconds}s`;
  }

  const hours = Math.floor(totalMinutes / 60);

  if (hours === 0) {
    return `${totalMinutes}min`;
  }

  if (totalMinutes % 60 === 0) {
    return `${hours}h`;
  }

  return `${hours}h ${totalMinutes % 60}min`;
};

export const formatClockTime = (epochInSeconds) => {
  const time = new Date(epochInSeconds * 1000);
  const minutes = time.getMinutes();
  return `${time.getHours()}:${leftpad(minutes)}`;
};

export const formatTimeLeft = (timeLeft) => {
  const {
    hours,
    minutes,
    seconds,
  } = sToTimeUnits(timeLeft);

  return `${leftpad(hours)}:${leftpad(minutes)}:${leftpad(seconds)}`;
};

export const formatYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1);
  const day = date.getDate();

  return `${year}-${leftpad(month)}-${leftpad(day)}`;
};

export const formatHHmm = ts => format(ts, 'HH:mm');
