import createComponent from 'styles/fela/createComponent';
import { BroadcastStates } from './utils';

const broadcastDurationHeight = '20px';
const broadcastDurationMarginTop = '10px';
const broadcastTitleMarginTop = '10px';
const broadcastBorderWidth = 2;

export const CONTAINER_PADDING = 4;
export const BROADCAST_PADDING = 16;

export const BroadcastContainer = createComponent(({ theme, hover }) => ({
  backgroundColor: theme.color.epgBackground,
  height: '100%',
  padding: `0 ${CONTAINER_PADDING}px`,
  overflow: 'hidden',
  textAlign: 'left',
  extend: [
    {
      condition: hover,
      style: {
        overflow: 'visible',
        position: 'absolute',
        zIndex: 1,
      },
    },
  ],
}), 'div', ['hover']);

export const BroadcastWrapper = createComponent(({
  state,
  isActive,
  isLiveAvailable,
  theme,
  width,
}) => ({
  display: 'block',
  lineHeight: theme.fontSize.medium,
  position: 'relative',
  padding: `1px ${BROADCAST_PADDING + 1}px`,
  textDecoration: 'none',
  fontWeight: 'bold',
  transition: [
    'background 300ms ease',
    'fill 300ms ease',
    'width 100ms ease',
  ],
  height: '100%',
  zIndex: 0,
  border: `${broadcastBorderWidth}px solid`,
  overflow: 'hidden',
  ':hover': {
    borderWidth: '3px',
    padding: `0 ${BROADCAST_PADDING}px`,
  },
  extend: [
    {
      condition: state === BroadcastStates.past
        || (state === BroadcastStates.live && !isLiveAvailable),

      style: {
        borderColor: 'transparent',
        backgroundColor: theme.color.broadcastPastBackground,
        color: theme.color.broadcastPastText,
      },
    },
    {
      condition: state === BroadcastStates.future,
      style: {
        backgroundColor: theme.color.broadcastFutureBackground,
        borderColor: theme.color.broadcastFutureBorder,
        ':hover': {
          padding: `1px ${BROADCAST_PADDING + 1}px`,
          borderWidth: '2px',
        },
      },
    },
    {
      condition: state === BroadcastStates.catchup,
      style: {
        borderColor: theme.color.broadcastCatchup,
        color: theme.color.primary,
        fill: theme.color.broadcastCatchup,
        ':hover': {
          backgroundColor: theme.color.highlight,
          borderColor: theme.color.broadcastCatchupHover,
          fill: theme.color.broadcastCatchupHover,
        },
      },
    },
    {
      condition: state === BroadcastStates.live && isLiveAvailable,
      style: {
        backgroundColor: theme.color.broadcastLive,
        borderColor: theme.color.broadcastLiveBorder,
        color: theme.color.primary,
        ':hover': {
          backgroundColor: theme.color.broadcastLiveHover,
        },
      },
    },
    {
      condition: width <= (BROADCAST_PADDING + broadcastBorderWidth) * 2,
      style: {
        padding: `1px ${(Math.floor(width / 2) - 1)}px`,
      },
    },
    {
      condition: isActive,
      style: {
        backgroundColor: theme.color.broadcastActive,
        ':hover': {
          backgroundColor: theme.color.broadcastActiveHover,
        },
        extend: [
          {
            condition: state === BroadcastStates.catchup,
            style: {
              backgroundColor: theme.color.broadcastCatchupBackground,
              ':hover': {
                backgroundColor: theme.color.broadcastCatchupBackgroundHover,
              },
            },
          },
        ],
      },
    },
  ],
}), 'div', [
  'state',
  'isActive',
  'isLiveAvailable',
  'width',
]);

export const BroadcastTitle = createComponent(({ hover }) => ({
  height: `calc(100% - ${broadcastDurationMarginTop} - ${broadcastDurationHeight} - ${broadcastTitleMarginTop})`,
  marginTop: broadcastTitleMarginTop,
  lineHeight: '20px',
  position: 'relative',
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  textOverflow: 'ellipsis',
  zIndex: 2,
  transition: 'all .3s ease',
  willChange: 'transform',
  extend: [
    {
      condition: hover,
      style: {
        textOverflow: 'initial',
        whiteSpace: 'nowrap',
      },
    },
  ],
}), 'div', ['hover']);

export const BroadcastDuration = createComponent(({ theme, state, showDuration }) => ({
  color: theme.color.broadcastTime,
  fontSize: theme.fontSize.small,
  height: broadcastDurationHeight,
  marginTop: broadcastDurationMarginTop,
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  textOverflow: 'ellipsis',
  position: 'relative',
  zIndex: 2,
  transition: 'all .3s ease',
  willChange: 'transform',
  extend: [
    {
      condition: state === BroadcastStates.past,
      style: {
        color: theme.color.broadcastPastText,
      },
    },
    {
      condition: state === BroadcastStates.catchup && !showDuration,
      style: {
        visibility: 'hidden',
      },
    },
  ],
}), 'div', ['state', 'showDuration']);
