import { CollectionDisplayType } from 'utils/constants';

export const HIDE_HORIZONTAL_MENU = 'ui/HIDE_HORIZONTAL_MENU';
interface HideHorizontalMenuAction {
  type: typeof HIDE_HORIZONTAL_MENU
  hide: boolean
}
export function hideHorizontalMenu(hide: boolean): HideHorizontalMenuAction {
  return {
    type: HIDE_HORIZONTAL_MENU,
    hide,
  };
}

export const SET_COLLECTION_DISPLAY_STYLE = 'ui/SET_COLLECTION_DISPLAY_STYLE';
interface SetCollectionDispayTypeAction {
  type: typeof SET_COLLECTION_DISPLAY_STYLE
  collectionDisplayStyle: CollectionDisplayType
}
export const setCollectionDisplayStyle = (
  collectionDisplayStyle: CollectionDisplayType,
): SetCollectionDispayTypeAction => ({
  type: SET_COLLECTION_DISPLAY_STYLE,
  collectionDisplayStyle,
});

/**
 * Views determine whether the header should be shown as transparent or
 * opaque (actual transparency values based on app config).
 *
 * Also include an id for the transparency enabled value, to prevent the
 * `willComponentUnmount` of the previous component disabling the transparency
 * after the new component finished the init (MDM-11027)
 */
export const SET_HEADER_TRANSPARENCY = 'ui/SET_HEADER_TRANSPARENCY';
interface SetHeaderTransparencyAction {
  type: typeof SET_HEADER_TRANSPARENCY
  locationName: string,
  collectionId: string,
  supportsTransparency: boolean,
}
export const setHeaderTransparency = (
  locationName: string,
  collectionId: string,
  supportsTransparency: boolean,
): SetHeaderTransparencyAction => ({
  type: SET_HEADER_TRANSPARENCY,
  locationName,
  collectionId,
  supportsTransparency,
});

export type UIActions = (
  HideHorizontalMenuAction | SetCollectionDispayTypeAction | SetHeaderTransparencyAction
);
