import React from 'react';
import Icon from './Icon';

const directionMap: Record<string, number> = {
  right: 0,
  down: 90,
  left: 180,
  up: 270,
};

interface IChevronArrowIconProps {
  direction: string;
  size?: string;
  style?: React.CSSProperties
}

export default function ChevronArrowIcon(props: IChevronArrowIconProps): JSX.Element {
  const { direction = 'right', style, ...otherProps } = props;
  const iconProps = {
    ...otherProps,
    style: {
      ...style,
      transform: `rotateZ(${directionMap[direction]}deg)`,
      transition: 'transform .3s',
    },
  };

  return (
    <Icon {...iconProps} width="10" height="16" viewBox="0 0 10 16">
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M0.362893 0.35199C0.84675 -0.11733 1.63124 -0.11733 2.11509 0.35199L10 8L2.1151 15.648C1.63124 16.1173 0.846753 16.1173 0.362895 15.648C-0.120962 15.1787 -0.120962 14.4178 0.362895 13.9485L6.4956 8L0.362893 2.05155C-0.120964 1.58223 -0.120964 0.82131 0.362893 0.35199Z" />
    </Icon>
  );
}
