import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ADYEN_RESULT_TYPES } from 'utils/constants';
import { Text } from 'components';

export default function AdyenResultHandler({
  onPaymentSucceded = () => {},
  onPaymentFailed = () => {},
}) {
  const [error, setError] = useState(null);
  const paymentResult = useSelector(state => state?.router?.query?.paymentResult);

  const onError = (msg) => {
    setError(msg);
    onPaymentFailed(msg);
  };

  useEffect(() => {
    if (!paymentResult) {
      return;
    }

    switch (paymentResult) {
      case ADYEN_RESULT_TYPES.AUTHORISED:
        onPaymentSucceded();
        return;

      case ADYEN_RESULT_TYPES.REFUSED:
        onError('payment.rejected');
        return;

      case ADYEN_RESULT_TYPES.CANCELLED:
        onError(null);
        return;

      default:
        onError('error');
    }
  }, []);

  if (!error) {
    return null;
  }

  return (
    <Text
      id={error}
      color="danger"
    />
  );
}

AdyenResultHandler.propTypes = {
  onPaymentSucceded: PropTypes.func,
  onPaymentFailed: PropTypes.func,
};
