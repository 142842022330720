import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { PrivacyPolicyLink } from 'components';

const PrivacyPolicyLinkCreateAccount = (): React.ReactElement | null => {
  const privacyPolicy = useSelector((state: RootState) => state.settings.features?.privacyPolicy);

  if (!privacyPolicy || !privacyPolicy.label) {
    return null;
  }

  return (
    <PrivacyPolicyLink
      consentText={privacyPolicy.label}
      url={privacyPolicy.url}
      urlLabel={privacyPolicy.urlLabel}
    />
  );
};

export default PrivacyPolicyLinkCreateAccount;
