import { parse } from 'query-string';
import { Query, RouterLocation } from './location';

export const LOCATION_CHANGE = 'router/ROUTER_LOCATION_CHANGE';
interface LocationChangeAction {
  type: typeof LOCATION_CHANGE
  pathname: string
  query: Query
  isPush: boolean
  suppressViewChange: boolean
}
export function locationChange(
  pathname: string,
  query: Query,
  isPush = false,
  suppressViewChange = false,
): LocationChangeAction {
  return {
    type: LOCATION_CHANGE,
    pathname,
    query,
    isPush,
    suppressViewChange,
  };
}

export function propagateCurrentLocation(
  isPush = false,
  suppressViewChange = false,
): LocationChangeAction {
  const query = parse(window.location.search);
  return locationChange(window.location.pathname, query, isPush, suppressViewChange);
}

export const SET_ACTIVE_LOCATION = 'router/SET_ACTIVE_LOCATION';
interface SetActiveLocationAction {
  type: typeof SET_ACTIVE_LOCATION
  location: RouterLocation
}
export function setActiveLocation(location: RouterLocation): SetActiveLocationAction {
  return {
    type: SET_ACTIVE_LOCATION,
    location,
  };
}

export const SET_ROUTE_ERROR = 'router/SET_ROUTE_ERROR';
interface SetRouteErrorAction {
  type: typeof SET_ROUTE_ERROR
  error: Error
}
export function setRouteError(error: Error): SetRouteErrorAction {
  return {
    type: SET_ROUTE_ERROR,
    error,
  };
}

export const PUSH = 'router/ROUTER_PUSH';
export interface PushAction {
  type: typeof PUSH
  to: RouterLocation
}
export function push(location: RouterLocation): PushAction {
  return {
    type: PUSH,
    to: location,
  };
}

export const PUSH_RELATIVE = 'router/ROUTER_PUSH_RELATIVE';
interface PushRelativeAction {
  type: typeof PUSH_RELATIVE
  relativeHref: string
}
export function pushRelative(relativeHref: string): PushRelativeAction {
  return {
    type: PUSH_RELATIVE,
    relativeHref,
  };
}

export const REPLACE = 'router/ROUTER_REPLACE';
interface ReplaceAction {
  type: typeof REPLACE
  to: RouterLocation
}
export function replace(location: RouterLocation): ReplaceAction {
  return {
    type: REPLACE,
    to: location,
  };
}

export const REPLACE_PASSIVE = 'router/ROUTER_REPLACE_PASSIVE';
interface ReplacePassiveAction {
  type: typeof REPLACE_PASSIVE
  to: RouterLocation
}
export function replacePassive(location: RouterLocation): ReplacePassiveAction {
  return {
    type: REPLACE_PASSIVE,
    to: location,
  };
}

export type RouterActionTypes = (
  LocationChangeAction
  | SetActiveLocationAction
  | SetRouteErrorAction
  | PushAction
  | PushRelativeAction
  | ReplaceAction
  | ReplacePassiveAction
);
