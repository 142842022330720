import createComponent from 'styles/fela/createComponent';

export const ANIMATION_DURATION_SEC = 0.51;

export const Wrapper = createComponent(() => ({
  width: '100%',
  overflowY: 'visible',
  overflowX: 'clip',
  position: 'relative',

  '&:hover > div': {
    visibility: 'visible',
  },
}));

export const Container = createComponent(() => ({
  display: 'flex',
  transform: 'translate3d(-100%, 0, 0)',
  willChange: 'transform',
}));

export const Item = createComponent(() => ({
  width: '100%',
  flex: '0 0 100%',
}));

export const PagerContainer = createComponent(() => ({
  position: 'absolute',
  bottom: '0',
  width: '100%',
  paddingBottom: '0.7rem',
}));

export const NavigationContainer = createComponent(({ type }) => ({
  visibility: 'hidden',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: '1.5rem',
  cursor: 'pointer',
  color: '#ffffff',
  zIndex: 10,
  opacity: '0.7',
  ':hover': {
    opacity: '1',
  },

  '& > svg': {
    transition: '0.5s transform',
  },
  ':hover > svg': {
    transform: 'scale(1.2)',
  },

  extend: [
    {
      condition: type === 'left',
      style: {
        left: 0,
      },
    },
    {
      condition: type === 'right',
      style: {
        right: 0,
      },
    },
  ],
}), 'div', ['type']);
