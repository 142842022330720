import createComponent from 'styles/fela/createComponent';
import paypalLogo from './images/paypal.svg';

const PaypalLogo = createComponent(({ theme, center }) => ({
  backgroundColor: '#fff',
  height: theme.size.paypalLogoSize,
  width: theme.size.paypalLogoSize,
  position: 'relative',
  ':after': {
    content: '" "',
    backgroundImage: `url(${paypalLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    height: `calc(${theme.size.paypalLogoSize} - 1rem)`,
    width: `calc(${theme.size.paypalLogoSize} - 1rem)`,
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem',
  },

  extend: [
    {
      condition: center,
      style: {
        margin: '0 auto',
      },
    },
  ],
}), 'div', ['center']);

export default PaypalLogo;
