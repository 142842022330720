import { isSameDay } from 'date-fns';

export const getSecFromDays = days => days * 24 * 60 * 60;

/**
 * Determines whether a given timestamp lies in the past
 *
 * @param {Number} timestamp The timestamp in milliseconds
 */
export const isTimestampInPast = timestamp => (
  timestamp < Date.now()
);

/**
 * Determines whether a given timestamp lies in the future
 *
 * @param {Number} timestamp The timestamp in milliseconds
 */
export const isTimestampInFuture = timestamp => (
  timestamp > Date.now()
);

/**
 * Determines whether an availability time interval would make an asset
 * available at the moment.
 *
 * @param {Number} fromTimestamp Timestamp in milliseconds for the beginning of the interval
 * @param {Number} toTimestamp  Timestamp in milliseconds for the end of the interval
 */
export const isIntervalAvailableNow = (fromTimestamp, toTimestamp) => {
  const now = Date.now();
  return (
    fromTimestamp <= now
    && toTimestamp >= now
  );
};

/**
 * Given a time frame expressed in seconds, it returns the
 * number of days, hours, minutes and seconds it represents.
 *
 * @param {Number} sCount The timeframe in number of milliseconds
 * @returns {Object} An object containing the number of
 * days, hours, minutes and seconds that interval represents
 */
export const sToTimeUnits = (sCount) => {
  if (!sCount) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  let deltaSeconds = sCount;

  const days = Math.floor(deltaSeconds / 86400);
  deltaSeconds -= days * 86400;

  const hours = Math.floor(deltaSeconds / 3600) % 24;
  deltaSeconds -= hours * 3600;

  const minutes = Math.floor(deltaSeconds / 60) % 60;
  deltaSeconds -= minutes * 60;

  const seconds = Math.floor(deltaSeconds % 60);

  return { days, hours, minutes, seconds };
};

/**
 * Given a time frame expressed in milliseconds, it returns the
 * number of days, hours, minutes and seconds it represents.
 *
 * @param {Number} msCount The timeframe in number of milliseconds
 * @returns {Object} An object containing the number of
 * days, hours, minutes and seconds that interval represents
 */
export const msToTimeUnits = (msCount) => {
  if (!msCount) {
    return null;
  }

  return sToTimeUnits(msCount / 1000);
};

export const nowS = () => Date.now() / 1000;

export const isUpcomingToday = (timestampS) => {
  const currentTimeS = nowS();

  const isUpcoming = currentTimeS < timestampS;

  return isUpcoming && isSameDay(timestampS * 1000, currentTimeS * 1000);
};

export const formatEntitlementDuration = (sCount) => {
  const timeUnits = sToTimeUnits(sCount);

  // If entitled time is 2 days or less, only show number of hours
  let { days, hours } = timeUnits;
  if (timeUnits.days < 2
    || (timeUnits.days === 2 && hours === 0)) {
    hours += timeUnits.days * 24;
    days = 0;
  }

  return { days: days || 0, hours: hours || 0 };
};
