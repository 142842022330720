import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'components/Grid';
import { Box } from 'components/StyledSystem/Box';
import { TooltipTrigger } from 'components/HoverState';
import { ITEM_MODE } from 'components/VideoItems/constants';
import { BLOCK_INTERFACE_TYPES } from 'utils/constants';
import { viewableShape } from './prop-types';

function GridLayout(props) {
  const {
    innerRef,
    items,
    refreshData,
    onClick,
    ItemComponent,
    itemWidth,
    is16x9,
    hideChannelLogo,
    loadMore,
    collectionId,
    categoryKind,
  } = props;

  return (
    <Grid innerRef={innerRef} itemWidth={itemWidth} is16x9={is16x9}>
      {items.map(viewable => (
        <TooltipTrigger
          key={viewable.id}
          viewable={viewable}
          refreshData={() => refreshData(viewable)}
          collectionId={collectionId}
          categoryKind={categoryKind}
        >
          <ItemComponent
            viewable={viewable}
            onClick={onClick}
            mode={ITEM_MODE.GRID}
            hideChannelLogo={hideChannelLogo}
          />
        </TooltipTrigger>
      ))}
      {loadMore && (
        <Box column justifyContent="center" gridColumn="span 2">
          {loadMore}
        </Box>
      )}
    </Grid>
  );
}

GridLayout.propTypes = {
  innerRef: PropTypes.object,
  items: PropTypes.arrayOf(viewableShape).isRequired,
  refreshData: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  ItemComponent: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  itemWidth: PropTypes.number.isRequired,
  is16x9: PropTypes.bool,
  loadMore: PropTypes.object,
  hideChannelLogo: PropTypes.bool,
  collectionId: PropTypes.string,
  categoryKind: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)),
};

GridLayout.defaultProps = {
  is16x9: false,
  refreshData: () => null,
};

export default React.memo(GridLayout);
