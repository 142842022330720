import { RootState } from 'reducers';
import { Action } from 'redux';
import { ThunkAction as ReduxThunkAction } from 'redux-thunk';

export type AppAction = ReduxThunkAction<void, RootState, unknown, Action>;
export type AppAsyncAction<R = void> = ReduxThunkAction<Promise<R>, RootState, unknown, Action>;

export const GRAPHQL_REQUEST = 'helpers/GRAPHQL_REQUEST';
export interface GraphqlAction {
  type: typeof GRAPHQL_REQUEST
  gql: {
    query: string,
    variables: Record<string, any>,
  },
}
export type GraphqlResult = {
  data: any,
  errors: any[],
};
export function graphqlRequest(
  query: string,
  variables: Record<string, any>,
): AppAsyncAction<GraphqlResult> {
  return async (dispatch) => {
    // request is made by GraphQL redux middleware, which will return Promise, thus await
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const result: any = await dispatch({
      type: GRAPHQL_REQUEST,
      gql: {
        query,
        variables,
      },
    });

    return result as GraphqlResult;
  };
}

export const NETWORK_REQUEST = 'helpers/NETWORK_REQUEST';
type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export interface NetworkAction {
  type: typeof NETWORK_REQUEST
  url: string
  method: HTTPMethod
  headers: Record<string, string>
  body: any
}
export function networkRequest(
  url: string,
  method: HTTPMethod,
  headers = {},
  body = {},
): AppAsyncAction<Response> {
  return async (dispatch) => {
    // request is made by network request redux middleware, which will return Promise, thus await
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const result: any = await dispatch({
      type: NETWORK_REQUEST,
      url,
      method,
      headers,
      body,
    });

    return result as Response;
  };
}

export const API_REQUEST = 'helpers/API_REQUEST';
export interface APIRequestAction {
  type: typeof API_REQUEST
  api: {
    method: string,
    params: Record<string, any>,
  }
}
export function apiNetworkRequest(
  method: string,
  params: Record<string, any> = {},
): AppAsyncAction<any> {
  return async (dispatch) => {
    // request is made by api redux middleware, which will return Promise, thus await
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const result: any = await dispatch({
      type: API_REQUEST,
      api: {
        method,
        params,
      },
    });

    return result;
  };
}
