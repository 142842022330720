import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'components/index';
import { Title } from 'components/VideoItems/Styles';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import { extractLiveEventTime } from 'utils/live-event';
import { VIEWABLE_TYPES } from 'utils/constants';

const ItemTitle = ({ viewable, minHeight }) => {
  const liveEventTime = extractLiveEventTime(viewable);

  return (
    <Box mb="fine">
      <Title
        large
        withMinHeight={!!liveEventTime || minHeight}
      >
        {
          viewable.__typename === VIEWABLE_TYPES.Episode
            ? (
              <>
                {viewable.show?.title} <Text
                  id="seasonEpisodeShort"
                  values={{
                    seasonNumber: viewable.seasonNumber,
                    episodeNumber: viewable.episodeNumber,
                  }}
                />
              </>
            )
            : viewable.title
        }
      </Title>
    </Box>
  );
};

ItemTitle.propTypes = {
  viewable: viewableShape.isRequired,
  minHeight: PropTypes.bool,
};

export default React.memo(ItemTitle);
