import createComponent from 'styles/fela/createComponent';
import CleanLink from 'components/StyledSystem/CleanLink';

export const ContainerLink = createComponent(({ width }) => ({
  width,
  display: 'block',
  position: 'relative',
}), CleanLink, ['width']);

export const Title = createComponent(({ theme, large, nowrap, withMinHeight }) => ({
  color: theme.color.primary,
  fontSize: theme.fontSize.normal,
  fontWeight: 'bold',
  lineHeight: '1.4',
  wordBreak: 'break-word',

  extend: [
    {
      condition: large,
      style: {
        fontSize: theme.fontSize.medium,
      },
    },
    {
      condition: withMinHeight,
      style: {
        minHeight: '3.5rem',
      },
    },
    {
      condition: nowrap,
      style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  ],
}), 'h6', ['large', 'nowrap', 'withMinHeight']);
