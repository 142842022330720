import React from 'react';
import { useFela } from 'react-fela';
import { GlobalTheme } from 'types';
import {
  addDays,
  getUnixTime,
  fromUnixTime,
  startOfDay,
  differenceInDays,
  isToday,
} from 'date-fns';
import { px2sec } from 'utils/broadcast';
import { TextDate } from 'components';
import DateSwitcher from './DateSwitcher';
import DatePicker from './DatePicker';
import { epgHeaderStyle } from './styles';

interface IEpgHeaderProps {
  startTime: number;
  scrollLeft: number;
  scrollToTime: (timestamp: number) => Promise<void>;
  scrollToNow: () => Promise<void>;
}

const EpgHeader: React.FC<IEpgHeaderProps> = (props) => {
  const { startTime, scrollLeft, scrollToTime, scrollToNow } = props;
  const { css } = useFela<GlobalTheme>();

  const currentScreenTime = startTime + px2sec(scrollLeft);
  const currentScreenDate = fromUnixTime(currentScreenTime);

  const onDateChange = (date: Date) => {
    if (isToday(date)) {
      void scrollToNow();
      return;
    }
    const newScreenTime = getUnixTime(
      addDays(
        currentScreenDate,
        differenceInDays(startOfDay(date), startOfDay(currentScreenDate)),
      ),
    );
    void scrollToTime(newScreenTime);
  };

  return (
    <div className={css(epgHeaderStyle)}>
      <DateSwitcher currentScreenDate={currentScreenDate} scrollToTime={scrollToTime}>
        <TextDate
          value={currentScreenTime * 1000}
          options={{
            day: '2-digit',
            weekday: 'long',
            month: 'long',
          }}
          as="h1"
          style={{ display: 'inline' }}
        />
      </DateSwitcher>
      <DatePicker onDateChange={onDateChange} currentDateTime={currentScreenTime * 1000} />
    </div>
  );
};

export default EpgHeader;
