import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import validate from 'utils/validate';
import { INPUT_FIELD_TYPE } from 'utils/constants';
import {
  Box,
  Heading,
  Hr,
} from 'components';
import InputField from './InputField';

const findFieldByType = (inputGroups, type) => {
  for (const group of inputGroups) {
    for (const field of group.inputFields) {
      if (field.inputType === type) {
        return field;
      }
    }
  }

  return null;
};

const requiredFieldsErrorMessages = {
  identity: 'validate.emailRequired',
  accessKey: 'validate.passRequired',
  name: 'validate.nameRequired',
};

function InputFields({ inputGroups, disabledFields = {} }) {
  const { control, trigger } = useFormContext();

  const passwordConfirmField = findFieldByType(inputGroups, 'passwordConfirmation');
  const emailConfirmField = findFieldByType(inputGroups, 'emailConfirmation');

  const inputFieldValidations = (field) => {
    const validations = {};

    if (field.required) {
      validations.required = validate.required(requiredFieldsErrorMessages[field.key] ?? 'validate.notBlank');
    }

    if (field.inputType === INPUT_FIELD_TYPE.emailConfirmation) {
      validations.confirm = validate.same('identity', field.validations[0].message);
    }

    if (field.inputType === INPUT_FIELD_TYPE.passwordConfirmation) {
      validations.confirm = validate.same('accessKey', field.validations[0].message);
    }

    if (field.inputType === INPUT_FIELD_TYPE.date) {
      validations.date = validate.date();
    }

    return validations;
  };

  const inputFieldMapper = (field) => {
    let onChange;

    if (field.key === 'identity' && emailConfirmField) {
      const emailConfirmFieldRef = control?.fieldsRef?.current?.[emailConfirmField.key];
      if (emailConfirmFieldRef) {
        onChange = () => trigger(emailConfirmField.key);
      }
    }

    if (field.key === 'accessKey' && passwordConfirmField) {
      const passwordConfirmFieldRef = control?.fieldsRef?.current?.[passwordConfirmField.key];
      if (passwordConfirmFieldRef) {
        onChange = () => trigger(passwordConfirmField.key);
      }
    }

    return (
      <InputField
        key={field.key}
        onChange={onChange}
        className={`e2e-${field.key}`}
        field={field}
        validations={inputFieldValidations(field)}
        disabled={disabledFields[field.key]}
        name={field.key}
      />
    );
  };

  return (
    <>
      {inputGroups.map((inputGroup, idx) => (
        <Fragment key={inputGroup.title}>
          {
            idx > 0 && (
              <Box>
                <Box mt="large" mb="xlarge">
                  <Hr height={5} />
                </Box>
                <Heading mb="small" as="h3">
                  {inputGroup.title}
                </Heading>
                <Heading fontSize="medium" mb="medium" as="h4">
                  {inputGroup.subtitle}
                </Heading>
              </Box>
            )
          }
          {inputGroup?.inputFields?.map(inputFieldMapper)}
        </Fragment>
      ))}
    </>
  );
}
InputFields.propTypes = {
  inputGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabledFields: PropTypes.object,
};

export default React.memo(InputFields);
