import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ClickOutside } from 'components';
import {
  Input,
  List,
  ListItem,
  SelectWrapper,
} from './SelectStyles';
import { InputErrorMessage, InputPlaceholder } from '../Styles';
import { toMessage } from '../utils';

function Select(props) {
  const {
    input,
    options,
    meta: {
      error,
    },
    placeholder,
    disabled,
  } = props;

  const inputRef = React.useRef();
  const [isOpen, setOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const listRef = useRef(null);

  const selectedItemIndex = input.value
    ? options.findIndex(option => option.value === input.value)
    : -1;

  const open = () => {
    if (disabled) return;

    setOpen(true);
  };
  const close = () => {
    setOpen(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    if (isOpen) {
      inputRef.current.focus();
      return;
    }
    setFocused(false);
    input.onBlur();
  };

  const onListItemClick = (value) => {
    input.onChange({ target: { value } });

    close();
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
      case ' ': {
        if (isOpen) {
          close();
        } else {
          open();
        }
        e.preventDefault();
        break;
      }
      case 'ArrowLeft':
      case 'ArrowUp': {
        if (selectedItemIndex > 0) {
          const prevItem = options[selectedItemIndex - 1];
          input.onChange({ target: { value: prevItem.value } });
        }
        e.preventDefault();
        break;
      }
      case 'ArrowRight':
      case 'ArrowDown': {
        if (selectedItemIndex < options.length - 1) {
          const nextItem = options[selectedItemIndex + 1];
          input.onChange({ target: { value: nextItem.value } });
        }
        e.preventDefault();
        break;
      }
      default: {
        break;
      }
    }
  };

  const longestItemLength = options.reduce((acc, option) => {
    if (option.localizedValue.length > acc) {
      return option.localizedValue.length;
    }

    return acc;
  }, placeholder.length);

  return (
    <SelectWrapper longestItemLength={longestItemLength}>
      <InputPlaceholder
        isFloating={isFocused || isOpen || !!input.value}
        isFocused={isFocused || isOpen}
      >
        {placeholder}
      </InputPlaceholder>
      <Input
        innerRef={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={open}
        onKeyDown={onKeyDown}
        disabled={disabled}
        tabIndex="0"
      >
        {selectedItemIndex > -1 ? (
          options[selectedItemIndex].localizedValue
        ) : (
          <span>&nbsp;</span>
        )}
      </Input>
      {isOpen && (
        <ClickOutside wrapperRef={listRef} onClickOutside={close}>
          <List innerRef={listRef} itemsCount={options.length}>
            {options.map((option, index) => (
              <ListItem
                key={index} // eslint-disable-line react/no-array-index-key
                onClick={() => onListItemClick(option.value)}
                selected={option.value === input.value}
              >
                {option.localizedValue}
              </ListItem>
            ))}
          </List>
        </ClickOutside>
      )}
      <InputErrorMessage isVisible={error}>{toMessage(error)}</InputErrorMessage>
      <select
        ref={input.ref}
        name={input.name}
        onChange={input.onChange}
        style={{ display: 'none' }}
      >
        <option value="">
          &nbsp;
        </option>

        {options.map((option, i) => (
          <option
            key={i} // eslint-disable-line react/no-array-index-key
            value={option.value}
          >
            {option.localizedValue}
          </option>
        ))}
      </select>
    </SelectWrapper>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    localizedValue: PropTypes.string.isRequired,
  })),
  input: PropTypes.shape({
    ref: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(Select);
