import { CleanLink, Box, Heading, SecondaryButton, Text } from 'components';
import * as React from 'react';
import SettingsContainer from 'views/Settings/SettingsContainer';
import AdyenDropInComponents from 'components/AdyenDropIn/AdyenDropInComponents';
import { FlowTypes } from 'components/AdyenDropIn/adyen-dropin-manager';
import { useRouter } from 'router';

const PaymentMethodView = () => {
  const router = useRouter();
  const paymentsPageRoute = {
    name: 'settings-payment',
  };
  const paymentsConfirmPageRoute = {
    name: 'settings-payment-setup-confirm',
  };
  const returnPath = router.getUrl(paymentsConfirmPageRoute, false);
  const setAdyenError = () => {
  };
  return (
    <SettingsContainer>
      <Heading
        id="payment"
        letterSpacing="2.3px"
        mb="large"
      />

      <Box mb="large">
        <AdyenDropInComponents
          returnPath={returnPath}
          onError={setAdyenError}
          flowType={FlowTypes.SETUP}
        />
      </Box>

      <SecondaryButton
        as={CleanLink}
        to={paymentsPageRoute}
      >
        <Text id="cancel" />
      </SecondaryButton>
    </SettingsContainer>
  );
};

export default React.memo(PaymentMethodView);
