import { AppAction } from './helpers';

export const UPDATE_PAGE = 'collections/UPDATE_PAGE';

type Page = {
  id: string,
  lastViewDate: Date,
  isExpired: boolean,
};

interface UpdatePageAction {
  type: typeof UPDATE_PAGE
  payload: Page,
}
export function UpdatePage(payload: Page): AppAction {
  return (dispatch) => {
    dispatch<UpdatePageAction>({
      type: UPDATE_PAGE,
      payload,
    });
  };
}

export type PageActions = UpdatePageAction;
