import PropTypes from 'prop-types';
import { PLAYABLE_KINDS, VIEWABLE_TYPES } from 'utils/constants';

const showShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

const playableShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(PLAYABLE_KINDS)).isRequired,
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    logoDark: PropTypes.string,
  }),
  startTimeUtc: PropTypes.number,
});

export const viewableShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(VIEWABLE_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  banner: PropTypes.string,
  defaultPlayable: playableShape,
  show: showShape,
});
