import React from 'react';
import Icon from './Icon';

export default function CheckIcon(props) {
  return (
    <Icon width="47" height="47" viewBox="0 0 47 47" fill="none" {...props}>
      <circle cx="23.5" cy="23.5" r="22.0052" stroke="currentColor" strokeWidth="2" />
    </Icon>
  );
}
