import React from 'react';
import Link from 'router/Link';
import { Box, PrimaryButton, Text } from 'components';
import VerifyIcon from 'components/Icons/VerifyIcon';

const StepSuccess = () => (
  <Box
    mt="xxlarge"
    flexBox
    flexDirection="column"
    fullWidth
    alignItems="center"
    px="medium"
  >
    <Box
      mb="xlarge"
      md-width="120"
      width="80"
      mt="large"
      md-mt="0"
    >
      <VerifyIcon size="100%" />
    </Box>
    <Text
      bold
      as="h1"
      align="center"
      md-fontSize="giant"
      fontSize="loud"
      mb="large"
      id="deviceAuth.successTitle"
    />
    <Text
      as="h2"
      align="center"
      md-fontSize="loud"
      fontSize="sectionHeading"
      id="deviceAuth.successSubTitle"
    />
    <Box mx="auto" mt="xxxlarge" flexBox justifyContent="center">
      <PrimaryButton as={Link} to={{ name: 'home' }}>
        <Text id="home" />
      </PrimaryButton>
    </Box>
  </Box>
);

export default StepSuccess;
