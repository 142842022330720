import {
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';

import possibleTypes from 'queries/possibleTypes';
import { relayStylePagination } from '@apollo/client/utilities';

const collectionViewables = {
  fields: {
    viewables: relayStylePagination(),
  },
};

export default () => new InMemoryCache({
  possibleTypes,
  dataIdFromObject(responseObject) {
    // set supertype key for subtypes
    for (const type of Object.keys(possibleTypes)) {
      if (possibleTypes[type].includes(responseObject.__typename)) {
        if (responseObject.carouselId) {
          // https://magine.atlassian.net/browse/MDM-22336
          // requires different cache because carousel items has additional fields
          return `Carousel:${type}:${responseObject.id}`;
        }

        return `${type}:${responseObject.id}`;
      }
    }

    return defaultDataIdFromObject(responseObject);
  },
  typePolicies: {
    View: {
      fields: {
        blocks: relayStylePagination(),
      },
    },
    Viewer: {
      fields: {
        search: {
          keyArgs: ['kinds', 'query', 'first'],
          merge(existing, incoming) {
            return {
              ...incoming,
              edges: [
                ...(existing?.edges ?? []),
                ...(incoming.edges ?? []),
              ],
            };
          },
        },
      },
    },
    BookmarksCollection: collectionViewables,
    ContinueWatchingCollection: collectionViewables,
    EntitledContentCollection: collectionViewables,
    FavouriteChannelsCollection: collectionViewables,
    FeaturedCarouselCollection: collectionViewables,
    FeaturedCollection: collectionViewables,
    LiveChannelsCollection: collectionViewables,
    PosterCollection: collectionViewables,
    SixteenNineCollection: collectionViewables,
  },
});
