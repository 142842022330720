import PropTypes from 'prop-types';
import {
  INPUT_FIELD_TYPE,
  MENU_ITEM_TYPES,
  MENU_LINK_TARGET_TYPES,
} from 'utils/constants';

// https://tvoli.github.io/generated/superscription/superscription.html#users__userid__subscription_ge
export const channelShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logoLight: PropTypes.string.isRequired,
  logoDark: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
});

// BASED ON https://github.com/tvoli/pioneer-docs/blob/master/api.raml

export const validationShape = PropTypes.shape({
  pattern: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export const inputFieldShape = PropTypes.shape({
  inputType: PropTypes.oneOf(Object.values(INPUT_FIELD_TYPE)),
  placeholder: PropTypes.string,
  secureEntry: PropTypes.bool,
  key: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validations: PropTypes.arrayOf(validationShape),
});

export const forgotPasswordShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  httpMethod: PropTypes.string.isRequired,
  triggerLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
  inputField: inputFieldShape.isRequired,
  submitLabel: PropTypes.string.isRequired,
});

export const loginMethodShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  httpMethod: PropTypes.string.isRequired,
  doneLabel: PropTypes.string.isRequired,
  inputFields: PropTypes.arrayOf(inputFieldShape).isRequired,
  forgotPassword: forgotPasswordShape,
});

export const welcomeConfigShape = PropTypes.shape({
  label: PropTypes.string,
  footerLabel: PropTypes.string,
  signupLabel: PropTypes.string,
  loginLabel: PropTypes.string,
  footerLink: PropTypes.object,
});

export const menuItemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(MENU_ITEM_TYPES)),
  identifier: PropTypes.string,
  url: PropTypes.string,
  linkTarget: PropTypes.oneOf(Object.values(MENU_LINK_TARGET_TYPES)),
  _id: PropTypes.string,
  trackingEvent: PropTypes.string,
  available: PropTypes.arrayOf(PropTypes.oneOf(['logged-in', 'logged-out', 'open-service'])),
});
