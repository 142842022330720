import createComponent from 'styles/fela/createComponent';
import { CardType } from 'utils/credit-card';

import visaLogo from './images/visa.png';
import mastercardLogo from './images/mastercard.png';
import maestroLogo from './images/maestro.png';
import amexLogo from './images/amex.png';

export const CardLogo = createComponent(({ theme, type }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  borderRadius: theme.radius.default,
  display: 'inline-block',
  height: theme.size.creditCardHeight,
  marginRight: theme.margin.fine,
  verticalAlign: 'middle',
  width: theme.size.creditCardWidth,
  extend: [
    {
      condition: type === CardType.VISA,
      style: {
        backgroundImage: `url(${visaLogo})`,
      },
    },
    {
      condition: type === CardType.MASTERCARD,
      style: {
        backgroundImage: `url(${mastercardLogo})`,
      },
    },
    {
      condition: type === CardType.MAESTRO,
      style: {
        backgroundImage: `url(${maestroLogo})`,
      },
    },
    {
      condition: type === CardType.AMEX,
      style: {
        backgroundImage: `url(${amexLogo})`,
      },
    },
  ],
}), 'div', ['type']);

export const Bullet = createComponent(({ theme }) => ({
  backgroundColor: theme.color.light,
  borderRadius: '50%',
  display: 'inline-block',
  height: '6px',
  marginRight: '1px',
  verticalAlign: 'middle',
  width: '6px',
}));

export const CardWrapper = createComponent(({ theme }) => ({
  background: theme.color.creditCardBackground,
  borderRadius: theme.radius.default,
  color: theme.color.light,
  padding: `${theme.margin.small} ${theme.margin.large}`,
  textAlign: 'left',
  width: theme.size.creditCardWrapperWidth,
}));
