import { ApolloCache } from '@apollo/client';
import { ViewableType } from 'types';

export const removeFromContinueWatchingList = (
  cache: ApolloCache<any>,
  viewable: ViewableType,
  collectionId: string,
): void => {
  cache.modify({
    id: `ContinueWatchingCollection:${collectionId}`,
    fields: {
      viewables(existingViewables = []) {
        const newViewablesEdges = existingViewables.edges
          .filter((edge: { node: { __ref: string } }) => edge.node.__ref !== `ViewableInterface:${viewable.id}`);
        const newViewables = {
          ...existingViewables,
          edges: newViewablesEdges,
        };
        return newViewables;
      },
    },
  });
};
