import React from 'react';
import Icon, { IconWithText } from '../Icon';

const SeekForwardIcon = (props: IconWithText): JSX.Element => {
  const { text, ...otherProps } = props;
  return (
    <Icon {...otherProps}>
      <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
      <path fill="#fff" d="M113.833 89.167c-2.083 0-3.791 1.541-4.083 3.583-2 14.042-15.708 24.333-30.917 20.667-9.375-2.25-16.291-9.459-18.291-18.875C57.167 78.417 69.458 64.167 85 64.167v11.625c0 1.875 2.25 2.791 3.542 1.458l15.791-15.792a2.062 2.062 0 0 0 0-2.958L88.542 42.708C87.25 41.417 85 42.333 85 44.208v11.625c-20.583 0-36.833 18.667-32.667 40 2.5 12.959 12.084 22.917 24.959 25.792 20.125 4.5 38.125-9.167 40.708-27.792.375-2.458-1.667-4.666-4.167-4.666Z" />
      <text fill="#fff" x="50%" y="55%" dominantBaseline="central" textAnchor="middle">{text}</text>
    </Icon>
  );
};

export default SeekForwardIcon;
