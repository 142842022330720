import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFela } from 'react-fela';
import { useIsClientSide, useShareForwardedRef } from 'utils/hooks';
import { ShowEyeIcon, HideEyeIcon } from 'components/Icons';
import {
  InputErrorMessage,
  InputPlaceholder,
  InputWrapper,
  Input,
} from '../Styles';
import {
  ShowHidePassword,
} from './TextFieldStyles';
import { toMessage } from '../utils';

const TextField = React.forwardRef((props, ref) => {
  const {
    className,
    placeholder,
    name,
    type,
    onKeyDown,
    normalize,
    onChange,
    autoFocus,
    disabled,
    error,
    dirty,
    touched,
    isSubmitted,
    value,
    defaultValue,
    autoComplete,
  } = props;

  const inputRef = useShareForwardedRef(ref);
  const isClientSide = useIsClientSide();
  const { renderer } = useFela();
  const [autofill, setAutofill] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const isApproved = !error && dirty && touched;
  const showErrorMessage = error && ((dirty && touched) || isSubmitted);

  // Attempts to fix autofill based on this blog post:
  const onAutoFillStart = renderer.renderKeyframe(() => ({
    // https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
    from: {},
    to: {},
  }));

  const onAutoFillCancel = renderer.renderKeyframe(() => ({
    // this animation needs to be different for fela to not reuse the animation
    // name from `onAutoFillStart`
    '0%': {},
    '100%': {},
  }));

  const handleAnimationStart = ({ animationName }) => {
    if (animationName === onAutoFillStart) {
      setAutofill(true);
    }
    if (animationName === onAutoFillCancel) {
      setAutofill(false);
    }
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      const { length } = inputRef.current.value;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length); // put cursor at the end of the input
    }
  }, []);

  useEffect(() => {
    try {
      // Sometimes the css animation event is triggered before the javascript is initialized.
      // So we add an extra check to see if it's autofilled or not and set that state.
      setAutofill(Boolean(inputRef.current.parentNode.querySelector(':-webkit-autofill')));
    } catch (e) {
      //
    }
  }, []);

  const handleChange = (e) => {
    if (typeof normalize === 'function') {
      let cursor;
      try {
        cursor = e.target.selectionStart;
      } catch (ignore) {
        // ignore failures when not possible to read cursor position
      }

      e.target.value = normalize(e.target.value);

      try {
        // Safari - cursor jumps to the end after modifying target`s value
        // so keeping it at correct position

        e.target.selectionEnd = cursor;
      } catch (ignore) {
        // ignore failures on email/number fields in other browsers
      }
    }

    if (typeof onChange === 'function') {
      return onChange(e);
    }

    return true;
  };

  const isPasswordType = type === 'password';
  const getType = () => (showPassword ? 'text' : type);

  return (
    <InputWrapper className={className}>
      <InputPlaceholder
        isFloating={autofill || isActive || !!value || !!defaultValue}
        isFocused={isActive}
      >
        {placeholder}
      </InputPlaceholder>
      <Input
        onAutoFillStart={onAutoFillStart}
        onAutoFillCancel={onAutoFillCancel}
        innerRef={inputRef}
        rightPadding={isPasswordType}
        isApproved={isApproved}
        type={getType()}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        onAnimationStart={handleAnimationStart}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        clientSide={isClientSide}
      />
      {isPasswordType && (
        <ShowHidePassword onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <HideEyeIcon /> : <ShowEyeIcon />}
        </ShowHidePassword>
      )}
      <InputErrorMessage isVisible={!!showErrorMessage}>
        {toMessage(error)}
      </InputErrorMessage>
    </InputWrapper>
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  dirty: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  normalize: PropTypes.func,
  autoComplete: PropTypes.string,
};

TextField.defaultProps = {
  autoComplete: 'off',
};

export default React.memo(TextField);
