import placeholderPrefixer from 'fela-plugin-placeholder-prefixer';
import webPreset from 'fela-preset-web';
import { createRenderer } from 'fela';
import rtl from 'fela-plugin-rtl';
import sortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';
import typescript from 'fela-plugin-typescript';
import fullscreenPrefixer from 'fela-plugin-fullscreen-prefixer';

const getConfig = (direction) => {
  const config = {
    selectorPrefix: '_',
  };
  const enhancers = [sortMediaQueryMobileFirst()];
  const plugins = [
    ...webPreset,
    placeholderPrefixer(),
    typescript(),
    fullscreenPrefixer(),
  ];

  if (direction === 'rtl') {
    plugins.push(rtl());
  }

  if (__DEVELOPMENT__) {
    // use require instead of import to not include this module in production mode
    const validator = require('fela-plugin-validator').default; // eslint-disable-line global-require
    plugins.push(validator({ logInvalid: true, deleteInvalid: false }));
    config.devMode = true;
  }

  return {
    plugins,
    enhancers,
    ...config,
  };
};

export default function createFelaRenderer(direction) {
  const config = getConfig(direction);
  return createRenderer(config);
}
