import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Arrow,
  ArrowButton,
  ScrollButtonWrapper,
} from './ScrollButtonStyles';

const ScrollButton = ({ isLeft, isRTL, scrollLeft, onScrollLeftUpdate }) => {
  const timer = useRef();
  const scrollStep = useRef(0);
  const scrollLeftRef = useRef(scrollLeft);

  const stopTimer = () => {
    clearInterval(timer.current);
    timer.current = null;
  };

  useEffect(() => () => {
    stopTimer();
  }, []);

  useEffect(() => {
    scrollLeftRef.current = scrollLeft;
  }, [scrollLeft]);

  const scroll = () => {
    const pos = scrollLeftRef.current + (scrollStep.current * (isLeft ? -1 : 1) * (isRTL ? -1 : 1));
    onScrollLeftUpdate(pos);
  };

  const onMouseMove = (e) => {
    // The behaviour implemented is depending on if you
    // are hovering the beginning or the end of the button
    // the EPG will scroll at different speeds.

    const {
      left,
      width,
    } = e.currentTarget.getBoundingClientRect();
    let offsetX = 0;
    if (isLeft) {
      offsetX = width - (e.clientX - left);
    } else {
      offsetX = (e.clientX - left);
    }

    // The button is a circle but only one half-circle
    // is visible to the user (to be able to create
    // the current hover effect).
    const visibleWidth = width / 2;
    const velocity = offsetX / visibleWidth;

    if (velocity < 0.33) {
      scrollStep.current = 10;
    } else if (velocity < 0.66) {
      scrollStep.current = 20;
    } else {
      scrollStep.current = 80;
    }
    if (!timer.current) {
      const fps = 60;
      timer.current = setInterval(scroll, (1000 / fps));
    }
  };

  const arrow = isLeft ? (
    <Arrow isLeft>
      &#10094;
    </Arrow>
  ) : (
    <Arrow>
      &#10095;
    </Arrow>
  );

  return (
    <ScrollButtonWrapper isLeft={isLeft}>
      <ArrowButton
        isLeft={isLeft}
        onMouseMove={onMouseMove}
        onMouseLeave={stopTimer}
      >
        {arrow}
      </ArrowButton>
    </ScrollButtonWrapper>
  );
};

ScrollButton.propTypes = {
  isLeft: PropTypes.bool,
  isRTL: PropTypes.bool,
  scrollLeft: PropTypes.number,
  onScrollLeftUpdate: PropTypes.func.isRequired,
};

export default ScrollButton;
