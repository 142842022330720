import React from 'react';
import Icon, { IconType } from './Icon';

export default function CheckInCircleIcon(props: IconType): JSX.Element {
  return (
    <Icon {...props} width="20" height="20" viewBox="0 0 26 26">
      <path fill="#008905" d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM13 23.4C7.267 23.4 2.6 18.733 2.6 13C2.6 7.267 7.267 2.6 13 2.6C18.733 2.6 23.4 7.267 23.4 13C23.4 18.733 18.733 23.4 13 23.4ZM18.044 8.177L10.4 15.821L7.956 13.377C7.449 12.87 6.63 12.87 6.123 13.377C5.616 13.884 5.616 14.703 6.123 15.21L9.49 18.577C9.997 19.084 10.816 19.084 11.323 18.577L19.89 10.01C20.397 9.503 20.397 8.684 19.89 8.177C19.383 7.67 18.551 7.67 18.044 8.177V8.177Z" />
    </Icon>
  );
}
