import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { PIXELS_PER_MINUTE } from 'utils/broadcast';
import { formatHHmm } from 'utils/human-format';
import { ClientSideComponent } from 'components';

const INDICATORS_PER_HOUR = 12;

const Item = createComponent(({ theme, bottom }) => ({
  width: (60 / INDICATORS_PER_HOUR) * PIXELS_PER_MINUTE,
  flexShrink: '0',

  extend: [
    {
      condition: bottom,
      style: {
        borderTop: `3px solid ${theme.color.divider}`,
      },
    },
    {
      condition: !bottom,
      style: {
        borderBottom: `3px solid ${theme.color.divider}`,
      },
    },
  ],
}), 'div', ['bottom']);

const Line = createComponent(({ theme, bottom, long }) => ({
  borderLeft: `1px solid ${theme.color.secondary}`,
  height: long ? 9 : 6,
  position: 'absolute',

  extend: [
    {
      condition: bottom,
      style: {
        top: '10px',
      },
    },
    {
      condition: !bottom,
      style: {
        bottom: '10px',
      },
    },
  ],
}), 'div', ['bottom', 'long']);

const Label = createComponent(({ theme, bottom }) => ({
  color: theme.color.primary,
  fontSize: theme.fontSize.fine,
  height: '24px',
  lineHeight: '14px',
  textAlign: 'center',
  transform: 'translateX(-50%)',
  position: 'absolute',

  extend: [
    {
      condition: bottom,
      style: {
        top: '24px',
      },
    },
    {
      condition: !bottom,
      style: {
        bottom: '24px',
      },
    },
  ],
}), 'div', ['bottom']);

export const TIMEBAR_HEIGHT = 50;
const Container = createComponent(({ theme }) => ({
  height: TIMEBAR_HEIGHT,
  position: 'relative',
  background: theme.color.page,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'stretch',
}));

const Timebar = ({ startTime, stopTime, bottom }) => {
  const cols = INDICATORS_PER_HOUR * Math.ceil((stopTime - startTime) / (60 * 60));

  const items = Array(cols).fill(0).map((_, colIndex) => {
    const date = startTime + (colIndex * (60 / INDICATORS_PER_HOUR) * 60);
    const hasText = (colIndex % (INDICATORS_PER_HOUR / 2)) === 0;
    return (
      <Item key={date} bottom={bottom}>
        <Line long={hasText} bottom={bottom} />

        {hasText && (
          <Label bottom={bottom}>
            <ClientSideComponent>{formatHHmm(date * 1000)}</ClientSideComponent>
          </Label>
        )}
      </Item>
    );
  });

  return (
    <Container>
      {items}
    </Container>
  );
};

Timebar.propTypes = {
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
  bottom: PropTypes.bool,
};

export default Timebar;
