import React from 'react';
import PropTypes from 'prop-types';
import { SliderWrapper, Slider } from './Styles';

const ToggleSwitch = props => (
  <SliderWrapper onClick={props.onSwitchHandler}>
    <Slider
      checked={props.checked}
      data-cy={`toggle-switch-${props.checked ? 'enabled' : 'disabled'}`}
    />
  </SliderWrapper>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onSwitchHandler: PropTypes.func,
};

export default ToggleSwitch;
