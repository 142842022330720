import createComponent from 'styles/fela/createComponent';

const PanelContent = createComponent(({ theme, centered, width }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: theme.margin.large,
  position: 'relative',
  zIndex: 1,
  extend: [
    {
      condition: centered,
      style: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      },
    },
    {
      condition: width,
      style: {
        width,
      },
    },
  ],
}), 'div', ['centered', 'width']);

PanelContent.displayName = 'PanelContent';

export default PanelContent;
