import * as React from 'react';
import { useFela } from 'react-fela';
import { GlobalTheme } from 'types';
import Timebar from './Timebar';
import TimebarContainer from './TimebarContainer';
import LiveIndicator from './LiveIndicator';
import * as styles from './styles';

interface ITimebarOverlayProps {
  width: number,
  currentTime: number,
  scrollLeft: number,
  startTime: number,
  stopTime: number,
  onClickLive: () => void,
}

const TimebarOverlay: React.FC<ITimebarOverlayProps> = (props) => {
  const {
    currentTime,
    width,
    scrollLeft,
    startTime,
    stopTime,
    onClickLive,
  } = props;

  const { css } = useFela<GlobalTheme>({ direction: 'ltr' });

  return (
    <div className={css(styles.overlayContainerStyle)}>
      <TimebarContainer scrollLeft={scrollLeft}>
        <Timebar startTime={startTime} stopTime={stopTime} />
      </TimebarContainer>

      <LiveIndicator
        startTime={startTime}
        currentTime={currentTime}
        scrollLeft={scrollLeft}
        width={width}
        onClick={onClickLive}
      />

      <TimebarContainer scrollLeft={scrollLeft} bottom>
        <Timebar startTime={startTime} stopTime={stopTime} bottom />
      </TimebarContainer>
    </div>
  );
};

export default TimebarOverlay;
