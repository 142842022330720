import getScrollBarWidth from 'get-scrollbar-width';
import { BrowserType, OSType } from 'utils/constants';

export const UPDATE_PLATFORM_INFO = 'common/UPDATE_PLATFORM_INFO';
type PlatformInfo = {
  os: OSType,
  device: string,
  browser: BrowserType,
  version: string,
  description: string,
  platformLogin?: {
    location: any,
  }
};
interface UpdatePlatformInfoAction {
  type: typeof UPDATE_PLATFORM_INFO,
  payload: PlatformInfo,
}
export const updatePlatformInfo = (payload: PlatformInfo): UpdatePlatformInfoAction => ({
  type: UPDATE_PLATFORM_INFO,
  payload,
});

export const SCROLLBAR_WIDTH = 'common/SCROLLBAR_WIDTH';
interface SetScrollabarWidthAction {
  type: typeof SCROLLBAR_WIDTH,
  payload: {
    scrollBarWidth: number,
  },
}
export const setScrollBarWidth = (): SetScrollabarWidthAction => ({
  type: SCROLLBAR_WIDTH,
  payload: {
    scrollBarWidth: getScrollBarWidth(),
  },
});

export const SET_TIME_ZONE = 'common/SET_TIME_ZONE';
interface SetTimeZoneAction {
  type: typeof SET_TIME_ZONE
  payload: string,
}
export const setTimeZone = (timeZone: string): SetTimeZoneAction => ({
  type: SET_TIME_ZONE,
  payload: timeZone,
});

export type CommonActions = (
  UpdatePlatformInfoAction
  | SetScrollabarWidthAction
  | SetTimeZoneAction
);
