import createComponent from 'styles/fela/createComponent';

const TextArea = createComponent(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '3.5rem',
  borderRadius: theme.radius.default,
  padding: `${theme.margin.small} ${theme.margin.medium}`,
}), 'textarea');

export default TextArea;
