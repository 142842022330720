import * as React from 'react';
import { useFela } from 'react-fela';
import { GlobalTheme } from 'types';
import * as styles from './styles';

interface ITimebarContainerProps {
  bottom?: boolean,
  scrollLeft: number,
}

const TimebarContainer: React.FC<React.PropsWithChildren<ITimebarContainerProps>> = (props) => {
  const { bottom, scrollLeft, children } = props;
  const { css } = useFela<GlobalTheme>({ bottom, scrollLeft });

  return (
    <div className={css(styles.timebarContainerStyle)}>{children}</div>
  );
};

export default TimebarContainer;
