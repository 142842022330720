/* eslint-disable no-restricted-syntax */

export const messages = {
  notBlank: 'validate.notBlank',
  invalidEmail: 'validate.invalidEmail',
  invalidDate: 'validate.invalidDate',
  maxLength: 'validate.maxLength',
  minLength: 'validate.minLength',
};

function applyValidators(validators, value, values) {
  for (const validator of validators) {
    const isValid = validator.validate(value, values);
    if (!isValid) {
      return validator.message;
    }
  }

  return null;
}

function validate(validations) {
  const fields = Object.keys(validations);

  return (values) => {
    const errors = {};

    // eslint-disable-next-line no-unused-vars
    for (const field of fields) {
      errors[field] = applyValidators(validations[field], values[field], values);
    }

    return errors;
  };
}

validate.required = (id = messages.notBlank) => ({
  validate: (val) => {
    if (typeof val === 'string' && val.trim()) {
      return true;
    }

    if (val) {
      return true;
    }

    return false;
  },
  message: { id },
});

const emailRegex = /^\S+@\S+[.]\S+$/;
validate.email = (id = messages.invalidEmail) => ({
  validate: val => emailRegex.test(val),
  message: {
    id,
  },
});

validate.date = (id = messages.invalidDate) => ({
  validate: (val) => {
    if (!val) {
      return false;
    }

    const [year, month, day] = val.split('-').map(item => parseInt(item || '0', 10));

    // sensible defaults
    if (year < 1900 || year > 2100) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }

    if (day < 1 || day > 31) {
      return false;
    }

    // checking of Date is a Correct Date
    const d = new Date(year, month - 1, day);
    if (d.getFullYear() !== year || d.getMonth() !== month - 1 || d.getDate() !== day) {
      return false;
    }

    return true;
  },
  message: {
    id,
  },
});

validate.minLength = (minLength, id = messages.minLength) => {
  if (minLength == null) {
    throw new Error('minLength validator: minimum length must be provided');
  }

  return {
    validate: val => val?.length >= minLength,
    message: {
      id,
      values: {
        minLength,
      },
    },
  };
};

validate.maxLength = (maxLength, id = messages.maxLength) => {
  if (maxLength == null) {
    throw new Error('maxLength validator: maximum length must be provided');
  }

  return {
    validate: val => val?.length <= maxLength,
    message: {
      id,
      values: {
        maxLength,
      },
    },
  };
};

// Example:
//
// validate({
//   password: [validate.required()],
//   passwordConfirm: [validate.required(), validate.same('password', `Passwords don't match`)],
// })
validate.same = (field, id) => ({
  validate: (value, values) => {
    if (!id) {
      throw new Error('same validator: translation message id must be provided');
    }
    return value === values[field];
  },
  message: { id },
});

validate.inputFieldValidations = validations => ({
  validate: (value) => {
    // eslint-disable-next-line no-unused-vars
    for (const validation of validations) {
      const regex = new RegExp(validation.pattern);
      if (!regex.test(value)) {
        return validation.message;
      }
    }
    return null;
  },
});

export default validate;
