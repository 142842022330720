import * as React from 'react';
import { useSelector } from 'reducers';
import { Text, Link, Box } from 'components';

const RecaptchaDisclaimer = (): JSX.Element | null => {
  const captchaEnabled = useSelector(state => !!state.settings.features.captcha);

  if (!captchaEnabled) return null;

  return (
    <Box row mb="large" justifyContent="center">
      <Text
        as="p"
        fontSize="fine"
        id="recaptcha.disclaimer"
        values={{
          privacyPolicy: (
            <Link
              href="https://policies.google.com/privacy"
              target="_blank"
              key="privacyPolicy"
              id="recaptcha.privacyPolicy"
            />
          ),
          tos: (
            <Link
              href="https://policies.google.com/terms"
              target="_blank"
              key="termsOfService"
              id="recaptcha.termsOfService"
            />
          ),
        }}
      />
    </Box>
  );
};

export default RecaptchaDisclaimer;
