import React, { useEffect, useState, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useQuery } from '@apollo/client';
import {
  ClickOutside,
} from 'components';
import { HamburgerIcon } from 'components/Icons';
import { useIsSmallScreen, useWindowWidth } from 'utils/hooks';
import * as uiActions from 'actions/ui';
import TopNavigation from './TopNavigation';

import HEADER_QUERY from './headerQuery.gql';
import {
  HeaderContainer,
  HeaderSpacer,
  Content,
  LogoLink,
  Hamburger,
  LogoImage,
  MenuNavigationWrapper,
} from './Styles';

// px, the rounding while zooming in Chrome could be pretty imprecise
const VISIBILITY_THRESHOLD = 2;

function Header() {
  const [scrollStarted, setScrollStarted] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const navRef = useRef(null);
  const logoRef = useRef(null);
  const contentRef = useRef(null);
  const windowWidth = useWindowWidth();
  const isSmallScreen = useIsSmallScreen();
  const dispatch = useDispatch();
  const { data, refetch } = useQuery(HEADER_QUERY);

  const {
    brandName,
    headerLogo,
    headerTransparencySupported,
    headerOpacity,
    isMobileOS,
    paymentMandatory,
    entitledPacks,
    userLoggedIn,
    horizontalMenuHidden,
  } = useSelector(state => ({
    brandName: state.settings.brand.name,
    headerLogo: state.settings.brand.headerLogo,
    headerTransparencySupported: state.ui.headerTransparency[
      `${state.router.location.name}:${state.router.location.name === 'home'
        ? state.settings.navigation.menu[state.settings.navigation.defaultIndex].identifier
        : state.router.location.params.id}`
    ],
    headerOpacity: state.settings.headerOpacity,
    isMobileOS: state.common.isMobileOS,
    entitledPacks: state.packs.entitled,
    userLoggedIn: state.auth.isLoggedIn,
    paymentMandatory: state.auth.isLoggedIn
      && state.settings.features.payment?.onboarding?.mandatory,
    horizontalMenuHidden: state.ui.horizontalMenuHidden,
  }), shallowEqual);

  useEffect(() => {
    let unmounted = false;
    const onScroll = () => {
      if (unmounted) {
        return;
      }

      setScrollStarted(window.scrollY > 10);
    };

    const onScrollDebounced = debounce(onScroll, 10);

    window.addEventListener('scroll', onScrollDebounced);

    return () => {
      unmounted = true;
      window.removeEventListener('scroll', onScrollDebounced);
    };
  }, []);

  useEffect(() => {
    if (windowWidth) {
      const styles = window.getComputedStyle(contentRef.current);
      const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
      const freeSpace = contentRef.current?.clientWidth - padding
        - (logoRef.current?.clientWidth ?? 0);

      const hideMenu = (navRef.current?.clientWidth - freeSpace) > VISIBILITY_THRESHOLD;

      dispatch(uiActions.hideHorizontalMenu(hideMenu || isSmallScreen || isMobileOS));
    }
  }, [windowWidth, isSmallScreen, isMobileOS]);

  useEffect(() => {
    if (!userLoggedIn) return;
    refetch();
  }, [userLoggedIn, entitledPacks.length]);

  const closeMenu = () => setMenuExpanded(false);
  const menuToggle = () => setMenuExpanded(prevState => !prevState);

  // see https://magine.atlassian.net/browse/MDM-10551
  const headerContainerOpacity = useMemo(() => {
    let beforeScroll = {
      gradientStart: headerOpacity.regular,
      gradientEnd: headerOpacity.regular,
    };
    let afterScroll = headerOpacity.regular;
    if (headerTransparencySupported && !menuExpanded) {
      beforeScroll = {
        gradientStart: headerOpacity.transparentStart,
        gradientEnd: headerOpacity.transparent,
      };
    } else if (menuExpanded && horizontalMenuHidden) {
      // set full opacity for header if is mobile view and menu is expanded
      afterScroll = 100;
      beforeScroll = {
        gradientStart: 100,
        gradientEnd: 100,
      };
    }

    return { beforeScroll, afterScroll };
  }, [headerTransparencySupported, menuExpanded, horizontalMenuHidden]);

  const hideItemsForUnentitled = useMemo(
    () => paymentMandatory && !data?.viewer.entitlements.edges.length,
    [data?.viewer.entitlements],
  );

  return (
    <>
      <HeaderContainer
        opacity={headerContainerOpacity}
        scroll={`${scrollStarted}`}
      >
        <ClickOutside wrapperRef={contentRef} onClickOutside={menuExpanded ? closeMenu : null}>
          <Content onClick={menuExpanded ? closeMenu : null} innerRef={contentRef}>
            <div ref={logoRef}>
              <LogoLink to={{ name: 'home' }}>
                <LogoImage
                  src={headerLogo}
                  alt={brandName}
                />
              </LogoLink>
            </div>
            <Hamburger
              onClick={menuToggle}
              visible={horizontalMenuHidden}
            >
              <HamburgerIcon />
            </Hamburger>
            <MenuNavigationWrapper offCanvas={horizontalMenuHidden}>
              <TopNavigation
                position="horizontal"
                innerRef={navRef}
                hideItemsForUnentitled={hideItemsForUnentitled}
                isOffCanvas={horizontalMenuHidden}
              />
            </MenuNavigationWrapper>
            <TopNavigation
              position="vertical"
              isExpanded={menuExpanded && horizontalMenuHidden}
              hideItemsForUnentitled={hideItemsForUnentitled}
            />
          </Content>
        </ClickOutside>
      </HeaderContainer>

      {!headerTransparencySupported && (
        <HeaderSpacer />
      )}
    </>
  );
}

export default React.memo(Header);
