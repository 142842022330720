import * as React from 'react';
import {
  Box,
  Text,
  Hr,
} from 'components';
import { RadioButton } from 'components/Form';
import { useI18n } from 'components/I18n';
import { useSelector } from 'reducers';
import { PAYMENT_PROVIDERS } from 'utils/constants';
import { BillingPaymentMethod, PaymentMethods } from 'types';

export enum PaymentOptions {
  EXISTING = 'existing',
  NEW = 'new',
}

type PaymentOptionSelectionProps = {
  fullWidth?: boolean,
  selected: PaymentOptions,
  onChange: React.Dispatch<React.SetStateAction<PaymentOptions>>,
};

const PaymentOptionSelection = (
  {
    fullWidth,
    selected,
    onChange,
  }: PaymentOptionSelectionProps,
) => {
  const i18n = useI18n();
  const paymentMethod = useSelector(({ billing }) => {
    if (!billing.paymentMethod?.data) return null;
    return billing.paymentMethod;
  });
  const providerType = useSelector(
    ({ settings }) => settings.features.payment?.provider?.type,
  );

  React.useEffect(() => {
    onChange(paymentMethod ? PaymentOptions.EXISTING : PaymentOptions.NEW);
  }, [paymentMethod]);

  if (!paymentMethod || providerType !== PAYMENT_PROVIDERS.STRIPE) {
    return null;
  }

  const getPayWithExistingLabel = (billingPaymentMethod: BillingPaymentMethod) => {
    switch (billingPaymentMethod.method) {
      case PaymentMethods.PAYPAL: {
        return i18n.formatText('payment.payWithExistingPaypal');
      }
      case PaymentMethods.CREDITCARD: {
        return i18n.formatText('payment.payWithExistingCardWithNum', {
          lastGroup: billingPaymentMethod.data?.ccMaskedNumber,
        });
      }
      default: {
        return i18n.formatText('payment.payWithExistingCardWithNum', {
          lastGroup: undefined,
        });
      }
    }
  };

  return (
    <>
      <Hr height={2} />
      <Box mb="large" mt="large">
        <Text
          id="payment.selectPaymentOption"
          fontSize="medium"
          bold
        />
      </Box>
      <Box
        column
        fullWidth={fullWidth}
        alignItems="start"
        mx="auto"
        display="inline-flex"
      >
        <Box mb="small">
          <RadioButton
            label={getPayWithExistingLabel(paymentMethod)}
            checked={selected === PaymentOptions.EXISTING}
            onChange={() => onChange(PaymentOptions.EXISTING)}
          />
        </Box>
        <Box>
          <RadioButton
            label={i18n.formatText('payment.payWithNewCard')}
            checked={selected === PaymentOptions.NEW}
            onChange={() => onChange(PaymentOptions.NEW)}
          />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(PaymentOptionSelection);
