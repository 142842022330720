import React from 'react';
import Icon, { IconType } from '../Icon';

const VolumeDownIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
    <path fill="#fff" d="M57 77.142v16.666a4.179 4.179 0 0 0 4.167 4.167h12.5l13.708 13.708c2.625 2.625 7.125.75 7.125-2.958V62.183c0-3.708-4.5-5.583-7.125-2.958l-13.708 13.75h-12.5A4.179 4.179 0 0 0 57 77.142Zm56.25 8.333c0-7.375-4.25-13.708-10.417-16.792v33.542c6.167-3.042 10.417-9.375 10.417-16.75Z" />
  </Icon>
);

export default VolumeDownIcon;
