import UniversalRouter, { Route as UniversalRouterRoute } from 'universal-router';
import generateUrls from 'universal-router/generateUrls';
import * as queryString from 'query-string';
import { normalizeQueryParams } from './utils';
import { Query, RouterLocation } from './location';

type HookArgs = {
  location: RouterLocation,
  route: Route,
  previousLocation?: RouterLocation,
  previousRoute?: Route,
};

type RedirectLocation = (RouterLocation & { reload?: boolean }) | string;
type OnEnterHook = (args: HookArgs) => RedirectLocation | undefined;

type OnNavigationHook = (args: HookArgs) => RouterLocation | undefined;

type Route = UniversalRouterRoute & {
  onEnter: OnEnterHook[],
  onNavigation: OnNavigationHook[],

  init?(location: RouterLocation): Promise<void>,
  component: React.ComponentType,
  couldBeInitialView: boolean,
};

type ResolveResult = {
  error: Error,
} | {
  redirectLocation: RedirectLocation,
} | {
  route: Route,
  location: RouterLocation,
};

export class Router {
  private _activeRoute?: Route;
  private _activeLocation?: RouterLocation;
  private _routes: Route[] = [];

  private _router: UniversalRouter;
  private _url: ReturnType<typeof generateUrls>;

  constructor(private _baseUrl: string) {
    this._router = new UniversalRouter(this._routes, {
      resolveRoute({ route, params, query }) {
        if (!route.name) {
          return undefined;
        }

        const location = {
          name: route.name,
          params: {
            ...params,
          },
          query: {
            ...query,
          },
        };

        return { route, location };
      },
    });

    this._url = generateUrls(this._router);
  }

  setRoutes(newRoutes: Route[]): void {
    this._routes.length = 0;
    this._routes.push(...newRoutes);
  }

  getUrl({ name, params, query }: RouterLocation, absolute = true): string {
    const search = queryString.stringify(normalizeQueryParams(query));

    // in case of not-found params[0] would contain relative path
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const path = name === 'not-found' ? params![0] : this._url(name, params);

    return (absolute ? this._baseUrl : '') + path + (search ? `?${search}` : '');
  }

  getActiveRoute(): Route | undefined {
    return this._activeRoute;
  }

  getActiveLocation(): RouterLocation | undefined {
    return this._activeLocation;
  }

  getRouteByName(name: string): Route | undefined {
    return this._routes.find(route => route.name === name);
  }

  getBaseUrl(): string {
    return this._baseUrl;
  }

  async resolve(pathname: string, query: Query): Promise<ResolveResult> {
    try {
      const {
        route,
        location,
      }: {
        route: Route,
        location: RouterLocation,
      } = await this._router.resolve({ pathname, query });

      for (const onEnter of route.onEnter) {
        // eslint-disable-next-line no-await-in-loop
        const redirectLocation = await Promise.resolve(onEnter({
          location,
          route,
          previousLocation: this._activeLocation,
          previousRoute: this._activeRoute,
        }));

        if (redirectLocation) {
          return {
            redirectLocation, // { name, params, query, reload? } | string
          };
        }
      }

      this._activeRoute = route;
      this._activeLocation = location;

      return {
        route,
        location,
      };
    } catch (error: any) {
      return {
        error,
      };
    }
  }
}
