import React from 'react';
import IntlMessageFormat from 'intl-messageformat';
import { format } from 'date-fns';
import logger from 'utils/logger';
import { hashCode } from 'utils/helpers';

const MANUALLY_FORMATTED_CURRENCIES = ['XOF', 'XAF']; // see MDM-13921

const CURRENCY_DISPLAY = { DKK: 'code' }; // see MDM-17248

// based on https://en.wikipedia.org/wiki/List_of_circulating_currencies
const NUMBER_TO_BASIC = {
  1000: ['BHD', 'IQD', 'KWD', 'LYD', 'OMR', 'TND'],
  10: ['VND'],
  5: ['MGA', 'MRU'],
};

const getNumberToBasic = (currency) => {
  if (NUMBER_TO_BASIC['1000'].includes(currency)) {
    return 1000;
  }

  if (NUMBER_TO_BASIC['10'].includes(currency)) {
    return 10;
  }

  if (NUMBER_TO_BASIC['5'].includes(currency)) {
    return 5;
  }

  return 100;
};

export class I18n {
  constructor(language, copy, dateFnsLocale) {
    this.language = language;
    this.copy = copy;
    this.dateFnsLocale = dateFnsLocale;
    this._cache = {};
  }

  _getMessage(idOrCopy) {
    const msgFromCache = this._cache[hashCode(idOrCopy)];
    if (msgFromCache) {
      return msgFromCache;
    }

    let template = this.copy[idOrCopy];
    if (template === undefined) {
      template = idOrCopy;
      logger.warn(`I18nProvider: can't find copy with id ${idOrCopy}, using copy id as a fallback`);
    }

    const msg = new IntlMessageFormat(template, this.language);
    this._cache[hashCode(idOrCopy)] = msg;

    return msg;
  }

  hasMessage(id) {
    return !!this.copy[id];
  }

  formatText(idOrCopy, values) {
    try {
      return this._getMessage(idOrCopy).format(values);
    } catch (e) {
      logger.warn(`I18nProvider: failed to format copy with id "${idOrCopy}"`, e.toString());
    }

    return '';
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
  formatDate(value, options) {
    if (!value) {
      logger.warn('I18nProvider.formatDate: value is mandatory for date messages');
      return null;
    }

    try {
      const formatter = new Intl.DateTimeFormat(this.language, options);
      const dateToFormat = typeof value === 'string' ? new Date(value) : value;

      return formatter.format(dateToFormat);
    } catch (e) {
      logger.warn(`I18nProvider: failed to format date ${typeof value}:${value}`, e);
    }

    return null;
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  formatNumber(value, options) {
    const formatter = new Intl.NumberFormat(this.language, options);

    return formatter.format(value);
  }

  formatCurrency(price, currency) {
    if (MANUALLY_FORMATTED_CURRENCIES.includes(currency)) {
      return `${price} ${currency}`;
    }

    return this.formatNumber(
      price / getNumberToBasic(currency),
      {
        style: 'currency',
        currency,
        ...(CURRENCY_DISPLAY[currency] ? { currencyDisplay: CURRENCY_DISPLAY[currency] } : null),
      },
    );
  }

  formatDateWithFormat(value, formatStr) {
    return format(value, formatStr, {
      locale: this.dateFnsLocale,
    });
  }
}

export const i18nFallback = new I18n('en-fallback', {});

export const I18nContext = React.createContext(i18nFallback);
