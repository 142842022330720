import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TRemoveFromContinueWatchingMutationVariables = Types.Exact<{
  viewableId: Types.Scalars['String'];
}>;


export type TRemoveFromContinueWatchingMutation = { __typename?: 'Mutation', archiveContinueWatching?: { __typename?: 'ArchiveContinueWatchingPayload', viewableId?: string | null } | null };


export const RemoveFromContinueWatchingDocument = gql`
    mutation removeFromContinueWatching($viewableId: String!) {
  archiveContinueWatching(input: {viewableId: $viewableId, clientMutationId: ""}) {
    viewableId
  }
}
    `;
export type TRemoveFromContinueWatchingMutationFn = Apollo.MutationFunction<TRemoveFromContinueWatchingMutation, TRemoveFromContinueWatchingMutationVariables>;

/**
 * __useRemoveFromContinueWatchingMutation__
 *
 * To run a mutation, you first call `useRemoveFromContinueWatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromContinueWatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromContinueWatchingMutation, { data, loading, error }] = useRemoveFromContinueWatchingMutation({
 *   variables: {
 *      viewableId: // value for 'viewableId'
 *   },
 * });
 */
export function useRemoveFromContinueWatchingMutation(baseOptions?: Apollo.MutationHookOptions<TRemoveFromContinueWatchingMutation, TRemoveFromContinueWatchingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TRemoveFromContinueWatchingMutation, TRemoveFromContinueWatchingMutationVariables>(RemoveFromContinueWatchingDocument, options);
      }
export type RemoveFromContinueWatchingMutationHookResult = ReturnType<typeof useRemoveFromContinueWatchingMutation>;
export type RemoveFromContinueWatchingMutationResult = Apollo.MutationResult<TRemoveFromContinueWatchingMutation>;
export type RemoveFromContinueWatchingMutationOptions = Apollo.BaseMutationOptions<TRemoveFromContinueWatchingMutation, TRemoveFromContinueWatchingMutationVariables>;