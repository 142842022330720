import { addResizerParams } from 'utils/helpers';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

export const minWidth = (width, content) => ({
  [`@media screen and (min-width: ${width}px)`]: content,
});
export const maxWidth = (width, content) => ({
  [`@media screen and (max-width: ${width}px)`]: content,
});
export const widthRange = (from, to, content) => ({
  [`@media screen and (max-width: ${to}px) and (min-width: ${from}px)`]: content,
});
export const maxWidthOrientation = (width, orientation, content) => ({
  [`@media screen and (max-width: ${width}px) and (orientation: ${orientation})`]: content,
});
export const minAspectRatio = (aspectRatio, content) => ({
  [`@media screen and (min-aspect-ratio: ${aspectRatio})`]: content,
});

export const smallScreen = content => maxWidth(BREAKPOINTS.sm - 1, content);
export const smallScreenL = content => maxWidthOrientation(BREAKPOINTS.md, 'landscape', content);
export const responsiveSm = content => minWidth(BREAKPOINTS.sm, content);
export const responsiveMd = content => minWidth(BREAKPOINTS.md, content);
export const responsiveLg = content => minWidth(BREAKPOINTS.lg, content);
export const responsiveXl = content => minWidth(BREAKPOINTS.xl, content);

export const ieInputHideClearIcon = () => ({
  '::-ms-clear': {
    display: 'none',
    width: '0',
    height: '0',
  },
});

export const ieOnly = content => ({
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': content,
});

export const withSpinning = (duration, clockwise = true, iterationCount = 'infinite') => ({
  animationName: {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: `rotate(${clockwise ? '+' : '-'}359deg)`,
    },
  },
  animationDuration: `${duration}ms`,
  animationIterationCount: iterationCount,
  animationTimingFunction: 'linear',
});

export const withPulsing = duration => ({
  animationName: {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.4 },
    '100%': { opacity: 1 },
  },
  animationDuration: `${duration}ms`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
});

export const truncateLines = (lines, fontSize, lineHeight) => ({
  fontSize,
  lineHeight,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WordWrap: 'break-word',
  OverflowWrap: 'break-word',
  transition: 'max-height 0.1s',

  maxHeight: `calc(${Number.isFinite(lineHeight) ? `${fontSize} * ${lineHeight}` : lineHeight} * ${lines})`,

  // works in 94% of browsers as of Jan'20 https://caniuse.com/#feat=css-line-clamp
  '@supports (-webkit-line-clamp: 1)': {
    visibility: 'visible',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
  },
});

// make element have same width when its content gets bold
export const reserveBoldTextWidth = (textAttr = 'data-content') => ({
  '::after': {
    display: 'block',
    content: `attr(${textAttr})`,
    fontWeight: 'bold',
    height: 0,
    visibility: 'hidden',
    overflow: 'hidden',
  },
});

export const withOverlay = (color, zIndex = -1) => ({
  '::before': {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
    background: color,
    zIndex,
  },
});

export const withBackgroundResizer = ({ src, frontLayer = '', width, height }) => {
  const makeStyleForBreakpoint = (breakpoint, crop) => {
    if (!src || src === 'none') return 'none';
    const url = `url(${addResizerParams({ src, breakpoint, crop, minWidth: width, minHeight: height })})`;
    return frontLayer ? `${frontLayer}, ${url}` : url;
  };

  return {
    ...smallScreen({ backgroundImage: makeStyleForBreakpoint('sm', true) }),
    ...responsiveSm({ backgroundImage: makeStyleForBreakpoint('md') }),
    ...responsiveMd({ backgroundImage: makeStyleForBreakpoint('lg') }),
    ...responsiveLg({ backgroundImage: makeStyleForBreakpoint('xl') }),
  };
};

export const withBasePadding = (props = { left: true, right: true }) => {
  const { left = true, right = true } = props;
  return {
    ...maxWidth(BREAKPOINTS.sm, {
      ...left && { paddingLeft: BASE_MARGIN.small },
      ...right && { paddingRight: BASE_MARGIN.small },
    }),
    ...widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      ...left && { paddingLeft: BASE_MARGIN.medium },
      ...right && { paddingRight: BASE_MARGIN.medium },
    }),
    ...minWidth(BREAKPOINTS.md, {
      ...left && { paddingLeft: BASE_MARGIN.large },
      ...right && { paddingRight: BASE_MARGIN.large },
    }),
  };
};
