import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { I18nContext } from 'components/I18n';
import { Text } from 'components';
import { DATE_FORMAT, TWENTY_FOUR_HOURS, TITLE_TYPES } from './constants';

export default function UpcomingTitle({ startTime, type, ...otherProps }) {
  const i18context = useContext(I18nContext);
  const currentTime = Date.now();

  if (currentTime > startTime) return null;

  const whenFormat = startTime - currentTime > TWENTY_FOUR_HOURS
    ? DATE_FORMAT[type]
    : 'HH:mm';

  return (
    <Text
      italic
      bold
      id="upcoming.coming"
      values={{
        when: i18context.formatDateWithFormat(startTime, whenFormat),
      }}
      {...otherProps}
    />
  );
}

UpcomingTitle.propTypes = {
  startTime: PropTypes.number.isRequired,
  type: PropTypes.oneOf([TITLE_TYPES.HERO, TITLE_TYPES.POSTER]),
};

UpcomingTitle.defaultProps = {
  type: TITLE_TYPES.POSTER,
};
