import React from 'react';
import Icon from './Icon';

export default function CircleCheckIcon(props) {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <path d="M14 0.666664C6.63999 0.666664 0.666656 6.64 0.666656 14C0.666656 21.36 6.63999 27.3333 14 27.3333C21.36 27.3333 27.3333 21.36 27.3333 14C27.3333 6.64 21.36 0.666664 14 0.666664ZM10.3867 19.72L5.59999 14.9333C5.07999 14.4133 5.07999 13.5733 5.59999 13.0533C6.11999 12.5333 6.95999 12.5333 7.47999 13.0533L11.3333 16.8933L20.5067 7.72C21.0267 7.2 21.8667 7.2 22.3867 7.72C22.9067 8.24 22.9067 9.08 22.3867 9.6L12.2667 19.72C11.76 20.24 10.9067 20.24 10.3867 19.72Z" />
    </Icon>
  );
}
