import React from 'react';
import {
  Price,
  Box,
  Text,
  TextDate,
} from 'components';
import { Usps } from 'components/Payment';
import { discountShape, entitledPackShape } from 'reducers/packs';
import { EntitlementStatus, DATE_FORMAT } from 'utils/constants';

function Discount(props) {
  const {
    pack: {
      currency,
      status,
      grossPrice: fullPrice,
      netPrice: discountedPrice,
      recurringPeriod,
    }, discount,
  } = props;

  const {
    discount: {
      discountCycles,
    },
  } = discount;

  let priceDetails = (
    <Text
      id="discount.discountText"
      values={{
        originalPrice: (
          <Price key="originalPrice" price={fullPrice} currency={currency} />
        ),
        discountedPrice: (
          <Price key="discountedPrice" price={discountedPrice} currency={currency} />
        ),
      }}
    />
  );

  if (discountCycles) {
    priceDetails = (
      <Text
        id="discount.discountTextWithPeriod"
        values={{
          period: recurringPeriod,
          originalPrice: (
            <Price key="originalPrice" price={fullPrice} currency={currency} />
          ),
          discountedPrice: (
            <Price key="discountedPrice" price={discountedPrice} currency={currency} />
          ),
          discountCycles,
        }}
      />
    );
  }

  return (
    <>
      <Box mb="fine">
        <Text
          id="subscription.promoSectionHeading"
          values={{ promotionCount: 1 }}
          bold
        />
      </Box>

      <Box mb="fine">
        <Text>
          {discount.discount.name}
        </Text>
      </Box>

      <Text color="secondary">
        {discount.discount.prefix}
      </Text>

      <Box m="fine">
        <Usps
          usps={discount.discount.bulletPoints}
          type="bullet"
          size="small"
        />
      </Box>

      {/**
       Temporarily hide price when the pack is cancelled, so we don't show
       incorrect price for offers partners.
       Fixme: to be removed when MDM-9165 is fixed from the backend if the
       `netPrice` for cancelled packages is no longer 0.
       */}
      {status !== EntitlementStatus.cancelled && (
        <Box my="fine">
          {priceDetails}
        </Box>
      )}

      {discount.endDate ? (
        <Box my="fine">
          <Text
            fontSize="small"
            color="secondary"
            id="subscription.endsOn"
            values={{
              date: <TextDate key="endDate" value={discount.endDate} options={DATE_FORMAT} />,
              period: recurringPeriod,
            }}
          />
        </Box>
      ) : ''}
    </>
  );
}

Discount.propTypes = {
  pack: entitledPackShape.isRequired,
  discount: discountShape.isRequired,
};

export default React.memo(Discount);
