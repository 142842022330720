import React from 'react';
import { viewableShape } from 'types/viewableShape';
import { Image, Box, ChannelLogo } from 'components';

export default function ViewableImage({ viewable }) {
  const channel = viewable.defaultPlayable?.channel || viewable;

  return (
    <Box position="relative">
      <Image
        type="detailsPoster"
        src={viewable.poster || viewable.banner}
        alt={viewable.title}
        rounded="default"
        fullWidth
      />
      {!!channel.logoDark && !viewable.poster && (
        <Box position="absolute" right="15px" bottom="15px">
          <ChannelLogo
            alt={channel.title}
            src={channel.logoDark}
            withBackground
            size="4rem"
          />
        </Box>
      )}
    </Box>
  );
}

ViewableImage.propTypes = {
  viewable: viewableShape.isRequired,
};
