import createComponent from 'styles/fela/createComponent';

export const ShowHidePassword = createComponent(({ theme }) => ({
  color: theme.color.brand,
  cursor: 'pointer',
  position: 'absolute',
  right: '5px',
  marginTop: '0.4rem',
  top: theme.margin.large,
}), 'div');
