import React from 'react';
import PropTypes from 'prop-types';
import {
  SEARCH_FILTER_KINDS,
} from 'utils/constants';
import { withSeparator } from 'utils/helpers';
import {
  Box,
  Text,
  Spacer,
} from 'components';

const FILTER_KINDS = [
  SEARCH_FILTER_KINDS.all,
  SEARCH_FILTER_KINDS.shows,
  SEARCH_FILTER_KINDS.movies,
  SEARCH_FILTER_KINDS.programs,
];

export default function KindFilter(props) {
  const { searchFilterKinds, onClick, searchResultCounts } = props;

  const items = FILTER_KINDS
    .filter(kind => kind === SEARCH_FILTER_KINDS.all || searchResultCounts[kind] > 0)
    .map((kind) => {
      const isSelected = searchFilterKinds.length > 0
        ? searchFilterKinds.includes(kind)
        : kind === SEARCH_FILTER_KINDS.all;

      return (
        <Box
          key={kind}
          pb="2px"
          borderBottom={isSelected ? 'searchKindActive' : undefined}
        >
          <Text
            id={`search.kind.${kind}`}
            onClick={() => onClick(kind)}
            color="brand"
            cursor="pointer"
            bold={isSelected}
            boldWidthForTextInAttr="data-content"
            withDataContent
          />
        </Box>
      );
    });

  return (
    <Box row alignItems="center">
      <Box pb="4px">
        <Text
          id="search.kindFilter"
          color="secondary"
        />
      </Box>

      <Spacer width="small" />

      {withSeparator(items, item => (
        <Box
          key={`separator-${item.key}`}
          mx="fine"
          width="1px"
          height="40%"
          bg="secondary"
        >
          &nbsp;
        </Box>
      ))}
    </Box>
  );
}
KindFilter.propTypes = {
  searchFilterKinds: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchResultCounts: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
