import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import storage from 'utils/storage';
import { Notification, Text } from 'components';

export default function IEDeprecationNotification() {
  const [isVisible, setIsVisible] = useState(false);
  const browserName = useSelector(state => state.common.browser);

  const handleClose = () => {
    storage.setItem('ieDeprecationNotificationShown', 'true');
    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(browserName === 'IE' && !storage.getItem('ieDeprecationNotificationShown'));
  }, [browserName]);

  if (!isVisible) return null;

  return (
    <Notification onClose={handleClose}>
      <Text id="platform.useSupportedBrowser" />
    </Notification>
  );
}
