import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { SearchIcon } from 'components/Icons';
import createComponent from 'styles/fela/createComponent';
import { I18nContext } from 'components/I18n';
import { Placeholder } from 'components/SearchInput/Styles';
import { MenuLink } from './Styles';

export const StyledMenuLink = createComponent(() => ({
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
}), MenuLink, []);

function SearchLink({ onClick, iconSize }) {
  const searchMenu = useSelector(state => state.settings.searchMenu);
  const label = searchMenu.title;
  const labelRef = useRef();
  const i18nContext = useContext(I18nContext);

  const {
    horizontalMenuHidden,
  } = useSelector(state => ({
    horizontalMenuHidden: state.ui.horizontalMenuHidden,
  }), shallowEqual);

  if (horizontalMenuHidden) {
    return (
      <MenuLink
        onClick={onClick}
        to={{ name: 'search' }}
        data-content={label}
      >
        {label}
      </MenuLink>
    );
  }

  return (
    <StyledMenuLink
      onClick={onClick}
      to={{ name: 'search' }}
      data-content={label}
    >
      <SearchIcon size={iconSize} />
      <Placeholder
        innerRef={labelRef}
        aria-hidden="true"
        style={{
          // prevent font from increasing when mouseover
          fontWeight: 'normal',
          // 28px search icon + 14px close icon - 40px padding from parent
          marginLeft: 'calc(28px + 14px - 40px)',
        }}
      >
        {i18nContext.formatText('search')}
      </Placeholder>
    </StyledMenuLink>
  );
}
SearchLink.propTypes = {
  onClick: PropTypes.func,
  iconSize: PropTypes.number,
};

export default React.memo(SearchLink);
