import React from 'react';
import Icon, { IconType } from '../Icon';

const PlayIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <circle cx="85" cy="85" r="85" fill="#000" opacity=".4" />
    <path fill="#fff" d="M66 59.86v50.28c0 3.834 4.222 6.164 7.474 4.077l39.506-25.14c3.009-1.893 3.009-6.261 0-8.202L73.474 55.783C70.222 53.696 66 56.026 66 59.86Z" />
  </Icon>
);

export default PlayIcon;
