import React from 'react';
import Icon, { IconType } from '../Icon';

const FullscreenInIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
    <path fill="#fff" d="M60 93.333a4.179 4.179 0 0 0-4.167 4.167V110A4.179 4.179 0 0 0 60 114.167h12.5A4.179 4.179 0 0 0 76.667 110a4.179 4.179 0 0 0-4.167-4.167h-8.333V97.5A4.179 4.179 0 0 0 60 93.333Zm0-16.666a4.179 4.179 0 0 0 4.167-4.167v-8.333H72.5A4.179 4.179 0 0 0 76.667 60a4.179 4.179 0 0 0-4.167-4.167H60A4.179 4.179 0 0 0 55.833 60v12.5A4.179 4.179 0 0 0 60 76.667Zm45.833 29.166H97.5A4.179 4.179 0 0 0 93.333 110a4.179 4.179 0 0 0 4.167 4.167H110a4.18 4.18 0 0 0 4.167-4.167V97.5A4.179 4.179 0 0 0 110 93.333a4.179 4.179 0 0 0-4.167 4.167v8.333ZM93.333 60a4.179 4.179 0 0 0 4.167 4.167h8.333V72.5A4.179 4.179 0 0 0 110 76.667a4.179 4.179 0 0 0 4.167-4.167V60A4.179 4.179 0 0 0 110 55.833H97.5A4.179 4.179 0 0 0 93.333 60Z" />
  </Icon>
);

export default FullscreenInIcon;
