import PropTypes from 'prop-types';
import {
  isSameDay,
  compareAsc,
  startOfToday,
  addDays,
  subDays,
} from 'date-fns';
import { uniq } from 'utils/helpers';
import { EPGActions, GET_EPG } from 'actions/epg';

export const channelShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoLight: PropTypes.string,
  logoDark: PropTypes.string,
  isEntitled: PropTypes.bool.isRequired,
});

export const broadcastShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  stop: PropTypes.number.isRequired,
  availableFrom: PropTypes.number,
  availableUntil: PropTypes.number,
  isLiveAvailable: PropTypes.bool.isRequired,
  channelId: PropTypes.string.isRequired,
});

export type EpgReducerState = {
  channels: any[],
  broadcasts: any[],
  datesLoaded: Date[],
  startTime: number,
  stopTime: number,
  isMaxDateLoaded: boolean,
  isMinDateLoaded: boolean,
};
const defaultState: EpgReducerState = {
  channels: [],
  broadcasts: [],
  datesLoaded: [], // Date[]
  startTime: 0,
  stopTime: 0,
  isMaxDateLoaded: false,
  isMinDateLoaded: false,
};

const normalizeChannel = (channel: any) => ({
  id: channel.node.id,
  title: channel.node.title,
  logoDark: channel.node.logoDark,
  logoLight: channel.node.logoLight,
  isEntitled: !!channel.node.entitlement,
});

const normalizeBroadcast = (broadcast: any, channelId: string) => ({
  id: broadcast.id,
  title: broadcast.title,
  start: broadcast.start,
  stop: broadcast.stop,
  availableFrom: broadcast.catchup ? broadcast.catchup.from : undefined,
  availableUntil: broadcast.catchup ? broadcast.catchup.to : undefined,
  isLiveAvailable: broadcast.liveAvailable,
  channelId,
});

export function epgReducer(state = defaultState, action: EPGActions): EpgReducerState {
  switch (action.type) {
    case GET_EPG: {
      if (!action.data?.epg) {
        return state;
      }

      const channels = uniq(
        action.data.epg.channels.edges
          .filter((channel: any) => channel.node) // some channels have null node
          .map(normalizeChannel)
          .concat(...state.channels),
        channel => channel.id,
      );

      const broadcasts = uniq(
        action.data.epg.channels.edges.reduce((acc: any[], channel: any) => {
          if (channel.node?.broadcasts) {
            acc.push(
              ...channel.node.broadcasts
                .map((broadcast: any) => normalizeBroadcast(broadcast, channel.node.id)),
            );
          }

          return acc;
        }, []).concat(...state.broadcasts),
        broadcast => broadcast.id,
      );

      const date = new Date(Date.parse(action.day));
      const datesLoaded = uniq(state.datesLoaded.concat(date), d => d.getTime()).sort(compareAsc);

      const today = startOfToday();

      // how many days in future we allow to load
      const maxDate = addDays(today, 7);

      // how many days in past we allow to load
      const minDate = subDays(today, 7);

      const now = Math.floor(Date.now() / 1000);

      const startTime = broadcasts.length
        ? broadcasts
          .map(broadcast => broadcast.start)
          .sort()[0]
        : now;

      const stopTime = broadcasts.length
        ? broadcasts
          .map(broadcast => broadcast.stop)
          .sort()[broadcasts.length - 1]
        : now;

      return {
        ...state,
        channels,
        broadcasts,
        datesLoaded,
        startTime,
        stopTime,
        isMaxDateLoaded: isSameDay(datesLoaded[datesLoaded.length - 1], maxDate),
        isMinDateLoaded: isSameDay(datesLoaded[0], minDate),
      };
    }

    default: {
      return state;
    }
  }
}
