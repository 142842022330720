import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';

const FRICTION = 0.2;
const DRAG_END_DELAY = 200;
const MIN_DRAG_DISTANCE = 10;

const DragScrollWrapper = createComponent(({ mouseDown }) => ({
  cursor: [
    // fallback to 'move' for browsers that doesn't support 'grab' or 'grabbing'.
    'move',
    mouseDown ? 'grabbing' : 'grab',
  ],
  userSelect: 'none',
}), 'div', ['mouseDown']);

export const getManhattanDistance = (point1, point2) => (
  Math.abs(point1.x - point2.x) + Math.abs(point1.y - point2.y)
);

const DragScroll = ({ children }) => {
  const [mouseDownState, setMouseDownState] = useState(false);

  const mouseDown = useRef(false);
  const mouseMoving = useRef(false);
  const scrollMoving = useRef(false);
  const mouse = useRef({ x: 0, y: 0 });
  const velocity = useRef({ x: 0, y: 0 });
  const initialClientPosition = useRef({ x: 0, y: 0 });
  const initialEPGPosition = useRef({ x: 0, y: 0 });
  const isDragPending = useRef(false);
  const lastDragMouseUpTimestamp = useRef(0);
  const childElement = useRef(null);
  const ref = useRef(null);
  const position = useRef({ x: 0, y: 0 });
  const previous = useRef({ x: 0, y: 0 });

  useEffect(() => {
    childElement.current = ref.current?.children[0];
    position.current = {
      x: childElement.current.scrollLeft,
      y: childElement.current.scrollTop,
    };
    previous.current = { x: position.current.x, y: position.current.y };
  }, [ref.current]);

  const step = () => {
    if (scrollMoving.current) {
      requestAnimationFrame(step);
      ['x', 'y'].forEach((key) => {
        if (mouseDown.current) {
          previous.current[key] = position.current[key];
          position.current[key] = mouse.current[key];
          velocity.current[key] = Math.round(position.current[key] - previous.current[key]);
        } else {
          position.current[key] += velocity.current[key];
          velocity.current[key] = (velocity.current[key] > 0 ? Math.floor : Math.ceil)(
            velocity.current[key] * (1 - FRICTION) * 100,
          ) / 100;
        }
      });

      if (velocity.current.x || velocity.current.y) {
        childElement.current.scrollLeft = position.current.x;
        childElement.current.scrollTop = position.current.y;
      } else {
        scrollMoving.current = false;
      }
    }
  };

  const onMouseDown = (e) => {
    initialEPGPosition.current = {
      x: e.clientX + childElement.current.scrollLeft,
      y: e.clientY + childElement.current.scrollTop,
    };
    mouse.current = {
      x: childElement.current.scrollLeft,
      y: childElement.current.scrollTop,
    };
    initialClientPosition.current = { x: e.clientX, y: e.clientY };
    isDragPending.current = true;
    e.preventDefault();
  };

  const onMouseMove = (e) => {
    // Only enable drag and drop after a certain distance to prevent normal clicks
    // from being ignored
    const currentPosition = { x: e.clientX, y: e.clientY };
    if (!mouseDown.current
      && isDragPending.current
      && getManhattanDistance(initialClientPosition.current, currentPosition) > MIN_DRAG_DISTANCE) {
      mouseDown.current = true;
      setMouseDownState(true);
      isDragPending.current = false;
    }

    if (mouseDown.current) {
      lastDragMouseUpTimestamp.current = 0;
      mouseMoving.current = true;
      scrollMoving.current = true;
      mouse.current = {
        x: initialEPGPosition.current.x - e.clientX,
        y: initialEPGPosition.current.y - e.clientY,
      };
      step();
    }
  };

  const onMouseUp = () => {
    if (mouseMoving.current) {
      mouseMoving.current = false;
      mouseDown.current = false;
      setMouseDownState(false);
      lastDragMouseUpTimestamp.current = Date.now();
    }
  };

  const onMouseLeave = () => {
    mouseMoving.current = false;
    mouseDown.current = false;
    setMouseDownState(false);
  };

  const onClick = (e) => {
    isDragPending.current = false;

    // Apply click if we're not dragging and the last drag happened more than
    // some threshhold ago. Prevents click events that happened so close to a
    // drag mouse up that they're probably from the same mouse release.
    const now = Date.now();
    if (!mouseMoving.current && (now - lastDragMouseUpTimestamp.current > DRAG_END_DELAY)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    mouseMoving.current = false;
    mouseDown.current = false;
    setMouseDownState(false);
  };

  return (
    <DragScrollWrapper
      mouseDown={mouseDownState}
      innerRef={ref}
      onMouseMove={onMouseMove}
      onMouseDownCapture={onMouseDown}
      onClickCapture={onClick}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
    >
      {/* Make sure this.props.children contains only one child */}
      {React.Children.only(children)}
    </DragScrollWrapper>
  );
};

DragScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DragScroll;
