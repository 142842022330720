import React, { useEffect, useState } from 'react';
import { usePortal } from 'utils/hooks';
import HotkeyIconWrapper, { HotkeyAction } from './HotkeyIconWrapper';

interface IHotkeyOverlayProps {
  action?: HotkeyAction,
  timestamp?: number,
}

const PortalToPlayer = ({ children }: { children: React.ReactNode }) => {
  const Portal = usePortal('player');
  return <Portal>{children}</Portal>;
};

const HotkeyOverlay: React.FC<IHotkeyOverlayProps> = (props) => {
  const { action, timestamp } = props;
  const [actionIcon, setActionIcon] = useState<HotkeyAction | undefined>();

  useEffect(() => {
    setActionIcon(action);
    const fadeTimeout = setTimeout(() => setActionIcon(undefined), 750);
    return () => clearTimeout(fadeTimeout);
  }, [action, timestamp]);

  return actionIcon ? (
    <PortalToPlayer>
      <HotkeyIconWrapper action={actionIcon} key={timestamp} />
    </PortalToPlayer>
  ) : null;
};

export default HotkeyOverlay;
