import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/StyledSystem/Box';
import ErrorMessage from 'components/StyledSystem/ErrorMessage';
import PrimaryButton from './PrimaryButton';

const AsyncButton = ({
  alignCenter = true,
  onClick,
  infinite,
  as: Component = PrimaryButton,
  disabled = false,
  ...otherProps
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);
  const unmountedRef = useRef(false);

  useEffect(() => () => {
    unmountedRef.current = true;
  }, []);

  const handleClick = async () => {
    if (inProgress) {
      return;
    }

    setInProgress(true);
    setError(null);

    try {
      await Promise.resolve(onClick());

      if (!infinite && !unmountedRef.current) {
        setInProgress(false);
      }
    } catch (err) {
      if (!unmountedRef.current) {
        setInProgress(false);
        setError(err);
      }
    }
  };
  return (
    <Box
      column
      alignItems={alignCenter ? undefined : 'stretch'}
      justifyContent="center"
    >
      <Component
        {...(disabled ? { disabled: true } : { onClick: handleClick })}
        showSpinner={inProgress}
        {...otherProps}
      />

      {error && (
        <Box mt="fine">
          <ErrorMessage align="center">{error}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};

AsyncButton.propTypes = {
  alignCenter: PropTypes.bool,
  onClick: PropTypes.func,
  infinite: PropTypes.bool,
  as: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
};

AsyncButton.defaultProps = {
  alignCenter: true,
  as: PrimaryButton,
  disabled: false,
};

export default AsyncButton;
