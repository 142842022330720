import createComponent from 'styles/fela/createComponent';
import {
  responsiveMd,
  responsiveXl,
  withBackgroundResizer,
  withBasePadding,
} from 'styles/fela/mixins';

export const Banner = createComponent(({ url, theme }) => ({
  position: 'absolute',
  backgroundPosition: `center ${theme.bannerVerticalAlignment}`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  outline: 'none',
  width: '100%',
  // height is 16/9 or smaller in case of very wide screen
  height: '56.25vw', // 100vw / 16 * 9,
  maxHeight: '120vh',
  // fix 1px wrong color on the bottom if zoom
  ':after': {
    position: 'absolute',
    height: '2px',
    width: '100%',
    content: '" "',
    backgroundColor: theme.color.page,
    left: 0,
    bottom: '-1px',
  },
  extend: [
    withBackgroundResizer({
      src: url,
      frontLayer: `linear-gradient(270deg, rgba(0,0,0,0) 50%, ${theme.color.page} 100%), linear-gradient(180deg, rgba(0,0,0,0) 50%, ${theme.color.page} 100%)`,
    }),
    responsiveMd({
      minHeight: '33.65rem',
    }),
    responsiveXl({
      minHeight: '50rem',
    }),
  ],
}), 'div', ['url']);

export const Container = createComponent(() => ({
  position: 'relative',

  extend: [
    responsiveMd({
      height: '100vh',
      maxHeight: '70vw',
      minHeight: '33.65rem',
    }),
    responsiveXl({
      minHeight: '50rem',
    }),
  ],
}));

export const InfoContainer = createComponent(() => ({
  position: 'relative',
  bottom: 0,
  paddingTop: 0,
  paddingBottom: '2.7rem',
  textAlign: 'left',
  width: '100%',

  extend: [
    withBasePadding(),
    responsiveMd({
      position: 'absolute',
    }),
  ],
}));

export const ContentContainer = createComponent(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  zIndex: 1,

  extend: [
    withBasePadding(),
  ],
}));
