import { getBaseFontFamily } from 'utils/helpers';

// https://docs.adyen.com/online-payments/drop-in-web/customization#styling
export const getAdyenDropInStyles = theme => `
#adyen-dropin-components {
    text-align: left;
}

#adyen-dropin-components .adyen-checkout__payment-method {
    color: ${theme.color.primary};
    background-color: ${theme.color.paperBackground};
    border: none;
    padding: 16px 0;
}

#adyen-dropin-components .adyen-checkout__payment-method--selected {
    border-radius: ${theme.radius.adyenDropIn};
}

#adyen-dropin-components .adyen-checkout__payment-method:first-child,
#adyen-dropin-components .adyen-checkout__payment-method--selected + .adyen-checkout__payment-method {
    margin-top: 0;
    border-top-left-radius: ${theme.radius.adyenDropIn};
    border-top-right-radius: ${theme.radius.adyenDropIn};
}

#adyen-dropin-components .adyen-checkout__payment-method:last-child,
#adyen-dropin-components .adyen-checkout__payment-method--next-selected {
    margin-bottom: 0;
    border-bottom-left-radius: ${theme.radius.adyenDropIn};
    border-bottom-right-radius: ${theme.radius.adyenDropIn};
}

#adyen-dropin-components .adyen-checkout__payment-method__header,
#adyen-dropin-components .adyen-checkout__payment-method__header__title {
    color: ${theme.color.primary};
    padding-top: 0;
    padding-bottom: 0;
}

#adyen-dropin-components .adyen-checkout__paypal__button,
#adyen-dropin-components .adyen-checkout__payment-method__details__content {
    margin-top: 16px;
    margin-bottom: 0;
}

/* field labels */
#adyen-dropin-components .adyen-checkout__label__text {
    color: ${theme.color.secondary};
}
/* field label color if error */
#adyen-dropin-components .adyen-checkout__label__text.adyen-checkout__label__text--error {
    color: ${theme.color.danger};
}
/* field error messages */
#adyen-dropin-components .adyen-checkout__error-text {
    color: ${theme.color.danger};
}
#adyen-dropin-components .adyen-checkout__error-text:empty {
    margin-top: 0;
}

/* input field background */
#adyen-dropin-components .adyen-checkout__input {
    background: ${theme.color.adyenDropInInputBackground};
    color: ${theme.color.adyenDropInInputColor};
    border-color: transparent;
    font-family: ${getBaseFontFamily(theme)};
    font-size: 16px;
}
#adyen-dropin-components .adyen-checkout__input::placeholder,
#adyen-dropin-components .adyen-checkout__input::-ms-input-placeholder {
    color: ${theme.color.adyenDropInInputPlaceholder};
}

#adyen-dropin-components .adyen-checkout__input.adyen-checkout__input--disabled {
    color: ${theme.color.adyenDropInDisabledInputColor};
}

/* checkbox background */
#adyen-dropin-components .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
    background: ${theme.color.adyenDropInInputBackground};
    border-color: transparent;
}
/* radio button background */
#adyen-dropin-components .adyen-checkout__payment-method__radio {
    background: ${theme.color.adyenDropInInputBackground};
    border-color: transparent;
}
#adyen-dropin-components .adyen-checkout__payment-method__radio::after {
    background: ${theme.color.adyenDropInInputColor};
}

/* checkout button */
#adyen-dropin-components .adyen-checkout__button {
    color: ${theme.color.light};
    background-color: ${theme.color.brand};
    font-size: larger;
}

/* hide lock icon from the Pay button */
#adyen-dropin-components .adyen-checkout__button__icon {
    display: none;
}

#adyen-dropin-components .adyen-checkout__checkbox__label {
    color: ${theme.color.secondary};
}

/* initial spinner */
#adyen-dropin-components .adyen-checkout__spinner {
    border-color: ${theme.color.brand};
    border-top-color: transparent;
}

#adyen-dropin-components .adyen-checkout__bankTransfer__introduction {
    color: ${theme.color.primary};
}

#adyen-dropin-components.third-party-stored-payment .adyen-checkout__payment-method:first-child .adyen-checkout__payment-method__details__content {
    margin-top: -5px;
}
#adyen-dropin-components.third-party-stored-payment .adyen-checkout__payment-method:first-child .adyen-checkout-form-instruction {
    display: none;
}
`;
