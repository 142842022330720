import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { LinkButton, Text } from 'components';

export const TableRow = createComponent(({ theme, expanded }: ComponentRuleProps): RuleStyles => ({
  display: 'table-row',
  width: '100%',

  extend: [
    {
      condition: expanded,
      style: {
        backgroundColor: theme.color.highlight,
      },
    },
  ],
}), 'tr', ['expanded']);

TableRow.displayName = 'TableRow';

export const TableCell = createComponent(({ align = 'left', expanded, paddingTopOff }: ComponentRuleProps): RuleStyles => ({
  display: 'table-cell',
  position: 'relative',
  textAlign: align,
  padding: paddingTopOff ? '0 min(8px, 1.5vw) 12px' : '12px min(8px, 1.5vw)',
  borderBottomWidth: '2px',
  borderStyle: 'solid',
  borderColor: '#979797',

  extend: [
    {
      condition: expanded,
      style: {
        borderBottom: 'none',
      },
    },
  ],
}), 'td', ['align', 'expanded']);

TableCell.displayName = 'TableCell';

export const Separator = createComponent(() => ({
  margin: '0 12px',
  height: '1px',
  width: 'calc(100% - 24px)',
  backgroundColor: '#979797',
}));

export const SubCell = createComponent(() => ({
  fontSize: '0.9em',
  ':not(:first-child)': {
    marginTop: '20px',
  },
}));
SubCell.displayName = 'SubCell';

export const CellHeader = createComponent(() => ({
  marginBottom: '12px',
  fontWeight: 'bold' as const,
}));

export const CellTitle = createComponent(() => ({
  color: '#979797',
  marginBottom: '6px',
}));

export const ToggleButton = createComponent(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0',
  margin: '0 0 0 auto',
  ':hover': {
    textDecoration: 'none',
    fontWeight: 'bold' as const,
  },
}), LinkButton);

ToggleButton.displayName = 'ToggleButton';

export const StyledText = createComponent(() => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  overflowWrap: 'anywhere',
}), Text);
