import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { getUpcomingBroadcastTime } from 'utils/broadcast';
import { UpcomingTitle } from 'components/Upcoming';

import createComponent from 'styles/fela/createComponent';
import {
  Image,
  Scrollable,
  VideoProgressBar,
  Box,
  Text,
  CleanLink,
} from 'components';

const Episode = createComponent(({ theme }) => ({
  cursor: 'pointer',
  display: 'block',
  marginRight: theme.margin.small,
  userSelect: 'none',
  width: '24rem',
  ':not(:first-child)': {
    marginLeft: theme.margin.small,
  },
  ':first-child': {
    marginLeft: 0,
  },
}), CleanLink);

const UpcomingEpisodeTime = createComponent(({ theme }) => ({
  fontSize: '26px',
  color: 'white',
  textShadow: theme.textShadow.light,
}), UpcomingTitle);

const renderEpisode = (episode, viewable) => {
  const playable = episode.defaultPlayable;
  const watchOffset = playable.watchOffset || 0;
  const broadcastStartTime = getUpcomingBroadcastTime(playable);

  return (
    <Episode
      key={episode.id}
      to={{
        name: 'watch',
        params: {
          id: viewable.id,
          playableId: playable.id,
        },
      }}
    >
      <Box position="relative">
        <Image
          alt={episode.title}
          hasShadow
          withMarginBottom={false}
          src={episode.banner}
          type="episodeBanner"
          rounded
        />
        {broadcastStartTime && (
          <Box
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            textAlign="center"
            mt="small"
          >
            <UpcomingEpisodeTime
              startTime={broadcastStartTime}
            />
          </Box>
        )}
        {watchOffset > 0 && (
          <VideoProgressBar
            offset={playable.watchOffset}
            duration={playable.duration}
          />
        )}
      </Box>

      <Box m="fine">
        <Box display="inline-block" mr="fine">
          <Text fontSize="pageHeading" bold>
            {episode.episodeNumber}
          </Text>
        </Box>

        <Text fontSize="medium" bold>
          {episode.title}
        </Text>
      </Box>

      <Text color="secondary" fontSize="small">
        {episode.description}
      </Text>

      <Box m="fine">
        <Text color="secondary" fontSize="small" bold>
          {episode.durationHuman}
        </Text>
      </Box>
    </Episode>
  );
};

const SeasonTab = (props) => {
  const {
    viewable,
    seasonNumber,
  } = props;

  const season = viewable.seasons.find(item => item.seasonNumber === seasonNumber);
  if (!season) {
    throw new Error(`Can't find season ${seasonNumber}`);
  }

  const items = season.episodes.map(episode => renderEpisode(episode, viewable));

  return (
    <Scrollable
      pageId={`watch-${viewable.id}`}
      id={`season-${season.seasonNumber}`}
      itemWidthRem={24.7}
    >
      {items}
    </Scrollable>
  );
};

SeasonTab.propTypes = {
  viewable: viewableShape.isRequired,
  seasonNumber: PropTypes.number.isRequired,
};

export default SeasonTab;
