import React, { useState, useEffect, useMemo } from 'react';
import { Box, Modal, ModalContainer, Text, PrimaryButton, Image, LinkButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTags } from 'actions/user';
import { addMessage } from 'actions/messages';
import storage from 'utils/storage';
import logger from 'utils/logger';

function PromoBanner() {
  const bannerFeature = useSelector(({ settings: { features } }) => features.banner);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const userTags = useSelector(({ user }) => user.tags);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const isUrlIncludesUserTag = () => {
    if (!bannerFeature?.actionURL) {
      return false;
    }
    // eslint-disable-next-line no-template-curly-in-string
    return bannerFeature.actionURL.includes('${UserTagPrefix_TAGVALUE}');
  };

  const displayModal = async () => {
    if (!bannerFeature || storage.getItem('promoBannerShown')) {
      return;
    }

    const userTagInUrl = isUrlIncludesUserTag();

    if (isLoggedIn && userTagInUrl && !!bannerFeature.userTagPrefix) {
      try {
        const tags = userTags ?? await dispatch(getUserTags());

        if (!tags.some(tag => tag.startsWith(`${bannerFeature.userTagPrefix}_`))) {
          // this banner URL doesn't match user tags
          return;
        }
      } catch (e) {
        dispatch(addMessage({ contentId: 'failedMessage' }));
        logger.error('Failed to load user tags', e);
        return;
      }
    }

    if (!isLoggedIn) {
      // don't display the banner for unauthenticated user
      return;
    }

    setShowModal(true);
  };

  useEffect(() => {
    displayModal();
  }, [isLoggedIn]);

  const onClose = () => {
    storage.setItem('promoBannerShown', 'true');
    setShowModal(false);
  };

  const actionUrl = useMemo(() => {
    if (isUrlIncludesUserTag() && userTags) {
      const userTag = userTags.find(tag => tag.startsWith(`${bannerFeature.userTagPrefix}_`));

      return bannerFeature.actionURL.replace(/\${UserTagPrefix_TAGVALUE}/g, userTag);
    }

    return bannerFeature?.actionURL;
  }, [bannerFeature?.actionURL, userTags]);

  if (!showModal) {
    return null;
  }

  return (
    <Modal onDismiss={onClose}>
      <Box width="90vw" md-width="50vw">
        <ModalContainer
          onClickClose={onClose}
          withShadow
        >
          {
            bannerFeature.title ? (
              <Box mx="xlarge">
                <Text
                  as="h1"
                  bold
                  fontSize="large"
                  color="brand"
                  align="center"
                >
                  {bannerFeature.title}
                </Text>
              </Box>
            ) : (
              <Box mt="large" hideEmpty={false} />
            )
          }
          {
            bannerFeature.imageURL && (
              <Box row mt="medium" alignItems="center">
                <Image
                  width="100%"
                  src={bannerFeature.imageURL}
                  alt={bannerFeature?.title || 'Promotion banner'}
                />
              </Box>
            )
          }
          {
            bannerFeature.description && (
              <Box row mt="medium">
                <Text whiteSpace="pre-wrap">{bannerFeature.description}</Text>
              </Box>
            )
          }
          {
            bannerFeature.actionURL && bannerFeature.actionTitle && (
              <Box row mt="medium">
                <PrimaryButton
                  as="a"
                  href={actionUrl}
                >
                  {bannerFeature.actionTitle}
                </PrimaryButton>
              </Box>
            )
          }
          <Box row mt="medium">
            <LinkButton
              variant="brand"
              onClick={onClose}
            >
              <Text id="close" />
            </LinkButton>
          </Box>
        </ModalContainer>
      </Box>
    </Modal>
  );
}

export default React.memo(PromoBanner);
