import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'reducers';
import logger from 'utils/logger';
import { useI18n } from 'components/I18n';
import * as campaignActions from 'actions/campaign';

type TData = (
  | Omit<campaignActions.PromocodeValidationResultSuccess, 'valid' | 'promoCode'>
  | Record<string, never>
);

type TUsePromoCode = {
  isPromoCodeAllowed: boolean;
  loading: boolean;
  error: string | null;
  data: TData;
};

export function usePromoCode(promoCode: string | undefined, packageId: string): TUsePromoCode {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const isPromoCodeAllowed = useSelector(state => state.settings.features.payment?.allowPromoCodes);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState<TData>({});

  React.useEffect(() => {
    if (!isPromoCodeAllowed || !promoCode || !packageId) {
      setData({});
      setError(null);
      return () => {};
    }

    let cancelled = false;

    setLoading(true);
    setError(null);
    setData({});

    dispatch(campaignActions.validatePromocode(packageId, promoCode)).then(
      (result) => {
        if (cancelled) {
          return;
        }

        if (result.valid) {
          setData({
            campaignName: result.campaignName,
            bypassPaymentMethod: result.bypassPaymentMethod,
            discount: result.discount,
            usps: result.usps,
          });
        } else {
          setError(i18n.formatText(result.errorId, { promoCode }));
        }
        setLoading(false);
      },
      (e) => {
        setLoading(false);

        if (cancelled) {
          return;
        }

        logger.warn('Promocode validation failed', e);
        setError(i18n.formatText('promoCode.error500'));
      },
    );

    return () => {
      cancelled = true;
    };
  }, [promoCode, packageId]);

  return {
    isPromoCodeAllowed,
    loading,
    error,
    data,
  };
}
