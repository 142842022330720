import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';

const Container = createComponent(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}), 'ul');

const Dot = createComponent(({ theme, selected, onClick }) => ({
  flex: '0 0 auto',
  width: '0.625rem',
  height: '0.625rem',
  borderRadius: '50%',
  background: theme.color.broadcastLive,
  margin: '0 0.875rem',
  extend: [
    {
      condition: selected,
      style: {
        background: theme.color.brand,
      },
    },
    {
      condition: onClick,
      style: {
        cursor: 'pointer',
      },
    },
  ],
}), 'li', ['selected']);

function Pager({ size, selectedPos, onClick }) {
  const items = Array(size).fill(0).map((_, i) => (
    <Dot
      key={i} // eslint-disable-line react/no-array-index-key
      selected={i === selectedPos}
      onClick={onClick ? () => onClick(i) : undefined}
    />
  ));

  return (
    <Container>
      {items}
    </Container>
  );
}

Pager.propTypes = {
  size: PropTypes.number.isRequired,
  selectedPos: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default React.memo(Pager);
