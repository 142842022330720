import * as offersActions from 'actions/offers';
import { IGeoipLookup } from 'types';
import {
  PURCHASE_FLOW_OFFER_TYPES,
} from 'utils/constants';
import logger from 'utils/logger';
import { apiNetworkRequest, AppAsyncAction } from './helpers';

const isLocalhost = (ip: string) => ['::1', 'localhost', '127.0.0.1', '10.0.2.2'].indexOf(ip) > -1;

export function isOpenServiceCountry(countryCode: string, whitelist?: string[]): boolean {
  if (!whitelist) {
    return true;
  }

  if (whitelist.includes(countryCode.toUpperCase())) {
    return true;
  }

  return false;
}

export const SET_COUNTRY_INFO = 'country/SET_COUNTRY_INFO';
interface SetCountryInfoAction {
  type: typeof SET_COUNTRY_INFO
  country: string
  isOpenServiceActive: boolean
  isCreateAccountEnabled: boolean
  isPurchaseFlowEnabled: boolean
}
function setCountryInfo(country: string): AppAsyncAction {
  return async (dispatch, getState) => {
    const {
      features: {
        openService,
        register,
        payment,
      },
      localFeatures,
    } = getState().settings;

    const isOpenServiceActive = !!openService
      && isOpenServiceCountry(country, openService.whitelist);

    let anyOffersInMarket = false;
    let offersInPurchaseFlow = false;

    // Query offers on the market only if registration is enabled,
    // and we don't force a market check skip on the partner
    if (register && !localFeatures.skipMarketCheck) {
      await dispatch(offersActions.fetchAllOffers());
      const { offers } = getState();
      const purchaseFlowOffers = offers.filter(
        // @ts-expect-error ts-migrate(2345) FIXME
        offer => PURCHASE_FLOW_OFFER_TYPES.includes(offer.__typename),
      );

      anyOffersInMarket = offers.length > 0;
      offersInPurchaseFlow = purchaseFlowOffers.length > 0;
    } else {
      logger.info('Registration or market check disabled. Market check skipped!');
    }

    const isCreateAccountEnabled = !!register
      && (localFeatures.skipMarketCheck || anyOffersInMarket);
    const isPurchaseFlowEnabled = !!payment?.onboarding
      && offersInPurchaseFlow;

    dispatch<SetCountryInfoAction>({
      type: SET_COUNTRY_INFO,
      country,
      isOpenServiceActive,
      isCreateAccountEnabled,
      isPurchaseFlowEnabled,
    });
  };
}

export function lookupCountry(ip: string, isE2E?: boolean): AppAsyncAction {
  return async (dispatch, getState) => {
    const fallbackCountry = getState().settings.fallbackGeoipCountry?.toUpperCase() || 'SE';

    if (isLocalhost(ip) || isE2E) {
      return dispatch(setCountryInfo(fallbackCountry));
    }

    try {
      const response: IGeoipLookup = await dispatch(apiNetworkRequest('geoip.lookup', { ip }));

      return await dispatch(setCountryInfo(response.country.toUpperCase()));
    } catch (e) {
      logger.warn(`Country lookup failed. Defaulting to ${fallbackCountry}`, e);
      return dispatch(setCountryInfo(fallbackCountry));
    }
  };
}

export type CountryActions = SetCountryInfoAction;
