import createComponent from 'styles/fela/createComponent';
import {
  smallScreen,
  responsiveSm,
  responsiveMd,
  responsiveLg,
  withBasePadding,
} from 'styles/fela/mixins';

export const Wrap = createComponent(({ theme }) => ({
  padding: '2rem',
  position: 'relative',
  zIndex: 1,
  filter: `drop-shadow(${theme.textShadow.featured})`,
  transform: 'translateZ(0)', // handle safari for drop-shadow
  marginTop: theme.margin.xxxlarge, // for header
  maxHeight: '90%',
  extend: [
    withBasePadding(),
  ],
}));

export const Title = createComponent(({ theme, chars }) => ({
  fontSize: theme.fontSize.loud,
  fontWeight: 'bold',
  marginBottom: '4rem',
  color: theme.color.genreTitle,
  extend: [
    smallScreen({
      marginBottom: '0',
    }),
    {
      condition: chars < 10,
      style: {
        fontSize: theme.fontSize.giant,
      },
    },
    {
      condition: chars > 20,
      style: {
        fontSize: theme.fontSize.pageHeading,
        extend: [
          responsiveLg({
            fontSize: theme.fontSize.loud,
          }),
        ],
      },
    },
    {
      condition: chars > 30,
      style: {
        fontSize: theme.fontSize.sectionHeading,
        extend: [
          responsiveLg({
            fontSize: theme.fontSize.loud,
          }),
        ],
      },
    },
    {
      condition: theme.collectionTitle?.style,
      style: {
        fontStyle: theme.collectionTitle?.style,
      },
    },
    {
      condition: theme.collectionTitle?.weight,
      style: {
        fontWeight: theme.collectionTitle?.weight,
      },
    },
  ],
}), 'h1', ['chars']);

export const Description = createComponent(({ theme }) => ({
  maxWidth: '45vw',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'inline-block',
  position: 'relative',
  marginTop: '10px',
  color: theme.color.genreTitle,
  lineHeight: 'normal',
  fontSize: theme.fontSize.normal,
  letterSpacing: '1.6px',
  extend: [
    smallScreen({
      maxWidth: '100%',
    }),
    responsiveSm({
      maxWidth: '60vw',
    }),
    responsiveMd({
      maxWidth: '45vw',
    }),
  ],
}), 'p');
