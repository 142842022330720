export const ITEM_MODE = {
  GRID: 'GRID',
  LIST: 'LIST',
  SLIDER: 'SLIDER',
  RELATED: 'RELATED',
};

export const sixteenNineType = {
  [ITEM_MODE.SLIDER]: 'sixteenNineBanner',
  [ITEM_MODE.GRID]: 'gridSixteenNineBanner',
  [ITEM_MODE.LIST]: 'listSixteenNineBanner',
  [ITEM_MODE.RELATED]: 'gridSixteenNineBanner',
};
