import { darken, transparentize } from 'polished';
import createComponent from 'styles/fela/createComponent';
import BasicButton from './BasicButton';

const PrimaryButton = createComponent(({ theme, variant = 'brand' }) => ({
  fontWeight: 'bold',
  transition: 'background-color 0.2s ease-in-out',
  extend: [
    {
      condition: variant === 'brand',
      style: {
        color: theme.color.light,
        backgroundColor: theme.color.brand,
        ':hover:not([disabled])': {
          backgroundColor: theme.color.primaryButtonHover,
        },

        '&[disabled]': {
          backgroundColor: theme.color.primaryButtonDisabled,
          color: transparentize(0.3, theme.color.light),
          '& svg': {
            color: theme.color.light,
            opacity: 0.7,
          },
        },
      },
    },
    {
      condition: variant === 'brandSecondary',
      style: {
        color: theme.color.light,
        backgroundColor: theme.color.brandSecondary,
        ':hover': {
          backgroundColor: darken(0.1, theme.color.brandSecondary),
        },
      },
    },
  ],
}), BasicButton, ['variant']);

PrimaryButton.displayName = 'PrimaryButton';

export default PrimaryButton;
