import createComponent from 'styles/fela/createComponent';
import { responsiveMd, withBasePadding } from 'styles/fela/mixins';
import { CleanLink, Content } from 'components';

export const Menu = createComponent(({ theme }) => ({
  background: theme.color.highlight,
  display: 'none',
  flexShrink: '0',
  flexDirection: 'column',
  paddingTop: theme.margin.xlarge,
  width: '19.6rem',
  extend: [
    responsiveMd({ display: 'flex' }),
  ],
}), 'nav');

export const StyledContent = createComponent(() => ({
  extend: [
    responsiveMd({
      paddingLeft: '2rem',
    }),
  ],
}), Content, []);

export const MenuItem = createComponent(({ theme, active }) => ({
  color: theme.color.primary,
  cursor: 'pointer',
  display: 'block',
  fontSize: theme.fontSize.small,
  padding: `${theme.margin.small} ${theme.margin.xlarge}`,
  width: '100%',
  ':hover': {
    fontWeight: 'bold',
  },

  extend: [
    withBasePadding({ right: false }),
    {
      condition: active,
      style: {
        background: theme.color.brand,
        color: theme.color.light,
        fontWeight: 'bold',
      },
    },
  ],
}), CleanLink, ['active']);

export const TabsWrapper = createComponent(({ theme }) => ({
  marginBottom: '3rem',
  marginTop: `-${theme.margin.large}`,

  extend: [
    responsiveMd({ display: 'none' }),
  ],
}));

export const ContentWrapper = createComponent(({ wide, maxwidth }) => ({
  maxWidth: '31rem',

  extend: [
    {
      condition: wide,
      style: {
        maxWidth: 'none',
      },
    },
    {
      condition: maxwidth,
      style: {
        maxWidth: maxwidth,
      },
    },
  ],
}), 'div', ['wide']);

export const MessageBox = createComponent(({ theme }) => ({
  backgroundColor: theme.color.highlight,
  color: theme.color.primary,
  marginBottom: theme.margin.large,
  maxWidth: '31rem',
  padding: theme.margin.large,
}));
