import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import Text from 'components/StyledSystem/Text';

const ErrorWrapper = createComponent(({ theme, left }) => ({
  color: theme.color.danger,
  fontSize: theme.fontSize.small,
  lineHeight: '22px',
  minHeight: '22px',
  textAlign: 'center',

  extend: [{
    condition: left,
    style: {
      textAlign: 'left',
    },
  }],
}), 'div', ['left']);

function GeneralError({ children, className, left }) {
  if (!children) {
    return null;
  }

  return (
    <ErrorWrapper className={className} left={left}>
      {children}
    </ErrorWrapper>
  );
}

GeneralError.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  left: PropTypes.bool,
};

export function FormattedError({ error }) {
  if (!error) {
    return null;
  }

  return (
    <GeneralError>
      <Text id={error} />
    </GeneralError>
  );
}

FormattedError.propTypes = {
  error: PropTypes.string,
};

export default GeneralError;
