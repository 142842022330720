import React from 'react';
import Icon, { IconWithText } from '../Icon';

const SeekBackIcon = (props: IconWithText): JSX.Element => {
  const { text, ...otherProps } = props;
  return (
    <Icon {...otherProps}>
      <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
      <path fill="#fff" d="M84.958 55.833V44.208c0-1.875-2.25-2.791-3.541-1.458L65.625 58.542a2.063 2.063 0 0 0 0 2.958l15.792 15.792c1.291 1.291 3.541.375 3.541-1.459V64.167c15.542 0 27.834 14.25 24.417 30.375-1.958 9.458-9.625 17.083-19.042 19.041-14.875 3.125-28.125-7.083-30.125-20.875-.25-2-2-3.541-4.083-3.541-2.5 0-4.5 2.208-4.167 4.708 2.584 18.292 20 31.833 39.709 28 13-2.542 23.458-13 26-26 4.125-21.375-12.084-40.042-32.709-40.042Z" />
      <text fill="#fff" x="50%" y="55%" dominantBaseline="central" textAnchor="middle">{text}</text>
    </Icon>
  );
};

export default SeekBackIcon;
