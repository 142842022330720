import React from 'react';
import Text from './Text';

export default function Heading(props) {
  return (
    <Text
      as="h1"
      fontWeight="600"
      color="primary"
      fontSize="pageHeading"
      {...props}
    />
  );
}
