import React from 'react';
import { useSelector } from 'react-redux';
import { viewableTagShape } from 'types/viewableShape';
import { withSeparator } from 'utils/helpers';
import { Link, Text } from 'components';

const renderLinks = (items, isSearchEnabled) => {
  if (!isSearchEnabled) {
    return items.join(', ');
  }

  return withSeparator(items.map(
    item => (
      <Link
        key={item}
        to={{ name: 'search', query: { q: item } }}
        color="primary"
      >
        {item}
      </Link>
    ),
  ), ', ');
};

const Tag = ({ data = {} }) => {
  const {
    isSearchEnabled,
  } = useSelector(({ settings }) => ({
    isSearchEnabled: !!settings.searchMenu,
  }));

  const {
    title,
    translationKey,
    values,
    searchable,
  } = data;

  if (!values?.length) {
    return null;
  }

  return (
    <>
      <span>
        <Text bold>
          { title || (
            <Text
              id={translationKey}
              values={{ valuesLength: values.length }}
            />
          )}:
        </Text> {renderLinks(values, isSearchEnabled && searchable)}
      </span>
      <br />
    </>
  );
};

Tag.propTypes = {
  data: viewableTagShape,
};

export default Tag;
