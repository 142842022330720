import logger from 'utils/logger';
import { useDispatch } from 'reducers';
import { useI18n } from 'components/I18n';
import * as campaignActions from 'actions/campaign';

interface IUsePromoValid {
  offerId?: string;
  onSuccess: (result: campaignActions.PromocodeValidationResultSuccess) => void;
  onFailure?: () => void;
}

export function usePromoCodeValidator(props: IUsePromoValid): (promoCode: string) => Promise<void> {
  const {
    offerId = '',
    onSuccess,
    onFailure = () => {},
  } = props;
  const i18n = useI18n();
  const dispatch = useDispatch();

  return async (promoCode) => {
    const result = await dispatch(
      campaignActions.validatePromocode(offerId, promoCode),
    ).catch((e) => {
      logger.error('Promocode validation failed', e);
      onFailure();
      throw i18n.formatText('promoCode.error500');
    });

    if (!result.valid) {
      onFailure();
      throw i18n.formatText(result.errorId, { promoCode });
    }

    await Promise.resolve(onSuccess(result));
  };
}
