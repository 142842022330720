import createComponent from 'styles/fela/createComponent';
import CleanLink from 'components/StyledSystem/CleanLink';
import { VIDEO_ANIMATION_DURATION } from 'components/PreviewPlayer';

export const ANIMATION_DURATION = 250;
export const TOOLTIP_WIDTH = 420;
export const TOOLTIP_IMG_HEIGHT = Math.ceil((TOOLTIP_WIDTH / 16) * 9);
export const ANIMATIONS = {
  on: 'on',
  off: 'off',
  onRight: 'onRight',
  offRight: 'offRight',
  onLeft: 'onLeft',
  offLeft: 'offLeft',
};

export const Tooltip = createComponent(({ theme, animation }) => ({
  position: 'absolute',
  overflow: 'hidden',
  zIndex: theme.zIndex.tooltip,
  borderColor: theme.color.page,
  borderRadius: theme.radius.paper,
  boxShadow: `0 6px 20px 2px ${theme.color.tooltipShadow}`,
  width: `${TOOLTIP_WIDTH}px`,
  color: theme.color.primary,

  '& #hp-subtitles': {
    fontSize: '18px',
  },

  '& .previewPlayer': {
    top: '-2px',
    aspectRatio: theme.size.image16x9,
    height: 'auto',
  },

  '& .previewPlayer ~ .videoBanner': {
    position: 'absolute',
    top: 0,
  },

  extend: [
    {
      condition: animation === ANIMATIONS.on,
      style: {
        opacity: 1,
        transition: 'opacity 100ms ease-in',
        animationName: {
          from: { transform: 'scale(0.5, 0.5)' },
          to: { transform: 'scale(1, 1)' },
        },
        animationDuration: `${ANIMATION_DURATION}ms`,
        animationIterationCount: 1,
        animationTimingFunction: 'ease-out',
      },
    },
    {
      condition: animation === ANIMATIONS.onLeft,
      style: {
        opacity: 1,
        transition: 'opacity 100ms ease-in',
        animationName: {
          from: { transform: 'translateX(-25%) scale(0.5, 0.5)' },
          to: { transform: 'translateX(0) scale(1, 1)' },
        },
        animationDuration: `${ANIMATION_DURATION}ms`,
        animationIterationCount: 1,
        animationTimingFunction: 'ease-out',
      },
    },
    {
      condition: animation === ANIMATIONS.onRight,
      style: {
        opacity: 1,
        transition: 'opacity 100ms ease-in',
        animationName: {
          from: { transform: 'translateX(25%) scale(0.5, 0.5)' },
          to: { transform: 'translateX(0) scale(1, 1)' },
        },
        animationDuration: `${ANIMATION_DURATION}ms`,
        animationIterationCount: 1,
        animationTimingFunction: 'ease-out',
      },
    },
    {
      condition: animation === ANIMATIONS.off,
      style: {
        opacity: 0,
        transform: 'scale(0.5, 0.5)',
        transition: `opacity 100ms ease-out ${ANIMATION_DURATION - 100}ms, transform ${ANIMATION_DURATION}ms ease-in`,
      },
    },
    {
      condition: animation === 'offLeft',
      style: {
        opacity: 0,
        transform: 'translateX(-25%) scale(0.5, 0.5)',
        transition: `opacity 100ms ease-out ${ANIMATION_DURATION - 100}ms, transform ${ANIMATION_DURATION}ms ease-in`,
      },
    },
    {
      condition: animation === ANIMATIONS.offRight,
      style: {
        opacity: 0,
        transform: 'translateX(25%) scale(0.5, 0.5)',
        transition: `opacity 100ms ease-out ${ANIMATION_DURATION - 100}ms, transform ${ANIMATION_DURATION}ms ease-in`,
      },
    },
  ],
}), 'div', ['animation']);

export const TriggerArea = createComponent(({ theme, position }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.tooltip,
  top: position.top,
  left: position.left,
  width: position.width,
  height: position.height,
}), CleanLink, ['position']);

export const ImageLinkContainer = createComponent(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: `${TOOLTIP_WIDTH}px`,
  // container is smaller as sometimes you can see 1 px difference (img and video)
  height: `${TOOLTIP_IMG_HEIGHT - 4}px`,
  overflow: 'hidden',
}), CleanLink);

export const Image = createComponent(({ visible }) => ({
  opacity: visible ? 1 : 0,
  transition: `opacity ${VIDEO_ANIMATION_DURATION}ms ease-in`,
  width: `${TOOLTIP_WIDTH}px`,
  height: `${TOOLTIP_IMG_HEIGHT}px`,
  zIndex: 1,
}), 'img', ['visible']);
