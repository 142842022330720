import createComponent from 'styles/fela/createComponent';
import PlayIconComponent from 'components/Icons/PlayIcon';

export const Container = createComponent(({ theme }) => ({
  margin: `0 ${theme.margin.fine}`,
  width: '292px',
  position: 'relative',
}));

export const Poster = createComponent(() => ({
  cursor: 'pointer',
  width: '100%',
}));

export const EmptyPoster = createComponent(({ theme, width, height, rounded }) => ({
  width,
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.color.imageBackground,
  extend: [
    {
      condition: rounded,
      style: {
        borderRadius: typeof rounded === 'string' ? rounded : theme.radius.poster,
      },
    },
  ],
}), Poster, ['width', 'height', 'rounded']);

export const Schedule = createComponent(({ theme }) => ({
  color: theme.color.primary,
  fontSize: theme.fontSize.small,
  listStyle: 'none',
  margin: 0,
  padding: `${theme.margin.fine} 0`,
}), 'ol');

export const ScheduleItem = createComponent(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  cursor: 'default',
  height: '2.8rem',
  overflow: 'hidden',
  ':first-child': {
    marginBottom: theme.margin.medium,
  },
}), 'li');

export const StartTime = createComponent(({ theme }) => ({
  paddingLeft: theme.margin.fine,
  marginRight: '5px',
  flexShrink: 0,
}));

const logoSize = 7;
export const PlayIcon = createComponent(() => ({
  height: `${logoSize}rem`,
  width: `${logoSize}rem`,
  paddingTop: '0.5rem',
}), PlayIconComponent);
