import createComponent from 'styles/fela/createComponent';
import { ieOnly, maxWidth, widthRange, minWidth } from 'styles/fela/mixins';
import { BREAKPOINTS } from 'utils/constants';

export const Grid = createComponent(({
  theme,
  itemWidth,
  spaceAround,
  is16x9,
  autoFillColumns = false,
}) => ({
  display: 'grid',
  gridGap: theme.margin.poster,
  justifyContent: 'space-between',
  extend: [
    ieOnly({ // IE11 doesn't support `repeat()` notation, so use Flexbox as a fallback
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      '> *': {
        flex: `0 0 ${itemWidth}rem`,
        margin: theme.margin.poster,
      },
    }),
    {
      condition: !autoFillColumns,
      style: {
        ...maxWidth(is16x9 ? 320 : 400, {
          gridTemplateColumns: `repeat(${is16x9 ? '1' : '2'}, 1fr)`,
        }),
        ...widthRange(is16x9 ? 321 : 401, BREAKPOINTS.sm, {
          gridTemplateColumns: `repeat(${is16x9 ? '2' : '4'}, 1fr)`,
        }),
        ...widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
          gridTemplateColumns: `repeat(${is16x9 ? '3' : '6'}, 1fr)`,
        }),
        ...widthRange(BREAKPOINTS.md, BREAKPOINTS.lg, {
          gridTemplateColumns: `repeat(${is16x9 ? '4' : '8'}, 1fr)`,
        }),
        ...widthRange(BREAKPOINTS.lg, BREAKPOINTS.xl, {
          gridTemplateColumns: `repeat(${is16x9 ? '5' : '10'}, 1fr)`,
        }),
        ...minWidth(BREAKPOINTS.xl, {
          gridTemplateColumns: `repeat(auto-fill, ${itemWidth}em)`,
        }),
      },
    },
    {
      condition: autoFillColumns,
      style: {
        gridTemplateColumns: `repeat(auto-fill, ${itemWidth}em)`,
      },
    },
    {
      condition: spaceAround,
      style: {
        justifyContent: 'space-around',
      },
    },
  ],
}), 'div', ['itemWidth', 'spaceAround', 'is16x9', 'autoFillColumns']);
