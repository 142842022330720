import {
  AuthActions,
  LOGOUT,
  SET_AUTH,
} from 'actions/auth';

export type AuthReducerState = {
  userId: string,
  sessionToken: string,
  isLoggedIn: boolean,
};

const defaultState: AuthReducerState = {
  userId: '',
  sessionToken: '',
  isLoggedIn: false,
};

export function authReducer(state = defaultState, action: AuthActions): AuthReducerState {
  switch (action.type) {
    case SET_AUTH: {
      const {
        sessionToken,
        userId,
      } = action;

      return {
        sessionToken,
        userId,
        isLoggedIn: Boolean(sessionToken && userId),
      };
    }

    case LOGOUT: {
      return defaultState;
    }
  }

  return state;
}
