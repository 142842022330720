import React from 'react';
import Icon, { IconType } from './Icon';

export default function ArrowForward(props: IconType): JSX.Element {
  return (
    <Icon {...props} width="1rem" height="1rem" viewBox="0 0 28 28">
      <path fill="currentColor" d="M13.3875 22.6917C13.2319 22.5362 13.1541 22.332 13.1541 22.0792C13.1541 21.8265 13.2319 21.6223 13.3875 21.4667L19.9791 14.8751H5.54163C5.28885 14.8751 5.07982 14.7924 4.91454 14.6271C4.74926 14.4619 4.66663 14.2528 4.66663 14.0001C4.66663 13.7473 4.74926 13.5383 4.91454 13.373C5.07982 13.2077 5.28885 13.1251 5.54163 13.1251H19.9791L13.3875 6.5334C13.2319 6.37784 13.1541 6.16882 13.1541 5.90632C13.1541 5.64382 13.2319 5.43479 13.3875 5.27923C13.543 5.12368 13.7472 5.0459 14 5.0459C14.2527 5.0459 14.4569 5.12368 14.6125 5.27923L22.7208 13.3876C22.818 13.4848 22.8861 13.582 22.925 13.6792C22.9638 13.7765 22.9833 13.8834 22.9833 14.0001C22.9833 14.0973 22.9638 14.1994 22.925 14.3063C22.8861 14.4133 22.818 14.5153 22.7208 14.6126L14.6125 22.7209C14.4569 22.8765 14.2527 22.9494 14 22.9396C13.7472 22.9299 13.543 22.8473 13.3875 22.6917Z" />
    </Icon>
  );
}
