import * as Types from '../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TResetPinCodeMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TResetPinCodeMutation = { __typename?: 'Mutation', resetPinCode?: { __typename?: 'ResetPinCodePayload', clientMutationId?: string | null } | null };


export const ResetPinCodeDocument = gql`
    mutation resetPinCode {
  resetPinCode(input: {clientMutationId: ""}) {
    clientMutationId
  }
}
    `;
export type TResetPinCodeMutationFn = Apollo.MutationFunction<TResetPinCodeMutation, TResetPinCodeMutationVariables>;

/**
 * __useResetPinCodeMutation__
 *
 * To run a mutation, you first call `useResetPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPinCodeMutation, { data, loading, error }] = useResetPinCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetPinCodeMutation(baseOptions?: Apollo.MutationHookOptions<TResetPinCodeMutation, TResetPinCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TResetPinCodeMutation, TResetPinCodeMutationVariables>(ResetPinCodeDocument, options);
      }
export type ResetPinCodeMutationHookResult = ReturnType<typeof useResetPinCodeMutation>;
export type ResetPinCodeMutationResult = Apollo.MutationResult<TResetPinCodeMutation>;
export type ResetPinCodeMutationOptions = Apollo.BaseMutationOptions<TResetPinCodeMutation, TResetPinCodeMutationVariables>;