import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { I18nContext } from 'components/I18n';
import { StyledText } from './Text';

const Price = ({ price, currency, ...otherProps }) => {
  const i18n = useContext(I18nContext);

  if (!currency) {
    return <></>;
  }

  return (
    <StyledText {...otherProps}>
      {i18n.formatCurrency(price, currency)}
    </StyledText>
  );
};

Price.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Price;
