import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Link from 'router/Link';
import * as billingActions from 'actions/billing';
import * as packsActions from 'actions/packs';
import logger from 'utils/logger';
import {
  PAYMENT_METHODS,
  ENTITLEMENT_TYPES,
  PAYMENT_PROVIDERS,
} from 'utils/constants';
import { useRouter } from 'router';
import * as routerActions from 'router/actions';
import {
  PrimaryButton,
  AsyncButton,
  Box,
  Text,
  Heading,
} from 'components';
import { useI18n } from 'components/I18n';
import { AdyenResultHandler } from 'components/Payment';
import { ClickTracker } from 'components/Tracking';
import CreditCardDisplay from 'components/CreditCard/CreditCardDisplay';
import PaypalDisplay from 'components/Paypal/PaypalDisplay';
import GooglePayDisplay from 'components/GooglePay/GooglePayDisplay';
import ApplePayDisplay from 'components/ApplePay/ApplePayDisplay';
import SettingsContainer from '../SettingsContainer';
import SubscriptionInfo from './SubscriptionInfo';
import { isSubscriptionActive } from './utils';

function PaymentMethodView() {
  const router = useRouter();
  const i18n = useI18n();
  const dispatch = useDispatch();

  const {
    entitlements,
    paymentMethod,
    discounts,
    withPayment,
    providerType,
    isSignUpViaMovistar,
  } = useSelector(({ packs, billing, settings, user }) => ({
    entitlements: packs.entitled,
    discounts: packs.discounts,
    paymentMethod: billing.paymentMethod,
    withPayment: !!settings.features.payment,
    providerType: settings.features.payment?.provider?.type,
    isSignUpViaMovistar: user.isSignUpViaMovistar,
  }), shallowEqual);

  const pack = entitlements.find(e => e.__typename === ENTITLEMENT_TYPES.SUBSCRIBE);
  const discount = discounts.find(d => d.discount.pack === pack.id);

  const updatePaymentMethod = async () => {
    if (providerType === PAYMENT_PROVIDERS.ADYEN_DROPIN) {
      dispatch(routerActions.push({
        name: 'settings-payment-setup',
      }));
      return;
    }
    try {
      await dispatch(billingActions.updatePaymentMethod(router.getUrl({ name: 'settings-payment' }, false)));
    } catch (e) {
      logger.error('Update payment method failed', e);
      throw i18n.formatText('error');
    }
  };

  return (
    <SettingsContainer>
      <Heading
        id="payment"
        letterSpacing="2.3px"
        mb="large"
      />

      {isSubscriptionActive(pack) && (
        <SubscriptionInfo
          pack={pack}
          discount={discount}
          paymentMethod={paymentMethod}
        />
      )}

      {paymentMethod?.method === PAYMENT_METHODS.CREDITCARD && (
        <Box mb="large">
          <CreditCardDisplay paymentMethod={paymentMethod} />
        </Box>
      )}

      {paymentMethod?.method === PAYMENT_METHODS.PAYPAL && (
        <Box mb="large">
          <PaypalDisplay />
        </Box>
      )}

      {paymentMethod?.method === PAYMENT_METHODS.GOOGLEPAY && (
        <Box mb="large">
          <GooglePayDisplay />
        </Box>
      )}

      {paymentMethod?.method === PAYMENT_METHODS.APPLEPAY && (
        <Box mb="large">
          <ApplePayDisplay />
        </Box>
      )}

      <Box mb="medium">
        {paymentMethod ? (
          <>
            <Box mb="medium">
              <ClickTracker
                component="Settings"
                element="change_payment_method"
                action="click"
                clickType="navigation"
              >
                <AsyncButton
                  alignCenter={false}
                  fixedWidth
                  variant="brand"
                  type="button"
                  onClick={updatePaymentMethod}
                  disabled={isSignUpViaMovistar}
                >
                  <Text id="paymentSettings.updatePaymentMethod" />
                </AsyncButton>
              </ClickTracker>
            </Box>

            {isSubscriptionActive(pack) && (
              <Box mb="medium" readableWidth>
                <Text
                  id="paymentSettings.updateMethodHelpMessage"
                  fontSize="small"
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {entitlements.length > 0 && (
              <ClickTracker
                component="Settings"
                element="add_payment_method"
                action="click"
                clickType="navigation"
              >
                <AsyncButton
                  alignCenter={false}
                  fixedWidth
                  variant="brand"
                  type="button"
                  onClick={updatePaymentMethod}
                  disabled={isSignUpViaMovistar}
                >
                  <Text id="paymentSettings.addPaymentMethod" />
                </AsyncButton>
              </ClickTracker>
            )}

            {entitlements.length === 0 && withPayment && (
              <PrimaryButton
                fixedWidth
                variant="brand"
                {...(isSignUpViaMovistar
                  ? { disabled: true }
                  : { to: { name: 'checkout' }, as: Link }
                )}
              >
                <Text id="startSubscription" />
              </PrimaryButton>
            )}
          </>
        )}
      </Box>

      <AdyenResultHandler />

    </SettingsContainer>
  );
}

export function initPaymentMethodView(store) {
  return Promise.all([
    store.dispatch(billingActions.getPaymentMethod()),
    store.dispatch(packsActions.fetchEntitledPacks()),
  ]);
}

export default React.memo(PaymentMethodView);
