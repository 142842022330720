import React, { useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { channelShape } from 'reducers/epg';
import {
  Link,
} from 'components';
import { ClickTracker } from 'components/Tracking';
import AdaptiveChannelLogo from 'components/ChannelLogo/AdaptiveChannelLogo';

export const CHANNEL_SIZE = {
  gap: 6, // gap between each channel
  width: 126,
  height: 87,
};

export const ROW_HEIGHT = CHANNEL_SIZE.height + CHANNEL_SIZE.gap;
export const OFFSET_TOP = CHANNEL_SIZE.gap;
export const OFFSET_BOTTOM = CHANNEL_SIZE.gap;

const Container = createComponent(({ theme }) => ({
  position: 'absolute',
  width: CHANNEL_SIZE.width,
  background: theme.color.epgBackground,
  boxShadow: '2px 2px 9px 0 rgba(0, 0, 0, 0.28)',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: 1,
}));

const Background = createComponent(({ theme }) => ({
  background: theme.color.divider,
  willChange: 'transform',
  height: '100%',
  overflow: 'visible',
}));

const Channel = createComponent(({ theme, disabled }) => ({
  display: 'block',
  textAlign: 'center',
  position: 'absolute',

  border: `1px solid ${theme.color.broadcastFutureBorder}`,
  borderRadius: '2px',
  transition: 'background 200ms ease',
  background: theme.color.page,
  overflow: 'hidden',
  extend: [
    {
      condition: disabled,
      style: {
        opacity: 0.7,
      },
    },
    {
      condition: !disabled,
      style: {
        ':hover': {
          background: theme.color.highlight,
          '& .hoverEdge': {
            display: 'block',
          },
        },
      },
    },
  ],
}), 'div', ['disabled']);
Channel.displayName = 'Channel';

const HoverEdge = createComponent(({ theme }) => ({
  display: 'none',
  height: 'calc(100% + 4px)',
  width: '4px',
  position: 'absolute',
  right: 'calc(100% - 4px)',
  top: '-2px',
  left: '-2px',
  background: theme.color.broadcastActiveHover,
}));

const LogoLink = createComponent(({ theme }) => ({
  display: 'block',
  height: '100%',
  transition: 'background 200ms ease',
  ':hover': {
    background: theme.color.highlight,
    textDecoration: 'none',
  },
}), Link);

const ChannelList = React.forwardRef(({ channels, onWheel, disabled }, ref) => {
  const collectionRef = useRef(null);
  const mouseOver = useRef(false);

  useImperativeHandle(ref, () => ({
    setScrollTop(scrollTop) {
      collectionRef.current.style.cssText = `transform: translateY(-${scrollTop}px)`;
    },
  }), []);

  const handleOnWheel = (e) => {
    onWheel(
      e.deltaY * (
        e.deltaMode === 1 // its a number of scrolled lines in FF https://bugzilla.mozilla.org/show_bug.cgi?id=1392460
          ? 40
          : 1
      ),
    );
  };

  const onMouseEnter = () => {
    mouseOver.current = true;
  };

  const onMouseLeave = () => {
    mouseOver.current = false;
  };

  const onPageScroll = (e) => {
    // fix broken "wheel" event in Safari by preventing body scroll
    // https://github.com/jquery/jquery-mousewheel/issues/156#issuecomment-185433754
    if (mouseOver.current) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', onPageScroll, true);
    return () => {
      window.removeEventListener('wheel', onPageScroll, true);
    };
  });

  const items = channels.map((channel, index) => {
    const style = {
      left: 0,
      top: OFFSET_TOP + index * ROW_HEIGHT - CHANNEL_SIZE.gap / 3,

      width: CHANNEL_SIZE.width,

      // eslint-disable-next-line no-mixed-operators
      height: CHANNEL_SIZE.height + (CHANNEL_SIZE.gap * 2 / 3),
    };
    const isDisabled = disabled.includes(channel.id);

    return (
      <Channel key={channel.id} style={style} disabled={isDisabled}>
        <ClickTracker
          component="EPG"
          eventName="click_channel_logo"
          action="click"
          clickType="asset"
          channelId={channel.id}
        >
          {isDisabled ? (
            <AdaptiveChannelLogo channel={channel} size={null} />
          ) : (
            <LogoLink to={{ name: 'watch', params: { id: channel.id } }}>
              <AdaptiveChannelLogo channel={channel} size={null} />
            </LogoLink>
          )}
        </ClickTracker>
        <HoverEdge className="hoverEdge" />
      </Channel>
    );
  });

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Background innerRef={collectionRef} onWheel={handleOnWheel}>
        {items}
      </Background>
    </Container>
  );
});

ChannelList.propTypes = {
  channels: PropTypes.arrayOf(channelShape).isRequired,
  onWheel: PropTypes.func.isRequired,
  disabled: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(ChannelList);
