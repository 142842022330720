export const SET_SCROLLABLE_OFFSET = 'scrollable/SET_SCROLLABLE_OFFSET';
interface SetScrollableOffsetAction {
  type: typeof SET_SCROLLABLE_OFFSET
  pageId: string
  id: string
  offset: number
}
export function setScrollableOffset(
  pageId: string,
  id: string,
  offset: number,
): SetScrollableOffsetAction {
  return {
    type: SET_SCROLLABLE_OFFSET,
    pageId,
    id,
    offset,
  };
}

export const SET_CLEAR_SCROLLABLE_OFFSET = 'scrollable/SET_CLEAR_SCROLLABLE_OFFSET';
interface SetClearScrollableOffsetAction {
  type: typeof SET_CLEAR_SCROLLABLE_OFFSET
  pageId: string
  id: string
  offset: number
}
export function setClearScrollableOffset(
  pageId: string,
  id: string,
  offset: number,
): SetClearScrollableOffsetAction {
  return {
    type: SET_CLEAR_SCROLLABLE_OFFSET,
    pageId,
    id,
    offset,
  };
}

export const SET_SCROLLABLE_SIZE = 'scrollable/SET_SCROLLABLE_SIZE';
interface SetScrollableSizeAction {
  type: typeof SET_SCROLLABLE_SIZE
  pageId: string
  id: string
  width: number
  fullWidth: number
  itemWidth?: number
  spacerWidth?: number
  marginLeftPx?: number
  length: number
}
// eslint-disable-next-line max-params
export function setScrollableSize(
  pageId: string,
  id: string,
  width: number,
  fullWidth: number,
  length: number,
  itemWidth?: number,
  spacerWidth?: number,
  marginLeftPx?: number,
): SetScrollableSizeAction {
  return {
    type: SET_SCROLLABLE_SIZE,
    pageId,
    id,
    width,
    fullWidth,
    length,
    itemWidth,
    spacerWidth,
    marginLeftPx,
  };
}

export const SCROLLABLE_SCROLL_LEFT = 'scrollable/SCROLLABLE_SCROLL_LEFT';
interface ScrollableScrollLeftAction {
  type: typeof SCROLLABLE_SCROLL_LEFT
  pageId: string
  id: string
}
export function scrollableScrollLeft(pageId: string, id: string): ScrollableScrollLeftAction {
  return {
    type: SCROLLABLE_SCROLL_LEFT,
    pageId,
    id,
  };
}

export const SCROLLABLE_SCROLL_RIGHT = 'scrollable/SCROLLABLE_SCROLL_RIGHT';
interface ScrollableScrollRightAction {
  type: typeof SCROLLABLE_SCROLL_RIGHT
  pageId: string
  id: string
}
export function scrollableScrollRight(pageId: string, id: string): ScrollableScrollRightAction {
  return {
    type: SCROLLABLE_SCROLL_RIGHT,
    pageId,
    id,
  };
}

export const RESET_SCROLLABLE_OFFSET = 'scrollable/RESET_SCROLLABLE_OFFSET';
interface ResetScrollableOffsetAction {
  type: typeof RESET_SCROLLABLE_OFFSET
  pageId: string
}
export function resetScrollableOffset(pageId: string): ResetScrollableOffsetAction {
  return {
    type: RESET_SCROLLABLE_OFFSET,
    pageId,
  };
}
export type ScrollableActions = (
  SetScrollableOffsetAction
  | SetClearScrollableOffsetAction
  | SetScrollableSizeAction
  | ScrollableScrollLeftAction
  | ScrollableScrollRightAction
  | ResetScrollableOffsetAction
);
