import { VIEWABLE_TYPES } from 'utils/constants';

export function getChannelLogoData(viewable) {
  if (viewable.__typename === VIEWABLE_TYPES.Channel) {
    return {
      title: viewable.title,
      logoDark: viewable.logoDark,
    };
  }

  if (viewable.__typename === VIEWABLE_TYPES.Show) {
    const channel = viewable.selectedEpisode?.defaultPlayable?.channel;
    if (!channel) {
      return null;
    }

    return {
      title: channel.title,
      logoDark: channel.logoDark,
    };
  }

  const channel = viewable.defaultPlayable?.channel;
  if (!channel) {
    return null;
  }

  return {
    title: channel.title,
    logoDark: channel.logoDark,
  };
}
