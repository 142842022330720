import { AppAction } from './helpers';

export const CLEAR_MESSAGE = 'messages/CLEAR_MESSAGE';
interface ClearMessageAction {
  type: typeof CLEAR_MESSAGE
  payload: {
    key: string,
  },
}
export const removeMessage = (messageKey: string): ClearMessageAction => ({
  type: CLEAR_MESSAGE,
  payload: {
    key: messageKey,
  },
});

let initialKey = 1;

export const ADD_MESSAGE = 'messages/ADD_MESSAGE';
interface AddMessageAction {
  type: typeof ADD_MESSAGE
  payload: {
    key: number,
    contentId: string,
    contentValue?: Record<string, string>,
    duration: number,
    type?: string,
  },
}
type MessageConfig = {
  contentId: string,
  contentValue?: Record<string, string>,
  duration?: number,
  type?: string,
};
export function addMessage(
  { contentId, duration = 10, type, contentValue }: MessageConfig,
): AppAction {
  return (dispatch) => {
    const key = initialKey;
    initialKey += 1;

    dispatch<AddMessageAction>({
      type: ADD_MESSAGE,
      payload: {
        key,
        contentId,
        duration,
        type,
        contentValue,
      },
    });

    if (duration) {
      setTimeout((k) => {
        dispatch(removeMessage(k));
      }, duration * 1000, key);
    }
  };
}

export type MessagesActions = ClearMessageAction | AddMessageAction;
