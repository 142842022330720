import PropTypes from 'prop-types';
import {
  VIEWABLE_TYPES,
  PLAYABLE_KINDS,
  BLOCK_INTERFACE_TYPES,
} from 'utils/constants';
import { collectionUIShape } from 'components/CollectionUI/prop-types';
import { genreShape } from 'components/GenreCard/GenreCard';

const entitlementShape = PropTypes.shape({
  id: PropTypes.string,
});
// This note can be removed, shouldn't shedule be schedule?
const sheduleItemShape = PropTypes.shape({
  banner: PropTypes.string,
  title: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  stop: PropTypes.number.isRequired,
  catchup: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
  }),
});

const playableShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(PLAYABLE_KINDS)).isRequired,
  duration: PropTypes.number,
  durationHuman: PropTypes.string,
  startTimeUtc: PropTypes.number,
  watchOffset: PropTypes.number,
});

const seasonShape = PropTypes.shape({
  seasonNumber: PropTypes.number,
});

const nodeProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.string,
  banner: PropTypes.string,
  image: PropTypes.string,
};

const tagToRenderShape = PropTypes.shape({
  title: PropTypes.string,
  translationKey: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
});
export const channelShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Channel]).isRequired,

  logoDark: PropTypes.string,
  logoLight: PropTypes.string,
  schedule: PropTypes.arrayOf(sheduleItemShape),
  playable: playableShape.isRequired,
  entitlement: entitlementShape,
  tagsToRender: PropTypes.arrayOf(tagToRenderShape),
});
const showShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Show]).isRequired,
  rating: PropTypes.string,
  productionYear: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  seasons: PropTypes.arrayOf(seasonShape).isRequired,
  inMyList: PropTypes.bool,
  entitlement: entitlementShape,
  tagsToRender: PropTypes.arrayOf(tagToRenderShape),
});
const episodeShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Episode]).isRequired,
  rating: PropTypes.string,
  duration: PropTypes.number.isRequired,
  durationHuman: PropTypes.string,
  seasonNumber: PropTypes.number.isRequired,
  episodeNumber: PropTypes.number.isRequired,
  defaultPlayable: playableShape.isRequired,
  entitlement: entitlementShape,
  show: showShape,
  tagsToRender: PropTypes.arrayOf(tagToRenderShape),
});
const movieShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Movie]).isRequired,
  rating: PropTypes.string,
  duration: PropTypes.number,
  durationHuman: PropTypes.string,
  productionYear: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  defaultPlayable: playableShape.isRequired,
  inMyList: PropTypes.bool,
  entitlement: entitlementShape,
  tagsToRender: PropTypes.arrayOf(tagToRenderShape),
});
const programShape = PropTypes.shape({
  ...nodeProps,
  __typename: PropTypes.oneOf([VIEWABLE_TYPES.Program]).isRequired,
  defaultPlayable: playableShape.isRequired,
  entitlement: entitlementShape,
  genres: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  tagsToRender: PropTypes.arrayOf(tagToRenderShape),
});

export const viewableShape = PropTypes.oneOfType([
  channelShape,
  episodeShape,
  showShape,
  movieShape,
  programShape,
]);

export const collectionShape = PropTypes.shape({
  __typename: PropTypes.oneOf(Object.values(BLOCK_INTERFACE_TYPES)).isRequired,
  magineId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  collectionUI: collectionUIShape,
  viewables: PropTypes.arrayOf(viewableShape).isRequired,
  links: PropTypes.arrayOf(genreShape).isRequired,
  hasNextPage: PropTypes.bool.isRequired,
});
