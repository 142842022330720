import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { useAdditionalInfo, useIsMediumScreen } from 'utils/hooks';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import { Box, Text, Spacer, TVODCountdown, CatchupCountdown } from 'components';
import { useSelector } from 'react-redux';
import OfferButtons from './OfferButtons';
import { TrailerButton, TrailerProvider } from './Trailer';
import { renderTitle } from '../utils';
import MobileButton from './MobileButton/MobileButton';
import InfoChannelLogo from './InfoChannelLogo';
import EventCounter from './EventCounter';
import PlayButton from './PlayButton';

const DETAILS_WIDTH = '50%';

function ViewableInfo({ viewable, refreshData, onPlay, isPlayBtnVisible, isPlayDisabled }) {
  const isMediumScreen = useIsMediumScreen();
  const isSignUpViaMovistar = useSelector(state => state.user.isSignUpViaMovistar);
  const additionalInfo = useAdditionalInfo(viewable);

  return (
    <TrailerProvider viewable={viewable}>
      <Box height="48vw" md-height="0" maxHeight="70vh" hideEmpty={false} />

      {viewable.entitlement && (
        <Box row mb="medium" justifyContent="center">
          <MobileButton />
        </Box>
      )}

      <EventCounter viewable={viewable} refreshData={refreshData} />

      <InfoChannelLogo viewable={viewable} />

      <Box mb="large" md-width={DETAILS_WIDTH}>
        <Text
          as="h1"
          color="primary"
          fontSize="xlarge"
          xl-fontSize="xxxlarge"
          bold
          wordBreak="break-word"
          textShadow="normal"
        >
          {renderTitle(viewable)}
        </Text>
      </Box>

      <Box row justifyContent="start" rowGap="small" wrap>
        {isPlayBtnVisible && (
          <Box width="100%" sm-width="auto" sm-mr="small">
            <PlayButton
              onPlay={isPlayDisabled ? undefined : onPlay}
              viewable={viewable}
              disabled={isPlayDisabled}
            />
          </Box>
        )}
        {!viewable.entitlement && !isSignUpViaMovistar && (
          <OfferButtons viewable={viewable} />
        )}

        {(onPlay || (!viewable.entitlement && !isSignUpViaMovistar)) && (
          <Box width="100%" md-width="0" hideEmpty={false} />
        )}

        <Box flexBox mr="large" sm-mr="xlarge" md-mr="0">
          <WatchlistButton
            viewable={viewable}
            refreshData={refreshData}
            short={!isMediumScreen}
            big
          />
        </Box>

        <TrailerButton short={!isMediumScreen} />
      </Box>

      {viewable.entitlement && (
        <Box mt="medium">
          <TVODCountdown entitlement={viewable.entitlement} refreshData={refreshData} />
        </Box>
      )}

      <Spacer height="large" xl-height="giant" />

      <Box mb="medium" md-width={DETAILS_WIDTH}>
        <Text
          textShadow="normal"
          fontSize="normal"
          opacity="0.7"
          textNumberLines={1}
        >
          {additionalInfo}
          <CatchupCountdown
            viewable={viewable}
            refreshData={refreshData}
          />
        </Text>
      </Box>

      <Box md-row md-justifyContent="space-between" lineHeight="1.5em">
        <Box mb="medium" xl-mb="xxlarge" md-width={DETAILS_WIDTH}>
          <Text textShadow="normal" fontSize="normal" textNumberLines={3}>{viewable?.description}</Text>
        </Box>
      </Box>

    </TrailerProvider>
  );
}

ViewableInfo.propTypes = {
  viewable: viewableShape.isRequired,
  refreshData: PropTypes.func.isRequired,
  onPlay: PropTypes.func,
  isPlayBtnVisible: PropTypes.bool,
  isPlayDisabled: PropTypes.bool,
};

export default React.memo(ViewableInfo);
