import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TAddToWatchlistMutationVariables = Types.Exact<{
  viewableId: Types.Scalars['String'];
}>;


export type TAddToWatchlistMutation = { __typename?: 'Mutation', addToMyList?: { __typename?: 'AddToMyListPayload', id?: string | null } | null };


export const AddToWatchlistDocument = gql`
    mutation addToWatchlist($viewableId: String!) {
  addToMyList(input: {viewableId: $viewableId, clientMutationId: ""}) {
    id: viewableId
  }
}
    `;
export type TAddToWatchlistMutationFn = Apollo.MutationFunction<TAddToWatchlistMutation, TAddToWatchlistMutationVariables>;

/**
 * __useAddToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWatchlistMutation, { data, loading, error }] = useAddToWatchlistMutation({
 *   variables: {
 *      viewableId: // value for 'viewableId'
 *   },
 * });
 */
export function useAddToWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<TAddToWatchlistMutation, TAddToWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TAddToWatchlistMutation, TAddToWatchlistMutationVariables>(AddToWatchlistDocument, options);
      }
export type AddToWatchlistMutationHookResult = ReturnType<typeof useAddToWatchlistMutation>;
export type AddToWatchlistMutationResult = Apollo.MutationResult<TAddToWatchlistMutation>;
export type AddToWatchlistMutationOptions = Apollo.BaseMutationOptions<TAddToWatchlistMutation, TAddToWatchlistMutationVariables>;