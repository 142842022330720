import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import Text from 'components/StyledSystem/Text';
import { Box } from 'components/StyledSystem/Box';

const ButtonContainer = createComponent(() => ({
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
  outline: 0,
  padding: '5px',
}), 'button');

const Svg = createComponent(({ theme }) => ({
  color: theme.color.brand,
  fill: theme.color.brand,
  stroke: theme.color.brand,
}), 'svg');

function RetryButton({ onClick }) {
  return (
    <ButtonContainer onClick={onClick}>
      <Box row alignItems="center">
        <Box mr="fine">
          <Svg width="24" height="20" viewBox="0 0 24 20">
            <g fillRule="evenodd">
              <g transform="translate(15 10)">
                <mask id="b" fill="rgb(255, 255, 255)" stroke="none">
                  <path id="a" d="M4 6L0 0h8z" />
                </mask>
                <path mask="url(#b)" d="M13 11H-5V-5h18" />
              </g>
              <path
                fill="none"
                d="M19 10c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9"
                strokeWidth="2"
              />
            </g>
          </Svg>
        </Box>
        <Text bold color="brand" id="retry" />
      </Box>
    </ButtonContainer>
  );
}

RetryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RetryButton;
