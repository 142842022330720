export const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

export const DATE_FORMAT = {
  POSTER: 'd MMM',
  HERO: 'd MMMM',
};

export const TITLE_TYPES = {
  POSTER: 'POSTER',
  HERO: 'HERO',
};
