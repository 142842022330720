import React from 'react';
import { Text } from 'components';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';

const NotFoundView = () => (
  <ErrorPage icon={ERROR_ICONS.NOT_FOUND}>
    <Text id="error.404" isHtml />
  </ErrorPage>
);

export default NotFoundView;
