import React from 'react';
import Icon from './Icon';

export default function PlusIcon(props) {
  return (
    <Icon width="47" height="47" viewBox="0 0 47 47" fill="none" {...props}>
      <path
        d="M21.9942 24.403H14.3967C14.0567 24.403 13.7696 24.2839 13.5352 24.0457C13.3008 23.8075 13.1836 23.5231 13.1836 23.1926C13.1836 22.8621 13.3008 22.5808 13.5352 22.3487C13.7696 22.1166 14.0567 22.0006 14.3967 22.0006H21.9942V14.403C21.9942 14.0718 22.1117 13.7868 22.3468 13.5481C22.5818 13.3093 22.8622 13.1899 23.188 13.1899C23.5138 13.1899 23.7966 13.3093 24.0366 13.5481C24.2766 13.7868 24.3966 14.0718 24.3966 14.403V22.0006H31.9942C32.3188 22.0006 32.5981 22.1182 32.8323 22.3535C33.0665 22.5888 33.1836 22.8717 33.1836 23.2022C33.1836 23.5327 33.0665 23.8155 32.8323 24.0505C32.5981 24.2855 32.3188 24.403 31.9942 24.403H24.3966V32.0006C24.3966 32.3251 24.2768 32.6045 24.0372 32.8387C23.7976 33.0728 23.5114 33.1899 23.1788 33.1899C22.8529 33.1899 22.574 33.0728 22.3421 32.8387C22.1102 32.6045 21.9942 32.3251 21.9942 32.0006V24.403Z"
        fill="currentColor"
      />
    </Icon>
  );
}
