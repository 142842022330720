export const getDefaultFormStateFromQuery = (inputGroups, query) => {
  const defaultValues = {};
  const disabledFields = {};

  for (const { inputFields } of inputGroups) {
    for (const { key, inputType, pickerValues } of inputFields) {
      if (query[key] && inputType !== 'picker') {
        defaultValues[key] = query[key];
      }

      if (query[key] && inputType === 'picker' && pickerValues.find(pv => pv.value === query[key])) {
        disabledFields[key] = true;
        defaultValues[key] = query[key];
      }
    }
  }

  return {
    defaultValues,
    disabledFields,
  };
};
