import { PageActions, UPDATE_PAGE } from 'actions/page';

type Page = {
  id: string,
  lastViewDate: Date,
  isExpired: boolean
};
type PageReducerState = Record<string, Page>;
const initialState: PageReducerState = {};

export function pageReducer(
  store = initialState,
  action: PageActions,
): PageReducerState {
  switch (action.type) {
    case UPDATE_PAGE: {
      return {
        ...store,
        [action.payload.id]: {
          ...store[action.payload.id],
          ...action.payload,
        },
      };
    }

    default: {
      return store;
    }
  }
}
