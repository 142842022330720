import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Box,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  Panel,
  PanelWrapper,
} from 'components';
import Link from 'router/Link';
import createComponent from 'styles/fela/createComponent';

export const ErrorScreen = createComponent(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.color.page,
  zIndex: 2,
}));

const ErrorScreenOverlay = ({ username, handleUseAnotherAccount }) => {
  const {
    devicePlatform,
    brandName,
  } = useSelector((state) => {
    const devicePlatformState = state.router.location.params?.platform;

    return ({
      devicePlatform: devicePlatformState,
      brandName: state.settings.brand.name,
    });
  }, shallowEqual);

  return (
    <ErrorScreen>
      <PanelWrapper>
        <Panel textAlign="center" maxWidth="34rem">
          <Heading mb="small" align="center">
            <Text id="createAccount.errDuplicateEmail.heading" />
          </Heading>

          <Box mb="medium" textAlign="center" fullWidth>
            <Text
              fontSize="medium"
              id="createAccount.errDuplicateEmail.subHeading"
              values={{
                brandName,
              }}
            />
          </Box>
          <Heading fontSize="medium" mb="xlarge" as="h2" align="center">
            {username}
          </Heading>

          <Box my="medium" align="center">
            <PrimaryButton
              to={{
                name: 'log-in',
                params: {
                  platform: devicePlatform,
                },
              }}
              as={Link}
              fixedWidth
              variant="brand"
            >
              <Text id="logIn" />
            </PrimaryButton>
          </Box>
          <Box my="medium" align="center">
            <SecondaryButton
              variant="brandSecondary"
              onClick={handleUseAnotherAccount}
            >
              <Text id="createAccount.errDuplicateEmail.useAnotherAccount" />
            </SecondaryButton>
          </Box>
          <Box mt="large" align="center">
            <Text id="createAccount.errDuplicateEmail.cancelSubscription" />
          </Box>
        </Panel>
      </PanelWrapper>
    </ErrorScreen>
  );
};

ErrorScreenOverlay.propTypes = {
  username: PropTypes.string.isRequired,
  handleUseAnotherAccount: PropTypes.func.isRequired,
};

export default React.memo(ErrorScreenOverlay);
