import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet-async';
import { truncateLines } from 'styles/fela/mixins';
import { getGoogleFontLink, hashCode } from 'utils/helpers';
import { LAYOUT_OBJECT_TYPES } from 'utils/constants';
import { PrimaryButton } from 'components';
import { useDispatch } from 'react-redux';
import { push } from 'router/actions';
import Logger from 'utils/logger';
import { useFontHeight } from 'utils/hooks';
import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { CollectionType } from 'components/CollectionUI/types';
import { GlobalTheme, LayoutObjectType } from 'types';

const CTA_TARGET_TYPES = {
  COLLECTIONS: 'collections',
  URL_PROPERTY: 'urlProperty',
  COLLECTION: 'collection',
  OFFER: 'offer',
};

const getContainerStyles = (
  { theme, fontSize, layoutObject, lineHeight }: ComponentRuleProps,
): RuleStyles => ({
  display: 'block',
  fontSize,

  extend: [
    {
      condition: layoutObject.objectVerticalSpacing,
      style: {
        marginBottom: layoutObject.objectVerticalSpacing,
      },
    },
    {
      condition: layoutObject.textFontName,
      style: {
        fontFamily: `'${layoutObject.textFontName}', ${theme.fontFamily}`,
      },
    },
    {
      condition: layoutObject.textFontColor,
      style: {
        color: layoutObject.textFontColor,
      },
    },
    {
      condition: layoutObject.textCaps,
      style: {
        textTransform: 'uppercase',
      },
    },
    {
      condition: !layoutObject.textCaps,
      style: {
        textTransform: 'initial',
      },
    },
    {
      condition: layoutObject.textBold,
      style: {
        fontWeight: 'bold',
      },
    },
    {
      condition: layoutObject.textNumberLines,
      style: truncateLines(layoutObject.textNumberLines, fontSize, lineHeight),
    },
    {
      condition: layoutObject.textAlignment
        && layoutObject.type !== LAYOUT_OBJECT_TYPES.buttonCta,
      style: {
        textAlign: layoutObject.textAlignment,
      },
    },
    {
      condition: layoutObject.textAlignment
        && layoutObject.type === LAYOUT_OBJECT_TYPES.buttonCta,
      style: {
        marginLeft: ['center', 'right'].includes(layoutObject.textAlignment) ? 'auto' : undefined,
        marginRight: ['center', 'left'].includes(layoutObject.textAlignment) ? 'auto' : undefined,
      },
    },
    {
      condition: layoutObject.type === LAYOUT_OBJECT_TYPES.buttonCta,
      style: {
        width: 'fit-content',
      },
    },
  ],
});

type LayoutObjectContainerProps = {
  layoutObject: LayoutObjectType,
  children: React.ReactNode,
  collection: CollectionType,
};

type ContainerType = {
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: () => void,
};

function LayoutObjectContainer(
  { layoutObject, collection, children }: LayoutObjectContainerProps,
): JSX.Element | null {
  const restProps: ContainerType = {
    onClick: () => null,
  };
  const dispatch = useDispatch();
  const { theme, css } = useFela<GlobalTheme>();
  const fontSize = layoutObject.textFontSize
    ? `${layoutObject.textFontSize}px`
    : theme.fontSize.normal;

  const lineHeight = useFontHeight({
    fontName: layoutObject.textFontName,
    fontSize,
    isBold: Boolean(layoutObject.textBold),
  });

  const Container = useMemo(() => {
    if (layoutObject.type === LAYOUT_OBJECT_TYPES.buttonCta) {
      if (
        layoutObject.ctaTargetType === CTA_TARGET_TYPES.URL_PROPERTY
        && layoutObject.ctaTarget !== 'webview'
      ) {
        return (props: ContainerType) => React.createElement(PrimaryButton, {
          as: 'a',
          href: layoutObject.ctaTargetValue, // eslint-disable-line react/prop-types
          target: layoutObject.ctaTarget === 'blank' ? '_blank' : undefined, // eslint-disable-line react/prop-types
          ...props,
        });
      }

      return PrimaryButton;
    }

    return 'div';
  }, [
    layoutObject.type,
    layoutObject.ctaTargetType,
    layoutObject.ctaTargetValue,
    layoutObject.ctaTarget,
  ]);

  if (!children) {
    return null;
  }

  if (layoutObject.type === LAYOUT_OBJECT_TYPES.buttonCta) {
    switch (layoutObject.ctaTargetType) {
      case CTA_TARGET_TYPES.COLLECTION: {
        restProps.onClick = () => dispatch(push({
          name: 'collection',
          params: {
            id: layoutObject.ctaTargetValue,
          },
        }));
        break;
      }
      case CTA_TARGET_TYPES.COLLECTIONS: {
        restProps.onClick = () => dispatch(push({
          name: 'collections',
          params: {
            id: layoutObject.ctaTargetValue,
          },
        }));
        break;
      }
      case CTA_TARGET_TYPES.OFFER: {
        restProps.onClick = () => dispatch(push({
          name: 'checkout-confirmation',
          query: {
            packageId: layoutObject.ctaTargetValue,
          },
        }));
        break;
      }
      case CTA_TARGET_TYPES.URL_PROPERTY: {
        if (layoutObject.ctaTarget === 'webview') {
          restProps.onClick = () => dispatch(push({
            name: 'collectionWebview',
            params: {
              collectionId: collection.magineId,
              encodedUrl: hashCode(layoutObject.ctaTargetValue),
            },
          }));
        }
        break;
      }

      default: {
        Logger.warn(`ctaTargetType "${layoutObject.ctaTargetType}" is not handled, skipping click event`);
      }
    }
  }

  const className = css(getContainerStyles({
    theme,
    fontSize,
    lineHeight,
    layoutObject,
  }));

  return (
    <Container className={className} {...restProps}>
      {layoutObject.textFontName && (
        <Helmet>
          <link
            key={layoutObject.textFontName}
            rel="stylesheet"
            href={getGoogleFontLink(layoutObject.textFontName)}
          />
        </Helmet>
      )}
      {children}
    </Container>
  );
}

export default React.memo(LayoutObjectContainer);
