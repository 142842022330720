// based on https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
function isStorageSupported(storage) {
  try {
    if (!__CLIENT__ || !storage) {
      return false;
    }

    const testKey = '------magine-random-test-key';
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);

    return true;
  } catch (e) {
    return false;
  }
}

class Storage {
  _storage = null;
  _inMemStorage = {};

  constructor(storage) {
    if (isStorageSupported(storage)) {
      this._storage = storage;
    }
  }

  getItem(key) {
    if (this._storage) {
      return this._storage.getItem(key);
    }

    return this._inMemStorage[key];
  }

  setItem(key, value) {
    if (this._storage) {
      this._storage.setItem(key, value);
    } else {
      this._inMemStorage[key] = String(value);
    }
  }

  removeItem(key) {
    if (this._storage) {
      this._storage.removeItem(key);
    } else {
      delete this._inMemStorage[key];
    }
  }

  clone() {
    if (this._storage) {
      return {
        ...this._storage,
      };
    }

    return {
      ...this._inMemStorage,
    };
  }

  clear() {
    if (this._storage) {
      this._storage.clear();
    } else {
      this._inMemStorage = {};
    }
  }
}

export const localStorage = new Storage(global.localStorage);
export default localStorage;
export const sessionStorage = new Storage(global.sessionStorage);
