import React, { Component } from 'react';

export default function withProps(WrappedComponent, props) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return class extends Component { // eslint-disable-line react/prefer-stateless-function
    static displayName = `withProps(${displayName})`;

    render() {
      return (
        <WrappedComponent {...props} {...this.props} />
      );
    }
  };
}
