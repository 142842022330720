import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { viewableShape } from 'types/viewableShape';
import logger from 'utils/logger';
import { getButtonProps } from 'views/ContentListView/Featured/utils';
import OfferButton from './OfferButton';

function OfferButtons({ viewable }) {
  const withPayment = useSelector(state => !!state.settings.features.payment?.content);

  const buttons = useMemo(
    () => !viewable?.entitlement && getButtonProps(viewable?.offers),
    [viewable?.offers],
  );

  if (!withPayment) {
    logger.info('Available offer buttons not displayed because payment is disabled.');
    return [];
  }

  return buttons.map((button, i) => (
    <OfferButton
      key={button.types}
      viewable={viewable}
      offer={button.offer}
      types={button.types}
      isSingleOffer={button.isSingleOffer}
      isPrimary={!i}
    />
  ));
}

OfferButtons.propTypes = {
  viewable: viewableShape.isRequired,
};

export default React.memo(OfferButtons);
