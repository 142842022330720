import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/StyledSystem/Text';
import { Box } from 'components/StyledSystem/Box';
import PrimaryButton from 'components/Button/PrimaryButton';
import Link from 'router/Link';
import RetryButton from './RetryButton';
import { ButtonType } from './constants';

function ErrorActionButton({ type, to, href, label }) {
  switch (type) {
    case ButtonType.HOME:
      return (
        <Box my="medium">
          <PrimaryButton variant="brand" to={{ name: 'home' }} as={Link}>
            <Text id="home" />
          </PrimaryButton>
        </Box>
      );
    case ButtonType.RETRY:
      return (
        <RetryButton onClick={() => window.location.reload()} />
      );
    case ButtonType.LINK:
      return (
        <Box my="medium">
          <PrimaryButton variant="brand" to={to} as={Link}>
            {label}
          </PrimaryButton>
        </Box>
      );
    case ButtonType.HREF:
      return (
        <Box my="medium">
          <PrimaryButton variant="brand" href={href} as="a">
            {label}
          </PrimaryButton>
        </Box>
      );
    default:
      return null;
  }
}

ErrorActionButton.propTypes = {
  type: PropTypes.oneOf(Object.values(ButtonType)),
  to: PropTypes.object,
  href: PropTypes.string,
  label: PropTypes.node,
};

export default React.memo(ErrorActionButton);
