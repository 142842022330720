import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getScrollableState } from 'reducers/scrollable';
import { scrollableScrollLeft, scrollableScrollRight, setScrollableOffset } from 'actions/scrollable';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import { usePrevious } from 'utils/hooks';
import { ThemeContext } from 'react-fela';
import { responsiveRem2Px } from 'utils/helpers';
import { ItemContainer, ArrowContainer, Arrow, ScrollableContainer } from './Styles';

const Scrollable = ({
  id,
  pageId,
  children,
  loadMore,
  hasMoreLoad,
  itemWidthPx = 0,
  itemWidthRem = 0,
  scrollToNewItem,
}) => {
  const {
    hasNext,
    hasPrev,
  } = useSelector(state => getScrollableState(state, pageId, id), shallowEqual);
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const itemContainerMarginLeftRem = parseFloat(theme.margin.medium);
  const itemContainerMarginRightRem = 0.125;
  const itemContainerMarginRem = itemContainerMarginLeftRem + itemContainerMarginRightRem;

  const previousFirstChildKey = usePrevious(children?.[0]?.key);

  useEffect(() => {
    if (
      scrollToNewItem
      && children.length > 1
      && previousFirstChildKey
      && children[0].key !== previousFirstChildKey
      && children[1].key === previousFirstChildKey
    ) {
      // new item is added
      // scroll collection to 0 position after rendering
      setTimeout(() => dispatch(setScrollableOffset(pageId, id, 0)));
    }
  }, [scrollToNewItem, children, previousFirstChildKey]);

  const items = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }

    return (
      <ItemContainer
        key={child.key || undefined}
        marginLeft={`${itemContainerMarginLeftRem}rem`}
        marginRight={`${itemContainerMarginRightRem}rem`}
      >
        {child}
      </ItemContainer>
    );
  });

  return (
    <ScrollableContainer>
      <ArrowContainer position="left" isVisible={hasPrev}>
        <Arrow
          position="left"
          isVisible={hasPrev}
          onClick={() => dispatch(scrollableScrollLeft(pageId, id))}
          data-cy="left-arrow"
          className="arrow"
        >
          ❮
        </Arrow>
      </ArrowContainer>

      <HorizontalScroll
        pageId={pageId}
        id={id}
        loadMore={loadMore}
        hasMoreLoad={hasMoreLoad}
        itemWidthRem={itemWidthRem || itemWidthPx
          ? itemWidthRem + itemContainerMarginRem
          : itemWidthRem}
        itemWidthPx={itemWidthPx}
        itemMarginLeftPx={responsiveRem2Px(itemContainerMarginLeftRem)}
        itemMarginRightPx={responsiveRem2Px(itemContainerMarginRightRem)}
        spacer
      >
        {items}
      </HorizontalScroll>

      <ArrowContainer position="right" isVisible={hasNext}>
        <Arrow
          position="right"
          isVisible={hasNext}
          onClick={() => dispatch(scrollableScrollRight(pageId, id))}
          data-cy="right-arrow"
          className="arrow"
        >
          ❯
        </Arrow>
      </ArrowContainer>
    </ScrollableContainer>
  );
};

Scrollable.propTypes = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  scrollToNewItem: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func,
  hasMoreLoad: PropTypes.bool,
  itemWidthRem: PropTypes.number,
  itemWidthPx: PropTypes.number,
};

export default Scrollable;
