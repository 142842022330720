import Link from 'router/Link';
import { colorWithOpacity } from 'utils/helpers';
import createComponent from 'styles/fela/createComponent';
import { reserveBoldTextWidth, withBasePadding } from 'styles/fela/mixins';

export const Nav = createComponent(({ theme, isExpanded, position }) => ({
  background: theme.color.page,
  color: theme.color.primary,
  boxShadow: `0 0 10px 0 ${theme.color.overlayDarkExtraFaint}`,
  clipPath: 'inset(0px 0px -10px 0px)',
  flexGrow: 1,
  flexShrink: 1,
  left: 0,
  position: 'absolute',
  top: theme.header.height,
  width: '100%',

  extend: [
    {
      condition: position === 'horizontal',
      style: {
        background: 'none',
        color: 'inherit',
        boxShadow: 'none',
        height: theme.header.height,
        paddingBottom: 0,
        paddingTop: 0,
        position: 'static',
        display: 'flex',
      },
    },
    {
      condition: position === 'vertical',
      style: {
        display: 'none',
      },
    },
    {
      condition: isExpanded,
      style: {
        display: 'block',
      },
    },
  ],
}), 'nav', ['isExpanded', 'position']);

export const MenuContainer = createComponent((
  {
    theme,
    align,
    position = 'vertical',
  },
) => ({
  display: 'block',
  width: '100%',
  fontSize: theme.fontSize.small,
  '> a': {
    display: 'block',
    width: '100%',
  },
  extend: [
    {
      condition: position === 'horizontal',
      style: {
        display: 'flex',
        '> a': {
          display: 'inline-block',
          width: 'auto',
        },
      },
    },
    {
      condition: align === 'left',
      style: {
        flexShrink: '3.2', // resolved IE11 issue with blocking shrinking of empty containeer
        justifyContent: 'flex-start',
      },
    },
    {
      condition: align === 'right',
      style: {
        justifyContent: 'flex-end',
      },
    },
    {
      condition: position === 'horizontal' && align === 'right',
      style: {
        '& a:last-of-type': {
          paddingRight: 0,
        },
      },
    },
  ],
}), 'div', ['align', 'position']);

export const LogoLink = createComponent(({ theme }) => ({
  marginRight: theme.margin.large,
  display: 'flex',
  height: theme.header.logo.height,
  width: theme.header.logo.width,
  alignItems: 'center',
  flexShrink: 0,
}), Link);

export const LogoImage = createComponent(() => ({
  height: 'auto',
  width: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
}), 'img');

export const Hamburger = createComponent(({ visible }) => ({
  alignSelf: 'center',
  background: 'transparent',
  border: 0,
  color: 'inherit',
  cursor: 'pointer',
  display: 'block',
  minHeight: '44px',
  minWidth: '44px',
  outline: 0,
  textAlign: 'right',
  extend: [
    {
      condition: !visible,
      style: {
        display: 'none',
      },
    },
  ],
}), 'button', ['visible']);

export const HeaderContainer = createComponent(({ theme, opacity, scroll }) => ({
  color: theme.header.textColor,
  height: theme.header.height,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.header,
  extend: [
    withBasePadding(),
  ],
  ':before': {
    // header gradient background before scroll
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: scroll === 'true' ? 0 : 1,
    background: `linear-gradient(${colorWithOpacity(theme.header.backgroundColor, opacity.beforeScroll.gradientStart)}, ${colorWithOpacity(theme.header.backgroundColor, opacity.beforeScroll.gradientEnd)})`,
    extend: [
      {
        // apply transition effect if before or after scroll header background has transparency
        condition: opacity.beforeScroll.gradientStart < 100
          || opacity.beforeScroll.gradientEnd < 100
          || opacity.afterScroll < 100,
        style: {
          extend: [
            {
              // do not apply transition effect if header has no gradient effect before scroll
              // and header same color before and after scroll
              condition: !(opacity.beforeScroll.gradientStart === opacity.beforeScroll.gradientEnd
                && opacity.beforeScroll.gradientEnd === opacity.afterScroll),
              style: {
                transition: `opacity 200ms ${scroll === 'true' ? 'ease-in' : 'ease-out'}`,
              },
            },
          ],
        },
      },
    ],
  },
  ':after': {
    // header gradient background after scroll
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: scroll === 'true' ? 1 : 0,
    background: `linear-gradient(${colorWithOpacity(theme.header.backgroundColor, opacity.afterScroll)}, ${colorWithOpacity(theme.header.backgroundColor, opacity.afterScroll)})`,
    extend: [
      {
        // apply transition effect if before or after scroll header background has transparency
        condition: opacity.beforeScroll.gradientStart < 100
          || opacity.beforeScroll.gradientEnd < 100
          || opacity.afterScroll < 100,
        style: {
          extend: [
            {
              // do not apply transition effect if header has no gradient effect before scroll
              // and header same color before and after scroll
              condition: !(opacity.beforeScroll.gradientStart === opacity.beforeScroll.gradientEnd
                && opacity.beforeScroll.gradientEnd === opacity.afterScroll),
              style: {
                transition: `opacity 200ms ${scroll === 'true' ? 'ease-out' : 'ease-in'}`,
              },
            },
          ],
        },
      },
    ],
  },
}), 'header', ['opacity']);

export const Content = createComponent(() => ({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  padding: 'inherit',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
}));

export const MenuLink = createComponent(({ theme, active }) => ({
  cursor: 'pointer',
  color: 'inherit',
  display: 'block',
  lineHeight: theme.header.height,
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  textAlign: 'center',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  ':hover': {
    fontWeight: 'bold',
  },
  extend: [
    reserveBoldTextWidth('data-content'),
    {
      condition: active,
      style: {
        fontWeight: 'bold',
      },
    },
  ],
}), Link, ['active']);
MenuLink.displayName = 'MenuLink';

export const HeaderSpacer = createComponent(({ theme }) => ({
  width: '100%',
  height: theme.header.height,
  background: theme.color.page,
}));

export const MenuNavigationWrapper = createComponent(({ offCanvas }) => ({
  flexGrow: 1,
  extend: [
    {
      condition: offCanvas,
      style: {
        position: 'absolute',
        top: -10000,
        left: -10000,
      },
    },
  ],
}), 'div', ['offCanvas']);
