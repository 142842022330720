import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { I18nContext } from 'components/I18n';
import ClientSideComponent from 'components/ClientSideComponent/ClientSideComponent';
import { StyledText } from './Text';

const TextDate = ({ value, options, ...otherProps }) => {
  const i18n = useContext(I18nContext);

  return (
    <StyledText {...otherProps}>
      <ClientSideComponent>{i18n.formatDate(value, options)}</ClientSideComponent>
    </StyledText>
  );
};

TextDate.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string,
  ]),
  options: PropTypes.object,
};

export default memo(TextDate, (prevProps, nextProps) => isEqual(prevProps, nextProps));
