import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { withSpinning } from 'styles/fela/mixins';

const Container = createComponent(({ size }) => ({
  display: 'inline-block',
  height: `${size}rem`,
  verticalAlign: 'middle',
  width: `${size}rem`,
  extend: [
    withSpinning(2000),
  ],
}), 'svg');

function Spinner({ size = 1.75, strokeWidth = 3 }) {
  return (
    <Container size={size} viewBox="0 0 32 32">
      <path fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={strokeWidth} d="M6.003 6.2A13.955 13.955 0 0 0 2 16c0 7.732 6.268 14 14 14s14-6.268 14-14S23.732 2 16 2" opacity=".787" />
    </Container>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default Spinner;
