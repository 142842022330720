import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import * as authActions from 'actions/auth';
import {
  PrimaryButton,
  FormattedError,
  Text,
  Link,
  Box,
  Heading,
  Panel,
  PanelContent,
  PanelWrapper,
} from 'components';
import { InputField } from 'components/Forms';
import ForgotPasswordSentView from './ForgotPasswordSentView';

function ForgotPasswordViewComponent() {
  const {
    createAccountEnabled,
    forgotPassword,
  } = useSelector(({ settings, country }) => ({
    createAccountEnabled: country.isCreateAccountEnabled,
    forgotPassword: settings.features.loginMethod.forgotPassword,
  }));
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState(null);
  const methods = useForm({ mode: 'onChange' });
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const { handleSubmit, setError } = methods;

  const submit = async (fields) => {
    setGeneralError(null);
    const response = await dispatch(authActions.requestPasswordChange(fields))
      .catch(() => {
        setGeneralError('forgotPassword.errFailed');
      });

    if (!response) return;

    if (!response.ok) {
      setError('email', {
        type: 'server',
        message: 'noAccount',
      });
      return;
    }

    setSubmitSucceeded(true);
  };

  if (submitSucceeded) {
    return (
      <ForgotPasswordSentView
        createAccountEnabled={createAccountEnabled}
        forgotPassword={forgotPassword}
      />
    );
  }

  return (
    <PanelWrapper className="e2e-forgot-password">
      <Panel textAlign="center">
        <Heading mb="small">
          {forgotPassword.title}
        </Heading>

        <Heading fontSize="medium" mb="medium">
          {forgotPassword.instructions}
        </Heading>
        <FormProvider {...methods}>
          <PanelContent centered as="form" onSubmit={handleSubmit(submit)} method="POST" noValidate>
            <InputField field={forgotPassword.inputField} />

            <FormattedError error={generalError} />

            <Box my="medium">
              <PrimaryButton variant="brand" type="submit">
                {/* MDM-11096 wrap text to avoid issues with Google Translate */}
                <span>
                  {forgotPassword.submitLabel}
                </span>
              </PrimaryButton>
            </Box>

            <Box mb="fine">
              <Link
                to={{ name: 'log-in' }}
                id="logIn"
                fontSize="small"
              />
            </Box>

            {createAccountEnabled && (
              <Text
                id="noAccountCreateAccount"
                values={{
                  createAccountLink: (
                    <Link
                      key="createAccountLink"
                      to={{ name: 'create-account' }}
                      id="createAccount"
                    />
                  ),
                }}
                color="secondary"
                fontSize="small"
              />
            )}
          </PanelContent>
        </FormProvider>
      </Panel>
    </PanelWrapper>
  );
}

export default React.memo(ForgotPasswordViewComponent);
