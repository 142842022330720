import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useRouter } from 'router';

// http://ogp.me/#types
export const OpenGraphTypes = {
  website: 'website',
  article: 'article',
  movie: 'video.movie',
  episode: 'video.episode',
  show: 'video.tv_show',
  otherVideo: 'video.other',
};

function getFaviconLinks(favicon) {
  const items = [];

  for (const [size, url] of Object.entries(favicon || {})) {
    items.push(<link key={size} rel="icon" sizes={size} href={url} />);
  }

  if (!items.length) {
    items.push( // just to prevent /favicon.ico requests
      <link key="empty" rel="icon" href="data:," />,
    );
  }

  return items;
}

function prepareDescription(originalDescription) {
  if (!originalDescription) {
    return '';
  }
  let description = originalDescription;

  if (Array.isArray(description)) {
    description = description.join('\n');
  }

  // https://moz.com/learn/seo/meta-description
  if (description.length > 300) { // trim description
    description = description.substring(0, 301);

    const trimPos = Math.max(
      150, // just a minimum length, so that description is not too short
      description.lastIndexOf('.'),
      description.lastIndexOf('!'),
      description.lastIndexOf('?'),
    );

    description = description.substring(0, trimPos);
    description = `${description}...`;
  }
  return description;
}

/**
 * Use this to set html metadata for SEO.
 */
function Metadata(props) {
  const router = useRouter();

  const {
    locale,
    favicon,
    brandName,
    headerLogo,
    location,
    query,
    welcomeLabel,
    shareImage,
    iosAppId,
    googlePlayLink,
  } = useSelector(state => ({
    locale: state.settings.l10n.language,
    favicon: state.settings.favicon,
    brandName: state.settings.brand.name,
    headerLogo: state.settings.brand.headerLogo,
    location: state.router.location,
    query: state.router.query,
    welcomeLabel: state.settings.features.welcome.label,
    shareImage: state.settings.localFeatures.shareImage,
    iosAppId: state.settings.appStoreLink?.match(/\/id(\d+)/)?.[1],
    googlePlayLink: state.settings.googlePlayLink,
  }), shallowEqual);

  const faviconLinks = getFaviconLinks(favicon);

  let title;
  const firstPartOfTitle = props.title?.trim() || welcomeLabel?.trim();
  if (firstPartOfTitle) {
    title = `${firstPartOfTitle} | ${brandName}`;
  } else {
    title = brandName;
  }

  const prepareImage = () => {
    if (props.imageUrl) {
      return props.imageUrl;
    }

    if (shareImage) {
      return router.getBaseUrl() + shareImage;
    }

    return headerLogo;
  };

  const description = prepareDescription(props.description);
  const image = prepareImage();

  const {
    url,
    canonicalUrl,
  } = React.useMemo(() => ({
    url: router.getUrl({
      name: location.name,
      params: location.params,
      query,
    }),

    // https://moz.com/learn/seo/canonicalization
    canonicalUrl: router.getUrl(props.canonicalData || {
      name: location.name,
      params: location.params,
      query: null,
    }),
  }), [location, query]);

  return (
    <Helmet>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <html lang={locale} prefix="og: http://ogp.me/ns#" />

      {faviconLinks}

      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=1.0, initial-scale=1.0"
      />
      <title>{title}</title>

      {/* Google et al. */}
      {description && (
        <meta name="description" content={description} />
      )}

      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph, for Facebook/Twitter/etc. */}
      {/* property="" is for Facebook, name="" is for others https://stackoverflow.com/a/22418095 */}
      <meta
        name="og:type"
        property="og:type"
        content={props.type || OpenGraphTypes.website}
      />
      <meta
        name="og:title"
        property="og:title"
        content={title}
      />
      <meta
        name="og:site_name"
        property="og:site_name"
        content={brandName}
      />
      {description && (
        <meta
          name="og:description"
          property="og:description"
          content={description}
        />
      )}
      <meta
        name="og:url"
        property="og:url"
        content={url}
      />
      <meta
        name="og:image"
        property="og:image"
        content={image}
      />
      {
        iosAppId && (
          <meta
            name="apple-itunes-app"
            content={`app-id=${iosAppId}`}
          />
        )
      }
      {
        googlePlayLink && (
          <link
            rel="manifest"
            href="/manifest.json"
          />
        )
      }
    </Helmet>
  );
}

Metadata.propTypes = {
  title: PropTypes.string, // allows to override default page title
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  imageUrl: PropTypes.string,
  type: PropTypes.oneOf(Object.values(OpenGraphTypes)),
  canonicalData: PropTypes.object,
};

export default React.memo(Metadata);
