import logger from 'utils/logger';
import { VIEWABLE_TYPES, OFFER_TYPES, OfferType } from 'utils/constants';
import { ViewableType, Offer } from 'types';

/**
 * Determine the title and image to display based on the type of the
 * viewable. Chanels will display the title and image for the currently
 * live broadcast.
 *
 * @param {Object} viewable The featured viewable
 */
export const getTitleAndImage = (
  viewable: ViewableType,
): {
  image: string | undefined;
  title: string,
} => {
  if (viewable.__typename === VIEWABLE_TYPES.Channel) {
    if (!viewable.schedule) {
      logger.warn(`Featured channel ${viewable.title}(${viewable.id}) does not have a schedule. Retrieving channel title and image.`);

      return {
        title: viewable.title,
        image: viewable.banner,
      };
    }

    if (viewable.schedule.length > 0) {
      const broadcast = viewable.schedule[0];
      return {
        title: broadcast.title,
        image: broadcast.banner,
      };
    }

    logger.info(`Featured channel ${viewable.title} (${viewable.id}) does not have a schedule. Using channel title and image.`);
  }

  return {
    title: viewable.title,
    image: viewable.banner,
  };
};

// order is defined in https://magine.atlassian.net/browse/MDM-8790
const offerTypes = [
  OFFER_TYPES.DefaultType, // should be on a first place
  OFFER_TYPES.SubscribeType,
  OFFER_TYPES.BuyType,
  OFFER_TYPES.RentType,
  OFFER_TYPES.PassType,
];

const multipleOfferTypesBtn = 'purchaseToWatch';
const offerTypeToText = {
  [OFFER_TYPES.SubscribeType]: 'subscribeToWatch',
  [OFFER_TYPES.BuyType]: 'buyToWatch',
  [OFFER_TYPES.RentType]: 'rentToWatch',
  [OFFER_TYPES.PassType]: 'passToWatch',
  [OFFER_TYPES.DefaultType]: 'signupToWatch',
};

type Info = {
  types: OfferType,
  text: string;
  offer: Offer,
  isSingleOffer: boolean,
};
const sortByPrice = (a: Offer, b: Offer) => a.priceInCents - b.priceInCents;

export function getOfferTypeInfo(offers: Offer[]): Info[] {
  const info: Info[] = [];

  for (const t of offerTypes) {
    const offersT = offers.filter(o => o.__typename === t).sort(sortByPrice);

    if (offersT.length) {
      info.push({
        types: t,
        text: offerTypeToText[t],
        offer: offersT[0],
        isSingleOffer: offersT.length === 1,
      });

      // only allow default offer if it exists
      if (t === OFFER_TYPES.DefaultType) break;
    }
  }

  return info;
}

type ButtonProps = {
  types: string,
  text: string,
  offer?: Offer,
  isSingleOffer?: boolean,
};

export function getButtonProps(offers: Offer[] = []): ButtonProps[] {
  const buttonInfo = [];
  const infoByType = getOfferTypeInfo(offers);

  // maximum 2 btns allowed
  if (infoByType.length <= 2) {
    // copy btns without changes
    buttonInfo.push(...infoByType);
  } else {
    // add first btn without changes
    buttonInfo.push(infoByType.shift()!);
    // merge last btns in one Purchase btn
    buttonInfo.push({
      types: infoByType.map(oTypeInfo => oTypeInfo.types).join(','),
      text: multipleOfferTypesBtn,
    });
  }

  return buttonInfo;
}

export function getProperViewable(viewable: ViewableType): ViewableType {
  // Show's offers buttons is always for E1:S1
  return viewable?.seasons?.[0]?.episodes?.[0] || viewable;
}
