import React from 'react';
import { useIsClientSide } from 'utils/hooks';
import PropTypes from 'prop-types';

const ClientSideComponent = ({ children }) => {
  const isClientSide = useIsClientSide();

  if (!isClientSide) return null;

  return <>{children}</>;
};

ClientSideComponent.propTypes = {
  children: PropTypes.node,
};
export default ClientSideComponent;
