import { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { EPG_HEADER_HEIGHT } from '../EpgStyles';

export const overlayContainerStyle = (): RuleStyles => ({
  position: 'absolute',
  top: EPG_HEADER_HEIGHT,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  direction: 'ltr',
});

export const timebarContainerStyle = ({ bottom, scrollLeft }: ComponentRuleProps): RuleStyles => ({
  position: 'absolute',
  zIndex: 2,
  transform: `translateX(-${scrollLeft}px)`,
  extend: [
    {
      condition: !bottom,
      style: {
        top: '0',
      },
    },
    {
      condition: bottom,
      style: {
        bottom: '0',
      },
    },
  ],
});
