import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PAYMENT_PROVIDERS } from 'utils/constants';
import {
  Box,
  Text,
} from 'components';
import AdyenLogo from './adyen-logo-green.png';
import StripeLogo from './stripe-logo-slate-sm.png';

function PaymentProviderFooter({ centered }) {
  const providerType = useSelector(state => state.settings.features.payment?.provider?.type);

  let logo = null;

  if (providerType === PAYMENT_PROVIDERS.ADYEN_DROPIN) {
    logo = (
      <Box
        as="img"
        hideEmpty={false}
        src={AdyenLogo}
        alt="Adyen logo"
        m="small"
        height="2rem"
      />
    );
  }

  if (providerType === PAYMENT_PROVIDERS.STRIPE) {
    logo = (
      <Box
        as="img"
        hideEmpty={false}
        src={StripeLogo}
        alt="Stripe logo"
        m="small"
        height="2rem"
      />
    );
  }

  return (
    <Box
      row
      wrap
      alignItems="center"
      justifyContent={centered ? 'center' : undefined}
    >
      <Text
        id="subscription.secureLabel"
        fontSize="small"
        color="secondary"
      />

      {logo}
    </Box>
  );
}

PaymentProviderFooter.propTypes = {
  centered: PropTypes.bool,
};

export default PaymentProviderFooter;
