import React from 'react';
import PropTypes from 'prop-types';
import { withPulsing } from 'styles/fela/mixins';
import createComponent from 'styles/fela/createComponent';
import LinkButton from './LinkButton';
import PrimaryButton from './PrimaryButton';

const makeStyles = ({ pending, bold }) => ({
  extend: [{
    condition: pending,
    style: {
      ...withPulsing(700),
      cursor: 'wait',
      color: 'pink',
      ':hover': {
        textDecoration: 'none',
      },
    },
  },
  {
    condition: bold,
    style: {
      fontWeight: 'bold',
    },
  },
  ],
});

const PulsingLinkButton = createComponent(props => makeStyles(props), LinkButton, ['pending', 'bold']);
const PulsingPrimaryButton = createComponent(props => makeStyles(props), PrimaryButton, ['pending', 'bold']);

export default function PulsingButton({ pending, onClick, link, ...props }) {
  const PulsingButtonStyles = link ? PulsingLinkButton : PulsingPrimaryButton;

  return (
    <PulsingButtonStyles
      onClick={pending ? null : onClick}
      pending={pending}
      {...props}
    />
  );
}

PulsingButton.propTypes = {
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  link: PropTypes.bool,
};
