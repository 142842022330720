import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TGetPaymentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TGetPaymentsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, paymentHistory: { __typename?: 'PaymentHistoryItemConnection', edges?: Array<{ __typename?: 'PaymentHistoryItemEdge', node: { __typename?: 'PaymentHistoryItem', status: Types.TPaymentStatus, netPrice: any, grossPrice: any, currency: string, timestamp: any, fromDate: any, toDate: any, transactionType: string, offerV3?: { __typename: 'SubscribeType', id: string, title?: string | null, recurringPeriod?: { __typename?: 'RecurringPeriodType', length: number, unit: Types.TRecurringPeriodUnitType } | null } | { __typename: 'BuyType', id: string, title?: string | null } | { __typename: 'DefaultType', id: string, title?: string | null } | { __typename: 'PassType', id: string, title?: string | null } | { __typename: 'RentType', entitlementDurationSec?: number | null, id: string, title?: string | null } | { __typename: 'ThirdPartyType', id: string, title?: string | null } | null } } | null> | null } } };


export const GetPaymentsDocument = gql`
    query getPayments {
  viewer {
    id: magineId
    paymentHistory(first: 60) {
      edges {
        node {
          status
          netPrice
          grossPrice
          currency
          timestamp
          fromDate
          toDate
          transactionType
          offerV3 {
            __typename
            id
            title
            ... on SubscribeType {
              recurringPeriod {
                length
                unit
              }
            }
            ... on RentType {
              entitlementDurationSec
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<TGetPaymentsQuery, TGetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TGetPaymentsQuery, TGetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TGetPaymentsQuery, TGetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TGetPaymentsQuery, TGetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<TGetPaymentsQuery, TGetPaymentsQueryVariables>;