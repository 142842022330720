/* eslint-disable no-continue, react/prop-types */
import * as React from 'react';
import { combineRules, CSSObject, IStyleExtension } from 'fela';
import { useFela } from 'react-fela';
import { GlobalTheme } from 'types';
import {
  smallScreen,
  responsiveSm,
  responsiveMd,
  responsiveLg,
  responsiveXl,
} from './mixins';

const responsivePrefixes = [
  'xs-',
  'sm-',
  'md-',
  'lg-',
  'xl-',
];

const hasResponsivePrefix = (str: string) => {
  for (const prefix of responsivePrefixes) {
    if (str.startsWith(prefix)) {
      return true;
    }
  }

  return false;
};

export type ExtendStyles = {
  condition: any,
  style: CSSObject | IStyleExtension,
};

export interface RuleStyles extends CSSObject {
  extend?: ExtendStyles[];
}

export interface ComponentRuleProps extends Record<string, any> {
  theme: GlobalTheme,
}

export type RuleProps = Omit<ComponentRuleProps, 'theme'>;

export type Rule = (props: ComponentRuleProps | RuleProps) => RuleStyles;
export type ComponentRule = (props: ComponentRuleProps) => RuleStyles;

type ComponentContext = {
  rule: Rule | ComponentRule,
  type: string | React.ElementType,
  blacklist: string[],
};

function filterProps(props: Record<string, any>, blacklist: string[]) {
  const result: Record<string, any> = {};

  for (const [key, value] of Object.entries(props)) {
    const isBlacklisted = blacklist.includes(key)
      || hasResponsivePrefix(key);

    // TODO maybe delete from props instead? check performance
    if (!isBlacklisted) {
      result[key] = value;
    }
  }

  return result;
}

function splitProps(props: RuleProps) {
  const result: {
    global: ComponentRuleProps,
    xsProps: ComponentRuleProps,
    smProps: ComponentRuleProps,
    mdProps: ComponentRuleProps,
    lgProps: ComponentRuleProps,
    xlProps: ComponentRuleProps,
  } = {
    global: { theme: props.theme },
    xsProps: { theme: props.theme },
    smProps: { theme: props.theme },
    mdProps: { theme: props.theme },
    lgProps: { theme: props.theme },
    xlProps: { theme: props.theme },
  };

  for (const [key, value] of Object.entries(props)) {
    if (key.startsWith('xs-')) {
      result.xsProps = result.xsProps || {};
      result.xsProps[key.substring(3)] = value;

      continue;
    }

    if (key.startsWith('sm-')) {
      result.smProps = result.smProps || {};
      result.smProps[key.substring(3)] = value;

      continue;
    }

    if (key.startsWith('md-')) {
      result.mdProps = result.mdProps || {};
      result.mdProps[key.substring(3)] = value;

      continue;
    }

    if (key.startsWith('lg-')) {
      result.lgProps = result.lgProps || {};
      result.lgProps[key.substring(3)] = value;

      continue;
    }

    if (key.startsWith('xl-')) {
      result.xlProps = result.xlProps || {};
      result.xlProps[key.substring(3)] = value;

      continue;
    }

    result.global[key] = value;
  }

  return result;
}

function createResponsiveRule(rule: Rule | ComponentRule) {
  return (props: RuleProps | ComponentRuleProps): RuleStyles => {
    const {
      global,
      xsProps,
      smProps,
      mdProps,
      lgProps,
      xlProps,
    } = splitProps(props);

    const items: ExtendStyles[] = [];

    items.push({
      condition: true,
      style: rule(global),
    });

    if (xsProps && Object.keys(xsProps).length > 1) {
      items.push({
        condition: true,
        style: smallScreen(rule(xsProps)),
      });
    }

    if (smProps && Object.keys(smProps).length > 1) {
      items.push({
        condition: true,
        style: responsiveSm(rule(smProps)),
      });
    }

    if (mdProps && Object.keys(mdProps).length > 1) {
      items.push({
        condition: true,
        style: responsiveMd(rule(mdProps)),
      });
    }

    if (lgProps && Object.keys(lgProps).length > 1) {
      items.push({
        condition: true,
        style: responsiveLg(rule(lgProps)),
      });
    }

    if (xlProps && Object.keys(xlProps).length > 1) {
      items.push({
        condition: true,
        style: responsiveXl(rule(xlProps)),
      });
    }

    return {
      extend: items,
    };
  };
}

const contextSymbol = Symbol('style-component-context');

function createComponentWithContext(context: ComponentContext) {
  function StyleComponent(originalProps: RuleProps) {
    const { css, theme }: { css: (styles: RuleStyles) => any, theme: GlobalTheme } = useFela();

    const className = css(context.rule({ ...originalProps, theme }));

    const props = filterProps(originalProps, context.blacklist);
    props.className = `${props.className || ''} ${className}`;

    let element = context.type;

    if (props.as) {
      element = props.as;
      delete props.as;
    }

    // pass through innerRef
    if (typeof element === 'string' && 'innerRef' in props) {
      props.ref = props.innerRef;
      delete props.innerRef;
    }

    return React.createElement(element, props);
  }

  (StyleComponent as any)[contextSymbol] = context;

  return StyleComponent;
}

export default function createComponent(
  rule: Rule | ComponentRule,
  type: string | React.ElementType = 'div',
  blacklist: string[] = [],
  responsive = false,
): React.FunctionComponent<Record<string, any>> {
  const context: ComponentContext = {
    rule: responsive ? createResponsiveRule(rule) : rule,
    type,
    blacklist,
  };

  const contextToExtend = (type as any)[contextSymbol];

  if (contextToExtend) {
    context.rule = combineRules(contextToExtend.rule, context.rule) as Rule;
    context.type = contextToExtend.type;
    context.blacklist.push(...contextToExtend.blacklist);
  }

  return createComponentWithContext(context);
}
