import * as React from 'react';
import { useFela } from 'react-fela';
import { useSelector } from 'reducers';
import { HotkeyIcons } from 'components/Icons';
import { hotkeyIconWrapperStyle, hotkeyIconAnimation } from './styles';

export type HotkeyAction =
  | 'MUTE'
  | 'UNMUTE'
  | 'FULLSCREENIN'
  | 'FULLSCREENOUT'
  | 'PAUSE'
  | 'PLAY'
  | 'VOLUMEUP'
  | 'VOLUMEDOWN'
  | 'SEEKBACK'
  | 'SEEKFORWARD';

const renderIconByAction = (action: HotkeyAction, text: number | string = ''): JSX.Element | null => {
  const iconProps = {
    size: '100',
    viewBox: '0 0 170 170',
    style: {
      font: '700 24px sans-serif',
    },
  };

  switch (action) {
    case 'FULLSCREENIN':
      return <HotkeyIcons.FullscreenInIcon {...iconProps} />;
    case 'FULLSCREENOUT':
      return <HotkeyIcons.FullscreenOutIcon {...iconProps} />;
    case 'VOLUMEDOWN':
      return <HotkeyIcons.VolumeDownIcon {...iconProps} />;
    case 'VOLUMEUP':
    case 'UNMUTE':
      return <HotkeyIcons.VolumeUpIcon {...iconProps} />;
    case 'MUTE':
      return <HotkeyIcons.MuteIcon {...iconProps} />;
    case 'PLAY':
      return <HotkeyIcons.PlayIcon {...iconProps} />;
    case 'PAUSE':
      return <HotkeyIcons.PauseIcon {...iconProps} />;
    case 'SEEKBACK':
      return <HotkeyIcons.SeekBackIcon {...iconProps} text={text} />;
    case 'SEEKFORWARD':
      return <HotkeyIcons.SeekForwardIcon {...iconProps} text={text} />;
    default:
      return null;
  }
};

interface IHotkeyIconWrapperProps {
  action: HotkeyAction,
}

const HotkeyIconWrapper: React.FC<IHotkeyIconWrapperProps> = (props) => {
  const { action } = props;
  const { css, renderer } = useFela();
  const hotkeyIconKeyframes = renderer.renderKeyframe(hotkeyIconAnimation, {});
  const seekStepInSeconds = useSelector(state => state.settings.features.player.seekStepInSeconds);

  return (
    <div className={css(hotkeyIconWrapperStyle)}>
      <div className={css({ animation: `${hotkeyIconKeyframes} 750ms normal forwards ease-out` })}>
        {renderIconByAction(action, seekStepInSeconds)}
      </div>
    </div>
  );
};

export default HotkeyIconWrapper;
