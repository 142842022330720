import React from 'react';
import PropTypes from 'prop-types';
import { Box, PrimaryButton, Text } from 'components';
import { Helmet } from 'react-helmet-async';
import createComponent from 'styles/fela/createComponent';
import { getGoogleFontLink } from 'utils/helpers';
import { truncateLines } from 'styles/fela/mixins';

const MetaDataContainer = createComponent(({ theme }) => ({
  extend: [
    {
      condition: theme.collectionUI.withTextShadow,
      style: {
        filter: `drop-shadow(${theme.textShadow.featured})`,
        transform: 'translateZ(0)', // handle safari
      },
    },
  ],
}), Box);

const MetaDataText = ({ fontFamily,
  fontSize,
  bold,
  uppercase,
  color,
  innerText,
  textNumberLines }) => (
    <Text
      fontFamily={fontFamily}
      fontSize={fontSize}
      bold={bold}
      uppercase={uppercase}
      color={color}
      textNumberLines={textNumberLines}
    >
      {fontFamily && (
      <Helmet>
        <link
          key={fontFamily}
          rel="stylesheet"
          href={getGoogleFontLink(fontFamily)}
        />
      </Helmet>
      )}
      {innerText}
    </Text>
);

const MetaPromoButton = createComponent(({ buttonColor, textCaps, textNumberLines, fontSize }) => ({
  display: 'inline-block',
  textTransform: textCaps ? 'uppercase' : 'none',
  lineHeight: '1.5rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  minHeight: '3.5rem',
  height: 'auto',

  extend: [
    {
      condition: buttonColor,
      style: {
        backgroundColor: buttonColor,
        ':hover': {
          backgroundColor: `${buttonColor}BB`,
        },
      },
    },
    {
      condition: textNumberLines,
      style: {
        '& > span': truncateLines(textNumberLines, fontSize, '1.5rem'),
      },
    },
  ],
}), PrimaryButton, ['buttonColor', 'textCaps', 'textNumberLines', 'fontSize']);

function MetaDataPromo({ blockItem,
  title,
  description,
  ctaTarget,
  ctaLabel,
  ctaValue,
  ctaValueType }) {
  const { type, fieldToRender } = blockItem;

  const getLinkValue = (linkValue) => {
    switch (linkValue) {
      case 'url': return ctaValue;
      case 'view': return `/collections/${ctaValue}`;
      case 'block': return `/collection/${ctaValue}`;
      case 'offer': return `/checkout/confirmation?packageId=${ctaValue}`;
    }
    return '/';
  };

  function getBlockValue() {
    switch (fieldToRender) {
      case 'title':
        return title;
      case 'description':
        return description;
      default:
        return '';
    }
  }

  if (type === 'textString') {
    return (
      <MetaDataContainer mb={blockItem?.objectVerticalSpacing} textAlign={blockItem?.textAlignment}>
        <MetaDataText
          innerText={getBlockValue()}
          fontFamily={blockItem?.textFontName}
          fontSize={blockItem?.textFontSize}
          bold={!!blockItem?.textBold}
          uppercase={!!blockItem?.textCaps}
          color={blockItem?.textFontColor}
          textNumberLines={blockItem?.textNumberLines}
        />
      </MetaDataContainer>
    );
  }

  if (type === 'buttonCta') {
    return (
      <Box mb={blockItem?.objectVerticalSpacing} textAlign={blockItem?.textAlignment || undefined}>
        <MetaPromoButton
          as="a"
          href={getLinkValue(ctaValueType)}
          target={`_${ctaTarget}`}
          buttonColor={blockItem.buttonColor}
          textCaps={blockItem.textCaps}
          textNumberLines={blockItem?.textNumberLines}
        >
          <MetaDataText
            innerText={ctaLabel}
            fontFamily={blockItem?.textFontName}
            fontSize={blockItem?.textFontSize}
            bold={!!blockItem?.textBold}
            uppercase={!!blockItem?.textCaps}
            color={blockItem?.textFontColor}
          />
        </MetaPromoButton>
      </Box>
    );
  }

  return null;
}

MetaDataPromo.propTypes = {
  blockItem: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaTarget: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaValue: PropTypes.string.isRequired,
  ctaValueType: PropTypes.string.isRequired,
};

MetaDataText.propTypes = {
  fontFamily: PropTypes.string,
  fontSize: PropTypes.number.isRequired,
  bold: PropTypes.bool.isRequired,
  uppercase: PropTypes.bool.isRequired,
  color: PropTypes.string,
  innerText: PropTypes.string.isRequired,
  textNumberLines: PropTypes.number,
};

export default React.memo(MetaDataPromo);
