import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useLazyQuery } from '@apollo/client';
import { useAsyncStateTracker } from 'utils/hooks';
import CAMPAIGN_QUERY from './campaignUsps.gql';
import PROMO_CODE_PREVIEW_QUERY from './promoCodePreview.gql';

export function usePromoCodeForm(initialValue, validatePromocode, offer) {
  const offers = useSelector(state => state.offers);
  const location = useSelector(state => state.router.location);

  const [
    getCampaign,
    { loading, data: campaignData },
  ] = useLazyQuery(location.name === 'settings-subscription' ? PROMO_CODE_PREVIEW_QUERY : CAMPAIGN_QUERY);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      promoCode: initialValue,
    },
  });

  const getCampaignInfo = (code = '', offerId = '') => getCampaign({
    variables: {
      promoCode: code.toUpperCase(),
      ...offerId ? { offerId } : {},
    },
  });

  const debouncedGetCampaignInfo = useCallback(debounce(getCampaignInfo, 500), []);

  const {
    handleSubmit,
    setError,
    formState: { isValid },
    control,
  } = methods;

  const promoCodeValue = useWatch({
    control,
    name: 'promoCode',
  });

  useEffect(
    () => {
      if (promoCodeValue) {
        debouncedGetCampaignInfo(promoCodeValue, offer?.id);
      }
    },
    [(promoCodeValue || '').toLocaleUpperCase(), debouncedGetCampaignInfo],
  );

  const isCampaignToOfferValid = useMemo(() => {
    if (!campaignData) return false;
    if (location.name === 'settings-subscription') return !!campaignData;
    if (!offer) return true;
    const { campaign } = campaignData.viewer;
    return campaign?.offers?.edges?.some(edge => edge?.node?.id === offer.id);
  }, [campaignData]);

  const promoCodeUsps = useMemo(() => {
    if (!campaignData || !isCampaignToOfferValid) return [];

    const { promoCodeAppliedPreview, campaign } = campaignData.viewer;
    const viewer = location.name === 'settings-subscription' ? promoCodeAppliedPreview : campaign;
    const promoCodeOfferId = viewer?.offers?.edges?.[0]?.node?.id;

    if (viewer?.usps && promoCodeOfferId && (
      // return Usps for purchase-offer (hidden offer) page and only for proper offer
      (location.name === 'purchase-offer' && location.params?.id === promoCodeOfferId)
      // return Usps for checkout page if offer is not hidden
      || (location.name !== 'purchase-offer' && offers.find(o => o.id === promoCodeOfferId))
    )) {
      return viewer.usps;
    }

    // No offerId for existed entitlement, so no validation. Accepted if exists
    if (viewer?.usps && location.name === 'settings-subscription') {
      return viewer.usps;
    }

    return [];
  }, [campaignData, isCampaignToOfferValid]);

  const [inProgress, onSubmit] = useAsyncStateTracker(async ({ promoCode }) => {
    try {
      await validatePromocode(promoCode);
    } catch (e) {
      setError('promoCode', {
        type: 'server',
        message: e,
      });
    }
  });

  return {
    methods,
    disableSubmit: !isValid || !campaignData || !isCampaignToOfferValid || loading,
    inProgress: inProgress,
    onSubmit: handleSubmit(onSubmit),
    usps: promoCodeValue && !loading ? promoCodeUsps : [],
  };
}
