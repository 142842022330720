import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { reserveBoldTextWidth, truncateLines } from 'styles/fela/mixins';
import { I18nContext } from 'components/I18n';
import { extractThemeProp } from 'components/StyledSystem/Box';

export const StyledText = createComponent(
  (
    {
      fontFamily,
      theme,
      bold,
      fontWeight,
      italic,
      fontStyle,
      textTransform,
      uppercase,
      textDecoration,
      color,
      fontSize,
      letterSpacing,
      align,
      whiteSpace,
      nowrap,
      cursor,
      opacity,
      display,
      textShadow,
      dropShadow,
      lineHeight,
      wordBreak,
      wordWrap,
      hover,
      noselect,
      boldWidthForTextInAttr,
      mb,
      mt,
      alignSelf,
      flexGrow,
      ellipsis,
      overflow,
      textNumberLines,
    } = {},
  ) => ({
    extend: [
      {
        condition: color,
        style: {
          color: theme.color[color] || color,
        },
      },
      {
        condition: fontSize,
        style: {
          fontSize: theme.fontSize[fontSize] || fontSize,
        },
      },
      {
        condition: whiteSpace,
        style: {
          whiteSpace,
        },
      },
      {
        condition: nowrap,
        style: {
          whiteSpace: 'nowrap',
        },
      },
      {
        condition: bold,
        style: {
          fontWeight: 'bold',
        },
      },
      {
        condition: fontWeight,
        style: {
          fontWeight,
        },
      },
      {
        condition: italic,
        style: {
          fontStyle: 'italic',
        },
      },
      {
        condition: fontStyle,
        style: {
          fontStyle,
        },
      },
      {
        condition: textTransform,
        style: {
          textTransform,
        },
      },
      {
        condition: uppercase,
        style: {
          textTransform: 'uppercase',
        },
      },
      {
        condition: textDecoration,
        style: {
          textDecoration,
        },
      },
      {
        condition: letterSpacing,
        style: {
          letterSpacing,
        },
      },
      {
        condition: align,
        style: {
          textAlign: align,
        },
      },
      {
        condition: cursor,
        style: {
          cursor,
        },
      },
      {
        condition: opacity,
        style: {
          opacity,
        },
      },
      {
        condition: display,
        style: {
          display,
        },
      },
      {
        condition: textShadow,
        style: {
          textShadow: theme.textShadow[textShadow] || textShadow,
        },
      },
      {
        condition: dropShadow,
        style: {
          filter: `drop-shadow(${theme.textShadow[dropShadow] || dropShadow})`,
          transform: 'translateZ(0)', // handle safari
        },
      },
      {
        condition: lineHeight,
        style: {
          lineHeight: theme.fontSize[lineHeight] || lineHeight,
        },
      },
      {
        condition: wordBreak,
        style: {
          wordBreak,
        },
      },
      {
        condition: wordWrap,
        style: {
          wordWrap,
        },
      },
      {
        condition: hover,
        style: {
          ':hover': hover,
        },
      },
      {
        condition: noselect,
        style: {
          userSelect: 'none',
        },
      },
      {
        condition: boldWidthForTextInAttr,
        style: reserveBoldTextWidth(boldWidthForTextInAttr),
      },
      {
        condition: mb,
        style: {
          marginBottom: theme.margin[mb] || mb,
        },
      },
      {
        condition: mt,
        style: {
          marginTop: theme.margin[mt] || mt,
        },
      },
      {
        condition: alignSelf,
        style: {
          alignSelf,
        },
      },
      {
        condition: flexGrow,
        style: {
          flexGrow,
        },
      },
      {
        condition: ellipsis,
        style: {
          textOverflow: 'ellipsis',
        },
      },
      {
        condition: overflow,
        style: {
          overflow,
        },
      },
      {
        condition: fontFamily,
        style: {
          fontFamily,
        },
      },
      {
        condition: textNumberLines,
        style: truncateLines(
          textNumberLines,
          extractThemeProp(theme.fontSize, fontSize) || fontSize,
          lineHeight,
        ),
      },
    ],
  }), 'span', [
    'fontFamily',
    'bold',
    'fontWeight',
    'italic',
    'fontStyle',
    'color',
    'fontSize',
    'textTransform',
    'uppercase',
    'textDecoration',
    'align',
    'letterSpacing',
    'whiteSpace',
    'nowrap',
    'cursor',
    'opacity',
    'display',
    'textShadow',
    'dropShadow',
    'lineHeight',
    'wordBreak',
    'wordWrap',
    'hover',
    'noselect',
    'boldWidthForTextInAttr',
    'mb',
    'mt',
    'alignSelf',
    'flexGrow',
    'ellipsis',
    'overflow',
    'textNumberLines',
  ], true,
);

function Text(props) {
  const {
    id,
    isHtml,
    values,
    children,
    withDataContent,
    ...otherProps
  } = props;

  const i18n = useContext(I18nContext);

  if (!id) {
    return (
      <StyledText data-content={withDataContent ? children : undefined} {...otherProps}>
        {children}
      </StyledText>
    );
  }
  const msg = i18n.formatText(id, values);

  if (isHtml) {
    return (
      <StyledText
        {...otherProps}
        data-i18n={id}
        dangerouslySetInnerHTML={{ __html: msg }}
      />
    );
  }

  return React.createElement(StyledText, {
    'data-content': withDataContent ? msg : undefined,
    ...otherProps,
    'data-i18n': id,
  }, msg);
}

Text.propTypes = {
  id: PropTypes.string,
  isHtml: PropTypes.bool,
  values: PropTypes.object,
  children: PropTypes.node,
  withDataContent: PropTypes.bool,

  fontFamily: PropTypes.string,
  bold: PropTypes.bool,
  fontWeight: PropTypes.string,
  italic: PropTypes.bool,
  fontStyle: PropTypes.string,
  textTransform: PropTypes.string,
  uppercase: PropTypes.bool,
  textDecoration: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  letterSpacing: PropTypes.string,
  align: PropTypes.string,
  whiteSpace: PropTypes.string,
  nowrap: PropTypes.bool,
  cursor: PropTypes.string,
  opacity: PropTypes.string,
  display: PropTypes.string,
  textShadow: PropTypes.string,
  dropShadow: PropTypes.string,
  lineHeight: PropTypes.string,
  wordBreak: PropTypes.string,
  wordWrap: PropTypes.string,
  hover: PropTypes.object,
  noselect: PropTypes.bool,
  boldWidthForTextInAttr: PropTypes.string,
  mb: PropTypes.string,
  mt: PropTypes.string,
  alignSelf: PropTypes.string,
  flexGrow: PropTypes.string,
  ellipsis: PropTypes.string,
  overflow: PropTypes.string,
  textNumberLines: PropTypes.number,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default React.memo(Text);
