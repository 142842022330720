import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useInterval,
  useHover,
  useIsSmallScreen,
} from 'utils/hooks';
import visibilityManager from 'utils/visibilityManager';
import { useCarouselManager } from './carouselManager';
import Pager from './Pager';
import ItemRenderer from './ItemRenderer';
import NextIcon from './NextIcon';
import {
  Wrapper,
  NavigationContainer,
  Container,
  PagerContainer,
} from './Styles';

const isAutoRotate = (availArr, pos) => {
  if (!availArr?.length) return true;

  return availArr[pos];
};

// How does carousel work:
// it renders only 3 items at the same time:
// [0] [1] [2]
//      ^ is visible due to css transform
// to switch to the next item carousel applies a css transform:
// [0] [1] [2]
//          ^ is visible due to css transform
// transform is animated, and when animation is done, carousel renders new items:
// [1] [2] [3]
//      ^ is visible due to css transform
// Due to "key" on ItemRenderer, React reuses items [1] and [2], and only [3] is being rendered
//
// So this is basically "virtualized" carousel, and it can handle a large number of items
// without a performance impact or increased memory/network usage

function Carousel({
  size,
  withPager,
  withNavigation,
  interval,
  children: renderItem,
  isRTL,
  autoRotateAvailability = [],
}) {
  const wrapperRef = React.useRef();
  const [stopInterval, setStopInterval] = React.useState(false);
  const containerRef = React.useRef();

  const {
    pos,
    prevPos,
    nextPos,
    setPos,
    animatedPos,
    animateNextPos,
    animatePrevPos,
  } = useCarouselManager(containerRef, size, isRTL);

  const isHover = useHover(wrapperRef);
  const isMobile = useIsSmallScreen();

  let intervalMs = interval * 1000;
  if (interval && isHover) { // add 10sec to interval on hover (if timer is on)
    intervalMs += 10 * 1000;
  }
  if (stopInterval || !isAutoRotate(autoRotateAvailability, pos)) {
    intervalMs = null;
  }
  useInterval(animateNextPos, intervalMs);

  // on/off autorotation depends on component visibility
  useEffect(() => {
    const el = wrapperRef.current;
    if (!el) return () => {};

    visibilityManager.add(el, v => setStopInterval(!v));
    return () => visibilityManager.remove(el);
  }, []);

  const onNextHandler = useCallback(() => {
    if (!stopInterval) {
      animateNextPos();
    }
  }, [stopInterval]);

  if (size === 1) {
    return renderItem(0);
  }

  return (
    <Wrapper
      innerRef={wrapperRef}
      onClick={() => setStopInterval(true)}
      onTouchStart={() => setStopInterval(true)}
    >
      {withNavigation && !isMobile && (
        <NavigationContainer
          type="left"
          onClick={animatePrevPos}
        >
          <NextIcon />
        </NavigationContainer>
      )}

      {withNavigation && !isMobile && (
        <NavigationContainer
          type="right"
          onClick={animateNextPos}
        >
          <NextIcon right />
        </NavigationContainer>
      )}

      <Container innerRef={containerRef}>
        <ItemRenderer
          key={prevPos}
          pos={prevPos}
          renderItem={renderItem}
        />
        <ItemRenderer
          key={pos}
          pos={pos}
          renderItem={renderItem}
          next={onNextHandler}
          active
        />
        <ItemRenderer
          // When size === 2, nextPos === prevPos and React shows warning about duplicate key.
          // So we shouldn't add key in that case
          key={size === 2 ? undefined : nextPos}
          pos={nextPos}
          renderItem={renderItem}
        />
      </Container>

      {withPager && (
        <PagerContainer>
          <Pager
            size={size}
            selectedPos={animatedPos}
            onClick={isMobile ? undefined : setPos}
          />
        </PagerContainer>
      )}
    </Wrapper>
  );
}
Carousel.propTypes = {
  size: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  withPager: PropTypes.bool,
  withNavigation: PropTypes.bool,
  interval: PropTypes.number.isRequired,
  isRTL: PropTypes.bool,
  autoRotateAvailability: PropTypes.arrayOf(PropTypes.bool),
};

export default React.memo(Carousel);
