import createComponent from 'styles/fela/createComponent';
import googlePayLogo from './images/googlePay.svg';

const GooglePayLogo = createComponent(({ theme, center }) => ({
  backgroundColor: '#fff',
  height: theme.size.googlePayLogoSize,
  width: theme.size.googlePayLogoSize,
  position: 'relative',
  ':after': {
    content: '" "',
    backgroundImage: `url(${googlePayLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    height: `calc(${theme.size.googlePayLogoSize} - 1rem)`,
    width: `calc(${theme.size.googlePayLogoSize} - 1rem)`,
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem',
  },

  extend: [
    {
      condition: center,
      style: {
        margin: '0 auto',
      },
    },
  ],
}), 'div', ['center']);

export default GooglePayLogo;
