import React from 'react';
import {
  Box,
  Text,
  Price,
} from 'components';
import { CloseIcon, CheckInCircleIcon } from 'components/Icons';
import { Usps } from 'components/Payment';
import { useI18n } from 'components/I18n';
import { DATE_FORMAT } from 'utils/constants';
import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { isTVOD } from 'utils/helpers';

const StyledCloseIcon = createComponent(
  ({ theme }: ComponentRuleProps): RuleStyles => ({
    color: theme.color.light,
  }),
  CloseIcon,
);

interface IDiscountProps {
  price: number;
  reducedPrice: number;
  currency: string;
  onRemoveDiscount?: () => void;
  usps?: string[];
  promoCode: string;
  campaignName: string;
  validFrom: string | Date;
  validTo?: string | Date;
  type?: string;
}

export default function Discount(props: IDiscountProps): JSX.Element | null {
  const i18n = useI18n();
  const {
    price,
    reducedPrice,
    currency,
    onRemoveDiscount,
    usps = [],
    promoCode,
    campaignName,
    validFrom,
    validTo,
    type,
  } = props;

  const discountAmount = price - reducedPrice;

  if (!discountAmount) {
    return null;
  }

  return (
    <>
      <Box
        bg="paperBackground"
        flexBox
        justifyContent="space-between"
        mt="large"
        borderRadius="9px"
        px="1.25rem"
        pt="medium"
        pb="fine"
        column
        alignItems="start"
        mx="0"
        sm-mx="-1.25rem"
      >
        <Box
          fullWidth
          row
          justifyContent="space-between"
          mb="fine"
        >
          <Text
            fontSize="medium"
            bold
          >
            {campaignName}
          </Text>

          {onRemoveDiscount && (
            <Box ml="small" mt="-3px" mr="-5px">
              <StyledCloseIcon onClick={onRemoveDiscount} />
            </Box>
          )}
        </Box>

        <Box
          fullWidth
          row
          justifyContent="space-between"
          mb="fine"
        >
          <Box row>
            <Box mr="small">
              <CheckInCircleIcon />
            </Box>
            <Text
              color="reducedPrice"
              fontSize="medium"
              bold
            >
              {promoCode}
            </Text>
          </Box>

          <Box row flex="0 0 auto">
            <Price
              color="reducedPrice"
              price={-discountAmount}
              currency={currency}
              fontSize="medium"
              bold
            />
          </Box>
        </Box>

        {!isTVOD(type) && (
          validTo ? (
            <Box>
              <Text
                fontSize="normal"
                bold
                id="discount.fromTo"
                values={{
                  from: i18n.formatDate(validFrom, DATE_FORMAT),
                  to: i18n.formatDate(validTo, DATE_FORMAT),
                }}
              />
            </Box>
          ) : (
            <Box>
              <Text
                fontSize="normal"
                bold
                id="discount.unlimited"
              />
            </Box>
          )
        )}

        <Usps
          size="small"
          type="check"
          usps={usps}
          alignItems="start"
          fullWidth
          pr="xlarge"
          mt="small"
        />
      </Box>
    </>
  );
}
