import * as React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  ParentalControlModal,
} from 'components';
import DISABLE_PARENTAL_CONTROL from './queries/disableParentalControl.gql';
import GET_PARENTAL_CONTROL_DETAILS from './queries/getParentalControlDetails.gql';

function DisablePINModal({ onClose }) {
  const [
    disableParentalControlMutation,
    {
      loading,
      error,
    },
  ] = useMutation(DISABLE_PARENTAL_CONTROL, {
    refetchQueries: [{ query: GET_PARENTAL_CONTROL_DETAILS }],

    onCompleted() {
      onClose();
    },

    onError() {}, // suppress promise rejection in mutation
  });

  const errorCode = error ? (error.graphQLErrors[0]?.extensions?.code || 'error') : undefined;

  const disableParentalControl = (pinCode) => {
    disableParentalControlMutation({
      variables: {
        pinCode,
      },
    });
  };

  return (
    <ParentalControlModal
      descriptionTextKey="appSettings.disableParentalControlModalInfo"
      onClose={onClose}
      submitPinCode={disableParentalControl}
      error={errorCode}
      pending={loading}
    />
  );
}
DisablePINModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default React.memo(DisablePINModal);
