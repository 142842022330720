import React from 'react';
import Icon from './Icon';

export default function ShowEyeIcon(props) {
  return (
    <Icon width="32" height="32" {...props} viewBox="0 0 32 32" fill="currentColor" data-cy="show-eye-icon">
      <path d="M16 9C11.4545 9 7.57273 11.8273 6 15.8182C7.57273 19.8091 11.4545 22.6364 16 22.6364C20.5455 22.6364 24.4273 19.8091 26 15.8182C24.4273 11.8273 20.5455 9 16 9ZM16 20.3636C13.4909 20.3636 11.4545 18.3273 11.4545 15.8182C11.4545 13.3091 13.4909 11.2727 16 11.2727C18.5091 11.2727 20.5455 13.3091 20.5455 15.8182C20.5455 18.3273 18.5091 20.3636 16 20.3636ZM16 13.0909C14.4909 13.0909 13.2727 14.3091 13.2727 15.8182C13.2727 17.3273 14.4909 18.5455 16 18.5455C17.5091 18.5455 18.7273 17.3273 18.7273 15.8182C18.7273 14.3091 17.5091 13.0909 16 13.0909Z" />
    </Icon>
  );
}
