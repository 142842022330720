import areIntlLocalesSupported from 'intl-locales-supported';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';

export async function installPolyfills(locale) {
  await Promise.all([
    global.AbortController || import(/* webpackChunkName: 'abort-controller-polyfill' */ 'abort-controller/polyfill'),
    'scrollBehavior' in (document.documentElement?.style || {}) || await import(/* webpackChunkName: 'scroll-behavior-polyfill' */ 'scroll-behavior-polyfill'),
    global.ResizeObserver || import(/* webpackChunkName: 'resize-observer-polyfill' */ 'resize-observer-polyfill').then((module) => {
      global.ResizeObserver = module.default;
    }),
    import('element-matches-polyfill'),
  ]);

  if (shouldPolyfillIntlGetCanonicalLocales()) {
    await import(/* webpackChunkName: 'intl-getcanonicallocales-polyfill' */ '@formatjs/intl-getcanonicallocales/polyfill'); // for IE11
  }

  if (shouldPolyfillIntlLocale()) {
    await import(/* webpackChunkName: 'intl-locale-polyfill' */ '@formatjs/intl-locale/polyfill');
  }

  if (shouldPolyfillPluralRules()) { // Safari on iOS and IE11
    await import(/* webpackChunkName: 'intl-pluralrules-polyfill' */ '@formatjs/intl-pluralrules/polyfill');
  }

  // + cover the case when browser has Intl.PluralRules API but doesn't support locale
  if (Intl.PluralRules.polyfilled || !areIntlLocalesSupported([locale], [Intl.PluralRules])) {
    await import(/* webpackChunkName: 'intl-pluralrules-polyfill-' */ `@formatjs/intl-pluralrules/locale-data/${locale}`);
  }

  // Polyfill Intl.NumberFormat for browsers on Android
  if (shouldPolyfillNumberFormat()) {
    await import(/* webpackChunkName: 'intl-numberformat-polyfill' */ '@formatjs/intl-numberformat/polyfill');
  }

  if (Intl.NumberFormat.polyfilled) {
    await import(/* webpackChunkName: 'intl-numberformat-polyfill-' */ `@formatjs/intl-numberformat/locale-data/${locale}`);
  }
}
