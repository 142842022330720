import React, { Ref, RefObject, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';
import debounce from 'lodash.debounce';
import { health } from 'actions/health';
import { useDispatch, useSelector } from 'react-redux';
import { BREAKPOINTS, OFFER_TYPES, INFO_DELIMITER } from 'utils/constants';
import {
  generateWatchLocation,
  hashCode,
  renderDuration,
  getStartDateTime,
  getStartAndStopTime,
} from 'utils/helpers';
import logger from 'utils/logger';
import {
  CollectionUIType,
  LayoutObjectType,
  ScreenSizeStyles,
  ScreenStyleType,
  ViewableType,
} from 'types';
import { AppDispatch, RootState } from 'reducers';
import { useI18n } from 'components/I18n';
import { push } from 'router/actions';
import { createRedirectLink } from 'utils/routing';

export function useAutofocus(): (el: HTMLElement) => void {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    ref?.focus(); // eslint-disable-line no-unused-expressions
  }, [ref]);

  return setRef;
}

export function useWindowWidth(): number | null {
  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
    }
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 200);

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

type WindowSize = {
  width: number;
  height: number;
};

const defaultSize = { width: 0, height: 0 };

export function useWindowSize(): WindowSize {
  const [size, setSize] = useState<WindowSize>(defaultSize);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    const handleResize = debounce(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }, 200);

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

export function useShareForwardedRef(
  forwardedRef: { current: any } | ((element: any) => void),
): Ref<any> {
  const innerRef = useRef(null);

  useEffect(() => {
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
      return;
    }

    forwardedRef.current = innerRef.current;
  });

  return innerRef;
}

export function useOnlineStatus(): boolean {
  const dispatch = useDispatch<AppDispatch>();
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const setConnectivity = () => {
      if (!navigator.onLine) {
        dispatch(health())
          .then(() => {
            setIsOnline(true);
          })
          .catch(() => {
            setIsOnline(false);
          });
      } else {
        setIsOnline(navigator.onLine);
      }
    };

    setConnectivity();

    window.addEventListener('online', setConnectivity);
    window.addEventListener('offline', setConnectivity);

    return () => {
      window.removeEventListener('online', setConnectivity);
      window.removeEventListener('offline', setConnectivity);
    };
  }, []);

  return isOnline;
}

export function useIsMountedRef(): RefObject<boolean> {
  const isMounted = useRef(true);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  return isMounted;
}

export function useAsyncStateTracker(
  asyncCb: (...props: any[]) => Promise<any>,
): [boolean, ((...props: any[]) => any)] {
  const [inProgress, setInProgress] = useState(false);
  const isMountedRef = useIsMountedRef();

  const wrappedAsyncCb = async (...params: any[]) => {
    setInProgress(true);

    try {
      return await asyncCb(...params);
    } finally {
      if (isMountedRef.current) {
        setInProgress(false);
      }
    }
  };

  return [inProgress, wrappedAsyncCb];
}

export function useCollectionUIStyles(
  screenStyles: CollectionUIType | undefined,
): ScreenStyleType | undefined {
  const width = useWindowWidth();
  const isMobileOS = useSelector(({ common }: RootState) => common.isMobileOS);

  return useMemo(() => {
    const isSmall = width
      ? width < BREAKPOINTS.sm
      : isMobileOS;

    const screen = isSmall
      ? ScreenSizeStyles.smallScreenStyle
      : ScreenSizeStyles.mediumScreenStyle;

    return screenStyles?.[screen];
  }, [width, isMobileOS]);
}

// Allows to schedule css transform in an upcoming animation frame
export function useTransformScheduler(elRef: RefObject<HTMLElement>): (transform: string) => void {
  const scheduledTransformationRef = React.useRef<string | null>(null);

  return React.useCallback((latestTransform) => {
    const isScheduled = !!scheduledTransformationRef.current;

    scheduledTransformationRef.current = latestTransform;

    if (!isScheduled) {
      window.requestAnimationFrame(() => {
        const el = elRef.current;

        if (!el || scheduledTransformationRef.current === null) {
          return;
        }

        el.style.transform = scheduledTransformationRef.current;
        scheduledTransformationRef.current = null;
      });
    }
  }, [elRef.current]);
}

export function useInterval(cb: () => any, timeoutMs: number): void {
  const cbRef = React.useRef(cb);

  React.useEffect(() => {
    cbRef.current = cb;
  }, [cb]);

  React.useEffect(() => {
    if (!timeoutMs) {
      return () => null;
    }

    const timeoutId = window.setInterval(() => {
      cbRef.current();
    }, timeoutMs);

    return () => window.clearInterval(timeoutId);
  }, [timeoutMs]);
}

export function useHover(elRef: RefObject<HTMLElement>): boolean {
  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {
    const el = elRef.current;

    if (!el) {
      return () => null;
    }

    // set initial state
    setHover(el.matches(':hover'));

    const onMouseEnter = () => {
      setHover(true);
    };
    const onMouseLeave = () => {
      setHover(false);
    };

    el.addEventListener('mouseenter', onMouseEnter, { passive: true });
    el.addEventListener('mouseleave', onMouseLeave, { passive: true });

    return () => {
      el.removeEventListener('mouseenter', onMouseEnter);
      el.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [elRef.current]);

  return hover;
}

export function useMediaQuery(mediaQueryStr: string): boolean {
  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(mediaQueryStr);

    setMatches(mediaQuery.matches);

    const listener = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    // addListener is deprecated,
    // but addEventListener is not available in Safari < 14
    if (typeof mediaQuery.addEventListener === 'function') {
      mediaQuery.addEventListener('change', listener);
    } else {
      mediaQuery.addListener(listener);
    }

    return () => {
      if (typeof mediaQuery.removeEventListener === 'function') {
        mediaQuery.removeEventListener('change', listener);
      } else {
        mediaQuery.removeListener(listener);
      }
    };
  }, [mediaQueryStr]);

  return matches;
}

export function useIsSmallScreen(): boolean {
  return useMediaQuery(`screen and (max-width: ${BREAKPOINTS.sm}px)`);
}

export function useIsMediumScreen(): boolean {
  return useMediaQuery(`screen and (max-width: ${BREAKPOINTS.md}px)`);
}

export function useNotifyOnce(): (msg: string) => void {
  const [hashes] = React.useState(new Set());

  return (msg) => {
    const hash = hashCode(msg);
    if (!hashes.has(hash)) {
      hashes.add(hash);
      logger.info(msg);
    }
  };
}

const fontMeasureCache = new Map();
const defaultLineHeight = 1.1;

// we need to calculate real font height for custom fonts
// since the glyphs can be located on different vertical levels
export function useFontHeight(
  {
    fontName,
    fontSize,
    isBold,
  }: {
    fontName: string | undefined,
    fontSize: string | number,
    isBold: boolean,
  },
): number {
  const fontOptions = [isBold && 'bold', fontSize, `'${fontName}'`].filter(Boolean).join(' ');
  const fontHash = hashCode(fontOptions);
  const initialHeight = fontName ? fontMeasureCache.get(fontHash) ?? null : defaultLineHeight;
  const [height, setHeight] = useState(initialHeight);
  const text = 'ÊÄŠČŽWjgp]\\’iItT1WQy@!-/#'; // magic string, the tallest available glyphs

  const calculate = () => {
    if (!fontMeasureCache.has(fontHash)) {
      fontMeasureCache.set(fontHash, 0);
      const div = document.createElement('div');
      div.style.font = fontOptions;
      div.style.visibility = 'hidden';
      div.style.lineHeight = 'normal';
      div.textContent = text;
      document.body.appendChild(div);
      fontMeasureCache.set(fontHash, `${div.clientHeight}px`);
      document.body.removeChild(div);
    }

    setHeight(fontMeasureCache.get(fontHash));
  };

  useEffect(() => {
    if (fontName) {
      // wait until custom font will be loaded
      new FontFaceObserver(fontName)
        .load(null, 30000)
        .then(calculate)
        .catch(e => logger.warn('Unable to calculate font height', e));
    }
  }, [fontName]);

  return height;
}

const loadedFonts = new Set<string>();

export const useAllCustomFontsLoaded = (layoutObjects: LayoutObjectType[] | undefined): boolean => {
  const uniqueCustomFonts = useMemo((): Set<string> => {
    const fonts = new Set<string>();
    if (!layoutObjects) return fonts;

    return layoutObjects.reduce(
      (acc: Set<string>, layoutObject: LayoutObjectType) => {
        if (layoutObject.textFontName) {
          acc.add(layoutObject.textFontName);
        }
        return acc;
      },
      fonts,
    );
  }, [layoutObjects]);

  const [
    allFontsLoaded,
    setAllFontsLoaded,
  ] = useState(() => {
    if (uniqueCustomFonts.size === 0) return true;
    return Array.from(uniqueCustomFonts).every(font => loadedFonts.has(font));
  });

  useEffect(() => {
    void (async () => {
      try {
        await Promise.all(
          Array
            .from(uniqueCustomFonts)
            .map(fontName => new FontFaceObserver(fontName).load()),
        );
        uniqueCustomFonts.forEach(font => loadedFonts.add(font));
      } catch (e) {
        logger.warn('Failed to wait for all fonts to be loaded', e);
      } finally {
        setAllFontsLoaded(true);
      }
    })();
  }, [uniqueCustomFonts]);

  return allFontsLoaded;
};

type PortalCallback = ({ children }: { children: React.ReactNode }) => React.ReactPortal | null;

export const usePortal = (id: string): PortalCallback => {
  const isBrowser = !!(typeof window !== 'undefined' && window?.document?.createElement);
  const portal = useRef(isBrowser ? document.createElement('div') : null);

  useEffect(() => {
    if (isBrowser && !portal.current) {
      portal.current = document.createElement('div');
    }
  }, [isBrowser, portal]);

  useEffect(() => {
    if (!isBrowser) return () => null;
    const target = document.getElementById(id);
    if (!(target instanceof HTMLElement) || !(portal.current instanceof HTMLElement)) {
      return () => null;
    }

    const node = portal.current;
    target.appendChild(portal.current);

    return () => {
      target.removeChild(node);
    };
  }, [isBrowser, portal, id]);

  const Portal = useCallback(({ children }: { children: React.ReactNode }) => {
    if (portal.current != null) return createPortal(children, portal.current);
    return null;
  }, [portal]);

  return Portal as PortalCallback;
};

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useIsClientSide() {
  const [isClientSide, setIsClientSide] = useState(false);

  useEffect(() => {
    // prevent server side rendering
    setIsClientSide(true);
  }, []);

  return isClientSide;
}

const NO_BREAKING_SPACE = String.fromCharCode(160); // &nbsp;
export function useAdditionalInfo(viewable: ViewableType | any) {
  const i18n = useI18n();
  const isClientSide = useIsClientSide();

  const additionalInfo = useMemo(
    () => {
      const broadcast = viewable.schedule?.[0];
      const seasonsCount = viewable?.seasons?.length || viewable?.show?.seasons?.length;
      const duration = renderDuration(viewable);
      const startTimeUtc = viewable?.defaultPlayable?.startTimeUtc || 0;

      return [
        broadcast && viewable.title,
        viewable?.productionYear,
        getStartDateTime(startTimeUtc * 1000, i18n),
        getStartAndStopTime(viewable),
        seasonsCount ? i18n.formatText('seasonsCount', { seasonsCount }) : duration,
        viewable?.genres?.join(', '),
        viewable?.rating || viewable?.pgAge,
      ].filter(i => i).join(INFO_DELIMITER);
    },
    [viewable],
  );

  if (!isClientSide) return NO_BREAKING_SPACE; // reserve space for info - no jumping

  return additionalInfo;
}

export function useOfferButtonClickHandler(viewable: ViewableType) {
  const dispatch = useDispatch();
  const { isLoggedIn, isCreateAccountEnabled } = useSelector((state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    isCreateAccountEnabled: state.country.isCreateAccountEnabled,
  }));

  return useCallback((types: string) => {
    const mediaOffersPage = {
      name: 'checkout-media',
      params: { id: viewable.episodeId || viewable.id },
      query: { types },
    };

    if (isLoggedIn) {
      dispatch(push(mediaOffersPage));
    } else {
      dispatch(push({
        name: isCreateAccountEnabled ? 'create-account' : 'log-in',
        query: {
          ...types === OFFER_TYPES.DefaultType
            ? {
              redirectTo: createRedirectLink(generateWatchLocation(viewable)),
              purchase: 'skip',
            }
            : {
              redirectTo: createRedirectLink(mediaOffersPage),
            },
        },
      }));
    }
  }, [viewable]);
}
