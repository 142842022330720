import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Notification,
  Text,
} from 'components';

const DeviceNotSupportedNotification = () => {
  const {
    isSupported,
  } = useSelector(state => ({
    isSupported: state.common.device !== 'smarttv',
  }), shallowEqual);

  const [isVisible, setIsVisible] = useState(!isSupported);

  const close = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Notification onClose={close}>
      <Text id="deviceNotSupported" />
    </Notification>
  );
};

export default DeviceNotSupportedNotification;
