import PropTypes from 'prop-types';
import { ANALYTICS_ACTION_SOURCES, ANALYTICS_ACTIONS, ANALYTICS_EVENTS } from 'utils/constants';

export default class Analytics {
  send = null;
  version = null;
  hash = null;
  seqId = 0;
  browser = {
    name: 'Other',
    version: '0.0.0',
  };

  constructor({ send, version, hash, browser }) {
    this.send = send;
    this.version = version;
    this.hash = hash;
    this.browser = browser;
  }

  log(data) {
    const connection = (global.navigator && global.navigator.connection) || {};

    const context = {
      browser_name: this.browser.name,
      browser_version: this.browser.version,
      network_type: connection.type || 'unknown',
      network_effective_type: connection.effectiveType || 'unknown',
      app_build: this.hash,
      app_version: this.version,
      seqId: this.seqId,
    };

    this.send({
      ...data,
      context: {
        ...data.context,
        ...context,
      },
    });

    this.seqId += 1;
  }

  onPageView(component, context) {
    this.log({
      component,
      context,
      action: ANALYTICS_ACTIONS.PAGE_VIEW,
      eventName: ANALYTICS_ACTIONS.PAGE_VIEW,
      source: ANALYTICS_ACTION_SOURCES.USER_ACTION,
    });
  }

  onSignupSuccess(provider) {
    this.log({
      component: 'signup',
      action: 'signup_success',
      eventName: 'signup_success',
      context: {
        provider,
      },
    });
  }

  onLoginSuccess(provider) {
    this.log({
      component: 'login',
      action: 'login_success',
      eventName: 'login_success',
      context: {
        provider,
      },
    });
  }

  onOffer(component, context) {
    this.log({
      component,
      eventName: ANALYTICS_EVENTS.OFFER,
      action: ANALYTICS_ACTIONS.BUY,
      context: {
        ...context,
      },
    });
  }

  onCancelSubscription(packId) {
    this.log({
      component: 'Subscribe',
      eventName: ANALYTICS_EVENTS.OFFER,
      action: ANALYTICS_ACTIONS.CANCEL,
      context: { packId },
    });
  }

  onVisible(data) {
    this.log({
      action: ANALYTICS_ACTIONS.VISIBLE,
      ...data,
    });
  }

  onClick({ component, action, ...rest }) {
    this.log({
      component,
      action,
      click_type: rest.clickType,
      ...rest,
    });
  }

  onError(message) {
    this.log({
      eventName: 'JavaScriptError',
      'gtm.errorMessage': message,
    });
  }

  onSearch(query) {
    this.log({
      component: 'Search',
      element: 'search_input',
      eventName: 'search_input',
      action: 'search_request_sent',
      searchString: query,
      search_string: query,
    });
  }
}

export const analyticsShape = PropTypes.instanceOf(Analytics);
