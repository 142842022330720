import React from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { sec2px, px2sec } from 'utils/broadcast';
import { Text } from 'components';
import { CHANNEL_SIZE } from '../Timeline/ChannelList';

const LivePinBody = createComponent(({ theme }) => ({
  backgroundColor: theme.color.livePinBackground,
  boxShadow: `0 -1px 2px 0 ${theme.color.livePinShadow}`,
  width: '3px',
  border: `1px solid ${theme.color.livePinBackground}`,

  position: 'absolute',
  bottom: '0',
  top: 35,
  zIndex: 3,

  pointerEvents: 'none',
  transition: 'all .3s ease',
}), 'div');

const LiveLabel = createComponent(({ theme, interval }) => ({
  height: '24px',
  lineHeight: '22px',
  padding: '0 6px',
  backgroundColor: theme.color.livePinHeadBackground,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  border: `1px solid ${theme.color.livePinHead}`,
  borderRadius: '6px',
  color: theme.color.livePinHead,
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  willChange: 'left',
  cursor: 'pointer',
  transition: [
    '.4s transform ease',
  ],

  extend: [
    {
      condition: interval === 'future',
      style: {
        transform: 'translateX(-100%)',
      },
    },
    {
      condition: interval === 'present',
      style: {
        transform: 'translateX(-50%)',
        cursor: 'auto',
        transition: 'unset',
      },
    },
    {
      condition: interval === 'past',
      style: {
        transform: 'translateX(-100%)',
      },
    },
  ],
}), 'div', ['inPresent']);

const LiveLabelWrapper = createComponent(() => ({
  position: 'absolute',
  top: '15px',
  zIndex: 4,
  textAlign: 'center',
  transition: 'all .3s ease',
}), 'div', ['inPresent']);

const OFFSET_PX = 20;

function deduceTimeInterval(currentTime, viewportStartTime, viewportEndTime) {
  if (currentTime < viewportStartTime + px2sec(CHANNEL_SIZE.width + OFFSET_PX)) {
    return 'future';
  }

  if (currentTime > viewportEndTime - px2sec(OFFSET_PX)) {
    return 'past';
  }

  return 'present';
}

const LiveIndicator = ({ onClick, startTime, currentTime, scrollLeft, width }) => {
  if (scrollLeft === null) {
    return null;
  }

  const viewportStartTime = startTime + px2sec(scrollLeft);
  const viewportEndTime = startTime + px2sec(scrollLeft + width);
  const interval = deduceTimeInterval(currentTime, viewportStartTime, viewportEndTime);
  const currPosition = sec2px(currentTime - viewportStartTime);
  let labelPosition = currPosition;

  if (interval === 'past') {
    labelPosition = width - OFFSET_PX;
  } else if (interval === 'future') {
    labelPosition = CHANNEL_SIZE.width + OFFSET_PX;
  }

  return (
    <>
      <LiveLabelWrapper
        style={{
          transform: `translateX(${labelPosition}px)`,
        }}
      >
        <LiveLabel
          className="e2e-livepin"
          interval={interval}
          onClick={interval === 'present' ? undefined : onClick}
        >
          {interval === 'future' && <span>&#10094; </span>}
          <Text id={interval === 'present' ? 'watch.live' : 'watch.backToLive'} />
          {interval === 'past' && <span> &#10095;</span>}
        </LiveLabel>
      </LiveLabelWrapper>
      {currPosition > CHANNEL_SIZE.width && (
        <LivePinBody
          style={{
            transform: `translateX(${currPosition}px)`,
          }}
        />
      )}
    </>
  );
};

LiveIndicator.propTypes = {
  startTime: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  scrollLeft: PropTypes.number,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LiveIndicator;
