import React from 'react';

export const DateFnsLocaleContext = React.createContext(null);

const TO_DATEFNS_LOCALE = {
  en: 'en-GB', // default
  ar: 'ar-SA', // fight-sport
};

export function locale2DateFnsLocale(locale) {
  return TO_DATEFNS_LOCALE[locale] || TO_DATEFNS_LOCALE.en;
}
