import React from 'react';
import PropTypes from 'prop-types';

const SANDBOX_RIGHTS = [
  'allow-scripts', // allow JS in iframe
  'allow-forms', // allow forms in iframe
  'allow-same-origin', // allow apps in iframe to access cookies/localstorage from their domain
  'allow-popups', // allow to open links in a new tab
];

function IFrame(props) {
  const {
    title,
    url,
    height,
    hideScroll,
  } = props;

  return (
    <iframe
      title={title}
      sandbox={SANDBOX_RIGHTS.join(' ')}
      src={url}
      style={{
        width: '100%',
        height,
      }}
      scrolling={hideScroll ? 'no' : undefined}
    />
  );
}

IFrame.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  height: PropTypes.string,
  hideScroll: PropTypes.bool,
};

export default React.memo(IFrame);
