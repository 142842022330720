import React from 'react';
import PropTypes from 'prop-types';
import {
  Item,
} from './Styles';

// Wrap renderItem into a component to avoid re-renders
function ItemRenderer({ pos, renderItem, active, next }) {
  return (
    <Item>
      {renderItem(pos, active, next)}
    </Item>
  );
}
ItemRenderer.propTypes = {
  pos: PropTypes.number.isRequired,
  renderItem: PropTypes.func.isRequired,
  next: PropTypes.func,
  active: PropTypes.bool,
};

export default React.memo(ItemRenderer);
