import React from 'react';
import {
  Container,
} from './Styles';

type MuteButtonProps = {
  muted: boolean,
  onClick: (e: React.MouseEvent) => void,
};

function MuteButton(props: MuteButtonProps) {
  const { onClick, muted, ...restProps } = props;

  return (
    <Container {...restProps} onClick={onClick}>
      <svg width="42" height="42" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle cx="23.0052" cy="23.0052" r="22.0052" stroke="white" strokeWidth="2" />
          {muted ? (
            <path d="M16.9708 26.8833C16.6904 26.8833 16.4584 26.7916 16.2751 26.6082C16.0917 26.4248 16 26.1929 16 25.9125V20.0875C16 19.8071 16.0917 19.5752 16.2751 19.3918C16.4584 19.2084 16.6904 19.1167 16.9708 19.1167H21.1777L25.9994 14.295C26.3014 13.993 26.652 13.9229 27.0511 14.0847C27.4502 14.2465 27.6498 14.5431 27.6498 14.9746V31.0254C27.6498 31.4569 27.4502 31.7535 27.0511 31.9153C26.652 32.0771 26.3014 32.007 25.9994 31.705L21.1777 26.8833H16.9708ZM25.7082 17.5634L22.0514 21.0584H17.9416V24.9416H22.0514L25.7082 28.4689V17.5634Z" fill="white" />
          ) : (
            <path d="M26.6081 33.946C26.2627 34.054 25.9497 34 25.669 33.7841C25.3884 33.5682 25.2481 33.2768 25.2481 32.9098C25.2481 32.7371 25.2966 32.5806 25.3938 32.4403C25.4909 32.2999 25.6258 32.2082 25.7986 32.165C27.7631 31.4742 29.3498 30.2977 30.5587 28.6354C31.7676 26.9731 32.3721 25.095 32.3721 23.0009C32.3721 20.9069 31.7676 19.0233 30.5587 17.3503C29.3498 15.6772 27.7631 14.5061 25.7986 13.8368C25.6258 13.7937 25.4909 13.6965 25.3938 13.5454C25.2966 13.3943 25.2481 13.2324 25.2481 13.0597C25.2481 12.6927 25.3938 12.4066 25.6852 12.2015C25.9767 11.9965 26.2843 11.9479 26.6081 12.0558C28.918 12.8762 30.78 14.2848 32.194 16.2817C33.608 18.2786 34.315 20.5183 34.315 23.0009C34.315 25.4835 33.608 27.7233 32.194 29.7202C30.78 31.7171 28.918 33.1257 26.6081 33.946ZM11.9715 26.9191C11.6908 26.9191 11.4587 26.8274 11.2752 26.6439C11.0917 26.4604 11 26.2283 11 25.9477V20.1189C11 19.8383 11.0917 19.6062 11.2752 19.4227C11.4587 19.2392 11.6908 19.1475 11.9715 19.1475H16.1811L21.006 14.3226C21.3083 14.0203 21.6591 13.9502 22.0584 14.1121C22.4578 14.274 22.6575 14.5708 22.6575 15.0026V31.064C22.6575 31.4958 22.4578 31.7926 22.0584 31.9545C21.6591 32.1164 21.3083 32.0463 21.006 31.7441L16.1811 26.9191H11.9715ZM24.6004 28.4735V17.5608C25.7662 17.9278 26.7052 18.6186 27.4177 19.6332C28.1301 20.6478 28.4863 21.7812 28.4863 23.0333C28.4863 24.307 28.1301 25.4404 27.4177 26.4334C26.7052 27.4265 25.7662 28.1065 24.6004 28.4735ZM20.7146 17.5931L17.0554 21.0904H12.9429V24.9762H17.0554L20.7146 28.5059V17.5931Z" fill="white" />
          )}
        </g>
      </svg>
    </Container>
  );
}

export default React.memo(MuteButton);
