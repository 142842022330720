import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import {
  RendererProvider as FelaProvider,
  ThemeProvider,
} from 'react-fela';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
  concat,
  from,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { Router, BrowserRouter, RouterContext } from 'router';
import AppView from 'views/AppView/AppView';
import {
  DateFnsLocaleContext,
  locale2DateFnsLocale,
} from 'components/I18n';
import createFelaRenderer from 'utils/createFelaRenderer';
import logger from 'utils/logger';
import { fetchWithTimeout } from 'utils/fetch';
import { configureSentry } from 'utils/sentry';
import { createReduxMiddlewares } from '../middlewares';
import { rootReducer } from '../reducers';
import getRoutes from '../routes';
import { createStore } from '../createStore';
import { installPolyfills } from './polyfills';
import apolloClientCache from '../../utils/apolloClientCache';

const initialState = window.__MAGINE_DATA;
delete window.__MAGINE_DATA;

const felaTheme = window.__MAGINE_THEME;
delete window.__MAGINE_THEME;

const apolloState = window.__MAGINE_APOLLO_STATE;
delete window.__MAGINE_APOLLO_STATE;

const router = new Router(window.location.origin);

const { settings } = initialState;

const store = createStore({
  reduxMiddlewares: createReduxMiddlewares({}, router),
  rootReducer,
  initialState,
});

const felaRenderer = createFelaRenderer(felaTheme.direction);

const httpLink = new HttpLink({
  uri: settings.graphqlEndpoint,
  headers: {
    'Magine-AccessToken': settings.clientApiToken,
    'Accept-Language': settings.l10n.language,
  },
  fetch: fetchWithTimeout,
});
const authLink = new ApolloLink((operation, forward) => {
  const { sessionToken } = store.getState().auth;

  operation.setContext({
    headers: {
      authorization: sessionToken ? `Bearer ${sessionToken}` : '',
    },
  });

  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 300000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: error => !!error,
  },
});

const apolloClient = new ApolloClient({
  link: from([retryLink, concat(authLink, httpLink)]),
  cache: apolloClientCache().restore(apolloState),
});

let root;

async function renderApp(hydrate) {
  let initialView = null;

  if (hydrate) {
    const {
      pathname,
      query,
    } = store.getState().router;

    const {
      route,
      location,
    } = await router.resolve(pathname, query);

    if (route) {
      initialView = React.createElement(route.component, { location });
    }
  }

  const locale = store.getState().settings.l10n.language;

  const dateFnsLocaleName = locale2DateFnsLocale(locale);
  const dateFnsLocale = await import(/* webpackChunkName: 'date-fns-locale-' */ `date-fns/locale/${dateFnsLocaleName}/index.js`).then(
    module => module.default,
    (e) => {
      logger.error(`failed to load date-fns locale ${locale}`, e);
    },
  );

  configureSentry(settings); // Initialize Sentry

  const getAppComponents = () => (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <FelaProvider renderer={felaRenderer}>
          <ThemeProvider theme={felaTheme}>
            <ReduxProvider store={store}>
              <RouterContext.Provider value={router}>
                <DateFnsLocaleContext.Provider value={dateFnsLocale}>
                  <BrowserRouter
                    RootComponent={AppView}
                    initialView={initialView}
                    hydrate={hydrate}
                  />
                </DateFnsLocaleContext.Provider>
              </RouterContext.Provider>
            </ReduxProvider>
          </ThemeProvider>
        </FelaProvider>
      </HelmetProvider>
    </ApolloProvider>
  );

  const container = document.getElementById('root');
  if (hydrate) {
    root = hydrateRoot(container, getAppComponents());
  } else {
    if (root) root.unmount();
    root = createRoot(container);
    root.render(getAppComponents());
  }
}

(async () => {
  await installPolyfills(settings.l10n.language).catch((e) => {
    logger.error('Failed to install polyfills', e);
  });

  router.setRoutes(await getRoutes(store, apolloClient));

  await renderApp(store.getState().settings.app.SSR);
})();

if (module.hot) {
  module.hot.accept(['../routes', '../reducers', '../views/AppView/AppView'], async () => {
    router.setRoutes(await getRoutes(store, apolloClient));

    store.replaceReducer(rootReducer);

    await renderApp(false);
  });
}
