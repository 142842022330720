import React, { SVGProps } from 'react';
import createComponent, { ComponentRuleProps, RuleStyles } from 'styles/fela/createComponent';
import { extractThemeProp } from 'components/StyledSystem/Box';

const Svg = createComponent(({ theme, onClick, color }: ComponentRuleProps): RuleStyles => ({
  cursor: onClick ? 'pointer' : 'inherit',
  extend: [
    {
      condition: color,
      style: {
        color: extractThemeProp(theme.color, color),
      },
    },
  ],
}), 'svg', ['color', 'opacity']);

export type IconType = SVGProps<SVGElement> & {
  size?: string,
  viewBox?: string,
  opacity?: number | string,
};

export type IconWithText = IconType & {
  text: string | number,
};

export default function Icon({
  size = '24',
  viewBox = '0 0 24 24',
  opacity = '1',
  ...props
}: IconType): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="currentColor"
      height={size}
      width={size}
      viewBox={viewBox}
      style={{ opacity }}
      {...props}
    />
  );
}
