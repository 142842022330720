import React from 'react';
import {
  Container,
} from './Styles';

type RestartButtonProps = {
  onClick: (e: React.MouseEvent) => void,
};

function RestartButton(props: RestartButtonProps) {
  const { onClick, ...restProps } = props;

  return (
    <Container {...restProps} onClick={onClick}>
      <svg width="3.5rem" height="3.5rem" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle cx="26" cy="26" r="25" stroke="white" strokeWidth="2" />
          <path d="M37.5557 25.9999C37.5557 32.3818 32.3821 37.5554 26.0001 37.5554C19.6182 37.5554 14.4446 32.3818 14.4446 25.9999C14.4446 19.6179 19.6182 14.4443 26.0001 14.4443C29.6302 14.4443 32.8693 16.1182 34.9878 18.7362" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M35.6299 14.4443L35.6299 19.5801L30.4941 19.5801" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    </Container>
  );
}

export default React.memo(RestartButton);
