import {
  UPDATE_PLATFORM_INFO,
  SET_TIME_ZONE,
  SCROLLBAR_WIDTH,
  CommonActions,
} from 'actions/common';
import {
  BrowserType,
  OSType,
  OS_TYPES,
} from 'utils/constants';

const MOBILE_OS = [
  OS_TYPES.ANDROID,
  OS_TYPES.IOS,
];

export type CommonReducerState = {
  os: OSType,
  isMobileOS: boolean,
  scrollBarWidth: number,
  browser?: BrowserType,
  device: string,
  version: string,
  description: string,
  overrideTimeZone?: string,
  platformLogin?: {
    location: any,
  },
};

const defaultState: CommonReducerState = {
  os: OS_TYPES.OTHER,
  isMobileOS: false,
  scrollBarWidth: 0,
  browser: undefined,
  device: '',
  version: '',
  description: '',
  // We use `overrideTimeZone` for the unit testing on Windows
  // where we can't set timezone via environment variable
  overrideTimeZone: undefined,
};

export function commonReducer(state = defaultState, action: CommonActions): CommonReducerState {
  switch (action.type) {
    case UPDATE_PLATFORM_INFO: {
      const {
        os,
        browser,
        device,
        version,
        description,
        platformLogin,
      } = action.payload;

      return {
        ...state,
        os,
        browser,
        device,
        version,
        description,
        platformLogin,
        isMobileOS: MOBILE_OS.includes(os) && device === 'mobile',
      };
    }

    case SCROLLBAR_WIDTH: {
      return {
        ...state,
        scrollBarWidth: action.payload.scrollBarWidth,
      };
    }

    case SET_TIME_ZONE: {
      return {
        ...state,
        overrideTimeZone: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
