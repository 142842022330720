import React from 'react';
import PropTypes from 'prop-types';
import { useFela } from 'react-fela';
import {
  fill,
  progressBarWrapper,
} from './Styles';

const ProgressBar = (props) => {
  const { css } = useFela();
  const { percent, extend } = props;

  return (
    <div
      className={css([progressBarWrapper, extend])}
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={percent}
    >
      <div
        className={css(fill)}
        style={{
          width: `${percent}%`,
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  extend: PropTypes.object,
};

ProgressBar.defaultProps = {
  extend: {},
};

export default React.memo(ProgressBar);

export function VideoProgressBar({ offset, duration, ...restProps }) {
  const percent = Math.round((offset / duration) * 100);

  return (
    <ProgressBar percent={percent} {...restProps} />
  );
}

VideoProgressBar.propTypes = {
  offset: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};
