import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import * as routerActions from 'router/actions';
import { useRouter } from './RouterContext';

if (global.window) {
  // Switch off the native scroll restoration behavior and handle it manually
  // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
}

type Props = {
  RootComponent: React.ComponentType<{ children: React.ReactNode }>,
  initialView: React.ReactNode,
  hydrate: boolean,
};

function BrowserRouter(props: Props) {
  const {
    RootComponent,
    initialView,
    hydrate,
  } = props;

  const router = useRouter();
  const dispatch = useDispatch();

  const {
    pathname,
    query,
    isPush,
    routeLoading,
  } = useSelector((state: RootState) => ({
    pathname: state.router.pathname,
    query: state.router.query,
    isPush: state.router.isPush,
    routeLoading: state.router.routeLoading,
  }), shallowEqual);

  const [view, setView] = React.useState<React.ReactNode>(initialView);
  const scrollPosRef = React.useRef<Record<string, any>>({});
  const isInitialRenderRef = React.useRef(true);

  React.useEffect(() => {
    const isInitialRender = isInitialRenderRef.current;
    isInitialRenderRef.current = false;

    // skip initial route resolve process when rehydrating
    if (hydrate && isInitialRender) {
      return () => {};
    }

    if (!routeLoading && !isInitialRender) {
      return () => {};
    }

    let ignoreResult = false;

    router.resolve(pathname, query).then((result) => {
      if (ignoreResult) {
        return undefined;
      }

      if ('redirectLocation' in result) {
        const { redirectLocation } = result;

        if (typeof redirectLocation === 'string') {
          window.location.href = redirectLocation;
          return undefined;
        }

        // allow to hard redirect in the browser
        if ('reload' in redirectLocation && redirectLocation.reload) {
          window.location.href = router.getUrl(redirectLocation);
          return undefined;
        }

        dispatch(routerActions.replace(redirectLocation));
        return undefined;
      }

      if ('error' in result) {
        throw result.error;
      }

      const {
        route,
        location,
      } = result;

      // call the view init function if any
      if (route.init) {
        return route.init(location).then(() => result);
      }

      return result;
    }).then((result) => {
      if (!result || ignoreResult) {
        return;
      }

      const { route, location } = result;

      dispatch(routerActions.setActiveLocation(location));

      // inject "location" property into each view
      setView(React.createElement(route.component, { location } as any));

      if (isPush) {
        delete scrollPosRef.current[pathname]; // delete stored scroll position for the next page
        window.scrollTo(0, 0);
      }
    }, (error) => {
      dispatch(routerActions.setRouteError(error));
      setView(null);
    });

    return () => {
      ignoreResult = true;
    };
  }, [pathname, routeLoading]);

  React.useEffect(() => () => {
    // save scroll pos when pathname changes
    if (!routeLoading) {
      scrollPosRef.current[pathname] = {
        offsetX: window.scrollX,
        offsetY: window.scrollY,
      };
    }
  }, [pathname, routeLoading]);

  React.useEffect(() => {
    if (scrollPosRef.current[pathname]) {
      // try to restore scroll position
      const { offsetX, offsetY } = scrollPosRef.current[pathname];
      window.scrollTo(offsetX, offsetY);
    }
  }, [view]);

  return (
    <RootComponent>
      {view}
    </RootComponent>
  );
}

export default React.memo(BrowserRouter);
