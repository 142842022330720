import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { broadcastShape, channelShape } from 'reducers/epg';
import * as epgActions from 'actions/epg';
import { px2sec, sec2px } from 'utils/broadcast';
import logger from 'utils/logger';
import { Text } from 'components';
import { withAnalytics, analyticsShape } from 'components/Tracking';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';
import { ButtonType } from 'components/ErrorPage/constants';
import ScrollButton from './ScrollButton/ScrollButton';
import Timeline from './Timeline/Timeline';
import TimebarOverlay from './Timebar/TimebarOverlay';
import { TIMEBAR_HEIGHT } from './Timebar/Timebar';
import EpgHeader from './Header/Header';
import {
  Epg,
  TimelineContainer,
  EPG_HEADER_HEIGHT,
} from './EpgStyles';

// How close in pixels to the edges
// in order to start fetching more data.
// We fetch additional full day because API returns only programs
// that starts in requested day.Also we can't provide a range
// and requested day, it is a time between 00:00 and 23:59 in UTC!
const FETCH_THRESHOLD = 7 * 60 * 20; // 7px == 1m

class EpgView extends PureComponent {
  static propTypes = {
    loadNextDay: PropTypes.func.isRequired,
    loadPreviousDay: PropTypes.func.isRequired,
    startTime: PropTypes.number.isRequired,
    stopTime: PropTypes.number.isRequired,
    broadcasts: PropTypes.arrayOf(broadcastShape).isRequired,
    channels: PropTypes.arrayOf(channelShape).isRequired,
    isRTL: PropTypes.bool.isRequired,
    subscriptionUrl: PropTypes.string,
    scrollBarWidth: PropTypes.number.isRequired,
    analytics: analyticsShape.isRequired,
  };

  state = {
    currentTime: Math.floor(Date.now() / 1000),
    scrollLeft: null,
    width: 100,
    height: 100,
  };

  timer = null;
  isLoading = false;
  fetchIfNeededReady = false;
  epgElement = null;

  setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve));
  }

  async componentDidMount() {
    window.addEventListener('resize', this.updateSizeFromDOM, { passive: true });
    document.body.style['overscroll-behavior-x'] = 'none';

    this.updateSizeFromDOM();

    await this.scrollToTime(this.state.currentTime);
    // This prevents fetch to be called right
    // away because scrollLeft is 0 until after first scroll.
    this.fetchIfNeededReady = true;

    this.startTimerIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.channels !== prevProps.channels
      || this.props.broadcasts !== prevProps.broadcasts) {
      this.startTimerIfNeeded();
    }
    // scroll position depends on `startTime` (the oldest loaded day) and `scrollLeft` position
    // we need update `scrollLeft` position when new older data is loaded
    if (prevProps.startTime !== this.props.startTime) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        scrollLeft: this.state.scrollLeft + sec2px(prevProps.startTime - this.props.startTime),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('resize', this.updateSizeFromDOM);
    document.body.style['overscroll-behavior-x'] = null;
  }

  startTimerIfNeeded() {
    const {
      channels,
      broadcasts,
    } = this.props;

    if (this.timer || !channels.length || !broadcasts.length) {
      return;
    }

    this.timer = setInterval(() => {
      window.requestAnimationFrame(() => {
        this.setState({
          currentTime: Math.floor(Date.now() / 1000),
        });
      });
    }, 1000);
  }

  updateSizeFromDOM = () => {
    if (!this.epgElement) {
      return;
    }

    const { width, height } = this.epgElement.getBoundingClientRect();

    this.setState({ width, height });
  };

  saveRef = (ref) => {
    this.epgElement = ref;
  };

  onClickLive = () => {
    this.scrollToTime(this.state.currentTime);
    this.props.analytics.onClick({
      component: 'EPG',
      eventName: 'click_live_button',
      action: 'click',
      clickType: 'action',
      element: 'live_button',
    });
  };

  onScrollLeftUpdate = async (rawScrollLeft) => {
    if (!this.fetchIfNeededReady || this.isLoading) {
      return;
    }

    const {
      startTime,
      stopTime,
      loadPreviousDay,
      loadNextDay,
      scrollBarWidth,
    } = this.props;

    const {
      width,
    } = this.state;

    const totalWidth = sec2px(stopTime - startTime);
    const maxScrollLeft = (totalWidth - width) + scrollBarWidth;

    const scrollLeft = Math.min(Math.max(rawScrollLeft, 0), maxScrollLeft);

    requestAnimationFrame(() => this.setState({ scrollLeft }));

    const scrollTime = startTime + px2sec(scrollLeft);

    const shouldFetchPrevious = scrollLeft >= 0 && scrollLeft < FETCH_THRESHOLD;
    if (shouldFetchPrevious) {
      this.isLoading = true;
      await loadPreviousDay();
      await this.scrollToTime(scrollTime, false);
    }

    const shouldFetchNext = scrollLeft > (maxScrollLeft - FETCH_THRESHOLD);
    if (shouldFetchNext) {
      this.isLoading = true;
      await loadNextDay();
      await this.scrollToTime(scrollTime, false);
    }

    this.isLoading = false;
  };

  async scrollToTime(time, center = true) {
    await this.setStateAsync((state) => {
      const alignOffset = center ? (state.width / 2) : 0;

      return { scrollLeft: sec2px(time - this.props.startTime) - alignOffset };
    });
  }

  render() {
    const {
      broadcasts,
      channels,
      startTime,
      stopTime,
      subscriptionUrl,
      scrollBarWidth,
      analytics,
      isRTL,
    } = this.props;

    const {
      currentTime,
      scrollLeft,
      width,
      height,
    } = this.state;

    if (!channels.length) {
      logger.warn('EPG: no channels available');
      return (
        <ErrorPage
          icon={ERROR_ICONS.EPG_ERROR}
          button={ButtonType.HREF}
          buttonProps={{
            href: subscriptionUrl,
            label: <Text id="subscribeNow" />,
          }}
        >
          <Text
            as="p"
            id="epg.needToBeSubscribed"
            isHtml
          />
        </ErrorPage>
      );
    }

    if (!broadcasts.length) {
      logger.warn('EPG: no broadcasts available');
      return (
        <ErrorPage button="none">
          <Text id="epg.noBroadcasts" />
        </ErrorPage>
      );
    }

    return (
      <Epg innerRef={this.saveRef}>
        <EpgHeader
          startTime={startTime}
          scrollLeft={scrollLeft}
          scrollToTime={timestamp => this.scrollToTime(timestamp, false)}
          scrollToNow={() => this.scrollToTime(this.state.currentTime)}
        />

        <ScrollButton
          isLeft
          isRTL={isRTL}
          onScrollLeftUpdate={this.onScrollLeftUpdate}
          scrollLeft={scrollLeft}
        />

        <ScrollButton
          isRTL={isRTL}
          onScrollLeftUpdate={this.onScrollLeftUpdate}
          scrollLeft={scrollLeft}
        />

        <TimebarOverlay
          width={width}
          currentTime={currentTime}
          scrollLeft={scrollLeft + (isRTL ? scrollBarWidth : 0)}
          startTime={startTime}
          stopTime={stopTime}
          onClickLive={this.onClickLive}
        />

        <TimelineContainer scrollBarWidth={scrollBarWidth}>
          <Timeline
            isRTL={isRTL}
            broadcasts={broadcasts}
            channels={channels}
            currentTime={currentTime}
            onScrollLeftUpdate={this.onScrollLeftUpdate}
            scrollLeft={scrollLeft}
            startTime={startTime}
            analytics={analytics}
            width={width + scrollBarWidth}
            height={height - EPG_HEADER_HEIGHT - 2 * TIMEBAR_HEIGHT + scrollBarWidth}
            scrollBarWidth={scrollBarWidth}
          />
        </TimelineContainer>
      </Epg>
    );
  }
}

export function initEpgView(store) {
  const { epg } = store.getState();
  if (!epg.datesLoaded.length) {
    return store.dispatch(epgActions.loadThreeDays());
  }
  return Promise.resolve();
}

const mapStateToProps = ({ epg, settings, common }) => ({
  isRTL: settings.l10n.direction === 'rtl',
  broadcasts: epg.broadcasts,
  channels: epg.channels,
  startTime: epg.startTime,
  stopTime: epg.stopTime,
  subscriptionUrl: settings.features.subscription?.url || '',
  scrollBarWidth: common.scrollBarWidth,
});

const mapDispatchToProps = {
  loadNextDay: epgActions.loadNextDay,
  loadPreviousDay: epgActions.loadPreviousDay,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAnalytics,
)(EpgView);
