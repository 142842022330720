import * as React from 'react';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';
import * as routerActions from './actions';
import { RouterLocation } from './location';
import { useRouter } from './RouterContext';

type SharedProps = {
  onClick?: (e: React.MouseEvent) => void,
  target?: string,
  children?: React.ReactNode,
};

export type Props = SharedProps & (
  {
    to: RouterLocation,
    href?: undefined,
  } | {
    to?: undefined,
    href: string,
  }
);

function Link(props: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    to,
    href,
    onClick = () => {},
    children,

    ...others
  } = props;

  const link = React.useMemo(() => {
    if (to) {
      return router.getUrl(to);
    }

    return href;
  }, [href, to]);

  if (!to && !href) {
    logger.warn('Link without action skipped. Provide either to or href prop.');
    return null;
  }

  if (to && href) {
    logger.warn('Link: both "to" and "href" provided. Navigation "to" is ignored');
  }

  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
    if (e.defaultPrevented) {
      return;
    }

    const isLeftClick = e.button === 0;
    if (!isLeftClick) {
      return;
    }

    if (href?.startsWith('/')) {
      e.preventDefault();
      dispatch(routerActions.pushRelative(href));
    }

    if (to) {
      e.preventDefault();
      dispatch(routerActions.push(to));
    }
  };

  return (
    <a
      href={link}
      onClick={handleClick}
      // automagically insert "rel" https://developers.google.com/web/tools/lighthouse/audits/noopener
      rel={others.target === '_blank' ? 'noopener' : undefined}
      {...others}
    >
      {children}
    </a>
  );
}

export default React.memo(Link);
