import { TTrialPeriodType } from '@types';
import {
  TRIAL_PERIOD,
} from 'utils/constants';

const secondsInADay = 60 * 60 * 24;

export const getTrialPeriodInDays = (trialPeriod: TTrialPeriodType): number => {
  switch (TRIAL_PERIOD[trialPeriod.unit]) {
    case TRIAL_PERIOD.DAYS: {
      return trialPeriod.length;
    }

    // FIXME: We don't really support this case at the moment. Convert to days
    // for the time being.
    case TRIAL_PERIOD.SECONDS: {
      return Math.floor(trialPeriod.length / secondsInADay);
    }

    default:
      throw new Error(`Unsupported trial period ${trialPeriod.unit}`);
  }
};
