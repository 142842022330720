import { Api } from '@tvoli/js-api-client';
import { APIRequestAction, API_REQUEST } from 'actions/helpers';
import { Middleware } from 'redux';

export function createApiMiddleware(requestHeaders: Record<string, string>): Middleware {
  return store => next => (action: APIRequestAction) => {
    if (action.type !== API_REQUEST) {
      return next(action);
    }
    const { method, params } = action.api;

    const {
      clientApiToken,
      apiBaseUri,
      app: { version },
    } = store.getState().settings;

    const updatedHeaders = {
      ...(requestHeaders || {}),
      'mdm-client-name': 'web',
      'mdm-client-version': version,
    };

    const apiClient = new Api({
      baseUri: apiBaseUri,
      clientApiToken,
      requestTimeoutMs: 10000,
      headers: updatedHeaders,
    });

    const tree = method.split('.');
    const apiMethod = tree.reduce((acc, key) => {
      if (acc && typeof acc[key] !== 'undefined') {
        return acc[key];
      }
      return null;
    }, apiClient);

    if (typeof apiMethod !== 'function') {
      return Promise.reject(new Error(`Api client does not have method ${method}`));
    }

    // update auth
    const {
      userId,
      sessionToken,
      isLoggedIn,
    } = store.getState().auth;
    const authData = isLoggedIn ? { userId, token: sessionToken } : undefined;
    apiClient.setAuthData(authData);

    return apiMethod.call(apiClient, params)
      .catch((err: Error) => {
        if (__DEVELOPMENT__) {
          console.error('API_MIDDLEWARE_ERROR', err); // eslint-disable-line no-console
        }

        throw err;
      });
  };
}
