import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { OS_TYPES } from 'utils/constants';
import appGooglePlay from './appGooglePlay.svg';
import appAppStore from './appAppStore.svg';

const MobileButton = () => {
  const {
    os,
    appStoreLink,
    googlePlayLink,
  } = useSelector((state: RootState) => ({
    os: state.common.os,
    googlePlayLink: state.settings.googlePlayLink,
    appStoreLink: state.settings.appStoreLink,
  }));

  if (os === OS_TYPES.IOS && appStoreLink) {
    return (
      <a href={appStoreLink}>
        <img src={appAppStore} alt="Download on the App Store" />
      </a>
    );
  }

  if (os === OS_TYPES.ANDROID && googlePlayLink) {
    return (
      <a href={googlePlayLink}>
        <img src={appGooglePlay} alt="Get it on Google Play" />
      </a>
    );
  }

  return null;
};

export default MobileButton;
