import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Spacer,
} from 'components';
import { CheckIcon } from 'components/Icons';
import { toMessage } from '../utils';
import { InputErrorMessage } from '../Styles';
import { Wrapper, StyledCheckbox } from './Styles';

const Checkbox = React.forwardRef((props, ref) => {
  const {
    className,
    label,
    error,
    dirty,
    touched,
    isSubmitted,
    value,
    name,
  } = props;

  const wrapperRef = useRef(null);
  const [checked, setChecked] = useState(!!value);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const showErrorMessage = error && ((dirty && touched) || isSubmitted);

  return (
    <Wrapper className={className} innerRef={wrapperRef}>
      <label htmlFor={`checkbox-${name}`}>
        <input
          className="hidden"
          ref={ref}
          type="checkbox"
          defaultChecked={value}
          name={name}
          id={`checkbox-${name}`}
          onChange={handleCheckboxChange}
        />

        <Box flexBox nowrap alignItems="center">
          <StyledCheckbox checked={checked}>
            {checked && <CheckIcon size="100%" />}
          </StyledCheckbox>

          <Spacer width="fine" />

          <Text cursor="pointer">
            {toMessage(label)}
          </Text>
        </Box>
      </label>

      <InputErrorMessage isVisible={showErrorMessage}>
        {/* use &nbsp; to preserve space for error message */}
        &nbsp;{toMessage(error)}
      </InputErrorMessage>
    </Wrapper>
  );
});
Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.object,
  dirty: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  value: PropTypes.bool,
};

export default React.memo(Checkbox);
