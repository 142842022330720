import createComponent from 'styles/fela/createComponent';

export const SliderWrapper = createComponent(() => ({
  position: 'relative',
  width: '40px',
  height: '21px',
  marginLeft: '10px',
}));

export const Slider = createComponent(({ theme, checked }) => ({
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'transparent',
  transition: '.4s',
  borderRadius: '34px',
  border: `2px solid ${theme.color.toggleSwitchInactive}`,
  ':before': {
    position: 'absolute',
    content: '""',
    height: '25px',
    width: '25px',
    left: '-7px',
    top: '-4px',
    backgroundColor: theme.color.page,
    border: `2px solid ${theme.color.toggleSwitchInactive}`,
    transition: '.4s',
    borderRadius: '50%',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.5)',
    extend: [
      {
        condition: checked,
        style: {
          transform: 'translateX(23px)',
          border: `2px solid ${theme.color.toggleSwitchActive}`,
        },
      },
    ],
  },
  extend: [
    {
      condition: checked,
      style: {
        backgroundColor: theme.color.toggleSwitchActive,
        border: `2px solid ${theme.color.toggleSwitchActive}`,
      },
    },
  ],
}));
