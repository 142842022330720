import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { responsiveRem2Px } from 'utils/helpers';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';
import { useIsMediumScreen, useIsSmallScreen } from 'utils/hooks';
import TooltipRenderer, { getImgUrl } from './TooltipRenderer';

const HOVER_DELAY = 400; // how much delay before the tooltip is shown + img loading delay.
const HIDE_DELAY = 0; // how much delay before the tooltip is hidden.

// Hide func of current/prev tooltip. Allow to hard close prev tooltip in case no mouseleave
let prevTooltipHide = null;
const clearOwnHide = (hideFunc) => {
  if (hideFunc === prevTooltipHide) prevTooltipHide = null;
};

const TooltipTrigger = (props) => {
  const { type, children, viewable, ...restProps } = props;
  let { pageGap } = props;
  const triggerEl = useRef();
  const hoverDelayTimer = useRef();
  const hideDelayTimer = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const isVisibleRef = useRef(isVisible);

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();
  if (pageGap === undefined) {
    pageGap = parseFloat(BASE_MARGIN.large);
    if (isSmallScreen) {
      pageGap = parseFloat(BASE_MARGIN.small);
    } else if (isMediumScreen) {
      pageGap = parseFloat(BASE_MARGIN.medium);
    }
  }

  const hide = useCallback(() => {
    setIsVisible(false);
    clearOwnHide(hide);
  }, []);

  const onMouseLeave = useCallback(() => {
    clearTimeout(hoverDelayTimer.current);
    hideDelayTimer.current = setTimeout(hide, HIDE_DELAY);
  }, []);

  const onLoad = useCallback(() => {
    // Remove mouse leave event listener after image has been lazy loaded
    // as mouse leave event listener will be handed over to TooltipRenderer after image is loaded
    triggerEl.current?.removeEventListener('mouseleave', onMouseLeave);
  }, []);

  useEffect(() => {
    if (!triggerEl.current || !getImgUrl(viewable)) {
      return () => {};
    }

    const show = () => {
      setIsVisible(true);

      // hide prev tooltip in case browser doesn't fire mouseleave for it
      if (prevTooltipHide && prevTooltipHide !== hide) prevTooltipHide();
      prevTooltipHide = hide;
    };

    const maybeShow = () => {
      // no tooltip for small screens
      if (isVisibleRef.current || window.innerWidth < BREAKPOINTS.sm) {
        return;
      }

      clearTimeout(hideDelayTimer.current);
      hoverDelayTimer.current = setTimeout(show, HOVER_DELAY);
    };

    const onMouseEnter = () => {
      maybeShow();
      triggerEl.current.addEventListener('mouseleave', onMouseLeave);
    };

    triggerEl.current.addEventListener('mouseenter', onMouseEnter);

    return () => {
      clearTimeout(hoverDelayTimer.current);
      clearTimeout(hideDelayTimer.current);
      clearOwnHide(hide);

      triggerEl.current?.removeEventListener('mouseenter', onMouseEnter);
    };
  }, []);

  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);

  return (
    <div ref={triggerEl}>
      {children}

      {isVisible && (
        <TooltipRenderer
          triggerEl={triggerEl.current}
          hide={hide}
          pageGap={responsiveRem2Px(pageGap)}
          viewable={viewable}
          onLoad={onLoad}
          {...restProps}
        />
      )}
    </div>
  );
};

TooltipTrigger.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.string,
  ...TooltipRenderer.props,
};

export default TooltipTrigger;
