import createComponent from 'styles/fela/createComponent';
import { ItemWrapper } from 'components/HorizontalScroll/HorizontalScroll';
import { maxWidth, minWidth, widthRange } from 'styles/fela/mixins';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

const arrowSize = 90;

export const ArrowContainer = createComponent(({ position, isVisible }) => ({
  position: 'absolute',
  top: 0,
  userSelect: 'none',
  zIndex: '10',
  minWidth: '50px',
  height: '100%',
  extend: [
    maxWidth(BREAKPOINTS.sm, {
      width: BASE_MARGIN.small,
    }),
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      width: BASE_MARGIN.medium,
    }),
    minWidth(BREAKPOINTS.md, {
      width: BASE_MARGIN.large,
    }),
    {
      condition: position === 'left',
      style: {
        left: 0,
      },
    },
    {
      condition: position === 'right',
      style: {
        right: 0,
      },
    },
    {
      condition: !isVisible,
      style: {
        display: 'none',
      },
    },
  ],
}), 'div', ['position', 'isVisible']);

export const Arrow = createComponent(({ theme, position, isVisible }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.zapperBackground,
  boxShadow: `0 0.125rem 0.25rem 0 ${theme.color.overlayDarkMedium}`,
  color: theme.color.zapper,
  cursor: 'pointer',
  display: 'flex',
  visibility: 'hidden', // see ScrollableContainer
  fontFamily: "'Icons', sans-serif",
  fontSize: theme.fontSize.sectionHeading,
  height: arrowSize,
  justifyContent: 'center',
  position: 'absolute',
  top: 'calc(50% - 22.5px)',
  transform: 'translateY(-50%)',
  userSelect: 'none',
  width: arrowSize / 2,
  zIndex: '10',
  extend: [
    {
      condition: position === 'left',
      style: {
        borderBottomRightRadius: arrowSize,
        borderTopRightRadius: arrowSize,
        left: 0,
      },
    },
    {
      condition: position === 'right',
      style: {
        borderBottomLeftRadius: arrowSize,
        borderTopLeftRadius: arrowSize,
        right: 0,
      },
    },
    {
      condition: !isVisible,
      style: {
        display: 'none',
      },
    },
  ],
}), 'div', ['position', 'isVisible']);

export const ScrollableContainer = createComponent(() => ({
  position: 'relative',

  '&:hover .arrow': {
    visibility: 'visible',
  },
}), 'div');

export const ItemContainer = createComponent(({ marginLeft, marginRight }) => ({
  marginLeft,
  marginRight,
  '&:nth-child(-n+2)': {
    // do not apply margin left to first 2 element as
    // 1st element is a spacer and the 2nd element is the first item
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: `calc(${marginLeft} + ${marginRight})`,
  },
}), ItemWrapper, ['marginLeft', 'marginRight']);

ItemContainer.displayName = 'ItemContainer';
