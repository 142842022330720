import { apiNetworkRequest, AppAsyncAction } from './helpers';

export function sendEvent(data: Record<string, any>): AppAsyncAction {
  return async (dispatch) => {
    await dispatch(apiNetworkRequest('tracker.sendTelemetryEvents', data));
  };
}

export function sendFeedback(
  surveyId: string,
  reasonId: string,
  reasonText: string,
  freeText: string,
): AppAsyncAction {
  return async (dispatch) => {
    await dispatch(apiNetworkRequest('tracker.sendFeedback', {
      id: surveyId,
      data: {
        reason_id: reasonId,
        reason_text: reasonText,
        freetext: freeText,
      },
    }));
  };
}
