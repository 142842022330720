import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getScrollableState } from 'reducers/scrollable';
import { setScrollableOffset, scrollableScrollLeft, scrollableScrollRight } from 'actions/scrollable';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import { usePrevious } from 'utils/hooks';
import {
  TabBar,
  TabLabel,
  TabTypes,
  ArrowContainer,
} from './Styles';
import Tab from './Tab';

const Tabs = (props) => {
  const {
    id,
    pageId,
    type,
    verticalShift,
    initialSelectedIndex = 0,
    onClick,
    children,
  } = props;

  const {
    hasNext,
    hasPrev,
    scrollableOffset,
    scrollableWidth,
    scrollableFullWidth,
    direction,
  } = useSelector((state) => {
    const scrollableState = getScrollableState(state, pageId, id);

    return {
      hasNext: scrollableState.hasNext,
      hasPrev: scrollableState.hasPrev,
      scrollableOffset: scrollableState.offset,
      scrollableWidth: scrollableState.width,
      scrollableFullWidth: scrollableState.fullWidth,
      direction: state.settings.l10n.direction,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(initialSelectedIndex);

  const selectedRef = useRef();

  const previousScrollableWidth = usePrevious(scrollableWidth);
  const previousScrollableFullWidth = usePrevious(scrollableFullWidth);
  const previousSelected = usePrevious(selected);

  useEffect(() => {
    if (!selectedRef.current) { // fix for HMR
      return;
    }

    const { offsetLeft, offsetWidth } = selectedRef.current;

    const directionalOffset = direction === 'rtl' ? previousScrollableFullWidth - (offsetLeft + offsetWidth) : offsetLeft;

    const selectedFullyVisible = directionalOffset >= scrollableOffset
      && directionalOffset + offsetWidth <= scrollableOffset + scrollableWidth;

    if (!selectedFullyVisible) {
      // make sure selected item is visible after changing device orientation
      const scrollableSizeChanged = scrollableWidth !== previousScrollableWidth
        || scrollableFullWidth !== previousScrollableFullWidth;

      const selectedChanged = selected !== previousSelected;

      if (scrollableSizeChanged || selectedChanged) {
        dispatch(setScrollableOffset(pageId, id, directionalOffset));
      }
    }
  }, [scrollableWidth, scrollableFullWidth, selected]);

  const selectTab = useCallback((e, index) => {
    if (onClick) {
      onClick(index);
    }
    setSelected(index);

    const tabBar = e.target.closest('#tabBar');

    tabBar?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }, [onClick]);

  const tabLabels = React.Children.map(children, (child, index) => {
    if (!child) {
      return null;
    }

    const { label } = child.props;
    const isSelected = index === selected;

    if (!label) {
      throw new Error('Tab element must have property "label"');
    }

    return (
      <TabLabel
        key={label}
        selected={isSelected}
        innerRef={(ref) => {
          if (isSelected) {
            selectedRef.current = ref;
          }
        }}
        onClick={e => selectTab(e, index)}
        role="tab"
        type={type}
        data-content={label}
      >
        {label}
      </TabLabel>
    );
  });

  const tabs = React.Children.map(children, (child, index) => {
    const isSelected = index === selected;

    return (
      <Tab isSelected={isSelected}>
        {child}
      </Tab>
    );
  });

  return (
    <div>
      <TabBar id="tabBar" type={type}>
        <ArrowContainer
          position="left"
          isVisible={hasPrev}
          verticalShift={verticalShift}
          onClick={() => dispatch(scrollableScrollLeft(pageId, id))}
        >
          ❮
        </ArrowContainer>

        <HorizontalScroll pageId={pageId} id={id}>
          {tabLabels}
        </HorizontalScroll>

        <ArrowContainer
          position="right"
          isVisible={hasNext}
          verticalShift={verticalShift}
          onClick={() => dispatch(scrollableScrollRight(pageId, id))}
        >
          ❯
        </ArrowContainer>
      </TabBar>

      {tabs}
    </div>
  );
};

Tabs.propTypes = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TabTypes)),
  verticalShift: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  initialSelectedIndex: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Tabs;
