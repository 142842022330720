import React from 'react';
import Icon from './Icon';

const directionMap: Record<string, number> = {
  right: 0,
  down: 90,
  left: 180,
  up: 270,
};

interface IChevronIconProps {
  direction: string;
  size?: string;
  style?: React.CSSProperties
}

export default function ChevronIcon(props: IChevronIconProps): JSX.Element {
  const { direction = 'right', style, ...otherProps } = props;
  const iconProps = {
    ...otherProps,
    style: {
      ...style,
      transform: `rotateZ(${directionMap[direction]}deg)`,
      transition: 'transform .3s',
    },
  };

  return (
    <Icon {...iconProps} viewBox="0 0 9 12" width="12" height="9">
      <path fill="currentColor" fillOpacity=".8" fillRule="evenodd" d="M0 0l9 5.5L0 11z" />
    </Icon>
  );
}
