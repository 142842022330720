import * as React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Offer } from 'types';
import * as campaignActions from 'actions/campaign';
import * as routerActions from 'router/actions';
import { useI18n } from 'components/I18n';
import { OFFER_TYPES } from 'utils/constants';
import {
  PaymentProviderFooter,
  AdyenResultHandler,
} from 'components/Payment';
import {
  Box,
  Heading,
  Text,
  PrimaryButton,
} from 'components';
import NoOffersErrorPage from 'components/ErrorPage/NoOffersErrorPage';
import { RouterLocation } from 'router';
import { AppDispatch, RootState } from 'reducers';
import { extractPromocodeValidationError } from 'actions/campaign';
import { flattenEdges, generateWatchLocation } from 'utils/helpers';
import ErrorPage, { ERROR_ICONS } from 'components/ErrorPage/ErrorPage';
import { useViewableOffersAndEntitlmentsQuery } from '../viewableOffersAndEntitlements.generated';
import PromoCodeModal from '../PromoCodeModal';
import { MSG_IDS, generateCheckoutConfLocation } from './utils';
import OfferComponent from './Offer';
import { Container } from './styles';

type Props = {
  location: RouterLocation,
};

// Allow users to select offer for provided viewableId. Offers are filtered by types
// Can be opened from Details/Home page
// Route: '/offers/:id' where id is viewableId (details page id)
function SelectOffersOfMedia({ location }: Props) {
  const i18n = useI18n();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = location.params!;
  const {
    types: typesFromUrl,
    promoCode,
    redirectTo,
  } = location.query!;
  const types = typesFromUrl && typeof typesFromUrl === 'string'
    ? typesFromUrl.split(',') : Object.values(OFFER_TYPES);

  const options = {
    variables: {
      viewableId: id,
    },
  };
  const {
    data,
    error: apolloError,
  } = useViewableOffersAndEntitlmentsQuery(options);

  const {
    withPromoCodes,
  } = useSelector((state: RootState) => ({
    withPromoCodes: state.settings.features.payment?.allowPromoCodes || false,
  }), shallowEqual);

  const [showModal, setShowModal] = React.useState(!!promoCode);

  const viewable = data?.viewer?.viewable;

  const hideModal = () => {
    if (promoCode) { // remove promoCode from the url
      dispatch(routerActions.push({
        name: 'checkout-media',
        params: { id },
        query: { types: typesFromUrl },
      }));
    }

    setShowModal(false);
  };

  const onOfferClick = (offerId: string) => {
    dispatch(routerActions.push(generateCheckoutConfLocation(
      offerId,
      viewable,
      redirectTo,
    )));
  };

  if (apolloError) {
    return (
      <ErrorPage button="retry" icon={ERROR_ICONS.NOT_FOUND}>
        <Text id="error" />
      </ErrorPage>
    );
  }

  if (!data || !viewable) {
    return null;
  }

  if (viewable?.entitlement) {
    dispatch(routerActions.replace(
      generateWatchLocation(viewable) as RouterLocation,
    ));
    return null;
  }

  const entitlements = flattenEdges(data.viewer?.entitlements);

  const availableOffers = viewable.offers
    // filter out purchased offers
    .filter(offer => !entitlements.includes(offer.id))
    // filter out unneeded offer types
    .filter(offer => types.includes(offer.__typename));

  if (!availableOffers.length) {
    return (
      <NoOffersErrorPage hasEntitlement={!!entitlements.length} />
    );
  }

  if (availableOffers.length === 1) {
    dispatch(routerActions.replace(generateCheckoutConfLocation(
      availableOffers[0].id,
      viewable,
      redirectTo,
    )));
    return null;
  }

  const validatePromocode = async (newPromoCode: string) => {
    const expectedOfferIds = availableOffers.map(offer => offer.id);

    try {
      const { offerIds } = await dispatch(campaignActions.getCampaign(newPromoCode));

      const matchingOfferIds = expectedOfferIds.filter((oId: string) => offerIds.includes(oId));

      if (!matchingOfferIds.length) {
        throw i18n.formatText('promoCode.notValidForOffer', { promoCode: newPromoCode });
      }

      dispatch(routerActions.push(generateCheckoutConfLocation(
        matchingOfferIds[0],
        viewable,
        redirectTo,
        newPromoCode,
      )));
    } catch (e) {
      throw i18n.formatText(
        extractPromocodeValidationError(JSON.stringify(e)),
        { promoCode: newPromoCode },
      );
    }
  };

  const availableOfferTypes = types.filter(
    (offerType: string) => availableOffers
      .filter(item => item.__typename === offerType).length > 0,
  );

  const items = availableOfferTypes.map((offerType: string) => {
    const offersOfType = availableOffers.filter(item => item.__typename === offerType) as Offer[];

    return (
      <Box
        key={offerType}
        mb="xlarge"
        maxWidth="100%"
      >
        <Heading
          fontSize="sectionHeading"
          align="center"
          // @ts-expect-error ts-migrate(7053)
          id={MSG_IDS[offerType]}
        />

        <Box mt="xlarge" row wrap columnGap="xlarge">
          {offersOfType.map(offer => (
            <OfferComponent
              key={offer.id}
              offer={offer}
              onClick={() => onOfferClick(offer.id)}
            />
          ))}
        </Box>
      </Box>
    );
  });

  return (
    <Container column mx="auto" mt="xxxlarge" maxWidth="100%">

      <Box mb="medium">
        <AdyenResultHandler />
      </Box>

      {items}

      {withPromoCodes && (
        <Box mb="large">
          <PrimaryButton
            variant="brandSecondary"
            onClick={() => setShowModal(true)}
            className="e2e-promocode-cta"
          >
            <Text id="payment.promoCodeButton" />
          </PrimaryButton>

          {showModal && (
            <PromoCodeModal
              initialValue={promoCode?.toString()}
              validatePromocode={validatePromocode}
              onClose={hideModal}
            />
          )}
        </Box>
      )}

      <Box mb="small">
        <PaymentProviderFooter />
      </Box>
    </Container>
  );
}

export default React.memo(SelectOffersOfMedia);
