import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  Text,
  Panel,
  PanelWrapper,
} from 'components';
import { useI18n } from 'components/I18n';

export default function PaymentFailed({ reason }) {
  const i18n = useI18n();
  const reasonI18nKey = `payment.refusal_${reason}`;
  return (
    <PanelWrapper>
      <Panel>
        <Heading
          id="payment.confirmation"
          fontWeight="bold"
          align="center"
        />

        <Box mt="xxlarge">
          {
            reason ? (
              <Text
                id="payment.transactionFailedWithReason"
                display="block"
                fontSize="medium"
                align="center"
                values={{
                  reason: i18n.hasMessage(reasonI18nKey)
                    ? i18n.formatText(reasonI18nKey)
                    : reason,
                }}
              />
            ) : (
              <Text
                id="payment.transactionFailed"
                display="block"
                fontSize="medium"
                align="center"
              />
            )
          }
        </Box>
      </Panel>
    </PanelWrapper>
  );
}

PaymentFailed.propTypes = {
  reason: PropTypes.string,
};
