import React from 'react';
import Icon, { IconType } from '../Icon';

const VolumeUpIcon = (props: IconType): JSX.Element => (
  <Icon {...props}>
    <path fill="#000" d="M170 85c0 46.944-38.056 85-85 85S0 131.944 0 85 38.056 0 85 0s85 38.056 85 85Z" opacity=".4" />
    <path fill="#fff" d="M47.5 76.667v16.666a4.179 4.179 0 0 0 4.167 4.167h12.5l13.708 13.708c2.625 2.625 7.125.75 7.125-2.958V61.708c0-3.708-4.5-5.583-7.125-2.958L64.167 72.5h-12.5a4.179 4.179 0 0 0-4.167 4.167ZM103.75 85c0-7.375-4.25-13.708-10.417-16.792v33.542C99.5 98.708 103.75 92.375 103.75 85ZM93.333 53.542v.833c0 1.583 1.042 2.958 2.5 3.542 10.75 4.291 18.334 14.833 18.334 27.083 0 12.25-7.584 22.792-18.334 27.083-1.5.584-2.5 1.959-2.5 3.542v.833c0 2.625 2.625 4.459 5.042 3.542 14.125-5.375 24.125-19 24.125-35s-10-29.625-24.125-35c-2.417-.958-5.042.917-5.042 3.542Z" />
  </Icon>
);

export default VolumeUpIcon;
