import React from 'react';
import PropTypes from 'prop-types';
import { Hero } from 'components';
import {
  Wrap,
  Title,
  Description,
} from './Styles';

const GenreHeader = ({ title, description, image }) => (
  <Hero src={image} overlay="transparent">
    <Wrap>
      <Title chars={title.length}>{title}</Title>
      <Description>{description}</Description>
    </Wrap>
  </Hero>
);
GenreHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default GenreHeader;
