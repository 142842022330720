import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormField } from 'components/Forms';
import { I18nContext } from 'components/I18n';
import { Input } from 'components/Form';

const uppercaseAndTrim = (str: string) => str.toUpperCase().trim();

type Props = {
  autoFocus?: boolean,
};

export default function PromoCodeInput({ autoFocus }: Props): JSX.Element {
  const i18n = useContext(I18nContext);

  // disable rule due to false-positive https://github.com/react-hook-form/react-hook-form/issues/2887
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { clearErrors, formState: { isValid } } = useFormContext();

  const inputProps = {
    placeholder: i18n.formatText('promoCode.input'),
    normalize: uppercaseAndTrim,
    autoFocus,
    onChange: () => !isValid && clearErrors('promoCode'),
  };

  return (
    <FormField
      as={Input}
      name="promoCode"
      {...inputProps}
    />
  );
}
