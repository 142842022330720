import createComponent from 'styles/fela/createComponent';

const PageWrapper = createComponent(({ theme, background, row }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  extend: [
    {
      condition: background,
      style: {
        background: theme.color.page,
      },
    },
    {
      condition: row,
      style: {
        flexDirection: 'row',
      },
    },
  ],
}), 'div', ['background', 'row']);

PageWrapper.displayName = 'PanelWrapper';

export default PageWrapper;
