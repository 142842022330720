import React from 'react';
import PropTypes from 'prop-types';
import { forgotPasswordShape } from 'utils/shapes';
import {
  Text,
  Link,
  Box,
  Heading,
  Panel,
  PanelContent,
  PanelWrapper,
} from 'components';

const ForgotPasswordSentView = ({ createAccountEnabled, forgotPassword }) => (
  <PanelWrapper>
    <Panel textAlign="center">
      <Heading mb="small">
        <span>{forgotPassword.title}</span>
      </Heading>

      <Box mb="medium">
        <Heading
          id="forgotPassword.emailSent"
          fontSize="medium"
        />
      </Box>

      <PanelContent centered>
        <Box mb="fine">
          <Link
            to={{ name: 'log-in' }}
            id="logIn"
            fontSize="small"
          />
        </Box>

        {createAccountEnabled && (
          <Text
            id="noAccountCreateAccount"
            values={{
              createAccountLink: (
                <Link
                  key="createAccountLink"
                  to={{ name: 'create-account' }}
                  id="createAccount"
                />
              ),
            }}
            color="secondary"
            fontSize="small"
          />
        )}
      </PanelContent>
    </Panel>
  </PanelWrapper>
);

ForgotPasswordSentView.propTypes = {
  createAccountEnabled: PropTypes.bool.isRequired,
  forgotPassword: forgotPasswordShape.isRequired,
};

export default ForgotPasswordSentView;
