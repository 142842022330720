import { ANIMATIONS } from './Styles';

const PAGE_GAP_PX = 16; // default space between page and tooltip
const NORMAL_ANIMATION_OVERFLOW = 70; // if tooltip overflow is bigger, side animation is on

export default class PlacementCalculator {
  constructor(triggerEl, tooltipEl, pageGap = PAGE_GAP_PX) {
    const triggerPos = triggerEl.getBoundingClientRect();
    const tooltipPos = tooltipEl.getBoundingClientRect();

    this.trigger = {
      top: window.scrollY + triggerPos.top,
      left: window.scrollX + triggerPos.left,
      width: triggerPos.width,
      height: triggerPos.height,
    };

    this.tooltipWidth = tooltipPos.width;
    this.tooltipHeight = tooltipPos.height;

    this.pageGap = pageGap;
  }

  findBestPlacement() {
    const trigger = { ...this.trigger };

    // hide tooltip if it's visible part is less than pageGap
    if (
      this.trigger.left + this.trigger.width < this.pageGap
      || this.trigger.left > document.body.offsetWidth - this.pageGap
    ) {
      return null;
    }

    // regular tooltip position
    let top = trigger.top + trigger.height / 2 - this.tooltipHeight / 2;
    let left = trigger.left + trigger.width / 2 - this.tooltipWidth / 2;
    // regular animation
    let animOn = ANIMATIONS.on;
    let animOff = ANIMATIONS.off;

    // check overflow on right side
    const maxLeft = document.body.offsetWidth - this.tooltipWidth - this.pageGap;
    if (maxLeft + NORMAL_ANIMATION_OVERFLOW < left) {
      animOn = ANIMATIONS.onRight;
      animOff = ANIMATIONS.offRight;
    }
    left = Math.min(left, maxLeft);

    // check overflow on left side
    const minLeft = this.pageGap;
    if (minLeft - NORMAL_ANIMATION_OVERFLOW > left) {
      animOn = ANIMATIONS.onLeft;
      animOff = ANIMATIONS.offLeft;
    }
    left = Math.max(left, minLeft);

    // check overflow on top side
    top = Math.max(top, PAGE_GAP_PX);

    // check overflow on bottom side
    top = Math.min(top, document.body.offsetHeight - this.tooltipHeight - PAGE_GAP_PX);

    // check trigger container overflow the left page side
    if (trigger.left < this.pageGap) {
      trigger.width -= (this.pageGap - trigger.left);
      trigger.left = this.pageGap;
    }
    // check trigger container overflow the right page side
    if ((trigger.width + trigger.left) > (document.body.offsetWidth - this.pageGap)) {
      trigger.width = Math.floor(document.body.offsetWidth - trigger.left - this.pageGap);
    }

    return { top, left, trigger, animOn, animOff };
  }
}
