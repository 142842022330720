import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { inputFieldShape } from 'utils/shapes';
import { INPUT_FIELD_TYPE } from 'utils/constants';
import validate from 'utils/validate';
import FormField from './FormField';
import TextField from './TextField/TextField';
import SelectField from './SelectField/SelectField';
import DatePickerField from './DatePickerField/DatePickerField';

const SUPPORTED_INPUT_FIELD_TYPES = [
  INPUT_FIELD_TYPE.default,
  INPUT_FIELD_TYPE.email,
  INPUT_FIELD_TYPE.emailConfirmation,
  INPUT_FIELD_TYPE.passwordConfirmation,
  INPUT_FIELD_TYPE.picker,
  INPUT_FIELD_TYPE.date,
];

const trim = value => (value || '').trim();

function InputField(props) {
  const {
    field,
    validations,
    ...otherProps
  } = props;

  const inputType = field.inputType || INPUT_FIELD_TYPE.default;

  const validateField = useCallback(
    validate.inputFieldValidations(field.validations || []),
    [field.validations],
  );

  const allValidations = {
    validate: validateField,
    ...(validations || {}),
  };

  if (!SUPPORTED_INPUT_FIELD_TYPES.includes(inputType)) {
    return null;
  }

  if (inputType === INPUT_FIELD_TYPE.date) {
    return (
      <DatePickerField
        name={field.key}
        placeholder={field.placeholder}
        validations={allValidations}
        {...otherProps}
      />
    );
  }

  let fieldAs = TextField;
  let fieldType = 'text';
  let normalize;
  if ([INPUT_FIELD_TYPE.email, INPUT_FIELD_TYPE.emailConfirmation].includes(inputType)) {
    fieldType = 'email';
    normalize = trim;
  }

  if (
    [INPUT_FIELD_TYPE.default, INPUT_FIELD_TYPE.passwordConfirmation].includes(inputType)
    && field.secureEntry
  ) {
    fieldType = 'password';
    normalize = trim;
  }

  if (inputType === INPUT_FIELD_TYPE.picker) {
    fieldAs = SelectField;
    otherProps.options = field.pickerValues;
  }

  return (
    <FormField
      as={fieldAs}
      name={field.key}
      placeholder={field.placeholder}
      type={fieldType}
      validations={allValidations}
      normalize={normalize}
      {...otherProps}
    />
  );
}

InputField.propTypes = {
  field: inputFieldShape.isRequired,
  validations: PropTypes.object,
};

export default React.memo(InputField);
