export const bar = ({ theme }) => ({
  background: theme.color.loadingBar,
  height: '3px',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: theme.zIndex.loadingBar,
});

export const peg = ({ theme }) => ({
  boxShadow: `0 0 10px ${theme.color.loadingBar}, 0 0 5px ${theme.color.loadingBar}`,
  display: 'block',
  height: '100%',
  opacity: 1,
  position: 'absolute',
  right: 0,
  transform: 'rotate(3deg) translate(0, -4px)',
  width: '100px',
});
