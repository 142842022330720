import React, { useEffect, useState, useRef, useContext, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { I18nContext } from 'components/I18n';
import { useAnalytics } from 'components/Tracking';
import { Box } from 'components';
import {
  IconButton,
  CloseIcon,
} from 'components/Icons';
import { useIsSmallScreen } from 'utils/hooks';
import {
  SearchWrapper,
  InputWrapper,
  Input,
  SearchIconStyled,
  Placeholder,
  StyledHidden,
} from './Styles';

function SearchInput(props) {
  const { onSearch, onClose, className, autoFocus, iconSize } = props;

  const {
    query,
    kinds,
  } = useSelector(({ router }) => ({
    query: (router.location.name === 'search' && router.query.q) || '',
    kinds: router.query.kinds,
  }));

  const i18nContext = useContext(I18nContext);
  const analytics = useAnalytics();
  const inputRef = useRef(null);

  const debouncedSearch = useMemo(() => debounce(onSearch, 500), [onSearch]);
  const debouncedAnalytics = useMemo(
    () => debounce(searchQuery => analytics.onSearch(searchQuery), 1000),
    [],
  );

  const [xVisible, setXVisible] = useState(query.length > 0);
  const [historyChanged, setHistoryChanged] = useState(false);

  const isMobile = useIsSmallScreen();

  const onChange = (e) => {
    const searchValue = e.target.value.trim();
    const isEmpty = searchValue.length === 0;

    setXVisible(!isEmpty);
    setHistoryChanged(false);

    if (!isMobile) {
      if (!isEmpty) {
        debouncedSearch(searchValue, kinds);
        debouncedAnalytics(searchValue);
      } else {
        debouncedSearch.cancel();
        debouncedAnalytics.cancel();
      }
    } else {
      debouncedSearch(searchValue, kinds);
      debouncedAnalytics(searchValue);
    }
  };

  const onKeyUp = (e) => {
    if (!inputRef.current.value && e.key === 'Backspace') {
      onClose({ keepFocus: true });
    }

    if (!inputRef.current.value && e.key === 'Escape') { // close search input on ESC
      onClose({ keepFocus: false, forceHide: true });
    }
  };

  const onClickX = () => {
    inputRef.current.value = '';
    setXVisible(false);
    onClose({ keepFocus: false, forceHide: true });
  };

  const handleBlur = () => {
    if (!inputRef.current.value) {
      onClose({ forceHide: true });
    }
  };

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
      const { length } = inputRef.current.value;
      inputRef.current.setSelectionRange(length, length); // put cursor at the end of the input
    }

    const popstateHandler = () => {
      setHistoryChanged(true);
    };

    window.addEventListener('popstate', popstateHandler);

    return () => window.removeEventListener('popstate', popstateHandler);
  }, [autoFocus]);

  useEffect(() => {
    if (!inputRef.current || !historyChanged) return;
    inputRef.current.value = query;
  }, [query, historyChanged]);

  useEffect(() => () => {
    debouncedSearch.cancel();
    debouncedAnalytics.cancel();
  }, []);

  return (
    <SearchWrapper className={className}>
      <InputWrapper>
        <SearchIconStyled size={iconSize} />
        <Box flex={1}>
          <Placeholder aria-hidden="true">
            {i18nContext.formatText('search')}
          </Placeholder>
          <Input
            type="text"
            placeholder={i18nContext.formatText('search')}
            defaultValue={query}
            autoFocus={autoFocus}
            innerRef={inputRef}
            onBlur={handleBlur}
            onChange={onChange}
            onKeyUp={onKeyUp}
            maxLength={100}
          />
        </Box>
        <StyledHidden when={!xVisible} preserveSpace>
          <IconButton onClick={onClickX}>
            <CloseIcon size={30} />
          </IconButton>
        </StyledHidden>
      </InputWrapper>
    </SearchWrapper>
  );
}

SearchInput.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  iconSize: PropTypes.number,
};

SearchInput.defaultProps = {
  onClose: () => null,
};

export default memo(SearchInput);
