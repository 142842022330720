import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  broadcastShape,
  channelShape,
} from 'reducers/epg';
import { useInterval } from 'utils/hooks';
import { analyticsShape } from 'components/Tracking';
import Broadcasts from './Broadcasts';
import ChannelList from './ChannelList';

const getDisabledChannels = (channels = [], broadcasts = []) => {
  const disabled = [];
  const currentTime = Math.floor(Date.now() / 1000);

  channels.forEach((ch) => {
    const isLiveAvailable = broadcasts
      .find(br => br.channelId === ch.id
        && br.start <= currentTime
        && br.stop >= currentTime)?.isLiveAvailable ?? true;

    if (!isLiveAvailable) disabled.push(ch.id);
  });

  return disabled;
};

const RECALCULATE_DISABLED_INTERVAL = 60 * 1000;

export default function Timeline({
  channels,
  broadcasts,
  currentTime,
  scrollLeft,
  onScrollLeftUpdate,
  scrollBarWidth,
  startTime,
  analytics,
  width,
  height,
  isRTL,
}) {
  const channelListRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollTopMax, setScrollTopMax] = useState(0);
  const [disabledChannels, setDisabledChannels] = useState([]);

  useEffect(() => {
    setDisabledChannels(getDisabledChannels(channels, broadcasts));
  }, []);

  useInterval(() => {
    setDisabledChannels(getDisabledChannels(channels, broadcasts));
  }, RECALCULATE_DISABLED_INTERVAL);

  const onScroll = (e) => {
    setScrollTop(e.scrollTop);
    setScrollTopMax(e.scrollHeight - e.clientHeight + scrollBarWidth);

    channelListRef.current?.setScrollTop(e.scrollTop);

    if (scrollLeft !== e.scrollLeft) {
      onScrollLeftUpdate(e.scrollLeft);
    }
  };

  const onWheel = (deltaY) => {
    const newValue = scrollTop + deltaY;
    const normalizedValue = deltaY > 0 ? Math.min(newValue, scrollTopMax) : Math.max(newValue, 0);
    setScrollTop(normalizedValue);
  };

  return (
    <>
      <Broadcasts
        startTime={startTime}
        currentTime={currentTime}
        channels={channels}
        broadcasts={broadcasts}
        scrollTop={scrollTop}
        scrollLeft={scrollLeft}
        onScroll={onScroll}
        height={height}
        width={width}
        isRTL={isRTL}
        analytics={analytics}
      />

      <ChannelList
        ref={channelListRef}
        channels={channels}
        onWheel={onWheel}
        disabled={disabledChannels}
      />
    </>
  );
}

Timeline.propTypes = {
  broadcasts: PropTypes.arrayOf(broadcastShape).isRequired,
  channels: PropTypes.arrayOf(channelShape).isRequired,
  startTime: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  scrollLeft: PropTypes.number,
  scrollBarWidth: PropTypes.number.isRequired,
  onScrollLeftUpdate: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  analytics: analyticsShape.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
