import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'reducers';
import { addDays } from 'date-fns';
import * as routerActions from 'router/actions';
import { RouterLocation } from 'router/location';
import { DATE_FORMAT } from 'utils/constants';
import {
  Usps,
  usePromoCodeValidator,
} from 'components/Payment';
import {
  Box,
  Text,
  TextDate,
  Price,
  LinkButton,
  ClientSideComponent,
} from 'components';
import { useI18n } from 'components/I18n';
import { TOfferPurchasable } from 'views/Checkout/types';
import { isTVOD } from 'utils/helpers';
import { formatEntitlementDuration } from 'utils/time';
import PromoCodeModal from '../PromoCodeModal';
import Discount from './Discount';

interface IOfferInfoProps {
  promoCode?: string;
  offer: TOfferPurchasable;
  price: number,
  trialDays: number,
  reducedPrice: number,
  campaignName?: string,
  usps?: string[],
  isPromoCodeAllowed: boolean,
  packageId: string,
  hasDiscount: boolean,
  recurringPeriod?: string,
  promoCodeExpiry?: string,
  date: Date,
}

function OfferInfo(props: IOfferInfoProps) {
  const {
    promoCode,
    offer,
    price,
    trialDays = 0,
    reducedPrice,
    campaignName,
    usps,
    isPromoCodeAllowed,
    packageId,
    hasDiscount,
    recurringPeriod,
    promoCodeExpiry,
    date,
  } = props;

  const i18n = useI18n();
  const dispatch = useDispatch();
  const {
    location,
  } = useSelector(state => ({
    location: state.router.location,
  }));
  const [showModal, setShowModal] = useState(false);
  const freeTrialExpiry = useRef(addDays(date, trialDays));

  const onPromoCode = (newPromoCode: string | undefined) => {
    dispatch(routerActions.push({
      ...location,
      query: {
        ...(location?.query || {}),
        promoCode: newPromoCode,
      },
    } as RouterLocation));
    setShowModal(false);
  };

  const validatePromocode = usePromoCodeValidator({
    offerId: packageId,
    onSuccess(result: any) {
      onPromoCode(result.promoCode);
    },
  });

  return (
    <Box
      p="large"
      sm-p="xlarge"
      borderRadius="paper"
      bg="paperBackground"
      fontSize="medium"
    >
      <Box mb="small" spaceBetween>
        <Box mr="small">
          <Text bold wordBreak="break-word">
            {offer.title}
          </Text>
        </Box>
        <Text
          id="payment.price"
          bold
          values={{
            period: recurringPeriod,
            price: (
              <Price key="price" price={price} currency={offer.currency} />
            ),
          }}
        />
      </Box>

      {!!trialDays && (
        <Box mb="small" spaceBetween>
          <Box mr="small">
            <Text
              id="checkout.freeTrialPrice"
              bold
              values={{
                date: (
                  <TextDate
                    key="date"
                    value={freeTrialExpiry.current}
                    options={DATE_FORMAT}
                  />
                ),
              }}
            />
          </Box>
          <Price
            price={0}
            currency={offer.currency}
            bold
          />
        </Box>
      )}

      <Usps
        mt="large"
        mb="fine"
        usps={offer.usps}
        type="bullet"
        size="small"
        alignItems="start"
      />

      {(offer.__typename === 'PassType' || offer.__typename === 'RentType') && offer.entitlementDurationSec && (
        <Box mb="medium">
          <Text
            fontSize="normal"
            id="checkout.offerDuration"
            values={formatEntitlementDuration(offer.entitlementDurationSec)}
          />
        </Box>
      )}

      {promoCode && (
        <Discount
          campaignName={campaignName || ''}
          promoCode={promoCode}
          price={price}
          usps={usps}
          reducedPrice={reducedPrice}
          currency={offer.currency}
          onRemoveDiscount={isPromoCodeAllowed ? () => onPromoCode(undefined) : undefined}
          validFrom={freeTrialExpiry.current}
          validTo={promoCodeExpiry}
          type={offer.__typename}
        />
      )}

      {hasDiscount && (
        <Box mt="xlarge" spaceBetween>
          <Box mr="small">
            <Text bold id="payment.total" />
          </Box>
          <Price
            price={trialDays ? 0 : reducedPrice}
            currency={offer.currency}
            bold
          />
        </Box>
      )}

      {isPromoCodeAllowed && !hasDiscount && (
        <Box>
          <LinkButton inline onClick={() => setShowModal(true)}>
            <Text
              id="checkout.promoCodeButton"
              bold
            />
          </LinkButton>

          {showModal && (
            <PromoCodeModal
              offer={offer}
              validatePromocode={validatePromocode}
              onClose={() => setShowModal(false)}
            />
          )}
        </Box>
      )}

      {(
        ((!!trialDays && !promoCodeExpiry) || promoCodeExpiry)
        && !isTVOD(offer.__typename)
      ) && (
        <Box mt="fine">
          <ClientSideComponent>
            <Text
              id="checkout.fullPriceApply"
              fontSize="small"
              values={{
                date: i18n.formatDate(promoCodeExpiry || freeTrialExpiry.current, DATE_FORMAT),
              }}
            />
          </ClientSideComponent>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(OfferInfo);
