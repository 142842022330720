import { SettingsReducerState } from 'server/middlewares/store-middleware/extractSettings';
import { SET_BRAND_LOGO, SettingActions } from 'actions/settings';

const defaultState: SettingsReducerState = {};

// we initialize this reducer directly through initial state
// eslint-disable-next-line max-len
export function settingsReducer(state = defaultState, action: SettingActions): SettingsReducerState {
  switch (action.type) {
    case SET_BRAND_LOGO: {
      return {
        ...state,
        brand: {
          ...state.brand,
          headerLogo: action.logo,
        },
      };
    }
    default:
      return state;
  }
}
