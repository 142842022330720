import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { BoxProps } from 'components';
import Select from './Select';

type SelectFieldOption = {
  value: string,
  localizedValue: string,
};

interface SelectFieldProps extends BoxProps {
  placeholder: string,
  value: string,
  error: string,
  name: string,
  disabled: boolean,
  options: SelectFieldOption[],
  onChange: (e: Event) => any,
  normalize: (val: string) => string,
}

const SelectFieldComponent = React.forwardRef(function SelectField(props: SelectFieldProps, ref) {
  const {
    placeholder,
    value,
    error,
    name,
    disabled,
    options,
    onChange,
    normalize,
  } = props;

  // disable rule due to false-positive https://github.com/react-hook-form/react-hook-form/issues/2887
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { setValue, trigger } = useFormContext();

  const handleChange = React.useCallback((e: Event) => {
    const input = e.target as HTMLInputElement;
    if (typeof normalize === 'function') {
      input.value = normalize(input.value);
    }

    setValue(name, input.value, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (typeof onChange === 'function') {
      return onChange(e);
    }

    return true;
  }, [name, normalize, setValue, onChange]);

  const handleBlur = () => {
    void trigger(name); // validate field
  };

  return (
    <Select
      name={name}
      input={{
        ref,
        value,
        onChange: handleChange,
        onBlur: handleBlur,
        name,
      }}
      placeholder={placeholder}
      disabled={disabled}
      meta={{
        error,
      }}
      options={options}
    />
  );
});

export default React.memo(SelectFieldComponent);
