import { sha512 } from 'js-sha512';
import logger from 'utils/logger';
import { extractCustomAdId } from './helpers';

const getTitle = viewable => (
  viewable.broadcastById?.title
  || viewable.schedule?.['0']?.title
  || viewable.title
);

const isMatchingAd = (ad, currentTime) => (
  currentTime >= ad.absStartTime && currentTime <= ad.absEndTime
);
const isTimeEqual = (first, second) => Math.floor(Math.abs(first - second)) === 0;

const POSITION_TIMEOUT = 1000;

const AD_TYPES = {
  POSTROLL: 'postroll',
};

// implementation of https://engineeringportal.nielsen.com/docs/DCR_Sweden_Video_Browser_SDK
export default class NielsenService {
  sdk = null;
  player = null;
  props = {};
  userId = '';

  contentMetadata = null; // always present when video playback starts
  adMetadata = null; // present only if ad is playing
  isStarted = false; // is service started
  isBuffering = false;
  ads = []; // all ads, uses to calculate content playheadPosition for VOD
  currentAd = null; // present only if ad is playing
  lastPosition = 0; // last content or ad position
  lastContentPosition = 0;
  contentLength = 0; // video.length - ads.length

  positionInterval = null; // sends position each sec

  prevMessage = { name: '', data: '' }; // last event that we send to the SDK
  optOutStatus = null;

  constructor({
    appId,
    instanceName,
    userEmail,
    player,
  }) {
    this.player = player;
    this.userId = sha512(userEmail);

    if (!this.sdk && window.NOLBUNDLE) {
      this.sdk = window.NOLBUNDLE.nlsQ(appId, instanceName, {
        ...(__DEVELOPMENT__ ? { nol_sdkDebug: 'debug', optout: 'true' } : null),
        hem_unknown: this.userId,
      });
    }
  }

  // run service
  start(props) {
    if (this.isStarted) {
      return;
    }

    this.props = props;
    this.isStarted = true;

    this.player.addEventListener('buffering', this.onBuffering);
    this.player.addEventListener('playing', this.onPlay);
    this.player.addEventListener('pause', this.onStop);
    this.player.addEventListener('streamEnded', this.onEnd);
    this.player.addEventListener('error', this.onEnd);
    this.player.addEventListener('dai:playing', this.onPlayAd);
    this.player.addEventListener('dai:ended', this.onEndAd);
    this.player.addEventListener('dai:allAds', this.saveAds);
    this.player.addEventListener('chromecastActivated', this.onChromecast);
    window.addEventListener('beforeunload', this.onEnd);
  }

  // stop service
  stop() {
    if (!this.isStarted) {
      return;
    }
    this.onEnd();
  }

  createAndSendContentMetadata() {
    if (!this.contentMetadata) {
      const { mmsOrigCode, viewable, broadcastId } = this.props;

      let length = 86400; // due to documentation for Live

      if (broadcastId) {
        const { streamDuration } = this.player.model;

        // streamDuration is the correct video length
        // usually 1-2 sec longer that viewable.stop-viewable.start
        // available only after manifest is loaded
        length = Math.round(streamDuration || (viewable.stop - viewable.start));

        // do next only now as we need streamDuration
        this.identifyPostRollAds();
        this.contentLength = Math.round(length - this.getVodAdsLength(length));
      }

      ((optout) => {
        this.player.model = this.player.model.merge({ optout });
      })(this.sdk.getOptOutStatus());

      this.contentMetadata = {
        type: 'content',
        assetid: this.props.broadcastId || `simulcast_${mmsOrigCode}`,
        clientpassparam: `origcode=${mmsOrigCode}`,
        length,
        program: viewable.title,
        title: getTitle(viewable),
        ispremiumcontent: 'no',
        isautoplay: this.props.broadcastId ? 'yes' : 'no',
        plugv: this.player.currentPlayer.model.hipsterPlayerVersion,
        playerv: 'Hipster Player',
        userid: this.userId,
      };
    }

    this.callSdk('loadMetadata', this.contentMetadata);
  }

  // main lifecycle function
  // called when video starts playing or after pause/buffering
  onPlay = () => {
    // prevents onPlay at the beginning of the video when buffering is not done yet
    if (this.positionInterval || this.isBuffering) return;

    // if we have an ad at the beginning of the video,
    // first dai:playing event is fired too late
    if (this.playAdAtTheBeginningIfNeeded()) {
      return;
    }
    if (!this.contentMetadata) this.createAndSendContentMetadata();
    // sends playhead position each second
    this.sendPosition();
    this.restartPositionInterval();
  };

  // start interval that will send playheadPosition each 1 sec
  restartPositionInterval = () => {
    this.clearPositionInterval();
    this.positionInterval = setInterval(this.sendPosition, POSITION_TIMEOUT);
  };

  // pause
  onStop = () => {
    // do not send "stop" before we start playing video
    if (!this.contentMetadata) return;
    this.clearPositionInterval();
    this.callSdk('stop');
  };

  // send end event to Nielsen and clear service
  onEnd = () => {
    if (!this.isStarted) {
      return;
    }

    // do not send 'end' after postroll ad
    // it is already called before ads
    if (!this.currentAd || this.currentAd.type !== AD_TYPES.POSTROLL) {
      // player.model.currentTime is incorrect if no internet/error
      const prevPosition = this.lastContentPosition;
      const newPosition = this.getPlayheadPosition();
      this.callSdk('end', newPosition <= 0 || this.adMetadata ? prevPosition : newPosition);
    }

    this.clearService();
  };

  clearService = () => {
    this.isStarted = false;
    this.isBuffering = false;
    this.contentMetadata = null;
    this.adMetadata = null;
    this.ads = [];
    this.currentAd = null;
    this.lastPosition = 0;
    this.lastContentPosition = 0;
    this.contentLength = 0;

    this.clearPositionInterval();

    this.player.removeEventListener('buffering', this.onBuffering);
    this.player.removeEventListener('playing', this.onPlay);
    this.player.removeEventListener('pause', this.onStop);
    this.player.removeEventListener('streamEnded', this.onEnd);
    this.player.removeEventListener('error', this.onEnd);
    this.player.removeEventListener('dai:playing', this.onPlayAd);
    this.player.removeEventListener('dai:ended', this.onEndAd);
    this.player.removeEventListener('dai:allAds', this.saveAds);
    this.player.removeEventListener('chromecastActivated', this.onChromecast);
    window.removeEventListener('beforeunload', this.onEnd);
  };

  onBuffering = () => {
    const {
      isBuffering,
      isPlaying,
    } = this.player.model;

    // `model.isBuffering` prop is sometimes incorrect but correct one when `buffering` event fired
    this.isBuffering = isBuffering;

    if (isBuffering) {
      this.onStop();
    } else if (isPlaying) {
      this.onPlay();
    }
  };

  // set ad.type = 'postroll' for ads at the end of the video
  identifyPostRollAds = () => {
    if (!this.ads.length) return;

    const { streamDuration } = this.player.model;
    let ad = this.ads[this.ads.length - 1];

    // check if we have a "postroll" ads
    if (ad.absEndTime >= streamDuration) {
      // we verify each ad from the end
      // is it postroll or not
      let nextAd = { absStartTime: ad.absEndTime }; // next ad in timeline
      for (let i = this.ads.length; i;) {
        i -= 1;
        ad = this.ads[i];
        if (isTimeEqual(ad.absEndTime, nextAd.absStartTime)) {
          ad.type = AD_TYPES.POSTROLL;
        } else {
          break;
        }
        nextAd = ad;
      }
    }
  };

  // save all ads from player
  saveAds = ({ detail: ads }) => {
    this.ads = [...ads];
  };

  // calculate ads length that were played before maxEndTime
  getVodAdsLength = (maxEndTime) => {
    let length = 0;
    this.ads.forEach((ad) => {
      if (!ad.absStartTime && ad.absEndTime > 0 && ad.absEndTime <= maxEndTime) {
        // ad is started in prev VOD
        length += ad.absEndTime;
        // normal ad
      } else if (ad.absStartTime >= 0 && ad.absEndTime <= maxEndTime) {
        length += ad.durationInSeconds;
      } else if (ad.absStartTime < maxEndTime && ad.absEndTime > maxEndTime) {
        // ad is too long and only partially showed in this VOD
        length += (maxEndTime - ad.absStartTime);
      }
    });

    return length;
  };

  // calculate playheadPosition
  // if VOD ad, return 0,1,2..{ad.length}
  // if VOD content, return 0,1,2..{content.length},
  //    this value do not include ads.length played before
  // if Live (ad or content), return player.currentTime, that is `new Date() - shiftFromLive`
  getPlayheadPosition = () => {
    const { currentTimeFloat } = this.player.model;

    // if VOD content - sum of ads duration that goes before currentTime
    // if VOD ad - ad start time
    let adShift = 0;

    if (this.props.broadcastId) {
      if (this.adMetadata) {
        // ad is playing
        adShift = this.currentAd.absStartTime;
      } else {
        // VOD content is playing
        adShift = this.getVodAdsLength(currentTimeFloat);
      }
    }
    // save position for case when we scroll during ad playing
    this.lastPosition = Math.round(currentTimeFloat - adShift);
    if (!this.adMetadata) {
      // save content position for end event
      this.lastContentPosition = this.lastPosition;
    }
    return this.lastPosition;
  };

  // detect if VOD starts from an ad
  playAdAtTheBeginningIfNeeded = () => {
    if (this.contentMetadata || this.currentAd) return false;

    const { currentTimeFloat } = this.player.model;
    const ad = this.ads.find(item => isMatchingAd(item, currentTimeFloat));

    if (ad) {
      // send ad metadata
      this.onPlayAd({ detail: { ad } });
    }
    return !!ad;
  };

  // fired when ad starts playing
  onPlayAd = ({ detail: { ad, afterSeek } }) => {
    if (!ad || ad === this.currentAd) return;

    // ad is playing at the beginning of the video
    if (!this.contentMetadata) {
      this.createAndSendContentMetadata();

    // new ad is playing after seek
    } else if (afterSeek) {
      // send last position when user scroll video
      this.callSdk('stop', this.lastPosition);

      // scrolling from postroll ad to some ad
      // we should send a contentMetadata
      // as end was called before
      if (this.currentAd?.type === AD_TYPES.POSTROLL
        && ad.type !== AD_TYPES.POSTROLL
      ) {
        // we need restart content
        this.createAndSendContentMetadata();
      }

      // viсe versa: scrolling from postroll to some ad
      if (this.currentAd?.type !== AD_TYPES.POSTROLL
        && ad.type === AD_TYPES.POSTROLL
      ) {
        // we should send an end before postroll ads
        this.callSdk('end', this.contentLength);
      }

    // ad was played before
    } else if (this.adMetadata) {
      // Send stop with ad.length(duration) for VOD because
      // if ad.duration = 20.4 and player position is 20.6
      // then it will send 21 but should be 20
      // For Live just regular playheadPosition eg 1636464057
      this.callSdk('stop', this.props.broadcastId ? this.adMetadata.length : null);

    // content was played before
    } else {
      // we should send an end before postroll ads
      // for regular ad send stop with current player position
      this.callSdk(ad.type === AD_TYPES.POSTROLL ? 'end' : 'stop', null);
    }

    this.adMetadata = {
      type: 'ad',
      assetid: extractCustomAdId(ad),
      length: Math.round(ad.durationInSeconds),
      isprogrammatic: 'yes',
      isthirdpartyad: 'yes',
      adplatformorigin: ad.adSystem,
      adidx: ad.index,
    };
    this.currentAd = ad;

    this.callSdk('loadMetadata', this.adMetadata);

    if (this.player.model.isPlaying) {
      this.sendPosition();
      this.restartPositionInterval();
    }
  };

  // fired when last ad of avail is ended
  onEndAd = ({ detail: { afterSeek } }) => {
    // prevents the wrong event from the previous video
    if (!this.adMetadata) return;

    this.callSdk('stop', afterSeek ? this.lastPosition : null);

    this.adMetadata = null;
    this.currentAd = null;

    this.createAndSendContentMetadata();

    if (this.player.model.isPlaying) {
      this.sendPosition();
      this.restartPositionInterval();
    }
  };

  onChromecast = ({ detail: { sessionId = '' } }) => {
    if (!this.sdk) return;

    this.optOutStatus = this.sdk.getOptOutStatus();

    const ottData = {
      ottStatus: '1',
      ottType: 'casting',
      ottDevice: 'chromecast',
      ottDeviceName: 'Google Chromecast',
      ottDeviceID: sessionId,
      ottDeviceManufacturer: 'Google',
    };

    const sendUpdateOTT = status => this.callSdk(
      'updateOTT',
      status ? { ottStatus: '0' } : ottData,
    );

    // if opt out is changed we send an updateOTT message
    const checkOptOut = () => {
      const newStatus = this.sdk.getOptOutStatus();

      if (newStatus !== this.optOutStatus) {
        this.optOutStatus = newStatus;
        sendUpdateOTT(newStatus);
      }
    };

    const interval = setInterval(checkOptOut, 60000);

    const stop = () => {
      sendUpdateOTT(true);
      clearInterval(interval);
      this.player.removeEventListener('chromecastDeactivated:before', stop);
      document.removeEventListener('visibilitychange', checkOptOut, false);
    };

    sendUpdateOTT(false);

    this.player.addEventListener('chromecastDeactivated:before', stop);
    document.addEventListener('visibilitychange', checkOptOut, false);
  };

  // send message to NielsenSDK
  callSdk = (name, options) => {
    const data = options ?? this.getPlayheadPosition();

    if (name === this.prevMessage.name && data === this.prevMessage.data) {
      if (__DEVELOPMENT__) {
        logger.debug(`NielsenService: duplicate message - ${name}`, data);
      }
      return;
    }

    if (__DEVELOPMENT__) {
      logger.debug(`NielsenService: ${name}`, data);
    }

    this.sdk.ggPM(name, data);
    this.prevMessage = { name, data };
  };

  clearPositionInterval = () => {
    if (this.positionInterval) {
      clearInterval(this.positionInterval);
      this.positionInterval = null;
    }
  };

  sendPosition = () => {
    this.callSdk('setPlayheadPosition');
  };
}

export const nielsenSdkScript = `
  <script>
    !function(t,n)
    {
      t[n]=t[n]||
      {
        nlsQ:function(e,o,c,r,s,i)
        {
         return s=t.document,
         r=s.createElement("script"),
         r.async=1,
         r.src=("http:"===t.location.protocol?"http:":"https:")+"//cdn-gl.imrworldwide.com/conf/"+e+".js#name="+o+"&ns="+n,
         i=s.getElementsByTagName("script")[0],
         i.parentNode.insertBefore(r,i),
         t[n][o]=t[n][o]||{g:c||{},
         ggPM:function(e,c,r,s,i){(t[n][o].q=t[n][o].q||[]).push([e,c,r,s,i])}},t[n][o]
        }
      }
    }

    (window,"NOLBUNDLE");
  </script>
`;
