import * as Types from '../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
export type TSubscribeOfferFieldsFragment = { __typename?: 'SubscribeType', priceInCents: any, currency: string, buttonText: string, recurringPeriod?: { __typename?: 'RecurringPeriodType', length: number, unit: Types.TRecurringPeriodUnitType } | null, trialPeriod?: { __typename?: 'TrialPeriodType', length: any, unit: Types.TTrialPeriodUnitType } | null };

export type TPassOfferFieldsFragment = { __typename?: 'PassType', priceInCents: any, currency: string, buttonText: string, entitlementDurationSec?: number | null };

export type TRentOfferFieldsFragment = { __typename?: 'RentType', priceInCents: any, currency: string, buttonText: string, entitlementDurationSec?: number | null };

export type TBuyOfferFieldsFragment = { __typename?: 'BuyType', priceInCents: any, currency: string, buttonText: string };

export type TOfferImagesFragment = { __typename?: 'KindImageType', url: string, isDefault: boolean, kind?: string | null };

export const SubscribeOfferFieldsFragmentDoc = gql`
    fragment subscribeOfferFields on SubscribeType {
  priceInCents
  currency
  recurringPeriod {
    length
    unit
  }
  trialPeriod {
    length
    unit
  }
  buttonText
}
    `;
export const PassOfferFieldsFragmentDoc = gql`
    fragment passOfferFields on PassType {
  priceInCents
  currency
  buttonText
  entitlementDurationSec
}
    `;
export const RentOfferFieldsFragmentDoc = gql`
    fragment rentOfferFields on RentType {
  priceInCents
  currency
  buttonText
  entitlementDurationSec
}
    `;
export const BuyOfferFieldsFragmentDoc = gql`
    fragment buyOfferFields on BuyType {
  priceInCents
  currency
  buttonText
}
    `;
export const OfferImagesFragmentDoc = gql`
    fragment offerImages on KindImageType {
  url
  isDefault
  kind
}
    `;