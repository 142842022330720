import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import logger from 'utils/logger';
import * as Sentry from '@sentry/react';

export default class ErrorHandler extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onError: PropTypes.func.isRequired,
  };

  hasError = false;
  state = {
    errorBoundaryKey: 0,
  };

  handleError = () => {
    this.hasError = true;
    this.props.onError();
  };

  componentDidCatch(error, info) {
    logger.error('ErrorHandler componentDidCatch', error, info);
    this.props.onError();
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children && this.hasError) {
      // there was an error, but now we want to render something (i.e. error page)
      // the only way to force ErrorBoundary re-render after the error
      // is to change its key, so lets do it
      this.hasError = false;
      this.incrementErrorBoundaryKey();
    }
  }

  incrementErrorBoundaryKey() {
    this.setState(({ errorBoundaryKey }) => ({ errorBoundaryKey: errorBoundaryKey + 1 }));
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      errorBoundaryKey,
    } = this.state;

    return (
      <Sentry.ErrorBoundary
        key={errorBoundaryKey}
        onError={this.handleError}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}
