const MIN_VISIBILITY = 0.2;

// [el -> handler]
const handlersMap = new Map();

const changeVisibilityCallback = (entries) => {
  entries.forEach((e) => {
    const handler = handlersMap.get(e.target);

    if (handler) {
      handler(e.isIntersecting);
    }
  });
};

let visibilityObserver;
// trigger callback if some component changes visibility
const init = () => {
  visibilityObserver = new IntersectionObserver(
    changeVisibilityCallback,
    { root: null, threshold: MIN_VISIBILITY },
  );
};

export default {
  add: (el, handler) => {
    if (!visibilityObserver) init();

    if (handlersMap.get(el)) return;

    handlersMap.set(el, handler);
    visibilityObserver.observe(el);
  },
  remove: (el) => {
    if (!visibilityObserver || !el) return;

    handlersMap.delete(el);
    visibilityObserver.unobserve(el);
  },
};
