import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import { Box } from '../StyledSystem/Box';

function ButtonWithSpinner({ showSpinner, spinnerSize, children, onClick, ...props }) {
  const Component = props.disabled ? 'div' : 'button';

  return ( // eslint-disable-next-line react/button-has-type
    <Component onClick={showSpinner ? null : onClick} {...props}>
      {showSpinner ? (
        <>
          <Spinner size={spinnerSize} />
          <Box display="none">{children}</Box>
        </>
      ) : (
        children
      )}
    </Component>
  );
}

ButtonWithSpinner.propTypes = {
  spinnerSize: PropTypes.number,
  showSpinner: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ButtonWithSpinner;
