import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { BROWSER_TYPES } from 'utils/constants';
import { PreviewPlayerContext } from 'components/PreviewPlayer';
import MuteButton from 'components/MuteButton';
import { useSelector } from 'reducers';
import storage from 'utils/storage';
import type MagineMediaPlayer from '@tvoli/hipster-player';
import { VideoContainer, MuteBtnContainer } from './Styles';
import playPriorityManager from './playPriorityManager';

export const VIDEO_ANIMATION_DURATION = 300;

interface PreviewPlayerProps {
  toggleImg: (state: boolean) => void;
  onTrailerEnd: () => void;
  important?: boolean;
  trailerUrl?: string;
  preferredBitrate?: string;
  trailers: {
    id: string;
    defaultPlayable: {
      id: string;
    }
  }[];
  zoom?: number;
}

const getPlayPauseControls = (
  player?: MagineMediaPlayer | HTMLVideoElement, important?: boolean,
) => ({
  play: () => void player?.play(),
  pause: () => void player?.pause(),
  important,
});

export default function PreviewPlayer(props: PreviewPlayerProps): JSX.Element {
  const {
    trailerUrl,
    trailers,
    toggleImg,
    onTrailerEnd,
    important,
    zoom = 100,
    preferredBitrate = 'medium',
  } = props;
  const [player, setPlayer] = useState<MagineMediaPlayer | undefined>();
  const playerRef = useRef<MagineMediaPlayer | undefined>();
  const playerId = useRef(`PreviewPlayer${Date.now()}`);
  const videoContainer = useRef<undefined | HTMLElement>();
  const updateVolume = useRef(() => {});
  const [visible, setVisible] = useState<boolean>(false);
  const [loadVideo, setLoadVideo] = useState<boolean>(false);
  const isBuffering = useRef(false);
  const { muted, toggleMute, pauseMiniPlayer } = useContext(PreviewPlayerContext);
  const {
    apiBaseUri,
    clientApiToken,
    sessionToken,
    defaultAudioTrack,
    muxKey,
    userId,
    partner,
    browser,
  } = useSelector(state => ({
    apiBaseUri: state.settings.apiBaseUri,
    clientApiToken: state.settings.clientApiToken,
    sessionToken: state.auth.sessionToken,
    defaultAudioTrack: state.settings.features.player.defaultAudioTrack,
    muxKey: state.settings.features.mux?.id,
    userId: state.auth.userId,
    partner: state.settings.partner,
    browser: state.common.browser,
  }), shallowEqual);

  playerRef.current = player;
  const isSafari = browser === BROWSER_TYPES.SAFARI;

  // Changes volume directly on video element and avoids storage
  updateVolume.current = () => {
    if (player?.playerElement) {
      const videoEl = player.playerElement as HTMLVideoElement;
      videoEl.volume = muted ? 0 : 1;
    }
  };

  const play = (viewableId: string, playableId: string) => {
    if (!player) return;

    void player.loadWithPreflight({
      accessToken: clientApiToken,
      ads: false,
      assetId: playableId,
      viewableId,
      authorizationKey: sessionToken,
      playbackStartTime: 0,
      preferredBitrate,
      ...(isSafari ? { preferredText: 'off' } : null),
    });
  };

  const muteHandler = useCallback((e: React.MouseEvent) => {
    toggleMute();

    e.stopPropagation();
    e.preventDefault();
  }, [player]);

  const handleClose = useCallback((callOnEnd: any) => {
    if (player && !player?.currentPlayer?.videoPlayer) {
      player.addEventListener('playerisinitialized', function handler() {
        void player.unload();
        player.removeEventListener('playerisinitialized', handler);
      });
    } else if (player) {
      void player.unload();
    }

    toggleImg(true);
    setVisible(false);
    if (callOnEnd && onTrailerEnd) onTrailerEnd();
  }, [player]);

  const onPlayHandler = useCallback(() => {
    pauseMiniPlayer();
    setVisible(true);
    toggleImg(false);

    // play/pause video depends on players' visibility and importance
    playPriorityManager.add(
      videoContainer.current,
      getPlayPauseControls(
        playerRef.current || videoContainer.current!.firstElementChild as HTMLVideoElement,
        important,
      ),
    );
  }, []);

  const initPlayer = (node: HTMLElement) => {
    if (player) {
      return player;
    }

    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const HipsterPlayer = require('@tvoli/hipster-player').default;

    const hipsterPlayer = new HipsterPlayer({
      baseUri: apiBaseUri,
      logLevel: __PRODUCTION__ ? 1 : 10,
      preferredAudio: storage.getItem('audioTrack') || defaultAudioTrack,
      playerContainer: node,
      muxKey,
      userId,
      partner,
      disableSAR: true,
    });
    // do not interact with the stored volume
    hipsterPlayer.currentPlayer.changeVolume = () => updateVolume.current();

    setPlayer(hipsterPlayer);

    return hipsterPlayer;
  };

  useEffect(() => {
    if (!videoContainer.current) return () => null;

    const el = videoContainer.current; // remember element to remove
    return () => playPriorityManager.remove(el);
  }, [!videoContainer.current]);

  // mute/unmute all Preview HP
  useEffect(() => {
    updateVolume.current();
  }, [muted]);

  useEffect(() => {
    if (player) {
      const trailer = trailers[0];
      play(
        trailer.id,
        trailer.defaultPlayable.id,
      );

      const onPlay = () => {
        if (!isBuffering.current && player.model.isPlaying) {
          onPlayHandler();
        }
      };
      const onBuffering = () => {
        // `model.isBuffering` prop is sometimes incorrect
        // but correct one when `buffering` event fired
        isBuffering.current = player.model.isBuffering;
        onPlay();
      };

      player.addEventListener('buffering', onBuffering);
      player.addEventListener('playing', onPlay);
      player.addEventListener('streamEnded', handleClose);

      return () => {
        player.removeEventListener('buffering', onBuffering);
        player.removeEventListener('playing', onPlay);
        player.removeEventListener('streamEnded', handleClose);
        handleClose(false);
      };
    }

    return () => {
      toggleImg(true);
    };
  }, [player]);

  useEffect(() => {
    if (trailerUrl) {
      // load hosted after end of show HS animation
      const timeout = setTimeout(() => setLoadVideo(true), VIDEO_ANIMATION_DURATION);
      return () => clearTimeout(timeout);
    }
    if (trailers?.length) {
      // load linked
      setLoadVideo(true);
    }
    return () => {};
  }, []);

  const playerContainerRef = useCallback((node: HTMLElement) => {
    if (node) {
      initPlayer(node);
      videoContainer.current = node;
    }
  }, []);

  return (
    <>
      { // Hosted trailer (more prio)
        loadVideo && trailerUrl && (
          <VideoContainer
            className="previewPlayer"
            id={playerId.current}
            innerRef={videoContainer}
            zoom={zoom}
          >
            <video
              autoPlay
              muted={muted}
              disablePictureInPicture
              disableRemotePlayback
              controlsList="nodownload"
              onPlay={onPlayHandler}
              onEnded={handleClose}
            >
              <source src={trailerUrl} />
            </video>
          </VideoContainer>
        )
      }
      { // Linked trailer[0]
        loadVideo && trailers?.length > 0 && !trailerUrl && (
          <VideoContainer
            className="previewPlayer"
            id={playerId.current}
            innerRef={playerContainerRef}
            zoom={zoom}
          />
        )
      }
      {
        visible && (
          <MuteBtnContainer className="muteBtnContainer">
            <MuteButton onClick={muteHandler} muted={muted} />
          </MuteBtnContainer>
        )
      }
    </>
  );
}
