import React, { useRef, useEffect, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getScrollableState } from 'reducers/scrollable';
import { setScrollableOffset, setScrollableSize, setClearScrollableOffset } from 'actions/scrollable';
import { responsiveRem2Px } from 'utils/helpers';
import { Wrapper, ItemWrapper, Container, StyledSpacer } from './Styles';

export { ItemWrapper };

// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
let passiveSupported = false;
try {
  const options = {
    get passive() {
      passiveSupported = true;
      return false;
    },
  };
  window.addEventListener('test', null, options);
  window.removeEventListener('test', null);
} catch (err) {
  passiveSupported = false;
}

const getVirtualItems = (size, width, removeItemLeftMargin, key = 'virtual') => {
  if (!size) return [];

  const items = [(
    <div key={key} style={{ display: 'inline-block', width: (size * width) - removeItemLeftMargin }} />
  )];

  return items;
};

const getRealItems = (items, from, to, isFullyLoaded) => {
  let arr = items;
  const indexFrom = from % items.length;
  const indexTo = indexFrom + (to - from);

  if (isFullyLoaded) {
    if (indexTo > items.length * 2) {
      // when amount of items is not enough to show 3 pages
      arr = [...items, ...items, ...items];
    } else if (indexTo > items.length) {
      // to show end and begin of the list
      arr = [...items, ...items];
    }
  }

  return arr.slice(indexFrom, indexTo);
};

const getCloneItems = (items, from, to) => {
  if (from === to) return [];

  return getRealItems(items, from, to, true)
    .map((item, i) => cloneElement(item, { key: `${item.key}-clone`, id: `cloned-${i}-${item.key}` }));
};

function HorizontalScroll({
  id,
  pageId,
  children,
  loadMore,
  hasMoreLoad,
  itemWidthRem = 0,
  itemWidthPx = 0,
  itemMarginLeftPx = 0,
  spacer = false,
}) {
  const {
    offset,
    width,
    fullWidth,
  } = useSelector(state => getScrollableState(state, pageId, id), shallowEqual);
  const showedItemsCount = useRef(0);
  const direction = useSelector(state => state.settings.l10n.direction);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const touchStart = useRef(null);
  const scheduleId = useRef(null);
  const delta = useRef(0);
  const animatedOffset = useRef(0);
  const clearOffset = useRef(null);
  const offsetModifier = direction === 'rtl' ? '+' : '-';
  const springProps = useSpring({
    transform: `translate3d(${offsetModifier}${offset}px, 0, 0)`,
  });
  const spacerRef = useRef();

  const [itemWidth, setItemWidth] = useState(
    Math.round(responsiveRem2Px(itemWidthRem)) + itemWidthPx,
  );

  const updateWidthFromDOM = () => {
    if (!containerRef.current) {
      return;
    }

    const newItemWidth = Math.round(responsiveRem2Px(itemWidthRem)) + itemWidthPx;
    if (newItemWidth !== itemWidth) {
      setItemWidth(newItemWidth);
    }

    const { offsetWidth: containerParentWidth } = containerRef.current.parentNode;
    const { offsetWidth: containerWidth } = containerRef.current;
    const { offsetWidth: spacerWidth = 0 } = spacerRef?.current || {};

    if (containerParentWidth === width && containerWidth === fullWidth) {
      return;
    }

    dispatch(setScrollableSize(pageId, id, containerParentWidth, containerWidth, children.length,
      newItemWidth, spacerWidth, itemMarginLeftPx));
  };

  const onWheel = (e) => {
    if (Math.abs(e.deltaY) > Math.abs(e.deltaX) && !e.shiftKey) {
      // Scrolled more vertically than horizontally therefore we
      // assume the user didn't mean to trigger a horizontal scroll.
      return;
    }

    // Adding Fix for RTL on web
    let diff;
    if (direction === 'ltr') {
      diff = e.deltaX || (e.shiftKey && e.deltaY) || 0;
    } else {
      diff = -e.deltaX || (e.shiftKey && e.deltaY) || 0;
    }

    if (diff) {
      dispatch(setScrollableOffset(pageId, id, animatedOffset.current + diff));
      e.preventDefault();
    }
  };

  const onTouchStart = (e) => {
    touchStart.current = e.touches[0]; // eslint-disable-line prefer-destructuring
  };

  const onTouchMove = (e) => {
    const newPos = e.touches[0];

    if (!touchStart.current) { // sometimes we receive touchmove event *without* touchstart
      touchStart.current = newPos;
      return;
    }

    const deltaY = touchStart.current.clientY - newPos.clientY;

    let deltaX;

    // Adding Fix for RTL on Mobile
    if (direction === 'ltr') {
      deltaX = touchStart.current.clientX - newPos.clientX;
    } else {
      // to revers the direction when RTL
      deltaX = newPos.clientX - touchStart.current.clientX;
    }

    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      // Scrolled more vertically than horizontally therefore we
      // assume the user didn't mean to trigger a horizontal scroll.
      return;
    }

    if (!deltaX) {
      return;
    }

    touchStart.current = newPos;
    delta.current += deltaX * 2;

    // prevent vertical scroll if any
    if (e.cancelable) {
      e.preventDefault();
    }

    if (scheduleId.current) {
      return;
    }

    scheduleId.current = window.setTimeout(() => {
      dispatch(setScrollableOffset(pageId, id, animatedOffset.current + delta.current));
      delta.current = 0;
      scheduleId.current = null;
    }, 0);
  };

  useEffect(() => {
    const wrapperEl = containerRef.current.parentNode;
    window.addEventListener('resize', updateWidthFromDOM, passiveSupported ? {
      passive: true,
    } : false);
    wrapperEl.addEventListener('touchmove', onTouchMove, passiveSupported ? {
      passive: false,
    } : false);

    updateWidthFromDOM();

    wrapperEl.addEventListener('wheel', onWheel, passiveSupported ? {
      passive: false,
      capture: true,
    } : true);

    return () => {
      window.removeEventListener('resize', updateWidthFromDOM);
      wrapperEl.removeEventListener('wheel', onWheel, true);
      wrapperEl.removeEventListener('touchmove', onTouchMove);

      if (clearOffset.current !== null && clearOffset.current !== offset) {
        dispatch(setClearScrollableOffset(pageId, id, clearOffset.current));
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(updateWidthFromDOM);
  }, [children]);

  useEffect(() => {
    animatedOffset.current = offset;
  }, [offset]);

  // Renders all items if items <= 2 screens
  // Renders only 2-3 screens if items > 2 screens
  // [(prev)] (current+1) (next)
  const getItems = (items) => {
    // full items per screen
    const itemsPerScreen = Math.floor(width / itemWidth);
    // max items to show
    // +1 - is for partially visible item
    let itemsCount = Math.floor(offset / itemWidth) + itemsPerScreen * 2 + 1;
    // how much items we show
    const needShowItemsCount = itemsPerScreen * (offset ? 3 : 2) + 1;

    if (hasMoreLoad && items.length < itemsCount) {
      loadMore?.();
    }

    // show all available items when
    // ssr or not enough items
    if (
      !width || !itemWidth
      || (!hasMoreLoad && (items.length < itemsPerScreen * 1.6 || items.length <= 3))
    ) {
      return [
        spacer && <StyledSpacer key={`spacer-${id}`} innerRef={spacerRef} />,
        ...items,
      ];
    }

    let spacerCount = 1;
    const itemsCountInNewCycle = itemsCount % items.length;
    // do not render items from the new cycle
    // until we achieve end of the list
    if (
      !hasMoreLoad && (
        (itemsCountInNewCycle && itemsCountInNewCycle < itemsPerScreen)
        || (itemsCountInNewCycle === itemsPerScreen && !(offset % itemWidth))
      )
    ) {
      itemsCount -= itemsCountInNewCycle;
      spacerCount = 0;
    }

    // update container size after render
    if (itemsCount !== showedItemsCount.current) {
      showedItemsCount.current = itemsCount;
      setTimeout(updateWidthFromDOM);
    }

    // unneeded prev items will be replaced by empty div
    const virtualAmount = Math.max(0, itemsCount - needShowItemsCount);

    // we need clone some items if items < 3 screen
    let cloneAmount = 0;
    if (!hasMoreLoad && offset && needShowItemsCount > items.length) {
      cloneAmount = needShowItemsCount % items.length || items.length;
    }

    // offset can be very big if user scrolls more than beginning of this list
    // items=10 offset=12 clearOffset=2
    clearOffset.current = offset % (items.length * itemWidth);

    return [
      spacer && <StyledSpacer key={`spacer-${id}`} innerRef={spacerRef} />,
      ...getVirtualItems(virtualAmount, itemWidth, itemMarginLeftPx),
      ...getRealItems(items, virtualAmount, itemsCount - cloneAmount, !hasMoreLoad),
      ...getCloneItems(items, itemsCount - cloneAmount, itemsCount),
      ...getVirtualItems(spacerCount, itemWidth, 0, 'spacer'),
    ];
  };

  return (
    <Wrapper onTouchStart={onTouchStart}>
      <Container innerRef={containerRef}>
        <animated.div style={springProps}>
          {getItems(children)}
        </animated.div>
      </Container>
    </Wrapper>
  );
}

HorizontalScroll.propTypes = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func,
  hasMoreLoad: PropTypes.bool,
  itemWidthRem: PropTypes.number,
  itemWidthPx: PropTypes.number,
  itemMarginLeftPx: PropTypes.number,
  spacer: PropTypes.bool,
};

export default React.memo(HorizontalScroll);
